// Customizable Area Start
import React, { ReactNode } from "react";
import KanbanBoardController, { Attachment, CustomPhase, IColumns, Task as ITask, PredefinedFormAttributes, Question, SectionsArray, SubQuestion, configJSON } from './KanbanBoardController';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { MoreVert } from "@material-ui/icons";
import { Avatar, AvatarGroup, Badge, Box, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Typography, Tooltip } from "@mui/material";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import { Button, Divider, InputAdornment, Tab, Tabs, TextField, styled } from "@material-ui/core";
import CommonPopover from "../../../components/src/CommonPopover";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CommonModal from "../../../components/src/CommonModal";
import CommonMessage from "../../../components/src/CommonMessage";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import InputField from "../../../components/src/InputField";
import CommonDatePicker from "../../../components/src/CommonDatePicker";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Task from "../../../blocks/tasks/src/Task.web";
import Loader from "../../../components/src/Loader.web";
import CommonAutoComplete from "../../../components/src/CommonAutoComplete";
import CommonSelect from "../../../components/src/CommonSelect";
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import CommonModalAction from "../../../components/src/CommonModalAction";
import CommonPageActions from "../../../components/src/CommonPageActions";
import { arrowArcLeft, arrowArcRight, cloudUpload, cloudUploadSvg, downloadFileIcon } from "./assets";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommonMultipleAnswerField from "../../../components/src/CommonMultipleAnswerField";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CanvasDraw from 'react-canvas-draw';
import { Skeleton } from "@material-ui/lab";

class KanbanBoard extends KanbanBoardController {

  renderPopoverMenu = () => {
    switch (this.state.menuType) {
      case "task-action": return <>
        {this.state.taskType === 'scratch' && <>
          <MenuItem data-test-id='rename-task' style={webStyles.menuItem} onClick={this.onClickRename} > {configJSON.Rename} </MenuItem>
          <MenuItem data-test-id='duplicate-task' style={webStyles.menuItem} onClick={this.onClickDuplicate} > {configJSON.Duplicate} </MenuItem>
        </>}
      </>
      case "column": return <>
        <MenuItem data-test-id='rename' style={webStyles.menuItem} onClick={this.renameColumn} > {configJSON.Rename} </MenuItem>
        <MenuItem data-test-id='delete' style={webStyles.menuItem} onClick={this.openDeleteTaskModal} > {configJSON.DeleteColumn} </MenuItem>
      </>
      case "memberList": return this.state.projectTaskDetails?.attributes.members.map((member) => (
        <MenuItem data-test-id='member-list' key={member.id} style={{ color: '#334155', fontSize: '14px', lineHeight: '22px', minHeight: 'auto', padding: '6px 16px' }} >
          <Avatar style={{ width: '24px', height: '24px', marginRight: '10px' }} alt={member.full_name} src={member.profile_image} />
          <Typography style={{ color: '#64748B', fontWeight: 400, fontSize: '12px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{member.full_name}</Typography>
        </MenuItem>
      ))
      case "add-member": return this.renderTaskDetailsPopover('Members',
        <Box style={{ minWidth: '250px' }}>
          <CommonAutoComplete
            fullWidth={true}
            data-test-id='assign-member'
            onChange={this.onChangeAutoComplete}
            value={this.state.autoCompleteValue}
            dataList={this.state.memberList.map((member) => ({ title: member.email, icon: member.profile_image }))}
            placeholder="Select Member" />
          {this.state.selectedMember && <>
            <Typography style={webStyles.memberWrapper}>Member</Typography>
            <Box sx={webStyles.selectedMemberWrapper} >
              <Avatar style={webStyles.avatar} src={this.state.selectedMember.profile_image} />
              <Box sx={{ marginLeft: '10px' }}>
                <Typography style={webStyles.selectedMemberEmail}>
                  {this.state.selectedMember.email}
                </Typography>
              </Box>
            </Box>
          </>}
        </Box>, null, true);
      case "date-picker": return this.renderTaskDetailsPopover('Date',
        <CommonDatePicker
          fullWidth
          disableToolbar
          disablePast
          InputProps={{ readOnly: true }}
          onClickOpen={this.openDatePicker}
          anchorEl={this.state.dueDateAnchorEl}
          onBlurClose={this.closeDatePickerOnBlur}
          onChangeDate={this.onChangeDueDate}
          error={Boolean(this.state.dueDateError)}
          helperText={this.state.dueDateError}
          data-test-id='due-date'
          label="Due date"
          asterisk
          value={this.state.tempLabelDate}
          placeholder="Select due date"
        />,
        this.renderModalActionBtns({ primary: { label: 'save', actionFn: () => this.onSaveDueDate() }, secondary: { label: 'cancel', actionFn: this.onClosePopOver } }), false);
      case 'attachment': return this.renderTaskDetailsPopover("Attachment",
        <Box>
          <Typography style={webStyles.attachFileFromComputer}>Attach a file from your computer</Typography>
          <input
            accept=".pdf,.xls,.xlsx,.jpg,.jpeg,.svg,.png"
            id="contained-button-file"
            data-test-id='file-upload'
            type="file"
            ref={this.fileUploadRef}
            style={{ display: 'none' }}
            onChange={this.handleChangeFiles}
          />
          {!this.state.attachment ? <label htmlFor="contained-button-file">
            <Button data-test-id='openBrowse' style={webStyles.chooseFileBtn} onClick={this.handleBrowseFiles} >
              <img style={{ marginRight: '5px' }} src={cloudUpload} alt="" /> Choose a file
            </Button>
          </label> :
            <Typography style={webStyles.selectedFile}>
              {this.state.attachment?.name}<CloseIcon onClick={this.removeAttachment} style={{ marginLeft: '5px', fontSize: '16px' }} />
            </Typography>}
          {this.state.attachmentError ? <Typography style={{ color: '#DC2626', fontSize: '12px', fontFamily: "'Inter', sans-serif", }}>{this.state.attachmentError}</Typography> : null}
        </Box>,
        this.renderModalActionBtns({ primary: { label: 'Insert', actionFn: this.finalAttachmentArray }, secondary: { label: 'Cancel', actionFn: this.onClosePopOver } }), false)
      case "change-member":
        return <>
          {this.state.memberList.map((member) => <MenuItem data-test-id="member" style={this.getMenuStyle(this.state.selectedMember?.full_name as string, member.full_name)} onClick={() => this.setNewMember(member)} >
            <Avatar src={member.profile_image} style={webStyles.changeMemberAvatar} />
            <Typography style={webStyles.changeMemberFullName} >
              {member.full_name}
            </Typography>
          </MenuItem>)}
        </>
      case "comment":
        return this.renderCommentPopover('PM Comment', this.renderCommentPopoverContent())
    }
  }
  renderTaskDetailsPopover = (title: string, content: ReactNode, actionBtns?: ReactNode, hideBottomDivider?: boolean) => {
    return <Box sx={webStyles.renderCommentWrapper}>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', fontFamily: "'Inter', sans-serif", }}>
        <Typography style={{ color: '#334155', fontSize: '18px', fontWeight: 700, lineHeight: '26px', fontFamily: "'Inter', sans-serif", }} >{title}</Typography>
        <IconButton data-test-id='closeIcon' aria-label="close" onClick={this.onClosePopOver}>
          <CloseIcon style={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box style={{ padding: '16px 40px' }}>
        {content}
      </Box>
      {!hideBottomDivider && <Divider />}
      {actionBtns && <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}> {actionBtns} </Box>}
    </Box>
  }

  renderCommentPopoverContent = () => {
    const questionData = this.state.commentQuestionData;
    let filledData = questionData?.comments as { id: number, content: string; type: string, question_attachment?: Attachment }[];
    let defaultAnswer = "No Comments";
    let sketchImage = questionData?.comments?.[0]?.question_attachment?.url;

    if (filledData?.length === 0) {
      return <Typography style={{ color: "#64748B", fontFamily: "'Inter', sans-serif", }}>{defaultAnswer}</Typography>;;
    }
    switch (this.state.commentQuestionData?.answer_type) {
      case 'long_answer':
      case "short_answer":
      case "phase4_tool2_dropdown":
        return <Typography style={{ fontFamily: "'Inter', sans-serif", color: "#64748B", }} >
          {filledData?.map((fieldInfo) => fieldInfo.content)}
        </Typography>
      case "stackholder_dropdown":
        return <Typography style={{ fontFamily: "'Inter', sans-serif", color: "#64748B", }} >
          {this.state.memberList.find((member) => member.email === filledData[0].content)?.full_name}
        </Typography>
      case "multiple_answer":
      case "multiple_with_relation":
        return <Box>
          <Box style={{ maxHeight: '215px', overflowY: 'auto' }} >
            {filledData?.map((fieldInfo, indx) => {
              if (fieldInfo?.type === 'default') {
                return <StyledInputField
                  style={{ marginBottom: '10px' }}
                  fullWidth
                  variant='standard'
                  value={fieldInfo.content}
                  InputProps={{
                    readOnly: true,
                    startAdornment:
                      <InputAdornment position="end">
                        <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }}>{questionData?.key ?? ''} {indx + 1} : &nbsp;</Typography>
                      </InputAdornment>
                  }}
                />
              }
            })}
          </Box>
          {filledData?.filter((fieldInfo, indx) => fieldInfo.type === 'relationship').map((fieldData) => <>
            <Typography style={{ color: '#64748B', fontWeight: 700, fontSize: '14px', lineHeight: '22px', margin: '6px 0', fontFamily: "'Inter', sans-serif", }}>Relationship:</Typography>
            <Typography style={{ color: '#1E293B', fontFamily: "'Inter', sans-serif", }} > {fieldData.content}</Typography>
          </>)}
        </Box>
      case "scale_value":
        return <Box style={{ width: '100%' }} >
          {filledData?.map((fieldInfo) => <RadioGroup data-test-id='scale-ans' style={{ flexDirection: 'row', width: '100%' }} value={fieldInfo.content} >
            {Array(10).fill(1).map((_numb, indx) => {
              return <FormControlLabel disabled={this.disablePredefinedFormFields()} value={indx + 1} control={<Radio icon={this.renderIcon()} checkedIcon={this.checkedIcon()} />} label={indx + 1} />
            })}
          </RadioGroup>)}
        </Box>
      case "sketch":
        return <Box sx={{ border: '1px solid #CBD5E1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={sketchImage} style={{ maxWidth: '100%', maxHeight: '300px' }} alt='sketch' />
        </Box>
    }
  }

  renderCommentPopover = (title: string, content: ReactNode) => {
    return <Box sx={webStyles.renderCommentWrapper}>
      <Box style={webStyles.renderCommentInnerWrapper}>
        <Typography style={webStyles.renderTitleWrapper} >{title}</Typography>
        <IconButton data-test-id='closeIcon' aria-label="close" onClick={this.onClosePopOver}>
          <CloseIcon style={{ color: '#334155' }} />
        </IconButton>
      </Box>
      <Divider />
      <Box style={{ padding: '16px' }}>
        {content}
      </Box>
    </Box>
  }

  renderNoTaskScreen = () => {
    return !this.state.isLoading ? <Box data-test-id='pageWrapper' style={webStyles.noTaskPageWrapper} >
      <Typography style={webStyles.noTaskText} >{configJSON.NoTask}</Typography>
      <Typography style={webStyles.noTaskSubtitle} >{configJSON.TryCreatingTasks}</Typography>
    </Box> : null
  }

  renderCard = (card: ITask, index: number) => {
    const isHidden = (card.status !== 'to_do' || card.task_type === 'predefined' || !this.isProjectManager()) ? configJSON.hidden : configJSON.visible;
    return <Draggable isDragDisabled={this.state.role === 'super_admin' || this.disableDrag()} draggableId={`${card.id}`} index={index} >
      {(provided) => (
        <Box
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          ref={provided.innerRef}
        >
          <Task
            taskProps={{
              taskId: card.id,
              title: card.title,
              dueDate: card.due_date,
              onClickTask: () => {
                if (this.isProjectOnHoldOrNotStarted()) {
                  this.showErrorForTaskClickOnHold()
                } else {
                  if (card.task_type === 'predefined') {
                    this.onClickPredefinedCard(card)
                  } else {
                    this.goToWithParams('TaskDetails', `${card.id}`)
                  }
                }
              },
              description: card.description,
              hideAction: isHidden,
              onClickThreeDots: this.isProjectNotRunning() ? undefined : (event) => this.handleOpenTaskActions(event, card.task_type),
              avatarProps: {
                full_name: card.member_full_name,
                image: card.member_profile_image
              }
            }}
            {...this.props}
          />
        </Box>
      )}
    </Draggable>
  }

  renderColumn = (column: IColumns, index: number, provided: DraggableProvided) => {
    return <Box style={webStyles.columWrapper}>
      <Box {...provided.dragHandleProps} sx={{ ...webStyles.columnName, cursor: (this.isProjectManager() && column.type === configJSON.TypeCustom) ? "move" : 'default' }} >
        {(this.state.tempCompareColumnId !== column.id || this.isEditOrAddModeOn() || (column.type === configJSON.TypeCustom && !this.state.editColumnMenu)) && <Typography style={webStyles.columnTitle} >{column.title}
          {column.count ? <Typography style={webStyles.count} > {this.formatForPad(`${column.count}`)}</Typography> : null}
        </Typography>}
        {column.title === "To Do" && <Box style={{ width: '100%' }} visibility={this.hideElement()}>
          <Tooltip onClose={this.closeAddTaskButtonPopover} open={this.state.openToolTip} title={this.state.toolTipMessage} slotProps={{ tooltip: { style: { background: 'rgb(51, 65, 85)', fontSize: '12px', fontFamily: "'Inter', sans-serif", textAlign: 'center', lineHeight: '18px' } }, arrow: { style: { color: 'rgb(51, 65, 85)' } } }} arrow>
            <Button onMouseEnter={this.openAddTaskButtonPopover} onMouseLeave={this.closeAddTaskButtonPopover} onClick={this.handleOpenStartAddNewTaskModal} style={webStyles.addTaskBtn} >
              <AddCircleOutlineRoundedIcon style={{ marginRight: '4px', fontSize: '16px' }} />
              <Typography style={{ fontWeight: 400, fontSize: '14px', fontFamily: "'Inter', sans-serif", }} >{configJSON.AddTask}</Typography>
            </Button>
          </Tooltip>
        </Box>}
        {column.type === configJSON.TypeCustom && (!this.state.editColumnMenu || !this.state.hide3dots || (column.id !== `custom_${this.state.openEditColumnId}`)) && <Box visibility={this.hideElement()}>
          <Tooltip onClose={this.closeColumnActionTooltip} open={this.state.columnActionTooltip} title={"can't perform any action"} slotProps={{ tooltip: { style: { background: 'rgb(51, 65, 85)', fontSize: '12px', fontFamily: "'Inter', sans-serif", textAlign: 'center', lineHeight: '18px' } }, arrow: { style: { color: 'rgb(51, 65, 85)' } } }} arrow>
            <IconButton onMouseEnter={this.openColumnActionTooltip} onMouseLeave={this.closeColumnActionTooltip} data-columnid={column.id} style={{ border: '1px solid #E2E8F0' }} data-columnname={column.title} onClick={this.handleColumnOpenPopOver} >
              <MoreVert />
            </IconButton>
          </Tooltip> </Box>}
        {column.id === this.state.tempCompareColumnId && this.state.editColumnMenu && <Box visibility={this.hideElement()} style={{ margin: '16px 0' }} >
          <input autoFocus value={this.state.tempColumnName} maxLength={20} onChange={this.onChangeColumnName} style={{ fontSize: '18px', backgroundColor: "transparent", border: 'none', outline: 'none', marginBottom: '10px' }} />
          <Box>
            <Button disabled={!this.state.tempColumnName} onClick={this.renameColumnName} style={{ backgroundColor: '#7C6AAA', color: 'white', textTransform: "capitalize", height: 'fit-content' }}>
              {this.renderAddColumnIcon()}  {configJSON.Rename}
            </Button>
            <IconButton onClick={this.closeAddEditColumnView} >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>}
      </Box>
      <Droppable droppableId={column.id} type={configJSON.TypeCard}>
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '10px', height: '100%', overflowY: 'auto', overflowX: 'hidden' }} >
            {column.cards.map((card, index) => (<React.Fragment key={card.id}> {this.renderCard(card, index)} </React.Fragment>))}
            {provided.placeholder &&
              <Draggable draggableId={configJSON.draggableIdPlaceholder} index={index} >
                {(provided) => (
                  <Box ref={provided.innerRef}>
                    {column.id !== configJSON.AddColumnId && <Box style={{ height: '126px', border: '1px dashed #BDB5D5', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px', borderRadius: '8px', backgroundColor: '#DEDAEA' }} >
                      <SystemUpdateAltRoundedIcon style={{ color: '#493777' }} /> <Typography style={{ color: '#493777', fontFamily: "'Inter', sans-serif", }} > {configJSON.MoveTaskHere} </Typography>
                    </Box>}
                  </Box>
                )}
              </Draggable>}
          </Box>
        )}
      </Droppable>
    </Box >
  }

  renderAddTaskModalContent = () => {
    // Add task modal where there are option to create from scratch or from predefined
    return <Box sx={webStyles.addTaskModalWrapper}>
      <Typography style={webStyles.addTaskModalTitle}>Add New Task</Typography>
      <Typography style={webStyles.addTaskModalSubTitle} >Please select a task from predefine task or Create from scratch task</Typography>
      <Box sx={webStyles.addTaskModalActionBtnWrapper} >
        <ModalSecondaryButton data-test-id='create-from-predefined' style={{ height: '160px', width: '100%', marginRight: '0' }} onClick={this.openCreatePredefinedTaskModal} >
          <Box>
            <BackupTableRoundedIcon style={{ fontSize: '60px', marginBottom: '20px' }} />
            <StyledTypography >Select from template</StyledTypography>
          </Box>
        </ModalSecondaryButton>
        <ModalSecondaryButton data-test-id='create-from-scratch' onClick={this.handleOpenCreateTaskFromScratchModal} style={{ height: '160px', width: '100%', marginRight: '0' }}>
          <Box>
            <AddCircleRoundedIcon style={{ fontSize: '60px', marginBottom: '20px' }} />
            <StyledTypography>Create Task from Scratch</StyledTypography>
          </Box>
        </ModalSecondaryButton>
      </Box>
    </Box>
  }

  renderAddTaskFromScratchModalContent = () => {
    //task from scratch first screen UI
    return <Box>
      <InputField
        fullWidth
        asterisk
        data-test-id='task-name'
        value={this.state.taskName}
        onChange={this.onChangeTaskTitle}
        error={Boolean(this.state.taskNameError)}
        inputProps={{ maxLength: 80 }}
        placeholder="Enter a Task name"
        helperText={this.state.taskNameError}
        style={{ marginBottom: '20px' }}
        label="Task Name"
      />
      <InputField
        fullWidth
        asterisk
        inputProps={{ maxLength: 1000 }}
        onChange={this.onChangeTaskDescription}
        data-test-id='task-description'
        error={Boolean(this.state.taskDescriptionError)}
        value={this.state.taskDescription}
        placeholder="Enter a Task Description"
        helperText={this.state.taskDescriptionError}
        style={{ marginBottom: '20px' }}
        label="Task Description"
        multiline
        minRows={4}
      />
      <CommonDatePicker
        fullWidth
        data-test-id='due-date'
        disableToolbar
        error={Boolean(this.state.dueDateError)}
        disablePast
        value={this.state.labelDate}
        helperText={this.state.dueDateError}
        style={{ marginBottom: '20px' }}
        InputProps={{ readOnly: true }}
        onChangeDate={this.onChangeScratchDueDate}
        onBlurClose={this.closeDatePickerOnBlur}
        onClickOpen={this.openDatePicker}
        asterisk
        label="Due Date"
        anchorEl={this.state.dueDateAnchorEl}
        placeholder="Select due date"
      />
      <InputField
        fullWidth
        label="Attachment"
        placeholder="Add attchment"
        readOnly
        style={{ marginBottom: '20px' }}
        endAdornment={<>
          <input
            accept=".pdf,.xls,.xlsx,.jpg,.jpeg,.svg,.png"
            id="contained-button-file"
            data-test-id='file-upload'
            type="file"
            ref={this.fileUploadRef}
            style={{ display: 'none' }}
            onChange={this.handleChangeFromSratchFileChange}
          />
          <label htmlFor="contained-button-file">
            <ModalSecondaryButton onClick={this.handleBrowseFiles} style={webStyles.browseBtn}>Browse</ModalSecondaryButton>
          </label>
        </>}
      />
      {this.state.projectManagerAttachmentData.length > 0 && this.state.projectManagerAttachmentData.map((attachment) => (< Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Box>
          <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{attachment.fileName}</Typography>
          <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{attachment.size}</Typography>
        </Box>
        <IconButton data-attachment-id={attachment.id} onClick={() => this.removeArrayAttachment(attachment.id)} >
          <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
        </IconButton>
      </Box>))
      }
      {
        this.state.subTasksArray.map((subtasks, indexNumber) => {
          return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <InputField
              fullWidth
              inputProps={{ maxLength: 80 }}
              data-test-id='sub-task'
              label={`Subtask - ${indexNumber + 1}`}
              value={subtasks.subtask}
              placeholder="Enter subtask"
              onChange={(text) => this.onChangeSubTask(text, subtasks.id)}
              error={Boolean(subtasks.error)}
              helperText={subtasks.error}
              style={{ marginBottom: '20px' }}
            />
            <IconButton data-test-id='delete-subtask' style={{ marginLeft: '10px' }} onClick={() => this.removeSubtask(subtasks.id)} >
              <DeleteOutlineRoundedIcon style={{ color: 'red', fontSize: '30px' }} />
            </IconButton>
          </Box>
        })
      }
      <Button data-test-id='add-subtask' onClick={this.addMoreSubTask} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', color: '#5B4595', width: '100%', textTransform: 'capitalize', textAlign: 'start', paddingLeft: '0' }}>
        <AddCircleOutlineRoundedIcon style={{ marginRight: "5px" }} /> {this.state.subTasksArray.length > 0 ? "Add more subtask" : "Add sub tasks"}
      </Button>
    </Box >
  }

  renderAddMemberContent = () => {
    return <>
      <CommonAutoComplete
        data-test-id='search-member'
        placeholder="Search and select member"
        label="Invite via Email"
        error={Boolean(this.state.noMemberSelectedError)}
        helperText={this.state.noMemberSelectedError}
        dataList={this.state.memberList.map((member) => ({ title: member.email, icon: member.profile_image }))}
        onChange={this.onChangeAutoComplete}
        value={this.state.autoCompleteValue}
      />
      {this.state.selectedMember && <Box sx={webStyles.addMemberAvatarWrapper} >
        <Avatar style={webStyles.addMemberAvatar} src={this.state.selectedMember.profile_image} />
        <Box sx={{ marginLeft: '10px' }}>
          <Typography style={webStyles.addMemberFullName} >
            {this.state.selectedMember.full_name}
          </Typography>
          <Typography style={webStyles.addMemberEmail}>
            {this.state.selectedMember.email}
          </Typography>
        </Box>
      </Box>}
    </>
  }

  renderSelectPhasesTool = () => {
    return <>
      <CommonSelect
        fullWidth
        mb="20px"
        data-test-id='phase'
        error={Boolean(this.state.phaseError)}
        helperText={this.state.phaseError}
        list={this.state.phaseList}
        placeholder="select phase"
        label="Select Phase"
        value={this.state.selectedPhase}
        onChange={this.onChangePhase}
      />
      <CommonSelect
        fullWidth
        data-test-id='tool'
        error={Boolean(this.state.toolError)}
        helperText={this.state.toolError}
        list={this.state.toolList}
        placeholder="select tool"
        label="Select Tool"
        value={this.state.selectedTool}
        onChange={this.onChangeTool}
      />
    </>
  }

  renderTaskDetailsModal = () => {
    return <Box>
      <Box style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: "wrap" }} >
        <Button data-test-id='members' onClick={this.openAddMemberPopover} style={webStyles.taskDetailsButtonWrapper}>
          <PersonOutlineRoundedIcon style={{ marginRight: '5px' }} /> Members
        </Button>
        <Button data-test-id='date' onClick={this.openDueDatePopover} style={webStyles.taskDetailsButtonWrapper}>
          <DateRangeRoundedIcon style={{ marginRight: '5px' }} /> Date
        </Button>
        <Button data-test-id='attachment' onClick={this.openAttachmentPopover} style={webStyles.taskDetailsButtonWrapper}>
          <AttachmentOutlinedIcon style={{ marginRight: '5px' }} /> Attachment
        </Button>
      </Box>
      <Box sx={webStyles.selectedMemberInPredefinedWrapper}>
        {this.state.selectedMember && <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={<CancelRoundedIcon onClick={() => this.setState({ selectedMember: null, autoCompleteValue: null })} style={{ color: '#5B4595', fontSize: '20px', backgroundColor: 'white', borderRadius: '50%' }} />}>
          <Avatar src={this.state.selectedMember?.profile_image} alt={this.state.selectedMember?.full_name} />
        </Badge>}
        {this.state.labelDate && <Typography style={webStyles.labelDateWrapper} >{this.formatDateInPredefined(this.state.labelDate)}</Typography>}
        {this.state.projectManagerAttachmentData.map((attachment) => <Typography style={webStyles.selectedFileName} >  {attachment.fileName} <CloseIcon data-test-id='remove-attachment' onClick={() => this.removeArrayAttachment(attachment.id)} style={{ fontSize: '16px', marginLeft: '5px' }} /> </Typography>)}
      </Box>
    </Box >
  }

  renderTaskDescription = () => {
    return <Box>
      <Typography style={webStyles.taskDescriptionTitle}>Description</Typography>
      <Typography style={{ color: '#64748B', fontSize: '16px', fontFamily: "'Inter', sans-serif", }}>{this.state.selectedDescription} </Typography>
    </Box>
  }

  renderModalContent = () => {
    switch (this.state.modalType) {
      case "delete":
        return {
          title: configJSON.ModalTitleDelete,
          content: <Typography style={{ fontSize: '20px', color: '#64748B', lineHeight: '32px', fontFamily: "'Inter', sans-serif", }} >{configJSON.ConfirmDeleteTask}</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.deleteColumn, label: configJSON.BtnDelete }}
            secondary={{ actionFn: this.closeModal, label: configJSON.BtnCancel }}
          />
        }
      case 'start-add-new-task':
        return {
          title: null,
          content: this.renderAddTaskModalContent(),
          actionBtns: null,
          contentBoxStyle: { padding: '0px 39px' },
          titleBoxStyle: { padding: '16px 16px 0 40px' }
        }
      case "create-from-scratch":
        return {
          title: 'Project Name',
          content: this.renderAddTaskFromScratchModalContent(),
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.handleAddTaskDetails, label: 'Assign' }}
            secondary={{ actionFn: this.clearFields, label: 'Cancel' }}
          />
        }
      case 'add-member':
        return {
          title: 'Assign Task',
          content: this.renderAddMemberContent(),
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.createTaskFromScratch, label: 'Invite' }}
            secondary={{ actionFn: this.clearFields, label: 'Cancel' }}
          />
        }
      case "delete-task":
        return {
          title: 'Delete task',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the task?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.deleteTask, label: 'Delete' }}
            secondary={{ actionFn: this.closeModal, label: 'Cancel' }}
          />
        };
      case "delete-default-phase":
        return {
          title: 'Delete Phase',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the Phase?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.removeDefaultPhases, label: 'Delete' }}
            secondary={{ actionFn: this.handleCloseDeleteConfirmationModal, label: 'Cancel' }}
          />
        };
      case "delete-custom-phase":
        return {
          title: 'Delete Phase',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the Phase?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.removeCustomPhase, label: 'Delete' }}
            secondary={{ actionFn: this.handleCloseDeleteConfirmationModal, label: 'Cancel' }}
          />
        };
      case "delete-custom-idea":
        return {
          title: 'Delete Idea',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the idea?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.deleteCustomIdeas, label: 'Delete' }}
            secondary={{ actionFn: this.handleCloseDeleteConfirmationModal, label: 'Cancel' }}
          />
        };
      case "delete-default-idea":
        return {
          title: 'Delete Idea',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the idea?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.deleteDefaultIdeas, label: 'Delete' }}
            secondary={{ actionFn: this.handleCloseDeleteConfirmationModal, label: 'Cancel' }}
          />
        };
      case "delete-custom-round":
        return {
          title: 'Delete Idea',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the round?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.deleteCustomRounds, label: 'Delete' }}
            secondary={{ actionFn: this.handleCloseDeleteConfirmationModal, label: 'Cancel' }}
          />
        };
      case "delete-default-round":
        return {
          title: 'Delete Idea',
          content: <Typography style={{ fontFamily: "'Inter', sans-serif", color: '#64748B', }} >Do you want to delete the round?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.deleteDefaultRounds, label: 'Delete' }}
            secondary={{ actionFn: this.handleCloseDeleteConfirmationModal, label: 'Cancel' }}
          />
        };
      case 'phases-tool':
        return {
          title: 'Select Phase',
          content: this.renderSelectPhasesTool(),
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.openTaskDescriptionModal, label: 'Done' }}
            secondary={{ actionFn: this.cancelPredefinedTask, label: 'Cancel' }}
          />
        };
      case "task-description":
        return {
          title: this.state.selectedPhaseName,
          hideTopDivider: true,
          contentBoxStyle: { padding: '0px 39px 16px' },
          titleBoxStyle: { padding: '16px 16px 0 40px' },
          content: this.renderTaskDescription(),
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.openPredefinedTaskform, label: 'Next' }}
            secondary={{ actionFn: this.cancelPredefinedTask, label: 'Cancel' }}
          />
        };
      case "add-predefined-task-details":
        return {
          title: this.state.selectedPhaseName,
          content: this.renderTaskDetailsModal(),
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{ actionFn: this.createPredefinedTask, label: 'Assign', disabled: !(this.state.selectedMember && this.state.dueDate) }}
            secondary={{ actionFn: this.cancelPredefinedTask, label: 'Cancel' }}
          />
        };
      case "remove-attachment":
        return {
          title: 'Remove Attachment',
          content: <Typography style={{ color: '#64748B', fontSize: '20px', fontFamily: "'Inter', sans-serif", }}>Do you want to remove this attachment?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{
              label: 'remove',
              actionFn: this.state.currentRemovingAttachmentType === 'PM' ? this.removePMArrayAttachment : this.removeParticipantArrayAttachment
            }}
            secondary={{
              label: 'cancel', actionFn: this.closeModal
            }} />
        }
      case "remove-phase5-attachment":
        return {
          title: 'Remove Attachment',
          content: <Typography style={{ color: '#64748B', fontSize: '20px', fontFamily: "'Inter', sans-serif", }}>Do you want to remove this attachment?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{
              label: 'remove',
              actionFn: this.removePhase5Attachment
            }}
            secondary={{
              label: 'cancel', actionFn: this.closeModal
            }} />
        }
      case "remove-sketch":
        return {
          title: 'Remove Sketch',
          content: <Typography style={{ color: '#64748B', fontSize: '20px', fontFamily: "'Inter', sans-serif", }}>Do you want to remove this sketch?</Typography>,
          actionBtns: <CommonModalAction
            data-test-id='common-action'
            primary={{
              label: 'remove',
              actionFn: this.removeSketchImage
            }}
            secondary={{
              label: 'cancel', actionFn: this.closeModal
            }} />
        }
      case "move-to-completed":
        return {
          title: 'Mark as Completed Task',
          content: <Typography style={{ color: '#64748B', fontSize: '20px', fontFamily: "'Inter', sans-serif", }} >Are you sure want to mark as completed task?</Typography>,
          actionBtns: <CommonModalAction data-test-id='common-action' secondary={{ label: 'No', actionFn: this.closeModal }} primary={{ label: 'Yes', actionFn: () => { this.setState({ isComplete: true }); this.updatePredefinedTaskDetails(); } }} />
        }
      default:
        return {
          title: '',
          content: '',
          actionBtns: null
        }
    }
  }

  renderIcon = () => <Box sx={webStyles.unCheckedIcon}></Box>

  checkedIcon = () => <Box sx={webStyles.checkedIcon}></Box>

  getActiveTabStyle = (value: number) => {
    return this.state.currentTab === value ? webStyles.activeTabStyle : webStyles.tabStyle
  }

  renerHiddenQuestionFormPhase6Tool1 = (question: Question, inputValue: string | null, error: string) => {
    const value = this.state.predefinedPMAnswer?.[question.id];
    let nameOfIdea = null;
    if (value?.length) {
      nameOfIdea = value[0].content;
    }
    return this.isProjectManager() && (this.state.taskDetails?.attributes.status === 'to_do' || this.state.isPredefinedCreation) ? <InputField
      data-test-id='hide-ans'
      style={{ marginBottom: '20px', marginTop: "-20px" }}
      inputProps={{ maxLength: 300 }}
      error={this.showShortAnswerErrors(question, error).error}
      disabled={this.disableShortAnswer(question)}
      iButtonClick={this.determineIButtonClick(question)}
      placeholder="Enter your answer here..."
      helperText={this.showShortAnswerErrors(question, error).erroMsg}
      value={nameOfIdea}
      label={""}
      onChange={(input) => this.handlePredefinedFormAnswer(question, input)}
    /> : <Typography style={{ ...webStyles.SectionName, color: 'rgb(51, 65, 85)', marginTop: '-18px' }} >{nameOfIdea}</Typography>
  }

  renderInput = (type: string, question: Question, questionIndex: number, subSectionLength?: string) => {
    // render inputs based on quesion type
    const value = (this.isProjectManager() && this.state.taskDetails?.attributes.status === 'to_do' || !this.state.selectedCardId) ? this.state.predefinedPMAnswer?.[question.id] : this.state.predefinedParticipantAnswer?.[question.id]
    let inputValue = null;
    let error = '';
    if (value?.length) {
      inputValue = value[0].content;
      error = value[0].error;
    }

    this.handleLastQuestionForPhase3(question);
    switch (type) {
      case "hide_question":
        return this.renerHiddenQuestionFormPhase6Tool1(question, inputValue, error);
      case 'short_answer':
        return <InputField
          data-test-id='short-ans'
          style={{ marginBottom: '20px' }}
          inputProps={{ maxLength: 300 }}
          disabled={this.disableShortAnswer(question)}
          error={this.showShortAnswerErrors(question, error).error}
          placeholder="Enter your answer here..."
          iButtonClick={this.determineIButtonClick(question)}
          label={this.renderShortQuestionLabel(question, questionIndex)}
          helperText={this.showShortAnswerErrors(question, error).erroMsg}
          value={this.renderShortQuestionValue(question, inputValue)}
          onChange={(input) => this.handlePredefinedFormAnswer(question, input)}
        />
      case 'long_answer':
        return <InputField
          data-test-id='long-ans'
          style={{ marginBottom: '20px' }}
          iButtonClick={this.determineIButtonClick(question)}
          disabled={this.disablePredefinedFormFields()}
          placeholder="Enter your answer here..."
          label={question.content}
          inputProps={{ maxLength: 1000 }}
          value={inputValue}
          multiline
          onChange={(input) => this.handlePredefinedFormAnswer(question, input)}
          minRows={4}
        />
      case 'scale_value':
        return <>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Typography style={{ color: '#334155', fontSize: '14px', fontWeight: 700, fontFamily: "'Inter', sans-serif", }} >{question.content}
              {this.determineIButtonClick(question) && <IconButton data-test-id='i' style={{ padding: '0 20px' }} onClick={this.determineIButtonClick(question)} > <InfoRoundedIcon style={{ color: '#64748B', width: '16px', height: '16px' }} /></IconButton>}
            </Typography>
            <RadioGroup data-test-id='scale-ans' style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }} value={inputValue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handlePredefinedFormAnswer(question, (event.target as HTMLInputElement).value)} >
              {Array(10).fill(1).map((_numb, indx) => {
                return <FormControlLabel
                  disabled={this.disablePredefinedFormFields()}
                  value={indx + 1}
                  control={<Radio icon={this.renderIcon()} checkedIcon={this.checkedIcon()} />}
                  label={indx + 1}
                />
              })}
            </RadioGroup>
          </FormControl>
        </>
      case "multiple_answer":
      case "multiple_with_relation":
        return <CommonMultipleAnswerField
          data-test-id='multiple-question'
          iButtonClick={this.determineIButtonClick(question)}
          placeHolderPrefix={question.key}
          disabled={this.disablePredefinedFormFields()}
          disabledAddMore={this.disabledAddMore(question.id)}
          onChangeDefaultField={(value, key) => this.handleChangeDefaultField(value, key, question)}
          addMoreFields={() => this.handleAddMoreFields(question.id)}
          removeField={(key) => this.handleRemoveField(key, question)}
          value={value?.length ? value.filter((fields) => fields.ans_type !== 'relationship') : undefined}
          relation={value?.filter((question) => question.ans_type === 'relationship')[0]}
          style={{ marginBottom: '20px' }}
          label={subSectionLength ? `${subSectionLength} ${question.content}` : question.content}
          isHavingRelation={question.answer_type === 'multiple_with_relation'}
          onLoadAddFields={() => this.handleAddeFieldsOnLoad(question)}
        />
      case "phase4_tool2_dropdown":
        return <CommonSelect
          fullWidth
          list={this.state.phase4dropdownList}
          data-test-id='phase4_tool2_dropdown'
          style={{ marginBottom: '20px' }}
          disabled={this.disablePredefinedFormFields()}
          placeholder="Select"
          iButtonClick={this.determineIButtonClick(question)}
          label={question.content}
          value={inputValue}
          onChange={(input) => this.handlePredefinedFormAnswer(question, input as string)}
        />
      case "sketch":
        return this.renderSketchInput(question)
      case "prototype":
        return this.renderPrototypeQuestion(question)
      case "stackholder_dropdown":
        return <CommonSelect
          fullWidth
          data-test-id='stake_holder'
          mb="20px"
          disabled={this.disabledDrodownforphase3(question)}
          placeholder="Select stake holder"
          error={Boolean(error)}
          helperText={error}
          iButtonClick={this.determineIButtonClick(question)}
          label={question.content}
          value={inputValue}
          list={this.state.memberList.map((member) => ({ label: member.full_name, value: member.email }))}
          onChange={(input) => this.handlePredefinedFormAnswer(question, input as string)}
        />
      case "gender_dropdown":
        return <CommonSelect
          fullWidth
          data-test-id='gender'
          style={{ marginBottom: '20px' }}
          disabled={this.disablePredefinedFormFields()}
          placeholder="Select gender"
          iButtonClick={this.determineIButtonClick(question)}
          label={question.content}
          value={inputValue}
          list={[{ label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Other", value: "other" }]}
          onChange={(input) => this.handlePredefinedFormAnswer(question, input as string)}
        />
    }
  }

  renderSketchInput = (question: Question) => {
    return <Box>
      <Typography style={{ color: '#0F172A', fontWeight: 700, marginBottom: "20px", fontFamily: "'Inter', sans-serif", }}>Draw a sketch that visualises the customer? {this.determineIButtonClick(question) && <IconButton data-test-id='i' style={{ padding: '0 20px' }} onClick={this.determineIButtonClick(question)} > <InfoRoundedIcon style={{ color: '#64748B', width: '16px', height: '16px' }} /></IconButton>} </Typography>
      <Box style={webStyles.tabSketchWrapper}>
        <Box sx={webStyles.tabsWrapper} >
          <StyledTabs
            TabIndicatorProps={{ hidden: true }}
            orientation="vertical"
            variant="scrollable"
            data-test-id='tabs'
            value={this.state.currentTab}
            onChange={this.handleChangeTab}
            aria-label="Vertical tabs example"
          >
            <StyledTab style={this.getActiveTabStyle(0)} label="Draw" {...this.renderA11yProps(0)} />
            <StyledTab style={this.getActiveTabStyle(1)} label="Upload" {...this.renderA11yProps(1)} />
          </StyledTabs>
        </Box>
        <Box style={{ width: '100%' }} >
          {this.state.currentTab === 0 && <Box>
            <Box style={{ margin: '15px 15px 20px 0' }}>
              <Box sx={{ marginLeft: 'auto', width: 'fit-content' }}>
                <Button data-test-id='undo' disabled={this.disablePredefinedFormFields()} onClick={this.handleUndoCanvas} style={webStyles.undoRedoBtn} ><img src={arrowArcLeft} /> </Button>
                <Button data-test-id='redo' disabled={this.disablePredefinedFormFields()} onClick={this.handleRedoCanvas} style={webStyles.undoRedoBtn} ><img src={arrowArcRight} /> </Button>
              </Box>
            </Box>
            <Box style={{ border: '1px solid #E2E8F0' }} >
              <Box style={{ position: 'relative' }}>
                {!this.state.isDrawing && (
                  <Typography variant="h6" sx={webStyles.drawHere} >
                    Draw here
                  </Typography>
                )}
                <CanvasDraw
                  hideGrid
                  disabled={this.disablePredefinedFormFields()}
                  data-test-id='canvas'
                  style={{ width: "calc(100%)" }}
                  ref={this.saveableCanvas}
                  onChange={() => this.handleSaveCanvas(question.id)}
                  canvasHeight={224}
                  brushColor="#000"
                  brushRadius={1}
                  lazyRadius={0}
                />
              </Box>
              <Divider style={{ color: 'E2E8F0' }} />
              <Button data-test-id='clear-canvas' disabled={this.disablePredefinedFormFields()} onClick={this.handleClearCanvas} style={webStyles.clearDrawing} >Clear</Button>
            </Box>
          </Box>}
          {this.state.currentTab === 1 && <Box style={{ width: '100%' }} >
            <Box style={webStyles.uplodComponentWrapper} >
              <Box sx={webStyles.upoadInnerWrapper}>
                <Box sx={webStyles.uploadCloudImageWrapper}>
                  <img style={{ width: '30px' }} src={cloudUploadSvg} alt="" />
                </Box>
                <Typography style={webStyles.uploadSketchText}>Upload your sketch file here</Typography>
                <input
                  accept=".jpg,.jpeg,.svg,.png"
                  id="contained-button-file"
                  type="file"
                  data-test-id='sketch-upload'
                  ref={this.sketchImageUploadRef}
                  disabled={this.disablePredefinedFormFields()}
                  onChange={() => this.sketchUploadFileChange(question)}
                  style={{ display: 'none' }}
                />
                <label htmlFor="contained-button-file">
                  <StyledPrimaryButton disabled={this.disablePredefinedFormFields()} data-test-id='sketch-browse' data-question={question.id} onClick={this.handleSketchImageBrowseFiles} style={webStyles.uploadBrowseBtn} >Browse</StyledPrimaryButton>
                </label>
              </Box>
            </Box>
          </Box>}
          <Box sx={webStyles.uploadAttachmentWrapper}>
            <Box sx={webStyles.uploadAttachmentInnerWrapper}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <div>
                  <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{this.state.sketchAttachment?.fileName}</Typography>
                  <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{this.state.sketchAttachment?.size}</Typography>
                </div>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {this.state.sketchAttachment && <ModalSecondaryButton onClick={this.openSketch} data-test-id='view-sketch' style={{ ...webStyles.browseBtn, borderRadius: '4px' }}>View</ModalSecondaryButton>}
                {!this.hideSketchAction() && <Box>
                  <IconButton data-test-id='remove-sketch' onClick={this.openRemoveSketchModal} >
                    <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
                  </IconButton>
                </Box>}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  }

  renderPrototypeQuestion = (question: Question) => {
    return <Box style={{ width: '100%' }} >
      <Typography style={{ color: '#5B4595', fontWeight: 700, marginBottom: '20px', textTransform: "uppercase", fontFamily: "'Inter', sans-serif", }}>Template - Instructions to use the tool :</Typography>
      <Box style={{ padding: '50px', backgroundColor: '#F8FAFC' }} >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ border: '2px solid #5B4595', borderRadius: '50%', height: '75px', width: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
            <img style={{ width: '30px' }} src={downloadFileIcon} alt="" />
          </Box>
          <Typography style={{ fontSize: '16px', color: '#64748B', marginBottom: '20px', fontFamily: "'Inter', sans-serif", }}>To see the intructions click download </Typography>
          <StyledPrimaryButton disabled={this.disablePredefinedFormFields()} data-test-id='download-template' onClick={this.downloadPhase5ToolPDF} style={{ marginRight: '0' }} >Download</StyledPrimaryButton>
        </Box>
      </Box>
      <Typography style={{ color: '#5B4595', fontWeight: 700, margin: '20px 0', textTransform: "uppercase", fontFamily: "'Inter', sans-serif", }}> compliant to the Template - Instructions :</Typography>
      <Box style={{ padding: '50px', backgroundColor: '#F8FAFC' }} >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ border: '2px solid #5B4595', borderRadius: '50%', height: '75px', width: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
            <img style={{ width: '30px' }} src={cloudUploadSvg} alt="" />
          </Box>
          <Typography style={{ fontSize: '16px', color: '#64748B', marginBottom: '20px', fontFamily: "'Inter', sans-serif", }}>Upload your sketch file here</Typography>
          <input
            accept=".pdf"
            id="contained-button-file"
            type="file"
            data-test-id='pdf-upload'
            ref={this.protoTypeAttachmentRef}
            disabled={this.disablePredefinedFormFields()}
            onChange={() => this.handleChangeProtoTypeFileChange(question)}
            style={{ display: 'none' }}
          />
          <label htmlFor="contained-button-file">
            <StyledPrimaryButton disabled={this.disablePredefinedFormFields()} data-test-id='template-browse' data-question={question.id} onClick={this.handleBrowsePrototypeFiles} style={{ marginRight: '0', width: '100%' }} >Upload</StyledPrimaryButton>
          </label>
        </Box>
      </Box>
      {this.state.protoTypeAttachment &&
        <Box sx={{ ...webStyles.participantAttachmentInnerWrapper, marginTop: '10px' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Box>
              <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{this.state.protoTypeAttachment?.fileName}</Typography>
              <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{this.state.protoTypeAttachment?.size}</Typography>
            </Box>
          </Box>
          <Box>
            <ModalSecondaryButton onClick={() => window.open(this.state.protoTypeAttachment?.url, 'blank')} disabled={this.state.protoTypeAttachment?.isLocal} style={{ ...webStyles.browseBtn, borderRadius: '4px' }}>View</ModalSecondaryButton>
            {((!this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'need_review' && this.state.taskDetails?.attributes.status !== 'completed') ||
              (this.isProjectManager() && this.state.taskDetails?.attributes.status === 'to_do') || !this.state.selectedCardId) && <IconButton data-test-id='answer-remove' onClick={this.openRemovePhase5AttachmentModal} >
                <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
              </IconButton>}
          </Box>
        </Box>}
      {this.state.participantProtoTypeAttachmentAnswer && this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'to_do' && <>
        <Divider style={{ margin: '30px 0 20px 0' }} />
        <Box>
          <Typography style={{ color: '#334155', fontSize: '18px', fontWeight: 700, marginBottom: '10px', fontFamily: "'Inter', sans-serif", }}>Participant Answer</Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '10px',
            padding: '10px 20px',
            backgroundColor: '#F8FAFC'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box>
                <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{this.state.participantProtoTypeAttachmentAnswer.fileName}</Typography>
                <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{this.state.participantProtoTypeAttachmentAnswer.size}</Typography>
              </Box>
            </Box>
            <Box>
              <ModalSecondaryButton onClick={() => window.open(this.state.participantProtoTypeAttachmentAnswer?.url, 'blank')} disabled={this.state.protoTypeAttachment?.isLocal} style={{ ...webStyles.browseBtn, borderRadius: '4px' }}>View</ModalSecondaryButton>
            </Box>
          </Box>
        </Box>
      </>}
    </Box>
  }

  renderAttachments = () => {
    return <Box style={{ display: 'flex', flexDirection: 'column' }} >
      <Typography style={webStyles.title} >Attachment</Typography>
      <input
        accept=".pdf,.xls,.xlsx,.jpg,.jpeg,.svg,.png"
        data-test-id='file-upload'
        id="contained-button-file"
        type="file"
        ref={this.PMAttachmentUploadRef}
        disabled={this.disablePredefinedFormFields()}
        style={{ display: 'none' }}
        onChange={this.ProjectManagerAttachmentFileChange}
      />
      {this.isProjectManager() && this.state.isEditMode && <label htmlFor="contained-button-file" style={{ width: 'fit-content', marginBottom: '20px' }}>
        <ModalSecondaryButton disabled={this.disablePredefinedFormFields() || this.state.projectManagerAttachmentData.length === 7} data-test-id='browse' style={{ width: '124px', height: '40px', marginRight: '0' }} onClick={this.handleProjectManagerBrowseFiles} >Browse</ModalSecondaryButton>
      </label>}
      {this.state.projectManagerAttachmentData.length > 0 ?
        <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }} >
          {this.state.projectManagerAttachmentData.map((attachment) => <Box>
            <Button disabled={attachment.isLocal} onClick={() => window.open(attachment.url, 'blank')} style={{ background: 'transparent', color: '#7C6AAA', border: '1px solid #7C6AAA', textTransform: 'capitalize', fontFamily: "'Inter', sans-serif", }}>
              {!attachment.isLocal && <SaveAltRoundedIcon style={{ fontSize: '20px', marginRight: '5px' }} />} {attachment.fileName}
            </Button>
            {(this.state.taskDetails?.attributes.status === 'to_do' && this.state.isEditMode && this.isProjectManager()) && <IconButton data-test-id='remove-attachment' onClick={() => this.openAttachmentRemoveConfirmation(attachment.id, 'PM')} > <CloseIcon style={{ color: '#334155' }} /> </IconButton>}
          </Box>)}
        </Box> :
        (!this.isProjectManager() || !this.state.isEditMode) && <Typography style={webStyles.value} >No Attachment</Typography>}
    </Box>
  }

  renderParticipantAttachment = () => {
    return <>{this.state.participantAttachmentData.length > 0 ?
      <>
        {this.isProjectManager() && <Typography style={{ ...webStyles.title, marginBottom: '10px' }} >Participant Attachments</Typography>}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {this.state.participantAttachmentData.map((attachment) => (< Box sx={webStyles.participantAttachmentWrapper}>
            <Box sx={webStyles.participantAttachmentInnerWrapper}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <AttachFileIcon />
                <div>
                  <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{attachment.fileName}</Typography>
                  <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{attachment.size}</Typography>
                </div>
              </Box>
              <Box>
                {(this.state.isEditMode) ? <ModalSecondaryButton onClick={() => window.open(attachment.url, 'blank')} disabled={attachment.isLocal} style={{ ...webStyles.browseBtn, borderRadius: '4px' }}>View</ModalSecondaryButton> : null}
                {this.state.isEditMode && !this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'need_review' && this.state.taskDetails?.attributes.status !== 'completed' && <IconButton data-test-id='answer-remove' onClick={() => this.openAttachmentRemoveConfirmation(attachment.id, 'PT')} >
                  <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
                </IconButton>}
              </Box>
            </Box>
          </Box>))}
        </Box>
      </> :
      this.isProjectManager() && (
        <>
          <Typography style={webStyles.title} >Participant Attachments</Typography>
          <Typography style={{ color: '#334155', fontWeight: 700, fontFamily: "'Inter', sans-serif", }}> No Attachment</Typography>
        </>
      )}</>
  }

  renderAnswerAttachment = () => {
    return !(!this.state.taskDetails?.attributes.is_reviewed && this.isProjectManager()) ? <Box style={{ display: 'flex', flexDirection: 'column', border: '1px solid #E2E8F0', backgroundColor: 'white', padding: '32px', borderRadius: '8px' }} >
      {!this.isProjectManager() && <InputField
        label="Attachment"
        fullWidth
        readOnly
        placeholder="Add attchment"
        style={{ marginBottom: '20px' }}
        endAdornment={<>
          <input
            accept=".pdf,.xls,.xlsx,.jpg,.jpeg,.svg,.png"
            id="contained-button-file"
            type="file"
            data-test-id='file-upload'
            ref={this.ParticipantAttachmentUploadRef}
            disabled={this.isAuthorized() ? this.state.taskDetails?.attributes.status !== 'in_progress' : true}
            onChange={this.AnswerFileChange}
            style={{ display: 'none' }}
          />
          <label htmlFor="contained-button-file">
            <ModalSecondaryButton onClick={this.handleParticipantBrowseFiles} disabled={this.isAuthorized() ? this.state.taskDetails?.attributes.status !== 'in_progress' || this.state.participantAttachmentData.length === 7 : true} style={webStyles.browseBtn}>Browse</ModalSecondaryButton>
          </label>
        </>}
      />}
      {this.renderParticipantAttachment()}
    </Box> : null
  }

  renderSkeletonForComments = () => {
    return <Box style={webStyles.skeletonWrapper} >
      <Box style={webStyles.skeletonProfileWrapper} >
        <Skeleton style={{ marginRight: '10px', height: '40px', width: '40px' }} animation="wave" variant="circle" />
        <Skeleton animation="wave" height={20} width={"30%"} style={{ marginBottom: 10 }} />
      </Box>
      <Skeleton animation="wave" height={16} width={"100%"} />
      <Skeleton animation="wave" height={16} width={"100%"} />
      <Skeleton animation="wave" height={16} width={"50%"} />
    </Box>
  }

  renderComments = () => {
    return <Box sx={webStyles.commentWrapper}>
      <Typography style={webStyles.commentLabel}>Comments</Typography>
      <Box>
        {this.state.allComments.length > 0 && <Box data-test-id='comment-wrapper' sx={{ maxHeight: '355px', overflowY: 'auto', margin: '10px 0 15px' }} onScroll={this.handleLoadCommentsOnScroll}>
          {this.state.allComments.map((comment, indx) => {
            return <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Avatar style={{ width: '24px', height: '24px' }} src={comment.attributes.account.profile_image} alt={comment.attributes.account.full_name} />
                <Typography style={{ color: '#0F172A', fontSize: '12px', fontWeight: 600, fontFamily: "'Inter', sans-serif", }} >{comment.attributes.account.full_name}</Typography>
                <Typography style={{ fontSize: '12px', color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{this.formatCommentTileTime(comment.attributes.created_at)}</Typography>
              </Box>
              <Typography style={{ color: '#64748B', fontSize: '12px', lineHeight: '18px', marginTop: '10px', whiteSpace: 'pre-wrap', fontFamily: "'Inter', sans-serif", }} >{comment.attributes.comment}</Typography>
              {indx + 1 !== this.state.allComments.length && <Divider style={{ margin: '10px 0' }} />}
            </>
          })}
          {this.state.isCommentLoading ? this.renderSkeletonForComments() : null}
        </Box>}
        {this.state.selectedCardId &&
          <InputField
            multiline
            data-test-id='comment'
            inputProps={{ maxLength: 300, style: { maxHeight: '100px', overflow: 'auto' } }}
            disabled={!this.isAuthorized()}
            value={this.state.comment}
            onChange={this.onChangeComment}
            placeholder="Add your comment here..."
            endAdornment={<ModalSecondaryButton disabled={this.disableComment()} onClick={this.addComment} style={{ ...webStyles.browseBtn, borderRadius: '4px' }}>Add</ModalSecondaryButton>}
          />
        }
      </Box>
    </Box>
  }

  renderTaskDetails = () => {
    return (
      <>
        <Box style={webStyles.taskDetailsWrapper} >
          <Box style={webStyles.taskDetailsInnerWrapper}>
            <Typography data-test-id='title' style={webStyles.mainLabel} >Task Details</Typography>
            <Box style={webStyles.taskDetailsubWrapper} >
              <Typography style={webStyles.title} >{"Phase Name"}</Typography>
              <Typography style={webStyles.value} >{this.state.predefinedForm?.attributes.phase_name}</Typography>
            </Box>

            <Box style={webStyles.taskDetailsubWrapper} >
              <Typography style={webStyles.title} >{"Tool Name"}</Typography>
              <Typography style={webStyles.value} >{this.state.predefinedForm?.attributes.tool_name}</Typography>
            </Box>

            <Box style={webStyles.flexColumn} >
              <Typography style={webStyles.title} >{"Task Due date"}</Typography>
              <Typography style={webStyles.value} >{this.formatDueDate(this.state.taskDetails?.attributes.due_date)}</Typography>
            </Box>
            <Divider />
            <Box style={webStyles.flexColumn} >
              <Typography style={webStyles.title} >Members</Typography>
              <Box style={webStyles.taskMemberWrapper} >
                <Button
                  disabled={true}
                  style={webStyles.addMemberButton} >
                  {this.state.selectedMember && <>
                    <Avatar src={this.state.selectedMember.profile_image} style={{ width: '24px', height: "24px", marginRight: "5px" }} />
                    <Typography style={webStyles.memberName} >
                      {this.state.selectedMember.full_name}
                    </Typography>
                  </>}
                </Button>
              </Box>
            </Box>
            <Divider />
            {this.renderAttachments()}
          </Box>
          {this.renderComments()}
          {this.renderTaskCommonActions()}

        </Box>

      </>
    )
  }
  renderNoTaskFound = () => {
    return <Box sx={webStyles.noProjectNoTaskWrapper} >
      <Box sx={webStyles.noTaskWrapper} >
        <Typography style={webStyles.noProjectTaskTitle} >No Tasks Found!</Typography>
        <StyledPrimaryButton style={{ fontWeight: 400, width: '200px' }} onClick={() => this.goToBlock('TaskProjects')} >Back to Projects</StyledPrimaryButton>
      </Box>
    </Box>
  }
  renderA11yProps = (index: any) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  renderQuestionList = (formAttributes: PredefinedFormAttributes | undefined) => {
    // render questions based on sections form or normal question list form
    const isPhase5 = formAttributes?.phase_name === 'Phase 5 -Prototype';
    const noSectionQuestions = formAttributes ? formAttributes.questions : [];
    const sectionQuestions = formAttributes && formAttributes?.sections?.length > 0 ? formAttributes.sections[0].questions : [];
    const questionsList = isPhase5 ? sectionQuestions : noSectionQuestions
    return formAttributes && formAttributes?.sections.length > 0 && !isPhase5 ?
      this.renderSectionWiseForm(formAttributes) :
      questionsList.map((question: Question, questionIndex: number) => {
        return <>
          {this.renderInput(question.answer_type, question, questionIndex)}
        </>
      })
  }

  renderSectionWiseForm = (formAttributes: PredefinedFormAttributes) => {
    switch (formAttributes.tool_name) {
      case "Tool 3 - Customer Journey Map":
        return this.renderPhase2Tool3(formAttributes)
      case "Tool 1 - Brainstorming":
        return this.renderPhase4Tool1(formAttributes)
      default: return formAttributes && formAttributes.sections.length > 0 && formAttributes.sections.map((section: SectionsArray) => {
        return <>
          <Typography style={webStyles.SectionName} >{section.name}</Typography>
          {
            section.questions.map((question: Question, questionIndex: number) => {
              return <>
                {this.renderInput(question.answer_type, question, questionIndex)}
              </>
            })
          }
        </>
      })
    }
  }

  renderPhase4Tool1 = (formAttributes: PredefinedFormAttributes) => {
    return <>
      {formAttributes && formAttributes.sections.length > 0 && formAttributes.sections.map((section: SectionsArray, sectionIndex: number) => {
        return <>
          <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography style={webStyles.SectionName} >{section.name} {this.numberToWords(sectionIndex + 1)}</Typography>
            {section.section_type === 'custom' && !this.hideCustomPhaseElements() && <IconButton data-test-id='default-round-delete' style={{ marginBottom: '10px' }} data-subsectionid={section.id} data-sectionquestionid={section.questions[0].id} onClick={this.openDeleteDefaultRound} >
              <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
            </IconButton>}
          </Box>
          {section.questions.map((question: Question, questionIndex: number) => {
            return <>
              {this.renderInput(question.answer_type, question, questionIndex)}
            </>
          })}
        </>
      })}

      {this.state.customRounds.map((round, customSectionIndex) => {
        return <>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography style={webStyles.SectionName} >{round.round} {this.numberToWords(formAttributes.sections.length + customSectionIndex + 1)}</Typography>
            <IconButton style={{ marginBottom: '10px' }} data-test-id='custom-round-delete' data-subsectionid={customSectionIndex} onClick={this.openDeleteCustomRound} >
              <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
            </IconButton>
          </Box>
          <CommonMultipleAnswerField
            data-test-id='multiple-question'
            disabled={true}
            value={round.subQuestion.fields}
            disabledAddMore={this.state.isPredefinedCreation}
            style={{ marginBottom: '20px' }}
            label={round.subQuestion.question}
            isHavingRelation={false}
          />
        </>
      })}
      {!this.hideCustomPhaseElements() && <Button data-test-id='add-rounds' onClick={this.addMoreRounds} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', color: '#5B4595', width: '100%', textTransform: 'capitalize', textAlign: 'start', paddingLeft: '0' }}>
        <AddCircleOutlineRoundedIcon style={{ marginRight: "5px" }} /> Add round
      </Button>}
    </>
  }

  renderPhase2Tool3 = (formAttributes: PredefinedFormAttributes) => {
    return <>
      {formAttributes.sections.map((section: SectionsArray, sectionIndex: number) => {
        const isSubSectionQuestion = section?.sub_sections?.length > 0 && section.section_type !== "custom";
        let renderQuestionsForPhase2Tool3;
        if (isSubSectionQuestion) {
          renderQuestionsForPhase2Tool3 = section.sub_sections.map((subSection, subSectionIndex) => {
            return <>
              <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                {!this.hideCustomPhaseElements() ?
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography style={webStyles.phase2Name}>Phase {subSectionIndex + 1}</Typography>
                    <InputField
                      fullWidth
                      data-test-id='default-phase-name'
                      placeholder="Enter phase name"
                      value={subSection.name}
                      style={{ marginRight: '10px' }}
                      error={Boolean(subSection.error)}
                      helperText={subSection.error}
                      onChange={(value) => this.handleChangeDefaultPhase(value, section, subSection, subSectionIndex)}
                    />
                  </Box> :
                  <Typography style={{ ...webStyles.SectionName, marginBottom: 0 }} > Phase {subSectionIndex + 1} - {subSection.name}</Typography>
                }
                {subSection.section_type === "custom" && !this.hideCustomPhaseElements() && <IconButton data-test-id='default-phase-delete' data-subsectionid={subSection.id} onClick={this.openDeleteDefaultConfirmPhase} >
                  <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
                </IconButton>}
              </Box>
              {subSection.questions.map((question, questionIndex: number) => {
                return this.renderInput(question.answer_type, question, questionIndex, sectionIndex === 1 && subSectionIndex > 1 ? `${subSectionIndex + 1}.${questionIndex + 1}` : undefined)
              })}
            </>
          })
        } else {
          renderQuestionsForPhase2Tool3 = section.questions.map((question, questionIndex: number) => {
            return <>
              {this.renderInput(question.answer_type, question, questionIndex)}
            </>
          })
        }
        return <>
          <Typography style={webStyles.SectionName} >{section.name}</Typography>
          {renderQuestionsForPhase2Tool3}
        </>
      })}
      {this.renderCustomPhases(formAttributes?.sections[1].sub_sections.length)}
    </>
  }

  renderCustomPhases = (subSectionLength: number,) => {
    return <>{this.state.customPhaseList.map((question: CustomPhase, mainQuestionIndex: number) => <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Typography style={{ color: '#5B4595', fontSize: '14px', fontWeight: 700, whiteSpace: 'nowrap', marginRight: '10px', textTransform: 'uppercase' }}>Phase {subSectionLength + mainQuestionIndex + 1} </Typography>
        <InputField
          placeholder="Enter phase name"
          data-test-id='custom-phase-name'
          value={question.value}
          style={{ marginRight: '10px' }}
          error={Boolean(question.error)}
          helperText={question.error}
          onChange={(value) => this.handleChangeCustomPhase(value, mainQuestionIndex)}
        />
        <IconButton data-test-id='custom-phase-delete' data-phaseIndex={mainQuestionIndex} onClick={this.openDeleteCustomConfirmPhase} >
          <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
        </IconButton>
      </Box>
      {
        question.questions.map((subQuestion: SubQuestion, subQuestionIndex: number) => {
          return <CommonMultipleAnswerField
            data-test-id='multiple-question'
            placeHolderPrefix={subQuestion.preFix}
            disabled={true}
            disabledAddMore={this.state.isPredefinedCreation}
            value={subQuestion.fields}
            style={{ marginBottom: '20px' }}
            label={`${subSectionLength + mainQuestionIndex + 1}.${subQuestionIndex + 1} ${subQuestion.question}`}
            isHavingRelation={false}
          />
        })
      }
    </>)}
      {!this.hideCustomPhaseElements() && <Button data-test-id='add-phase' onClick={this.addMorePhases} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', color: '#5B4595', width: '100%', textTransform: 'capitalize', textAlign: 'start', paddingLeft: '0' }}>
        <AddCircleOutlineRoundedIcon style={{ marginRight: "5px" }} /> Add Phase
      </Button>}
    </>
  }

  renderPredefinedForm = () => {
    const formAttributes = this.state.taskDetails ? this.state.taskDetails.attributes?.tool_details?.data?.attributes : this.state.predefinedForm?.attributes;
    return <><Box sx={webStyles.predefinedFormWrapper}>
      <Box sx={webStyles.predefinedInnerForm}>
        <Box sx={{ padding: '32px' }}>
          <Typography style={webStyles.phaseName}> {this.state.predefinedForm?.attributes.phase_name} </Typography>
          <Typography style={webStyles.toolName}> {this.state.predefinedForm?.attributes.tool_name} </Typography>
          {!this.state.isPredefinedCreation && this.isProjectManager() && <>
            <Box sx={{ margin: '32px 0' }}>
              <CommonDatePicker
                fullWidth
                disablePast
                asterisk
                label="Due date"
                data-test-id='pre-due-date'
                disabled={!this.isProjectManager() || this.disablePredefinedFormFields()}
                disableToolbar
                placeholder="Enter due date"
                InputProps={{ readOnly: true }}
                value={this.state.labelDate}
                onChangeDate={this.onChangeScratchDueDate}
                onBlurClose={this.closeDatePickerOnBlur}
                onClickOpen={!this.disablePredefinedFormFields() ? this.openDatePicker : () => { }}
                anchorEl={this.state.dueDateAnchorEl}
              />
            </Box>
            <Divider />
            <Box style={{ margin: '32px 0' }}>
              <Typography style={webStyles.title} >Members</Typography>
              <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} >
                <Button
                  disabled={!this.isProjectManager() || this.disablePredefinedFormFields()}
                  data-test-id='memberList'
                  onClick={this.handleChangeMemberList}
                  style={{ border: '1px solid #E2E8F0', height: '34px', borderRadius: '8px', textTransform: 'capitalize' }} >
                  {this.state.selectedMember && <>
                    <Avatar src={this.state.selectedMember.profile_image} style={{ width: '24px', height: "24px", marginRight: "5px" }} />
                    <Typography style={{ color: '#334155', fontSize: "12px", fontWeight: 700, lineHeight: '26px', fontFamily: "'Inter', sans-serif", }} >
                      {this.state.selectedMember.full_name}
                    </Typography>
                  </>}
                  <KeyboardArrowDownRoundedIcon style={{ color: "#94A3B8" }} />
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box style={{ margin: '32px 0 0' }}>
              {this.renderAttachments()}
            </Box>
          </>}
        </Box>
        <Divider />
        {this.renderPredefinedQuestion(formAttributes!)}
      </Box>
      {this.renderAnswerAttachment()}
      {this.state.isEditMode ? this.renderComments() : null}
    </Box>
      {this.renderTaskCommonActions()}
    </>
  }

  renderPredefinedQuestion = (formAttributes: PredefinedFormAttributes) => {
    return <Box data-test-id='form' sx={{ padding: '32px' }}>
      {formAttributes?.tool_name === 'Tool 2 - 2x2 Matrix' ? <>
        <Grid container columnSpacing={5}>
          {formAttributes.questions.map((question: Question, questionIndex: number) => {
            const breakPoint = !(questionIndex % 3) ? 12 : 6
            return <Grid item xs={12} sm={12} md={breakPoint} lg={breakPoint} xl={breakPoint}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {this.renderInput(question.answer_type, question, questionIndex)}
                {!(questionIndex % 3) && question.custom && !this.hideCustomPhaseElements() && <IconButton data-test-id='default-idea-delete' data-questionid={questionIndex} onClick={this.openDeleteDefaultIdea} >
                  <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
                </IconButton>}
              </Box>
            </Grid>
          })}
          {this.renderCustomIdeas(formAttributes)}
        </Grid>
      </> : this.renderQuestionList(formAttributes)}
    </Box>
  }

  renderCustomIdeas = (formAttributes: PredefinedFormAttributes) => {
    return <>{this.state.customIdeas.map((idea, index) => {
      const breakPoint = !(index % 3) ? 12 : 6
      return <Grid item xs={12} sm={12} md={breakPoint} lg={breakPoint} xl={breakPoint}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InputField
            disabled
            style={{ marginBottom: '20px' }}
            label={!(index % 3) ? `${idea.question} ${formAttributes.questions.length / 3 + index / 3 + 1}` : idea.question}
            placeholder={idea.placeHolder}
          />
          {!(index % 3) && <IconButton data-test-id='custom-idea-delete' data-questionid={index} onClick={this.openDeleteCustomIdea} >
            <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
          </IconButton>}
        </Box>
      </Grid>
    })}

      {!this.hideCustomPhaseElements() && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button data-test-id='add-Idea' onClick={this.addMoreIdeas} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', color: '#5B4595', width: '100%', textTransform: 'capitalize', textAlign: 'start', paddingLeft: '0' }}>
          <AddCircleOutlineRoundedIcon style={{ marginRight: "5px" }} /> Add Idea
        </Button>
      </Grid>}
    </>
  }

  renderPredefinedFlow = () => {
    // predefined details screen and question form screen
    return <Box sx={webStyles.renderPredefinedFlowWrapper}>
      {this.state.isEditMode || this.state.isPredefinedCreation ? this.renderPredefinedForm() : this.renderTaskDetails()}
    </Box>
  }
  renderTaskCommonActions = () => {
    return <CommonPageActions
      data-test-id='common-actions'
      ternary={this.getTernaryBtnData()}
      primary={{ actionFn: () => this.getPrimaryButtonData()?.actionFn(), label: this.getPrimaryButtonData()?.label, disabled: this.getPrimaryButtonData().disabled }}
      secondary={{ actionFn: () => this.getSecondaryButtonData()?.actionFn(), label: this.getSecondaryButtonData()?.label, disabled: this.getSecondaryButtonData().disabled }}
    />
  }

  renderElements = () => {
    return <Box style={webStyles.testElement} data-test-id='tool-tip' onMouseEnter={this.openAddTaskButtonPopover} onMouseLeave={this.closeAddTaskButtonPopover} >
      {this.buttonTestIdAndMethods().map((button, idnx) => (
        <Button key={idnx} data-test-id={button['data-test-id']} onClick={button.onClick}></Button>
      ))}
      <Button data-test-id='column-action-tool-tip' data-columnname={'To Do'} onMouseEnter={this.openColumnActionTooltip} onMouseLeave={this.closeColumnActionTooltip} onClick={this.handleColumnOpenPopOver}></Button>
      <Button data-test-id='add-column-tool-tip' onMouseEnter={this.openAddColumnTooltip} onMouseLeave={this.closeAddColumnTooltip} ></Button>
      <input type="file" accept=".pdf" ref={this.fileUploadRef} data-test-id='change-file' onChange={this.handleChangeFiles} />
      <input type="file" accept=".pdf" ref={this.ParticipantAttachmentUploadRef} data-test-id='answer-file' onChange={this.AnswerFileChange} />
      <input value={this.state.tempColumnName} data-test-id='column-name' onChange={this.onChangeColumnName} />
      <input data-test-id='scratch-file' onChange={this.handleChangeFromSratchFileChange} />
    </Box>
  }

  renderAddColumn = () => {
    return this.isProjectManager() && <Box style={webStyles.column}>
      <Box sx={webStyles.columnName} >
        {this.state.tempCompareColumnId !== 'add' && <Typography style={{ color: '#64748B', display: 'flex', alignItems: 'center', fontFamily: "'Inter', sans-serif", }} >{configJSON.AddNewColumn}</Typography>}
        {this.state.addColumnMenu && <Box visibility={this.hideElement()} style={{ margin: '16px 0' }} >
          <input ref={this.addColumnInputRef} onChange={this.onChangeColumnName} value={this.state.tempColumnName} style={webStyles.columnInput} />
          <Box>
            <Button onClick={this.addColumn} disabled={!this.state.tempColumnName.trim()} style={{ textTransform: "capitalize", backgroundColor: '#7C6AAA', color: 'white', height: 'fit-content', fontFamily: "'Inter', sans-serif", }}>{this.renderAddColumnIcon()}  {configJSON.AddColumn}</Button>
            <IconButton onClick={this.closeAddEditColumnView} ><CloseIcon /></IconButton>
          </Box>
        </Box>}
        {!this.state.addColumnMenu && <Box visibility={this.hideElement()}>
          <Tooltip onClose={this.closeAddColumnTooltip} open={this.state.addColumnTooltip} title={"can't add new column"} slotProps={{ tooltip: { style: { background: 'rgb(51, 65, 85)', fontSize: '12px', fontFamily: "'Inter', sans-serif", textAlign: 'center', lineHeight: '18px' } }, arrow: { style: { color: 'rgb(51, 65, 85)' } } }} arrow>
            <IconButton onMouseEnter={this.openAddColumnTooltip} onMouseLeave={this.closeAddColumnTooltip} onClick={this.openAddColumnView} data-columnid={'add'} style={{ border: '1px solid #E2E8F0' }} ><AddIcon /></IconButton>
          </Tooltip>
        </Box>}
      </Box>
    </Box>
  }

  render() {
    return <DashboardViewWrapper data-test-id='wrapper' {...this.props} onSearch={this.handleSearch}>
      {!this.state.isPredefinedFormFilling ? <>
        {this.state.projectId ? <Box sx={webStyles.pageWrapper} data-test-id='page-wrapper' >
          <Box sx={webStyles.kanbanviewWrapper} >
            <Typography style={webStyles.projectName} >{this.state.projectTaskDetails?.attributes.project_name}</Typography>
            <Typography style={webStyles.projectCreator} >{configJSON.CreatedBy} {this.state.projectTaskDetails?.attributes.creater_details.name} on {this.handleFormateProjectStartTime(this.state.projectTaskDetails?.attributes.created_at as string)} </Typography>
            <StyledAvatarGroup onMouseEnter={this.handleOpenMemberList} onMouseLeave={this.onClosePopOver} data-test-id='avatar-group' style={{ width: 'fit-content' }} spacing={"medium"} max={4}>
              {this.state.projectTaskDetails?.attributes.members.map((member) => <Avatar alt={member.full_name} key={member.id} style={{ width: '24px', height: '24px', textTransform: 'capitalize', fontWeight: 700 }} src={member.profile_image} />)}
            </StyledAvatarGroup>
          </Box>
          <Box sx={webStyles.kanbanWrapper}>
            {this.state.columns.length > 0 ? <Box style={{ width: `${(this.state.columns.length + (this.isProjectManager() ? 1 : 0)) * 288 + (this.state.columns.length - 1) * 20}px`, height: '100%' }} >
              <DragDropContext data-test-id='context' onDragEnd={this.onDragEnd} >
                <Droppable data-test-id='all-columns' droppableId={configJSON.DrapppableColId} direction="horizontal" type="COLUMN">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ display: 'flex', gap: '20px', height: '100%' }}>
                      {this.state.columns.map((column, index: number) =>
                        <Draggable isDragDisabled={this.isDragDisabled() || this.isDefaultColumn(column.title)} key={column.id} draggableId={column.id} index={index}>
                          {(provided) => (<>
                            <Box
                              style={{ height: '100%' }}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              data-test-id="searchedTask"
                            >
                              {this.renderColumn(column, index, provided)}
                            </Box>
                          </>)}
                        </Draggable>
                      )}
                      {this.renderAddColumn()}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box> : this.renderNoTaskScreen()}
          </Box>
        </Box> :
          this.renderNoTaskFound()
        } </> :
        <>
          {this.renderPredefinedFlow()}
        </>
      }
      <Loader loading={this.state.isLoading} />
      <CommonMessage data-test-id="message" open={this.state.openMessage} onClose={this.handleCloseMessage} messageType={this.state.messageType} message={this.state.message} />
      <CommonPopover
        data-test-id='popover'
        anchorEl={this.state.anchorEl}
        paperStyle={this.state.paperStyle}
        isOnHover={this.state.menuType === 'memberList'}
        menuList={this.renderPopoverMenu()}
        onClose={this.onClosePopOver}
      />
      <CommonModal
        data-test-id='modal'
        open={this.state.openModal}
        hideTopDivider={this.state.modalType === 'start-add-new-task' || this.renderModalContent().hideTopDivider}
        titleBoxStyle={this.renderModalContent().titleBoxStyle}
        contentBoxStyle={this.renderModalContent().contentBoxStyle}
        modalTitle={this.renderModalContent().title}
        closeIcon
        actionComponent={this.renderModalContent().actionBtns}
        onClose={this.cancelPredefinedTask}
        hideBottomDivider={this.state.modalType === 'start-add-new-task'} >
        {this.renderModalContent().content}
      </CommonModal>
      {this.renderElements()}
    </DashboardViewWrapper>
  }
}

export default KanbanBoard;

const webStyles = {
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: "100%",
    width: 'calc(100vw - 320px)',
    '@media(max-width:960px)': {
      width: '100%',
    }
  },
  kanbanWrapper: {
    height: "calc(100vh - 364px)",
    width: 'calc(100vw - 320px)',
    overflowX: 'auto',
    minHeight: '450px',
    '@media(max-width:960px)': {
      width: '100%',
    }
  },
  count: {
    backgroundColor: '#D9B051',
    lineHeight: '32px',
    height: '32px',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '100px',
    padding: "0px 16px",
    color: 'white',
    marginLeft: '20px'
  },
  tabSketchWrapper: {
    display: 'flex',
    backgroundColor: '#F8FAFC',
    alignItems: 'flex-start',
    '@media(max-width:900px)': {
      flexDirection: 'column'
    }
  },
  undoRedoBtn: {
    borderRadius: '0',
    border: '1px solid #E2E8F0',
    marginRight: '20px',
    fontFamily: "'Inter', sans-serif",
    minWidth: 'auto'
  },
  clearDrawing: {
    textTransform: 'capitalize',
    color: '#868686',
    fontSize: '14px',
    marginLeft: 'auto',
    fontFamily: "'Inter', sans-serif",
    display: 'block'
  } as React.CSSProperties,
  uplodComponentWrapper: {
    border: '1px solid #E2E8F0',
    padding: '50px'
  },
  upoadInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  testElement: { display: 'none' },
  uploadCloudImageWrapper: {
    border: '2px solid #5B4595',
    borderRadius: '50%',
    height: '75px',
    width: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  uploadSketchText: {
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
    color: '#64748B',
    marginBottom: '20px'
  },
  uploadBrowseBtn: {
    marginRight: '0',
    width: '100%',
    fontFamily: "'Inter', sans-serif",
  },
  uploadAttachmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 0'
  } as React.CSSProperties,
  uploadAttachmentInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    justifyContent: 'space-between',
    width: '100%'
  },
  participantAttachmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  participantAttachmentInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  feedbackTitle: {
    color: '#334155',
    fontSize: '18px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    marginBottom: '10px'
  },
  feedbackDescription: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    fontWeight: 700
  },
  taskDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '8px'
  } as React.CSSProperties,
  taskDetailsInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    fontFamily: "'Inter', sans-serif",
    border: '1px solid #E2E8F0',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px'
  } as React.CSSProperties,
  taskDetailsubWrapper: {
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,
  taskMemberWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  addMemberButton: {
    border: '1px solid #E2E8F0',
    height: '34px',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '8px',
    textTransform: 'capitalize'
  } as React.CSSProperties,
  memberName: {
    color: '#334155',
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    lineHeight: '26px'
  },
  SectionName: {
    color: '#5B4595',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '22px',
    textTransform: 'uppercase',
    marginBottom: '20px'
  } as React.CSSProperties,
  predefinedFormWrapper: {
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    flexDirection: 'column',
    gap: '20px'
  },
  predefinedInnerForm: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: 'white'
  },
  phaseName: {
    color: '#334155',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px'
  },
  toolName: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: '16px'
  },
  renderPredefinedFlowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  menuItem: {
    color: '#334155',
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '22px',
    minHeight: 'auto'
  },
  selectedMemberWrapper: {
    display: "flex",
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: '#DEDAEA',
    padding: '13px 15px',
    borderRadius: '8px'
  },
  avatar: {
    width: '32px',
    fontFamily: "'Inter', sans-serif",
    height: '32px',
    border: '1px solid #DEDAEA'
  },
  selectedMemberEmail: {
    color: '#475569',
    fontWeight: 400,
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px'
  },
  memberWrapper: {
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
    margin: '10px 0',
  },
  attachFileFromComputer: {
    color: '#334155',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    marginBottom: '5px'
  },
  chooseFileBtn: {
    border: '1px solid #7C6AAA',
    color: '#7C6AAA',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: 'white',
    textTransform: "capitalize",
    height: '34px'
  } as React.CSSProperties,
  selectedFile: {
    border: '1px solid #7C6AAA',
    padding: '6px 8px',
    fontFamily: "'Inter', sans-serif",
    color: 'white',
    backgroundColor: '#7C6AAA',
    textTransform: "capitalize",
    fontSize: '12px',
    width: 'fit-content',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center'
  } as React.CSSProperties,
  value: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: '16px'
  },
  changeMemberAvatar: {
    width: '24px',
    fontFamily: "'Inter', sans-serif",
    height: "24px",
    marginRight: "5px"
  },
  changeMemberFullName: {
    color: '#334155',
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    lineHeight: '26px'
  },
  renderCommentWrapper: {
    borderRadius: '16px',
    width: '450px',
    '@media(max-width:600px)': {
      width: '100%'
    }
  },
  renderCommentInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    justifyContent: 'space-between',
    padding: '16px'
  },
  renderTitleWrapper: {
    color: '#334155',
    fontSize: '18px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    lineHeight: '26px'
  },
  noTaskPageWrapper: {
    width: '100%',
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  } as React.CSSProperties,
  taskDetailsButtonWrapper: {
    backgroundColor: '#7C6AAA',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    height: '44px'
  } as React.CSSProperties,
  selectedMemberInPredefinedWrapper: {
    marginTop: '10px',
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '20px'
  },
  labelDateWrapper: {
    color: '#493777',
    fontFamily: "'Inter', sans-serif",
    border: '1px solid #493777',
    fontSize: '12px',
    padding: '10px 8px',
    borderRadius: '4px',
    width: 'fit-content'
  },
  selectedFileName: {
    color: '#493777',
    border: '1px solid #493777',
    fontSize: '12px',
    padding: '10px 8px',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '4px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center'
  },
  unCheckedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid #64748B',
    borderRadius: '50%'
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '50%',
    backgroundColor: '#5B4595'
  },
  drawHere: {
    position: 'absolute',
    top: '50%',
    fontFamily: "'Inter', sans-serif",
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: 'rgba(0,0,0,0.3)',
    pointerEvents: 'none'
  },
  title: {
    color: '#334155',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px'
  },
  mainLabel: {
    color: '#334155',
    fontWeight: '700',
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px',
    lineHeight: '26px',
  },
  browseBtn: {
    padding: '0',
    fontSize: '14px',
    width: '98px',
    fontFamily: "'Inter', sans-serif",
    marginRight: 0,
    height: '34px',
    fontWeight: 400
  },
  addTaskBtn: {
    backgroundColor: '#D9B051',
    marginLeft: 'auto',
    display: 'flex',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '100px',
    padding: '0 12px',
    textTransform: 'capitalize',
    height: '32px'
  } as React.CSSProperties,

  columnTitle: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  tabsWrapper: {
    marginRight: '20px',
  },
  tabStyle: {
    justifyContent: 'flex-start',
    background: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize',
    color: '#94A3B8'
  } as React.CSSProperties,

  activeTabStyle: {
    justifyContent: 'flex-start',
    fontFamily: "'Inter', sans-serif",
    borderLeft: "4px solid rgb(91, 69, 149)",
    background: 'linear-gradient(92.7deg, rgba(222, 218, 234, 0) 0%, #BFB2E8 100%)',
    textTransform: 'capitalize',
    color: '#5B4595',
  } as React.CSSProperties,

  columnName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    fontFamily: "'Inter', sans-serif",
    cursor: 'move',
    padding: '0 16px',
    boxSizing: 'border-box',
    minHeight: '56px',
    boxShadow: "0px 4px 8px 0px #00000008",
    backgroundColor: 'white'
  },
  timeStamp: {
    color: '#64748B',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    whiteSpace: 'nowrap',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E2E8F0',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px'
  } as React.CSSProperties,

  noTaskText: {
    color: '#241C3C',
    fontSize: '30px',
    lineHeight: '40px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    textAlign: 'center'
  } as React.CSSProperties,

  noTaskSubtitle: {
    color: '#94A3B8',
    lineHeight: '24px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontWeight: 400
  },

  columWrapper: {
    width: '288px',
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    flexDirection: 'column',
    gap: "20px",
    height: '100%'
  } as React.CSSProperties,

  addTaskModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center'
  },

  addTaskModalTitle: {
    color: '#5B4595',
    fontSize: '20px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '28px'
  },

  addTaskModalSubTitle: {
    color: '#94A3B8',
    fontSize: '15px',
    lineHeight: '34px',
    fontFamily: "'Inter', sans-serif",
    textAlign: 'center',
    marginBottom: '20px'
  } as React.CSSProperties,

  addTaskModalActionBtnWrapper: {
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center',
    gap: '15px',
    width: '100%'
  },

  addMemberAvatar: {
    width: '32px',
    height: '32px',
    fontFamily: "'Inter', sans-serif",
    border: '1px solid #DEDAEA'
  },

  addMemberAvatarWrapper: {
    display: "flex",
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center',
    marginTop: '20px'
  },

  addMemberFullName: {
    color: '#0F172A',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px'
  },

  addMemberEmail: {
    color: '#475569',
    fontWeight: 400,
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px'
  },

  taskDescriptionTitle: {
    color: '#334155',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px'
  },

  kanbanviewWrapper: {
    padding: '32px',
    borderRadius: '12px',
    border: "1px solid #E2E8F0",
    boxShadow: "0px 2px 8px 0px #00000014",
    backgroundColor: 'white'
  },

  projectName: {
    color: '#5B4595',
    fontFamily: "'Inter', sans-serif",
    fontSize: '24px',
    fontWeight: 700
  },

  projectCreator: {
    color: '#94A3B8',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    marginBottom: '10px'
  },

  column: {
    width: '288px',
    display: 'flex',
    flexDirection: 'column',
    gap: "20px",
    height: '100%'
  } as React.CSSProperties,

  columnInput: {
    fontSize: '18px',
    backgroundColor: "transparent",
    border: 'none',
    fontFamily: "'Inter', sans-serif",
    outline: 'none',
    marginBottom: '10px'
  },

  noProjectNoTaskWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center',
    justifyContent: 'center'
  } as React.CSSProperties,

  noTaskWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center'
  },

  noProjectTaskTitle: {
    color: '#241C3C',
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    textAlign: 'center',
    marginBottom: '10px'
  } as React.CSSProperties,

  commentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E2E8F0',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px'
  },
  commentLabel: {
    color: '#334155',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
  },
  skeletonWrapper: {
    width: '100%',
    height: "100px",
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 10px 0px'
  } as React.CSSProperties,

  skeletonProfileWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  phase2Name: {
    color: '#5B4595',
    fontSize: '14px',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    marginRight: '10px',
    textTransform: 'uppercase'
  } as React.CSSProperties
}

const StyledTypography = styled(Typography)({
  '&.MuiTypography-root': {
    fontSize: "16px",
    lineHeight: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    color: '#5B4595',
    whiteSpace: "nowrap",
    '@media(max-width:600px)': {
      fontSize: '12px',
      whiteSpace: 'normal'
    }
  }
})

const StyledTabs = styled(Tabs)({
  '&.MuiTabs-root': {
    width: '205px',
    fontFamily: "'Inter', sans-serif",
    '@media(max-width:900px)': {
      width: '100%',
      marginBottom: '20px'
    }
  }
})

const StyledTab = styled(Tab)({
  '& .MuiTab-wrapper': {
    alignItems: 'flex-start',
    fontFamily: "'Inter', sans-serif",
  },
  '@media(max-width:900px)': {
    width: '100%',
  }
})

const StyledInputField = styled(TextField)({
  fontFamily: "'Inter', sans-serif",
  '& .MuiInput-underline:hover::before': {
    borderBottom: "1px solid #CBD5E1"
  },
  "& .MuiInput-underline:hover": {
    borderBottom: "1px solid #CBD5E1",
  },
  "& .MuiInput-underline::before": {
    display: 'none',
  },
  '& .MuiInput-underline::after': {
    borderWidth: '1px'
  },
  "& .MuiInput-underline.Mui-focused": {
    borderBottom: "1px solid #CBD5E1",
  },
  '& .MuiInput-underline': {
    borderBottom: "1px solid #CBD5E1",
  },
  "& .MuiInputBase-root": {
    paddingLeft: "0px",
    fontFamily: "'Inter', sans-serif",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    fontFamily: "'Inter', sans-serif",
  },
  '& .MuiInput-underline.Mui-focused::after': {
    display: 'none',
  },
  '&.MuiInput-underline.Mui-error:after': {
    borderBottom: '1px solid #f44336'
  }
});

const StyledAvatarGroup = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    width: 24,
    height: 24,
  },
  '& .MuiAvatarGroup-avatar': {
    width: 24,
    height: 24,
    fontSize: '12px',
    color: '#7C6AAA',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },
});

export const StyledPrimaryButton = styled(Button)({
  color: 'white',
  backgroundColor: '#5B4595',
  textTransform: "capitalize",
  height: '56px',
  width: '120px',
  fontWeight: 700,
  fontFamily: "'Inter', sans-serif",
  fontSize: '16px',
  padding: '16px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#5B4595'
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#64748B',
    backgroundColor: "#F1F5F9",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

export const ModalSecondaryButton = styled(Button)({
  height: '56px',
  color: '#5B4595',
  backgroundColor: '#DEDAEA',
  fontWeight: 700,
  fontFamily: "'Inter', sans-serif",
  textTransform: "capitalize",
  padding: '16px',
  width: '120px',
  borderRadius: '8px',
  fontSize: '16px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: '#DEDAEA'
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#64748B',
    backgroundColor: "#F1F5F9",
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '16px',
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

// Customizable Area End
// Customizable Area End

import React from 'react';;
import { Box, Button, styled } from "@mui/material"

interface Props {
    primary?: { actionFn: () => void, label: string, disabled?: boolean }
    secondary?: { actionFn: () => void, label: string, disabled?: boolean }
}
const CommonModalAction = (props: Props) => {
    const { primary, secondary } = props;
    return <Box data-test-id='modal-action' style={{ display: 'flex', justifyContent: 'space-between' }}>
        {secondary ? <StyledSecondaryButton onClick={secondary.actionFn} disabled={secondary.disabled} data-test-id='cancel' >{secondary.label}</StyledSecondaryButton> : null}
        {primary ? <StyledPrimaryButton onClick={primary.actionFn} disabled={primary.disabled} data-test-id='done' >{primary.label}</StyledPrimaryButton> : null}
    </Box>
}

export default CommonModalAction;


export const StyledPrimaryButton = styled(Button)({
    color: 'white',
    backgroundColor: '#5B4595',
    textTransform: "capitalize",
    height: '56px',
    width: '120px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    fontSize: '16px',
    padding: '16px',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#5B4595'
    },
    '&.MuiButton-root.Mui-disabled': {
        color: '#64748B',
        backgroundColor: "#F1F5F9",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px'
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})

const StyledSecondaryButton = styled(Button)({
    height: '56px',
    color: '#5B4595',
    backgroundColor: '#DEDAEA',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    padding: '16px',
    width: '120px',
    borderRadius: '8px',
    fontSize: '16px',
    marginRight: '20px',
    '&:hover': {
        backgroundColor: '#DEDAEA'
    },
    '&.MuiButton-root.Mui-disabled': {
        color: '#64748B',
        backgroundColor: "#F1F5F9",
        lineHeight: '24px',
        fontWeight: 700,
        fontSize: '16px',
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})
import React from 'react'
import UnAuthorizedUIWrapper from "../../../components/src/UnAuthorizedUIWrapper";
import NewPasswordWebController from "./NewPasswordController.web";
import { Box, Typography, Button, styled, IconButton } from "@material-ui/core";
import { keyIcon, passwordPageThumbnail, successIcon } from "./assets";
import InputField from "../../../components/src/InputField";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import Loader from '../../../components/src/Loader.web';

const configJSON = require("./config");


class NewPassword extends NewPasswordWebController {
    render() {
        const isTwiceUsedLink = this.state.tokenUsedTwiceError?.length > 0;
        const pageSubTitle = isTwiceUsedLink ? configJSON.PasswordResetLinkAlreadyUsed : configJSON.defaultSubTitleOnNewPassword;
        const pageTitle = isTwiceUsedLink ? configJSON.PasswordResetLinkSubTitle : configJSON.ResetPassword;
        let title = pageTitle;
        let subTitle = pageSubTitle;
        let icon = keyIcon;
        let actionBtnFn = this.changePassword;
        let actionBtnText = configJSON.ChangePassword;
        let pageSubTitleStyle = { ...webStyle.pageSubTitle, textAlign: "left", width: '100%' } as React.CSSProperties;
        if (this.state.passwordChanged) {
            title = configJSON.ResetSuccess;
            subTitle = configJSON.ResetSuccessMsg;
            icon = successIcon;
            actionBtnFn = this.backToLogin;
            actionBtnText = configJSON.LoginBtnText;
            pageSubTitleStyle = webStyle.pageSubTitle
        }

        if (isTwiceUsedLink) {
            actionBtnFn = this.backToLogin;
            actionBtnText = configJSON.BackToLogin;
            pageSubTitleStyle = webStyle.pageSubTitle
        }
        return <UnAuthorizedUIWrapper thumbnail={passwordPageThumbnail}>
            <Loader loading={this.state.isLoading} />
            <Box style={webStyle.pageWrapper}>
                <img style={{ width: this.state.passwordChanged ? '80px' : '56px', marginBottom: '20px' }} src={icon} alt="key_icon" />
                <Typography data-test-id='title' style={webStyle.pageTitle}>{title}</Typography>
                <Typography style={pageSubTitleStyle}>{subTitle} </Typography>
                {!(this.state.passwordChanged || isTwiceUsedLink) && <><Box style={{ display: 'flex', flexDirection: "column", alignItems: 'start', width: '100%', marginBottom: '20px' }}>
                    <ul style={{ paddingLeft: '20px', margin: '0' }} >
                        {configJSON.PwdConstraints.map((text: string) => <li key={text} style={{ color: '#475569', fontFamily: "'Inter', sans-serif", }} > <Typography style={webStyle.rules} >{text} </Typography></li>)}
                    </ul>
                </Box>
                    {!isTwiceUsedLink && <Box style={{ width: '100%' }} >
                        <InputField
                            style={{ marginBottom: '30px' }}
                            fullWidth
                            data-test-id='new-password'
                            type={this.state.showPassword ? "text" : "password"}
                            label={configJSON.NewPassword}
                            placeholder={configJSON.EnterNewPassword}
                            helperText={this.state.newPasswordError}
                            error={Boolean(this.state.newPasswordError)}
                            onChange={this.handleChangeNewPassword}
                            value={this.state.newPassword}
                            endAdornment={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => this.handleShowPassword('password')}
                                    edge="end"
                                >
                                    {!this.state.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            } />
                        <InputField
                            style={{ marginBottom: '26px' }}
                            fullWidth
                            data-test-id='confirm-password'
                            type={this.state.showConfirmPassword ? "text" : "password"}
                            label={configJSON.ConfirmNewPassword}
                            placeholder={configJSON.ConfirmNewPasswordPlaceholder}
                            onChange={this.handleChangeConfirmPassword}
                            value={this.state.confirmPassowrd}
                            error={Boolean(this.state.confirmPasswordError)}
                            helperText={this.state.confirmPasswordError}
                            endAdornment={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => this.handleShowPassword('confirmPassword')}
                                    edge="end"
                                >
                                    {!this.state.showConfirmPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            } />
                    </Box>}
                </>}
            </Box>
            <StyledButton style={{ marginTop: isTwiceUsedLink ? '120px' : '0' }} variant="contained" disabled={!this.getIsBtnShouldDisabled()} data-test-id='changeBtn' onClick={actionBtnFn} >{actionBtnText}</StyledButton>
        </UnAuthorizedUIWrapper >
    }
}
export default NewPassword;

const StyledButton = styled(Button)({
    backgroundColor: "#5B4595",
    height: "56px", width: '100%',
    borderRadius: '8px',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: "#5B4595",
    }
});

const webStyle = {
    pageTitle: { textAlign: 'center', color: '#5B4595', fontSize: '20px', lineHeight: '28px', marginBottom: '40px', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
    pageSubTitle: { color: '#94A3B8', fontSize: '16px', fontWeight: 400, lineHeight: '24px', textAlign: 'center', marginBottom: '20px', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
    rules: { fontSize: '14px', color: '#475569', lineHeight: '22px', fontWeight: '400', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
    pageWrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center' } as React.CSSProperties
}
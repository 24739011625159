// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  styled,
  InputLabel,
  IconButton,
  Button,
  LinearProgress,
  Divider,
  Avatar,
  MenuItem,
} from "@material-ui/core";

import ProjectPortfolioController, {
  ProjectStatus,
  Props,
  configJSON,
} from "./ProjectPortfolioController";
import Loader from "../../../components/src/Loader";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import CommonSelect from "../../../components/src/CommonSelect";
import InputField from "../../../components/src/InputField";
import DateRangeIcon from '@mui/icons-material/DateRange';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { addIcon, calendarIcon, redCalander } from "./assets";
import CommonModal from "../../../components/src/CommonModal";
import CommonSearchableSelect from "../../../components/src/CommonSearchableSelect";
import CommonMessage from "../../../components/src/CommonMessage";
import GridCardLoader from "../../../components/src/GridCardLoader";
import { MoreVert } from "@mui/icons-material";
import { AvatarGroup, linearProgressClasses, Grid, Tooltip } from "@mui/material";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ListCardLoader from "../../../components/src/ListCardLoader";
import CommonPopover from "../../../components/src/CommonPopover";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  renderNoOfItems = ({ text }: { text: string }) => (
    <Typography style={webStyles.noOfItems} >{text}</Typography>
  );

  renderActionButtons = () => {
    return <Box data-test-id='modal-action' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <ModalSecondaryButton data-test-id="cancelModal" onClick={this.handleCancelAddMemberModal} >Cancel</ModalSecondaryButton>
      <StyledPrimaryButton onClick={this.state.modalType === 'delete-project' ? this.handleDeleteProject : this.handleAddMember} data-test-id='add-member' >{this.state.modalType === 'delete-project' ? configJSON.ModalType.Delete : configJSON.ModalType.Add}</StyledPrimaryButton>
    </Box>
  }

  renderListLoader = () => {
    return this.showListGridLoader() ? <ListCardLoader /> : null
  }

  renderGridLoader = () => {
    return this.showListGridLoader() ? <Grid data-test-id='grid-card' item xs={12} sm={6} md={6} lg={4} xl={4} >
      <GridCardLoader />
    </Grid> : null
  }

  renderArrowDownOfStatusMenu = () => {
    return !this.disableActionButton() && <KeyboardArrowDownRoundedIcon style={webStyles.statusArrow} />
  }

  renderProjectStatus = (status: string) => {
    return status && <Typography style={webStyles.projectStatus} >{status}</Typography>
  }

  renderProgress = () => {
    return <ListCardLoader />;
  }

  renderNoProjectScreen = () => {
    return !this.state.showLoader && !this.state.isLoading && !this.state.projectList.length ? <Box data-test-id='pageWrapper' style={{ width: '100%', display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
      <Typography style={{ color: '#241C3C', fontSize: '30px', lineHeight: '40px', fontWeight: 700, textAlign: 'center', fontFamily: "'Inter', sans-serif", }} >{configJSON.NoProjectFound}</Typography>
      {this.isProjectManager() && <Typography style={{ color: '#94A3B8', lineHeight: '24px', fontSize: '16px', fontWeight: 400, fontFamily: "'Inter', sans-serif", }} >{configJSON.TryCreatingProj}</Typography>}
    </Box> : null
  }

  renderSortMenu = () => {
    return <>
      <MenuItem data-test-id='sortByAZ' style={this.getMenuStyle(this.state.sortParameterLabel, configJSON.sortLabel.a2z)} onClick={this.handleSortByAZ}  >{this.state.sortParameterLabel === 'A to Z' ? "Z to A" : "A to Z"}</MenuItem >
      <MenuItem data-test-id='sortByTime' style={this.getMenuStyle(this.state.sortParameterLabel, configJSON.sortLabel.time)} onClick={this.handleSortByTime}  >{this.state.sortParameterLabel === 'Latest' ? "Oldest" : "Latest"}</MenuItem>
    </>
  }

  renderFilterStatusMenu = () => {
    return <>
      <MenuItem data-test-id='filterByAll' style={this.getMenuStyle(this.state.filterStatusLabel, "All Projects")} onClick={this.handleFilterByAll}  >All Projects</MenuItem>
      <MenuItem data-test-id='filterByNotStarted' style={this.getMenuStyle(this.state.filterStatusLabel, "Not Started")} onClick={this.handleFilterByNotStarted}  >Not Started</MenuItem>
      <MenuItem data-test-id='filterByInProgress' style={this.getMenuStyle(this.state.filterStatusLabel, "In Progress")} onClick={this.handleFilterByInProgress} >In Progress</MenuItem>
      <MenuItem data-test-id='filterByOnHold' style={this.getMenuStyle(this.state.filterStatusLabel, "On Hold")} onClick={this.handleFilterByOnHold} >On Hold</MenuItem>
      <MenuItem data-test-id='filterByCompleted' style={this.getMenuStyle(this.state.filterStatusLabel, "Completed")} onClick={this.handleFilterByCompleted} >Completed</MenuItem>
    </>
  }

  renderStatusList = () => {
    const id = this.state.projectId || 0
    return <>
      <MenuItem data-test-id='statusNotStarted' style={this.getMenuStyle(this.state.projectDetails?.attributes.status, "Not Started")} onClick={() => this.changeProjectStatus(id, ProjectStatus.NotStarted)}>Not Started</MenuItem>
      <MenuItem data-test-id='statusInProgress' style={this.getMenuStyle(this.state.projectDetails?.attributes.status, "In Progress")} onClick={() => this.changeProjectStatus(id, ProjectStatus.InProgress)}>In Progress</MenuItem>
      <MenuItem data-test-id='statusOnHold' style={this.getMenuStyle(this.state.projectDetails?.attributes.status, "On Hold")} onClick={() => this.changeProjectStatus(id, ProjectStatus.OnHold)}>On Hold</MenuItem>
      <Tooltip title={this.state.projectDetails?.attributes.completed_task_count === this.state.projectDetails?.attributes.total_task_count ? "" : "some tasks are incomplete so cannot mark it as completed"} slotProps={{ tooltip: { style: { background: 'black', fontSize: '14px', textAlign: 'center', lineHeight: '18px', width: '250px' } }, arrow: { style: { color: 'black' } } }} arrow>
        <Box>
          <MenuItem disabled={this.state.projectDetails?.attributes.completed_task_count !== this.state.projectDetails?.attributes.total_task_count} data-test-id='statusCompleted' style={this.getMenuStyle(this.state.projectDetails?.attributes.status, "Completed")} onClick={() => this.changeProjectStatus(id, ProjectStatus.Completed)}>Completed</MenuItem>
        </Box>
      </Tooltip>
    </>
  }

  renderNoOfRecords = () => {
    return this.state.projectList.length > 0 ? <Typography style={{ color: '#64748B', fontSize: '12px', lineHeight: '18px', fontWeight: 400, fontFamily: "'Inter', sans-serif", }} > Showing {this.state.projectList.length} out of {this.state.totalProjects} results</Typography> : null
  }

  getAddProjectConditionalItems = () => {
    if (this.state.isRename) {
      return {
        submitButton: 'Save',
        formTitle: configJSON.RENAMEPROJECTNAME,
        submitAction: this.renameProject,
      }
    } else if (this.state.isEdit) {
      return {
        submitButton: 'Save',
        formTitle: configJSON.EDITPROJECTNAME,
        submitAction: this.editProject,
      }
    }
    else {
      return {
        submitButton: 'Create',
        formTitle: configJSON.CREATENEWPROJECT,
        submitAction: this.handleCreateProject,
      }
    }
  }

  renderMenuList = (type: string) => {
    switch (type) {
      case 'kebabMenu': return this.renderKebabMenu();
      case 'memberList': return this.renderMemberList()
      case 'status': return this.renderFilterStatusMenu();
      case 'sort': return this.renderSortMenu();
      case "status-list": return this.renderStatusList();
      case "organizationList": return [{ value: null, label: "All Organizations" }, ...this.state.organizationList].map((organization) => {
        return <MenuItem data-test-id='organization-filter' onClick={() => this.handleFilterByOrganization(organization)} style={this.getMenuStyle(organization.label, this.state.filterOrganization)} >{organization.label}</MenuItem>
      })
      default: return <></>
    }
  }

  getMenuStyle = (stateLabel: string | undefined, sortParameter: string) => {
    return stateLabel === sortParameter ? webStyles.activeMenuItem : webStyles.defaultMenuItem;
  }

  renderKebabMenu = () => {
    return <>
      <MenuItem data-test-id='rename-project' style={webStyles.menuItemStyle} onClick={() => this.handleRenameDuplicateProject('rename')} >{configJSON.Rename}</MenuItem>
      <MenuItem data-test-id='duplicate-project' style={webStyles.menuItemStyle} onClick={() => this.handleRenameDuplicateProject('duplicate')} >{configJSON.Duplicate}</MenuItem>
      <MenuItem data-test-id='delete-project' style={webStyles.menuItemStyle} onClick={this.handleOpenDeleteProjectModal} >{configJSON.Delete}</MenuItem>
      <MenuItem data-test-id='edit-project' style={webStyles.menuItemStyle} onClick={() => this.handleRenameDuplicateProject('edit')} >{configJSON.Edit}</MenuItem>
    </>
  }

  renderAddProjectForm = () => {
    return this.state.addProjectMode && <Box data-test-id='create-project' style={webStyles.addProjectWrapper}>
      <Box>
        <Box style={webStyles.projectDetailsWrapper} >
          <Typography data-test-id="titleProject" style={webStyles.projectTitle} >{this.getAddProjectConditionalItems().formTitle}</Typography>
        </Box>
        <Box style={webStyles.projectOrganization} >
          <CommonSelect
            label={configJSON.Labels.OrganizationName}
            asterisk
            data-test-id='organization'
            onChange={this.onChangeOrganization}
            value={this.state.organization}
            error={Boolean(this.state.organizationError)}
            helperText={this.state.organizationError}
            mb={"30px"}
            list={this.state.organizationList}
            placeholder={configJSON.SelectOrg}
            disabled={this.state.isRename}
          />
          <InputField
            style={{ marginBottom: '30px' }}
            label={configJSON.Labels.ProjectName}
            data-test-id='project-name'
            asterisk
            value={this.state.projectName}
            onChange={this.onChangeProjectName}
            inputProps={{ maxLength: 80 }}
            error={Boolean(this.state.projectNameError)}
            helperText={this.state.projectNameError}
            placeholder={configJSON.ProjectName}
          />
          <InputField
            label={configJSON.ProjectDescription}
            placeholder={configJSON.ProjectDetails}
            asterisk
            onChange={this.onChangeProjectDescription}
            data-test-id='project-description'
            value={this.state.projectDescription}
            error={Boolean(this.state.projectDescriptionError)}
            helperText={this.state.projectDescriptionError}
            inputProps={{ maxLength: 2000 }}
            multiline
            style={{ marginBottom: '30px' }}
            minRows={4}
            disabled={this.state.isRename}
          />
          <InputLabel style={webStyles.dueDateLabel} >{configJSON.DueDate}  <span style={{ color: 'red', fontFamily: "'Inter', sans-serif", }} >*</span> </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StyledDatePicker
              disableToolbar
              variant="inline"
              clearLabel
              format="dd MMM yyyy"
              margin="normal"
              data-test-id='due-date'
              placeholder={configJSON.SelectDueDate}
              onClick={this.handleOpenDatePicker}
              disablePast
              PopoverProps={{
                anchorEl: this.state.datePickerAnchorEl,
                open: Boolean(this.state.datePickerAnchorEl),
                onClose: this.handleCloseDatePickerOnBlur,
              }}
              InputProps={{
                readOnly: true,
              }}
              error={Boolean(this.state.dueDateError)}
              helperText={this.state.dueDateError}
              keyboardIcon={<DateRangeIcon />}
              value={this.state.labelDate}
              disabled={this.state.isRename}
              onChange={(date) => this.onChangeDueDate(date as unknown as Date)}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
      <Box style={webStyles.addMemberWrapper} >
        <Box sx={{ display: 'flex' }} ><Typography style={webStyles.addMemberTitle} >{this.state.isEdit ? "EDIT MEMBERS" : "ADD MEMBERS"}</Typography>{this.renderNoOfItems({ text: `${this.state.selectedMembers.length}` })}</Box>
        <IconButton onClick={this.handleOpenModal} disabled={this.state.isRename} data-test-id='openMember' >
          <img src={addIcon} alt="" />
        </IconButton>
      </Box>
      <Box style={webStyles.actionWrapper}>
        <Box style={{ marginLeft: 'auto', display: "flex", justifyContent: 'flex-end' }} >
          <StyledSecondaryButton onClick={this.handleCancelProject} data-test-id='goback' >Cancel</StyledSecondaryButton>
          <StyledButton disabled={this.state.isSubmitDisabled} onClick={this.getAddProjectConditionalItems().submitAction} data-test-id='create' >{this.getAddProjectConditionalItems().submitButton}</StyledButton>
        </Box>
      </Box>
    </Box>

  }

  renderLoader = () => {
    return this.state.showLoader ? this.renderProgress() : null
  }

  render() {
    return (
      <DashboardViewWrapper data-test-id='wrapper' {...this.props} onSearch={this.handleSearch} setCurrentView={this.setCurrentView} pageTopBarProps={this.state.addProjectMode ? null : { tabProps: { tabLabel: 'All Projects', count: this.state.totalProjects }, bulkInvite: this.getAddProjectProps() }} >
        {!this.state.addProjectMode && <>
          {!this.state.isLoading && <Box style={webStyles.projectListWrapper}>
            <Box style={webStyles.projectSort}>
              <StyledActionLabelForData >{configJSON.SortBy} </StyledActionLabelForData>
              <StyledSortAction data-test-id='sortBy' disableRipple onClick={this.handleOpenSortMenu} >
                {this.state.sortParameterLabel}
              </StyledSortAction>
            </Box>
            <Box style={webStyles.projectSort}>
              <StyledStatusLabel style={webStyles.status} > Organization : </StyledStatusLabel>
              <StyledSortAction data-test-id='filter-by-org' disableRipple onClick={this.handleOpenOrganizationList} >
                {this.state.filterOrganization} <ExpandMoreRoundedIcon style={{ color: '#94A3B8' }} />
              </StyledSortAction>
            </Box>
            <Box style={{ display: "flex", alignItems: 'center' }} >
              <StyledStatusLabel style={webStyles.status} > {configJSON.Status}  </StyledStatusLabel>
              <StyledSortAction data-test-id='filterBy' disableRipple onClick={this.handleOpenStatusMenu} >
                {this.state.filterStatusLabel} <ExpandMoreRoundedIcon style={{ color: '#94A3B8' }} />
              </StyledSortAction>
            </Box>
          </Box >}
          {this.renderNoProjectScreen()}
          {this.state.projectList.length > 0 && <Box sx={webStyles.projectListScrollWrapper} onScroll={this.handleScroll} >
            {this.state.currentView === 'list' && <StyledGrid container rowSpacing={2}  >
              {this.state.projectList.map((project, idx) =>
                <StyledRow key={idx} data-test-id='list-card' item lg={12} xs={12} xl={12} md={12} sm={12}>
                  <Grid container style={webStyles.projectDetailsCardWrapper} data-test-id='title-description-wrapper' onClick={() => this.goToProjectDetails(Number(project.id))} >
                    <Grid item xs={this.getBreakPoints().title} style={{ paddingRight: '10px', cursor: 'pointer' }} >
                      <StyledTitle data-test-id='list-card-title' >{project.attributes.project_name}</StyledTitle>
                      <StyledSubTitle>{configJSON.PROJECTID} #{project.id}, {this.state.organizationList.find((organization) => organization.value === project.attributes.organization_id)?.label}</StyledSubTitle>
                    </Grid>
                    <Grid item xs={this.getBreakPoints().progress}>
                      <Box style={{ width: '100%' }}>
                        <Box style={webStyles.taskProgressWrapper}>
                          <StyledUploadStatus>{configJSON.TASKS}</StyledUploadStatus>
                          <Typography style={{ display: 'flex' }} > <Typography style={{ ...webStyles.statusText, ...this.getProgressStyle(project.attributes.completed_task_count, project.attributes.total_task_count) }} > {project.attributes.completed_task_count}  </Typography><Typography style={{ ...webStyles.statusText, color: '#475569', fontWeight: 700, fontFamily: "'Inter', sans-serif", }} > &nbsp;/ {project.attributes.total_task_count}</Typography></Typography>
                        </Box>
                        <BorderLinearProgress variant="determinate" value={this.getProgress(project.attributes.completed_task_count, project.attributes.total_task_count)} style={{ color: '#5B4595' }} />
                      </Box>
                    </Grid>
                    <Grid item xs={this.getBreakPoints().profileIcons} >
                      {project.attributes.members.length > 0 ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <StyledAvatarGroup data-test-id='list-avatar-group' style={{ width: 'fit-content' }} data-projectid={project.id} onMouseEnter={this.handleOpenMemberList} onMouseLeave={this.handleCloseKebabMenu} max={4}>
                          {project.attributes.members.map((member) => <Avatar alt={member.full_name} key={member.id} style={webStyles.memberAvatar} src={member.profile_image} />)}
                        </StyledAvatarGroup>
                      </Box> :
                        <Typography style={webStyles.noMember}>{configJSON.NOMEMBER}</Typography>
                      }
                    </Grid>
                    <Grid item xs={this.getBreakPoints().status}>
                      <Button key={idx} disabled={this.disableActionButton()} data-test-id="changeProjectStatus" onClick={(event) => this.openStatusList(event, project.id)} style={webStyles.projectStatusBtn} >
                        {this.renderProjectStatus(project.attributes.status)}
                        {this.renderArrowDownOfStatusMenu()}
                      </Button>
                    </Grid>
                    <Grid visibility={this.hideElement()} item xs={this.getBreakPoints().actionBtn} style={{ textAlign: 'right' }}>
                      <IconButton data-test-id='list-kabab-menu' data-projectid={project.id} onClick={this.handleOpenKebabMenu} >
                        <MoreVert />
                      </IconButton>
                    </Grid>
                  </Grid>
                </StyledRow>)}
              {this.renderListLoader()}
            </StyledGrid>}
            {this.state.currentView === 'grid' &&
              <Grid container rowSpacing={2} columnSpacing={2} >
                {this.state.projectList.map((project) => <Grid data-test-id='grid-card' item xs={12} sm={6} md={6} lg={4} xl={4} >
                  <Box data-test-id="title-wrapper" style={webStyles.projectGridCardWrapper} onClick={() => this.goToProjectDetails(Number(project.id))} >
                    <Box style={webStyles.projectTitleWrapper}>
                      <Box style={{ width: '80%', cursor: 'pointer' }} >
                        <StyledTitle data-test-id='grid-card-title' >{project.attributes.project_name}</StyledTitle>
                      </Box>
                      <Box visibility={this.hideElement()} style={{ marginLeft: 'auto' }} >
                        <IconButton data-test-id='grid-kabab-menu' data-projectid={project.id} onClick={this.handleOpenKebabMenu}>
                          <MoreVert />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box style={{ marginBottom: '20px', width: 'fit-content' }} >
                      <Button disabled={this.disableActionButton()} data-test-id='changeProjectStatus' data-projectid={project.id} onClick={(event) => this.openStatusList(event, project.id)} style={webStyles.statusWrapper} >
                        {this.renderProjectStatus(project.attributes.status)}
                        {this.renderArrowDownOfStatusMenu()}
                      </Button>
                    </Box>
                    <Box style={{ marginBottom: '20px' }} >
                      <StyledCardDescription>
                        {project.attributes.description}
                      </StyledCardDescription>
                    </Box>
                    <Box style={{ width: '100%', marginBottom: '20px' }}>
                      <Box style={webStyles.taskProgressWrapperGrid}>
                        <Typography style={webStyles.statusText} >{configJSON.TASKS}</Typography>
                        <Typography style={{ display: 'flex' }} > <Typography style={{ ...webStyles.statusText, ...this.getProgressStyle(project.attributes.completed_task_count, project.attributes.total_task_count) }} > {project.attributes.completed_task_count}  </Typography><Typography style={{ ...webStyles.statusText, color: '#475569', fontWeight: 700, fontFamily: "'Inter', sans-serif", }} > &nbsp;/ {project.attributes.total_task_count}</Typography></Typography>
                      </Box>
                      <BorderLinearProgress variant="determinate" value={this.getProgress(project.attributes.completed_task_count, project.attributes.total_task_count)} style={{ color: '#5B4595' }} />
                    </Box>
                    <Divider style={{ marginBottom: '20px' }} />
                    <Box style={webStyles.dueByWrapper}>
                      <Box style={{ display: 'flex', alignItems: 'center' }} >
                        <img src={this.isPastDueDate(project.attributes.due_date) ? redCalander : calendarIcon} style={{ marginRight: '10px' }} alt="calendar_icon" />
                        <Typography style={this.isPastDueDate(project.attributes.due_date) ? { ...webStyles.timeStamp, color: '#f44336' } : webStyles.timeStamp} >{configJSON.DueBy} {this.handleFormateTime(project.attributes.due_date)}</Typography>
                      </Box>
                      <Box>
                        {project.attributes.members.length > 0 ? <Box>
                          <StyledGridAvatarGroup data-test-id='grid-avatar-group' style={{ marginLeft: 'auto', width: 'fit-content' }} data-projectid={project.id} onMouseEnter={this.handleOpenMemberList} onMouseLeave={this.handleCloseKebabMenu} spacing={'medium'} max={4}>
                            {project.attributes.members.map((member) => <Avatar alt={member.full_name} key={member.id} style={webStyles.gridAvatarWrapper} src={member.profile_image} />)}
                          </StyledGridAvatarGroup>
                        </Box>
                          : <Typography style={webStyles.timeStamp}>{configJSON.NOMEMBER}</Typography>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Grid>)
                }
                {this.renderGridLoader()}
              </Grid >}
          </Box>}
          {this.renderNoOfRecords()}
        </>}

        {this.renderAddProjectForm()}
        {this.renderLoader()}
        <CommonPopover data-test-id='popover' isOnHover={this.state.kebabMenyType === 'memberList'} onClose={this.handleCloseKebabMenu} anchorEl={this.state.anchorEl} menuList={this.renderMenuList(this.state.kebabMenyType)} />

        <CommonModal data-test-id='modal' open={this.state.openModal} onClose={this.handleCloseModal} closeIcon modalTitle={this.getModalHeader()} actionComponent={this.renderActionButtons()} >
          <Box style={{ display: 'flex', flexDirection: 'column' }} >
            {this.state.modalType === 'delete-project' ?
              <Typography style={webStyles.deleteProject} >{configJSON.Modal.Decription.WantToDeleteProject}</Typography>
              : <>
                <CommonSearchableSelect
                  label={configJSON.Labels.Organization}
                  data-test-id='organization-multiselect'
                  selectedTitles={this.state.selectedOrganizationTitles}
                  selectedValues={this.state.selectedOrganizations}
                  onChange={this.onMultiOrganizationSelect}
                  placeholder={configJSON.PlaceHolder.SelectOrganization}
                  error={Boolean(this.state.selectedOrgError)}
                  helperText={this.state.selectedOrgError}
                  fullWidth mb={'20px'}
                  list={this.state.organizationList} />
                <CommonSearchableSelect
                  label={configJSON.Labels.Participant}
                  data-test-id='participant-multiselect'
                  selectedTitles={this.state.selectedMemberTitles}
                  selectedValues={this.state.selectedMembers}
                  error={Boolean(this.state.selectedMemberError)}
                  helperText={this.state.selectedMemberError}
                  onChange={this.onMultiSelectChange}
                  placeholder={configJSON.SelectParticipant}
                  fullWidth
                  list={this.state.memberList} />
              </>}
          </Box>
        </CommonModal>
        <CommonMessage data-test-id='common-msg' open={this.state.showCommonMessage} message={this.state.message} messageType={this.state.messageType} onClose={this.handleCloseCommonMessage} />
      </DashboardViewWrapper >
    );
  }
}

const webStyles = {
  projectStatusBtn: {
    border: '1px solid #E2E8F0',
    height: '34px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    width: '122px'
  } as React.CSSProperties,
  statusArrow: { color: "#334155", fontSize: '20px' },
  iIcon: {
    color: '#94A3B8',
    '@media(max-width:600px)': {
      fontSize: '16px'
    }
  },
  defaultMenuItem: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '22px',
    fontSize: '14px',
    minHeight: 'auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  activeMenuItem: {
    backgroundColor: 'rgb(240, 232, 255)',
    borderRadius: '8px',
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    color: '#334155',
    lineHeight: '22px',
    minHeight: 'auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  projectListScrollWrapper: {
    height: "calc(100vh - 364px)",
    overflowY: 'auto',
    minHeight: '300px',
    '@media(max-width:960px)': {
      width: '100%',
    }
  },
  reportTitle: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#0F172A',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '28px',
    whiteSpace: 'nowrap',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  statusText: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
  },
  timeStamp: {
    color: '#64748B',
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '22px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  sortMenuActive: {
    borderRadius: '8px',
    backgroundColor: 'rgb(240, 232, 255)',
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    color: '#334155',
    lineHeight: '22px'
  } as React.CSSProperties,
  menuItemStyle: {
    color: '#334155',
    lineHeight: '22px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    minHeight: 'auto'
  },
  sortBtn: {
    color: '#475569',
    padding: '0 10px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    textTransform: "initial",
  } as React.CSSProperties,
  noOfItems: { backgroundColor: '#D9B051', lineHeight: '32px', height: '32px', borderRadius: '100px', padding: "0px 16px", color: 'white', fontFamily: "'Inter', sans-serif" },
  projectStatus: { color: '#334155', fontSize: "14px", fontWeight: 400, lineHeight: '26px', fontFamily: "'Inter', sans-serif", },
  projectListWrapper: { display: 'flex', margin: '10px 0 0 auto', flexWrap: 'wrap' } as React.CSSProperties,
  projectSort: { display: 'flex', alignItems: 'center', marginRight: '2px' },
  sortBy: { color: '#94A3B8', fontSize: '16px', lineHeight: '28px', fontFamily: "'Inter', sans-serif", },
  status: {},
  projectDetailsCardWrapper: { borderRadius: '8px', width: '100%', backgroundColor: 'white', padding: '18px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: "'Inter', sans-serif", },
  taskProgressWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' },
  memberAvatar: { width: '32px', height: '32px', textTransform: 'capitalize' } as React.CSSProperties,
  noMember: { textAlign: 'center', fontSize: '14px', fontWeight: 400, color: "#64748B", fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
  projectGridCardWrapper: { boxShadow: "0px 4px 8px 0px #00000008", padding: '24px', borderRadius: '8px', backgroundColor: 'white' },
  projectTitleWrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
  statusWrapper: { border: '1px solid #CBD5E1', height: '34px', borderRadius: '8px', textTransform: 'capitalize' } as React.CSSProperties,
  taskProgressWrapperGrid: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' },
  dueByWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' },
  gridAvatarWrapper: { width: '24px', height: '24px', textTransform: 'capitalize' } as React.CSSProperties,
  addProjectWrapper: { display: 'flex', flexDirection: 'column', rowGap: '20px', backgroundColor: 'white' } as React.CSSProperties,
  projectDetailsWrapper: { padding: '0px 32px', height: '64px', borderRadius: '8px', border: '1px solid #E2E8F0', backgroundColor: 'white', boxShadow: "0px 2px 8px 0px #00000014", display: 'flex', alignItems: 'center' },
  projectTitle: { color: '#5B4595', fontWeight: 700, fontSize: '16px', lineHeight: '24px', textAlign: 'left', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
  projectOrganization: { border: '1px solid #E2E8F0', borderRadius: "8px", padding: '32px', display: 'flex', flexDirection: 'column' } as React.CSSProperties,
  projectDetailsLabel: { color: '#64748B', fontWeight: 400, fontSize: '16px', lineHeight: '24px', marginBottom: '40px', fontFamily: "'Inter', sans-serif", },
  dueDateLabel: { color: '#334155', fontWeight: '700', fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", },
  addMemberWrapper: { padding: '0px 32px', height: '92px', borderRadius: '8px', border: '1px solid #E2E8F0', backgroundColor: 'white', boxShadow: "0px 2px 8px 0px #00000014", display: 'flex', alignItems: 'center', justifyContent: 'space-between' } as React.CSSProperties,
  addMemberTitle: { color: '#5B4595', fontWeight: 700, fontSize: '16px', lineHeight: '24px', textAlign: 'left', display: 'flex', alignItems: 'center', marginRight: '10px', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
  actionWrapper: { padding: '40px 31px 40px 31px', borderRadius: '12px', backgroundColor: 'white' } as React.CSSProperties,
  deleteProject: { color: '#64748B', fontSize: '20px', lineHeight: '20px', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
};

const StyledSortAction = styled(Button)({
  color: '#475569',
  padding: '0 10px',
  fontFamily: "'Inter', sans-serif",
  fontSize: '16px',
  textTransform: "initial",
  "@media(max-width:600px)": {
    fontSize: '12px'
  }
})

const StyledActionLabelForData = styled(Typography)({
  color: '#94A3B8',
  fontSize: '16px',
  lineHeight: '28px',
  fontFamily: "'Inter', sans-serif",
  "@media(max-width:600px)": {
    fontSize: '12px'
  }
})

const StyledStatusLabel = styled(Typography)({
  color: '#94A3B8',
  fontSize: '16px',
  marginLeft: "5px",
  lineHeight: '28px',
  fontFamily: "'Inter', sans-serif",
  "@media(max-width:600px)": {
    fontSize: '12px'
  }
})


const StyledUploadStatus = styled(Typography)({
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 400,
  color: '#64748B',
  fontFamily: "'Inter', sans-serif",
  width: '50%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:hover': {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  }
})

const StyledAvatarGroup = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    width: 24,
    height: 24,
  },
  '& .MuiAvatarGroup-avatar': {
    width: 32,
    height: 32,
    fontSize: '12px',
    color: '#7C6AAA',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },
});

const StyledGridAvatarGroup = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    width: 24,
    height: 24,
  },
  '& .MuiAvatarGroup-avatar': {
    width: 24,
    height: 24,
    fontSize: '12px',
    color: '#7C6AAA',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },
});

const StyledSubTitle = styled(Typography)({
  color: '#64748B',
  fontFamily: "'Inter', sans-serif",
  lineHeight: '22px',
  fontSize: '14px',
  fontWeight: 400,
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E2E8F0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5B4595',
  },
}));

const StyledGrid = styled(Grid)({
  '@media(max-width:960px)': {
    maxWidth: '100%',
    overflowY: 'auto'
  }
});

const StyledRow = styled(Grid)({
  '&.MuiGrid-root ': {
    maxWidth: 'calc(100vw - 320px)',
    '@media(max-width:960px)': {
      minWidth: '960px'
    }
  },
})

const StyledTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '28px',
  color: '#0F172A',
  fontFamily: "'Inter', sans-serif",
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:hover': {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  }
})

export const StyledButton = styled(Button)({
  backgroundColor: '#5B4595',
  color: 'white',
  fontFamily: "'Inter', sans-serif",
  textTransform: "capitalize",
  height: '56px',
  fontWeight: 400,
  padding: '16px',
  width: '240px',
  borderRadius: '8px',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: '#5B4595'
  },
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: "#F1F5F9",
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px'
  },
  "@media(max-width:600px)": {
    width: 'auto',
    fontSize: '12px',
  }
})

export const StyledSecondaryButton = styled(Button)({
  backgroundColor: '#DEDAEA',
  color: '#5B4595',
  fontFamily: "'Inter', sans-serif",
  textTransform: "capitalize",
  height: '56px',
  fontWeight: 400,
  width: '240px',
  padding: '16px',
  borderRadius: '8px',
  fontSize: '16px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: '#DEDAEA'
  },
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: "#F1F5F9",
    color: '#64748B',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px'
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

export const StyledPrimaryButton = styled(Button)({
  color: 'white',
  fontFamily: "'Inter', sans-serif",
  backgroundColor: '#5B4595',
  height: '56px',
  textTransform: "capitalize",
  fontWeight: 700,
  width: '120px',
  padding: '16px',
  fontSize: '16px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#5B4595'
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#64748B',
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px'
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

export const ModalSecondaryButton = styled(Button)({
  height: '56px',
  backgroundColor: '#DEDAEA',
  color: '#5B4595',
  fontFamily: "'Inter', sans-serif",
  textTransform: "capitalize",
  fontWeight: 700,
  width: '120px',
  padding: '16px',
  fontSize: '16px',
  borderRadius: '8px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: '#DEDAEA'
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#64748B',
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

const StyledDatePicker = styled(KeyboardDatePicker)({
  "& .MuiInput-root": {
    marginTop: "4px",
    "@media(max-width:818px)": {
      top: "0",
      margin: "18 0"
    }
  },
  "& .MuiInputAdornment-positionEnd": {
    marginRight: "8px"
  },
  "& .MuiInputBase-root": {
    flexDirection: "row",
    marginBottom: '10px',
    fontFamily: "'Inter', sans-serif",
  },
  "& .MuiInputBase-input": {
    padding: '0 0 0 10px',
    fontFamily: "'Inter', sans-serif",
  },
  "& .MuiFormHelperText-root": {
    position: "relative",
    fontFamily: "'Inter', sans-serif",
    top: "7px",
    marginBottom: '10px',
    "@media(max-width:818px)": {
      top: "0"
    }
  },
  "& .MuiInput-underline::before": {
    content: "",
    position: "absolute",
    height: "56px",
    bottom: "-18px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",

  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    border: "1px solid #5B4595"
  },
  "& .MuiInput-underline::after": {
    content: "",
    position: "absolute",
    height: "56px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    bottom: "-18px",
    transition: '0s'
  },
  "& .MuiInput-underline.Mui-focused::after": {
    border: "1px solid #CBD5E1",
    transition: '0s'
  },
  "& .MuiInput-underline.Mui-error::after": {
    border: "1px solid red",
    transition: '0s'
  }
}
)

const StyledCardDescription = styled(Typography)({
  color: '#64748B',
  fontFamily: "'Inter', sans-serif",
  lineHeight: '22px',
  fontSize: '14px',
  fontWeight: 400,
  width: '100%',
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: 'hidden',
  WebkitLineClamp: 3,
  wordBreak: 'break-all',
  '&:hover': {
    display: "block",
    height: "auto",
    WebkitLineClamp: "unset",
    overflow: "visible",
    whiteSpace: "normal"
  }
})

// Customizable Area End

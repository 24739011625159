import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import moment from "moment";


interface CommentorAccount {
  id: number;
  full_name: string;
  profile_image: string;
}

interface CommentAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  created_at: string;
  updated_at: string;
  account: CommentorAccount;
}

interface CommentData {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface CommentsMeta {
  total_comments: number;
  total_pages: number;
}

interface CommentsResponse {
  data: CommentData[];
  meta: CommentsMeta;
}



interface SubTask {
  id: number;
  name: string;
  status: string;
  bx_block_tasks_task_id: number;
  created_at: string;
  updated_at: string;
}

interface IAttachment {
  id: number;
  fileName: string;
  size: string;
  file: File | null;
  isLocal: boolean;
  url: string
}
interface ISubTask {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    bx_block_tasks_task_id: number;
    description: string;
    status: string;
    feedback: string;
    created_at: string;
    updated_at: string;
    attachments: { id: number, url: string, file_name: string, byte_size: number }[];
    project_title: string;
    task_title: string;
  };
}


interface Member {
  id: number;
  full_name: string;
  email: string | null;
  profile_image: string;
  organization_id: number | null;
}

interface TaskAttributes {
  id: number;
  account_id: number;
  title: string;
  due_date: string;
  description: string;
  status: string;
  priority: string | null;
  created_at: string;
  updated_at: string;
  is_reviewed: boolean;
  prev_status: string | null;
  task_list_ids: number[];
  sub_tasks: SubTask[];
  section_id: number | null;
  project_id: number;
  is_creater: boolean;
  member_count: number;
  member: Member;
  feedback: string;
  pm_attachments: { id: number, file_name: string, url: string, byte_size: number }[];
  participant_attachments: { id: number, file_name: string, url: string, byte_size: number }[];
}

interface TaskData {
  id: string;
  type: string;
  attributes: TaskAttributes;
}

interface IMemberList {
  id: number;
  full_name: string;
  profile_image: string;
  email: string;
}

interface OrganizationId {
  id: number;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface PMember {
  id: number;
  full_name: string;
  email: string;
  organization_id: OrganizationId;
  profile_image: string;
}

interface CreaterDetails {
  name: string;
  id: number;
  profile_image: string;
}

interface Attributes {
  project_name: string;
  due_date: string;
  description: string;
  status: string;
  members_count: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  creater_details: CreaterDetails;
  total_task_count: number;
  completed_task_count: number;
  members: PMember[];
}

interface IProjectDetails {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Task {
  id: number;
  task_list_id: number | null;
  title: string;
  assigned_to_id: number | null;
  assigned_to_type: string | null;
  due_date: string | null;
  description: string;
  status: string | null;
  priority: string | null;
  created_at: string;
  updated_at: string;
  account_id: number;
  project_id: number;
  section_id: number | null;
}

interface Section {
  id: number;
  name: string;
  tasks: Task[];
  position: number;
  section_type: "default" | "custom"
}

interface CustomColumn {
  section: Section;
}

interface IProjectKanbanAttributes {
  project_name: string;
  due_date: string;
  description: string;
  status: string;
  members_count: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  members: IMember[];
  creater_details: { name: string; id: number; }
  customs_columns: CustomColumn[];
}

interface IMember {
  id: number;
  full_name: string
}

interface ProjectKanban {
  id: string;
  type: string;
  attributes: IProjectKanbanAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  expanded: string | false;
  taskId: number | null;
  taskDetails: TaskData | null;
  projectId: number | null;
  projectName: string;
  projectStatus: string;
  attachment: File | null;
  isEditMode: boolean;
  openMessage: boolean;
  messageType: "success" | "error";
  message: string;
  dueDateAnchorEl: HTMLElement | null;
  dueDate: string;
  labelDate: string | Date | null;
  dueDateError: string;
  subTasksArray: Array<{ subtask: string; id: number, error: string, isNew: boolean; }>;
  taskName: string;
  taskNameError: string;
  taskDescription: string;
  taskDescriptionError: string;
  selectedAttachment: string;
  noMemberSelectedError: string;
  prevSubtasks: Array<{ subtask: string; id: number, error: string, isNew: boolean; }>;
  subtaskToBeDeletedArray: Array<number>;
  openModal: boolean;
  modalType: "remove-attachment" | "move-to-completed" | "remove-subtask-attachment",
  listType: "member-list" | "review",
  anchorEl: HTMLElement | null;
  memberList: IMemberList[];
  selectedMember: { id: number, name: string, profile: string } | null;
  isRename: boolean;
  isDuplicate: boolean;
  isEdit: boolean;
  participantAttachmentData: { id: number, fileName: string, size: string, file: File | null, isLocal: boolean, url: string }[];
  projectManagerAttachmentData: { id: number, fileName: string, size: string, file: File | null, isLocal: boolean, url: string }[];
  tempParticipantAttachmentData: { id: number, fileName: string, size: string, file: File | null, isLocal: boolean, url: string }[];
  tempProjectManagerAttachmentData: { id: number, fileName: string, size: string, file: File | null, isLocal: boolean, url: string }[];
  role: string;
  columnStatusAndId: { id: number; status: string }[];
  attachmentsToRemove: number[];
  defaultAttachments: number[];
  attachmentToRemoveId: number | null;
  currentRemovingAttachmentType: 'PM' | "PT";
  comment: string;
  allComments: CommentData[],
  isLoading: boolean;
  isSubmitForReview: boolean;
  isAcceptEvent: boolean;
  isReassign: boolean;
  loginUserId: number | undefined;
  commentPage: number;
  totalCommentPage: number;
  isCommentLoading: boolean;
  // subtask state start
  allSubTaskComments: CommentData[],
  subtaskCommentPage: number;
  totalSubtaskCommentPage: number
  isSubTaskCommentLoading: boolean;
  subtaskDetails: ISubTask | null;
  subTaskId: number | null;
  attachments: Array<IAttachment>;
  // isEditMode: boolean;
  anchorSubtaskEl: HTMLElement | null;
  subtaskName: string;
  subtaskNameError: string;
  subtaskDescription: string;
  subtaskStatus: string;
  subtaskFeedback: string;
  subtaskAttachmentIdToRemove: number | null;
  reviewOptions: { label: string, value: string }[];
  isSubmitEvent: boolean;
  isSubTaskEditMode: boolean;
  isSaveAction: boolean;
  // subtask state end
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getTaskDetailsApiCallId: string = "";
  getProjectDetailsApiCallId: string = "";
  createTaskFromScratchApiCallId: string = "";
  getProjectMembersApiCallId: string = "";
  addSubtaskApiCallId: string = "";
  getProjectTasksApiCallId: string = "";
  changeTaskStatusApiCallId: string = "";
  getCommentsApiCallId: string = "";
  addCommentApiCallId: string = "";
  getSubTaskCommentsApiCallId: string = "";
  addSubtaskCommentApiCallId: string = "";
  getSubTaskDetailsApiCallId: string = "";
  changeSubTaskStatusApiCallId: string = "";
  AttachmentRef: React.RefObject<HTMLInputElement> = React.createRef();
  updateSubTaskApiCallId: string = "";
  PMAttachmentUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  ParticipantAttachmentUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      isVisibleModal: false,
      dropdownTasks: false,
      expanded: 'panel1',
      taskId: null,
      taskDetails: null,
      projectId: null,
      projectName: '',
      attachment: null,
      isEditMode: false,
      openMessage: false,
      messageType: "success",
      message: '',
      dueDateAnchorEl: null,
      dueDate: '',
      labelDate: null,
      dueDateError: '',
      subTasksArray: [],
      taskName: '',
      taskNameError: '',
      taskDescription: '',
      taskDescriptionError: '',
      selectedAttachment: '',
      noMemberSelectedError: '',
      prevSubtasks: [],
      subtaskToBeDeletedArray: [],
      openModal: false,
      modalType: 'remove-attachment',
      anchorEl: null,
      memberList: [],
      selectedMember: null,
      isEdit: true,
      isDuplicate: false,
      isRename: false,
      participantAttachmentData: [],
      projectManagerAttachmentData: [],
      tempParticipantAttachmentData: [],
      tempProjectManagerAttachmentData: [],
      role: 'participant',
      columnStatusAndId: [],
      defaultAttachments: [],
      attachmentsToRemove: [],
      listType: 'member-list',
      attachmentToRemoveId: null,
      currentRemovingAttachmentType: 'PM',
      comment: '',
      allComments: [],
      isLoading: false,
      isAcceptEvent: false,
      isSubmitForReview: false,
      isReassign: false,
      loginUserId: undefined,
      commentPage: 1,
      totalCommentPage: 1,
      isCommentLoading: false,
      subtaskDetails: null,
      subTaskId: null,
      attachments: [],
      anchorSubtaskEl: null,
      subtaskName: '',
      subtaskNameError: '',
      subtaskDescription: '',
      subtaskStatus: '',
      subtaskFeedback: '',
      reviewOptions: [],
      isSubmitEvent: false,
      isSubTaskEditMode: false,
      subtaskAttachmentIdToRemove: null,
      isSaveAction: false,
      allSubTaskComments: [],
      subtaskCommentPage: 1,
      totalSubtaskCommentPage: 1,
      isSubTaskCommentLoading: false,
      projectStatus: "Not Started"
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const taskId = this.props.navigation.getParam('navigationBarTitleText');
    const queryParams = new URLSearchParams(window.location.search);
    const loginUserId = await getStorageData('user_id')
    const projectId = await getStorageData('task-project-id');
    const encryptedRole = await getStorageData(configJSON.Role);
    const role = this.deCrypt(configJSON.Secretkey, encryptedRole);
    const isEditMode = window.location.pathname.includes('rename') || window.location.pathname.includes('duplicate');
    const isViewSubTask = window.location.pathname.includes('subTask')
    if (isViewSubTask) {
      this.handleNavigateToSubTask(taskId)
    } else {

      this.setState({
        role: role,
        taskId: taskId,
        projectId: projectId,
        isEditMode: isEditMode,
        subTaskId: Number(queryParams.get('subtask')) === 0 ? null : Number(queryParams.get('subtask')),
        loginUserId: Number(loginUserId),
        isRename: window.location.pathname.includes('rename'),
        isDuplicate: window.location.pathname.includes('duplicate'),
        isEdit: window.location.pathname.includes('edit'),
        reviewOptions: [
          { label: 'Assigned', value: 'assigned' },
          { label: 'Submitted for Review', value: 'submitted_for_review' },
          { label: role === 'project_manager' ? 'Accept' : 'Accepted', value: 'accepted' },
          { label: role === 'project_manager' ? 'Reject' : 'Rejected', value: 'rejected' }
        ]
      }, () => {
        this.getTaskDetails();
        this.getProjectDetails();
        this.getProjectMembers();
        this.getProjectTasks();
        this.getComments(this.state.subTaskId ? "subtask" : "task");
        if (this.state.subTaskId) {
          this.getSubTaskDetails()
        }
      })
    }

  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getTaskDetailsApiCallId) {
          this.setState({ taskDetails: responseJson.data, role: this.state.role, isLoading: false },
            () => this.handleUpdateOnTaskDetailsSet()
          )
        }
        this.handleResponse(apiRequestCallId, responseJson)
      }
    }
  };
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevProps.navigation.getParam('navigationBarTitleText') !== this.props.navigation.getParam('navigationBarTitleText')) {
      const taskId = this.props.navigation.getParam('navigationBarTitleText')
      const isViewSubTask = window.location.pathname.includes('subTask')

      if (isViewSubTask) {
        this.handleNavigateToSubTask(taskId)
      }
    }

  }
  handleNavigateToSubTask = (taskId: number) => {
    this.setState({
      subTaskId: taskId, comment: '', reviewOptions: [
        { label: 'Assigned', value: 'assigned' },
        { label: 'Submitted for Review', value: 'submitted_for_review' },
        { label: this.isProjectManager() ? 'Accept' : 'Accepted', value: 'accepted' },
        { label: this.isProjectManager() ? 'Reject' : 'Rejected', value: 'rejected' }
      ]
    }, () => { this.getSubTaskDetails(); this.getComments('subtask') })
  }
  handleUpdateOnTaskDetailsSet = () => {
    if (this.state.taskDetails) {
      let formattedSubtask = this.state.taskDetails.attributes.sub_tasks.map((subtask) => ({ subtask: subtask.name, id: subtask.id, error: '', isNew: false }));
      let participantAttachments = this.state.taskDetails.attributes.participant_attachments.map((attachment) => (attachment.id));
      let pmAttachments = this.state.taskDetails.attributes.pm_attachments.map((attachment) => (attachment.id));
      let labelDate: string | Date = this.state.taskDetails.attributes.due_date;
      let dueDate = moment(this.state.taskDetails.attributes.due_date).format('DD/MM/YYYY');
      let formattedParticipantAttachments = this.state.taskDetails.attributes.participant_attachments.map((attachment) => {
        return {
          id: attachment.id,
          url: attachment.url,
          file: null,
          fileName: attachment.file_name,
          isLocal: false,
          size: this.formatFileSize(attachment.byte_size)
        }
      })
      let formattedPMAttachments = this.state.taskDetails.attributes.pm_attachments.map((attachment) => {
        return {
          id: attachment.id,
          url: attachment.url,
          file: null,
          fileName: attachment.file_name,
          isLocal: false,
          size: this.formatFileSize(attachment.byte_size)
        }
      })
      if (this.state.isDuplicate) {
        formattedSubtask = []; participantAttachments = []; pmAttachments = []; labelDate = ''; dueDate = ''; formattedParticipantAttachments = []; formattedPMAttachments = []
      }
      this.setState({
        taskName: this.state.taskDetails.attributes.title,
        taskDescription: this.state.taskDetails.attributes.description,
        labelDate: labelDate,
        dueDate: dueDate,
        editMode: this.state.taskDetails?.attributes.status === 'in_progress',
        // isEditMode: false,
        subTasksArray: formattedSubtask,
        prevSubtasks: formattedSubtask,
        defaultAttachments: [...participantAttachments, ...pmAttachments],
        participantAttachmentData: formattedParticipantAttachments,
        tempParticipantAttachmentData: formattedParticipantAttachments,
        projectManagerAttachmentData: formattedPMAttachments,
        tempProjectManagerAttachmentData: formattedPMAttachments,
        selectedMember: {
          id: this.state.taskDetails.attributes.member.id,
          name: this.state.taskDetails.attributes.member.full_name,
          profile: this.state.taskDetails.attributes.member.profile_image
        }
      })
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: { data: IProjectDetails | ProjectKanban | ISubTask } & IMemberList[] & CommentsResponse) => {
    switch (apiRequestCallId) {
      case this.getProjectDetailsApiCallId:
        const projectDetails = responseJson.data as IProjectDetails;
        this.setState({ projectName: projectDetails.attributes.project_name, projectStatus: projectDetails.attributes.status })
        break;
      case this.getProjectMembersApiCallId:
        this.setState({ memberList: responseJson as IMemberList[] })
        break;
      case this.addSubtaskApiCallId:
        this.setState({ subTasksArray: [] });
        this.getTaskDetails();
        this.createTaskFromScratch();
        break;
      case this.createTaskFromScratchApiCallId:
        this.createTaskFromScratchResp()
        break;
      case this.getProjectTasksApiCallId:
        const response = responseJson.data as ProjectKanban;
        const filtereddata = response.attributes.customs_columns.filter((column) => column.section.section_type === 'default').map((column) => ({ id: column.section.id, status: column.section.name }))
        this.setState({ columnStatusAndId: filtereddata, isLoading: false })
        break;
      case this.changeTaskStatusApiCallId:
        if (this.state.isSubmitForReview || this.state.isAcceptEvent || this.state.isReassign) {
          this.goToKanban();
        }
        this.getProjectTasks();
        this.getTaskDetails();
        this.setState({ isEditMode: this.state.taskDetails?.attributes.status === 'in_progress' ? true : false, isLoading: false, openModal: false, isSubmitForReview: false, isAcceptEvent: false, isReassign: false });
        break;
      case this.getCommentsApiCallId:
        this.handleTaskCommentResp(responseJson)
        break;
      case this.getSubTaskCommentsApiCallId:
        this.handleSubtaskCommentResp(responseJson)
        break;
      case this.addCommentApiCallId:
        this.setState({ commentPage: 1 }, () => this.getComments('task'));
        break;
      case this.addSubtaskCommentApiCallId:
        this.setState({ subtaskCommentPage: 1 }, () => this.getComments('subtask'));
        break;
      case this.updateSubTaskApiCallId:
      case this.changeSubTaskStatusApiCallId:
        this.leaveSubTaskPage();
        this.getTaskDetails()
        this.setState({ isSubmitEvent: false })
        break;
      case this.getSubTaskDetailsApiCallId:
        this.setState({ subtaskDetails: responseJson.data as ISubTask, isLoading: false, role: this.state.role }, () => {
          if (this.state.subtaskDetails) {
            this.setState({
              projectName: this.state.subtaskDetails.attributes.project_title,
              taskName: this.state.subtaskDetails.attributes.task_title,
              subtaskName: this.state.subtaskDetails.attributes.name,
              subtaskDescription: this.state.subtaskDetails.attributes.description ?? "",
              subtaskStatus: this.state.subtaskDetails.attributes.status,
              defaultAttachments: this.state.subtaskDetails.attributes.attachments.map((attachment) => attachment.id),
              attachments: this.state.subtaskDetails.attributes.attachments.map((attachment) => {
                return {
                  id: attachment.id,
                  url: attachment.url,
                  file: null,
                  fileName: attachment.file_name,
                  isLocal: false,
                  size: this.formatFileSize(attachment.byte_size)
                }
              })
            })
          }
        })
    }
  }

  createTaskFromScratchResp = () => {
    if (!this.isProjectManager() && this.state.taskDetails?.attributes.status === 'in_progress' || this.state.isSubmitForReview) {
      this.changeCardStatus('need_review');
    }
    this.getTaskDetails();

    if (this.state.isSaveAction || this.state.isRename || this.state.isDuplicate) {
      this.goToKanban();
    }
    this.setState({
      subTasksArray: this.state.prevSubtasks,
      subtaskToBeDeletedArray: [],
      isEditMode: false,
      isSaveAction: false,
      attachmentsToRemove: [],
      participantAttachmentData: this.state.tempParticipantAttachmentData,
      projectManagerAttachmentData: this.state.tempProjectManagerAttachmentData
    })
  }

  handleSubtaskCommentResp = (responseJson: CommentsResponse) => {
    this.setState((prevState) => {
      const mergedArray = [...prevState.allSubTaskComments, ...responseJson.data];
      mergedArray.sort((first, second) => (moment(second.attributes.created_at).diff(first.attributes.created_at)))
      const uniqueMap = new Map();
      mergedArray.forEach(object => {
        if (!uniqueMap.has(object.id)) {
          uniqueMap.set(object.id, object)
        }
      });
      const newCommentArray = Array.from(uniqueMap.values())
      return { allSubTaskComments: newCommentArray, totalSubtaskCommentPage: responseJson.meta.total_pages, comment: '', isLoading: false, isSubTaskCommentLoading: false }
    });
  }

  handleTaskCommentResp = (responseJson: CommentsResponse) => {
    this.setState((prevState) => {
      const mergedComments = [...prevState.allComments, ...responseJson.data];
      mergedComments.sort((first, second) => (moment(second.attributes.created_at).diff(first.attributes.created_at)))
      const uniqueValues = new Map();
      mergedComments.forEach(object => {
        if (!uniqueValues.has(object.id)) {
          uniqueValues.set(object.id, object)
        }
      });
      const newCommentArray = Array.from(uniqueValues.values())
      return { allComments: newCommentArray, totalCommentPage: responseJson.meta.total_pages, comment: '', isLoading: false, isCommentLoading: false }
    });
  }

  setProjectId = () => {
    this.setState({ projectId: 1 })
  }

  setPMrole = () => {
    this.setState({ role: configJSON.ProjectManagerRole })
  }

  setParticipantRole = () => {
    this.setState({ role: 'Participant' })
  }

  setLoginUserId = () => {
    this.setState({ loginUserId: 107 })
  }

  deCrypt = (salt: string, encoded: string): string => {
    const textToChars = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
    const applySaltToChar = (code: number): number => textToChars(salt).reduce((first, second) => first ^ second, code);
    return encoded
      .match(/.{1,2}/g)
      ?.map((hexa) => parseInt(hexa, 16))
      ?.map(applySaltToChar)
      ?.map((charCode) => String.fromCharCode(charCode))
      .join("") ?? "";
  };

  getTaskDetails = async () => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaskDetailsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/tasks/${this.state.taskId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getProjectDetails = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProjectDetailsApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projectportfolio/projects/${this.state.projectId}`)
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod)
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    runEngine.sendMessage(reqMessage.id, reqMessage)
  }

  getProjectMembers = async () => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectMembersApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projectportfolio/projects/${this.state.projectId}/list_project_members`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getProjectTasks = async () => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    let endPoint = `${configJSON.customColumnEndPoint}/${this.state.projectId}/${configJSON.tasks_kanban_menu}`;
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectTasksApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }


  changeCardStatus = async (changeTo: string) => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.apiContentType,
    };
    const sectionId = this.state.columnStatusAndId.filter((column) => column.status === changeTo)
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changeTaskStatusApiCallId = requestMessage.messageId;
    const httpBody = {
      "task": {
        "status": changeTo,
        "section_id": sectionId[0].id
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.tasksEndPoint}/${this.state.taskId}/status_change`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PUT);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addMoreSubTask = () => {
    const isAllField = this.state.subTasksArray.every((task) => task.subtask.trim() !== "")
    if (isAllField) {
      this.setState((prev) => {
        return { subTasksArray: [...prev.subTasksArray, { subtask: '', id: new Date().getTime(), error: '', isNew: true }] }
      })
    } else {
      this.setState((prev) => {
        const updatedSubtasks = prev.subTasksArray.map((task) => {
          if (task.subtask.trim() === "") {
            return { ...task, error: 'please fill the empty subtask to add more' }
          }
          return task
        })
        return {
          subTasksArray: updatedSubtasks
        }
      })
    }
  }

  handleLoadCommentsOnScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    if (this.state.isCommentLoading) {
      return;
    }
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + offsetHeight >= scrollHeight - 100 && this.state.commentPage !== this.state.totalCommentPage) {
      this.setState((prevState) => {
        return { isCommentLoading: true, commentPage: prevState.commentPage + 1 }
      }, () => this.getComments('task'))
    }
  };


  handleLoadSubtaskCommentsOnScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    if (this.state.isSubTaskCommentLoading) {
      return;
    }
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + offsetHeight >= scrollHeight - 100 && this.state.subtaskCommentPage !== this.state.totalSubtaskCommentPage) {
      this.setState((prevState) => {
        return { isSubTaskCommentLoading: true, subtaskCommentPage: prevState.subtaskCommentPage + 1 }
      }, () => this.getComments('task'))
    }
  };

  onChangeSubTask = (text: string, taskId: number) => {
    this.setState((prev) => {
      const updatedSubTasks = prev.subTasksArray.map((subtask) => {
        if (subtask.id === taskId) {
          return { ...subtask, subtask: text, error: '' }
        }
        return subtask
      })
      return {
        subTasksArray: updatedSubTasks
      }
    })
  }

  removeSubtask = (subtaskId: number) => {
    this.setState((prev) => {
      const updatedArray = prev.subTasksArray.filter((subtask) => {
        if (subtask.id === subtaskId) {
          const deletedSubTask = this.state.prevSubtasks.filter((subTask) => subTask.id === subtaskId);
          if (deletedSubTask.length) {
            const tempTaskToBeDeletedArray = [...this.state.subtaskToBeDeletedArray]
            tempTaskToBeDeletedArray.push(deletedSubTask[0].id)
            this.setState({ subtaskToBeDeletedArray: tempTaskToBeDeletedArray })
          }
          return false;
        }
        return true;
      });
      return { subTasksArray: updatedArray }
    })
  }

  setNewMember = (member: IMemberList) => {
    this.setState({ selectedMember: { id: member.id, name: member.full_name, profile: member.profile_image }, anchorEl: null })
  }

  goToKanban() {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'KanbanBoard');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.projectId);
    this.send(message);
  }

  onCancel = () => {
    if (this.state.isEditMode) {
      this.setState({
        subTasksArray: this.state.prevSubtasks,
        subtaskToBeDeletedArray: [],
        isEditMode: false,
        commentPage: 1,
        totalCommentPage: 1,
        attachmentsToRemove: [],
        participantAttachmentData: this.state.tempParticipantAttachmentData,
        projectManagerAttachmentData: this.state.tempProjectManagerAttachmentData
      })
    } else {
      this.goToKanban()
    }
  }

  leaveSubTaskPage = () => {
    this.setState({ subTaskId: null, comment: '', allSubTaskComments: [], totalCommentPage: 1, subtaskCommentPage: 1 }, () => {
      this.resetTheUrl();
    })
  }

  resetTheUrl = () => {
    const isViewSubTask = window.location.pathname.includes('subTask')
    if (isViewSubTask) {
      this.goToBlock('TaskProjects')
    }
    window.history.pushState(null, '', `${window.location.pathname}`);
    this.getTaskDetails();
    this.getComments('task')
  }
  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    this.send(message);
  }
  renderCancel = () => {
    if (this.isProjectManager() && this.state.taskDetails?.attributes.status === 'need_review' && this.state.isEditMode || (this.state.taskDetails?.attributes.status === 'completed' && this.isProjectManager())) {
      return {
        label: "Re-assign task",
        actionFn: () => {
          this.setState({ isReassign: true, commentPage: 1, totalCommentPage: 1 })
          this.changeCardStatus('in_progress');
        },
        disabled: this.disabledReassignedBtn()
      }
    } else {
      const isCompletedOrOtherAction = this.state.taskDetails?.attributes.status === 'completed' || this.state.isRename || this.state.isDuplicate;
      return { label: isCompletedOrOtherAction ? 'Back to Tasks' : 'Cancel', actionFn: isCompletedOrOtherAction ? () => this.goToKanban() : () => this.onCancel() }
    }
  }

  isNotReviewed = () => {
    return this.isEnabledButton()
  }

  isProjectManager = () => {
    return this.state.role === configJSON.ProjectManagerRole
  }

  handleOpenMemberList = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, listType: 'member-list' })
  }

  handleCloseMenu = () => {
    this.setState({ anchorEl: null })
  }

  openRemoveAttachmentModal = () => {
    this.setState({ openModal: true, modalType: 'remove-attachment' })
  }

  closeModal = () => {
    this.setState({ openModal: false, attachmentToRemoveId: null })
  }

  action = () => {
    if (!this.state.isEditMode) {
      this.setState({ isEditMode: true }, async () => await this.getTaskDetails())
    } else {
      const isAllOld = this.state.subTasksArray.every(subtask => !subtask.isNew)
      if (!isAllOld) {
        this.addSubtasks();
      } else {
        this.createTaskFromScratch()
      }
    }
  }

  isSuperAdmin = () => {
    return this.state.role === 'super_admin';
  }

  disableComment = () => {
    return !this.state.comment.trim() || this.isSuperAdmin()
  }

  openDatePicker: React.MouseEventHandler<HTMLDivElement> = (event) => {
    this.setState({ dueDateAnchorEl: event.currentTarget })
  }

  closeDatePickerOnBlur = () => {
    this.setState({ dueDateAnchorEl: null })
  }

  onChangeDueDate = (date: Date) => {
    this.setState({ labelDate: date, dueDate: moment(date).format('DD/MM/YYYY'), dueDateAnchorEl: null, dueDateError: '' })
  }

  onChangeTaskTitle = (value: string) => {
    this.setState({ taskName: value, taskNameError: '' })
  }

  onChangeTaskDescription = (value: string) => {
    this.setState({ taskDescription: value, taskDescriptionError: '' })
  }

  onChangeComment = (text: string) => {
    this.setState({ comment: text })
  }

  disabledFields = () => {
    return this.isAuthorized() ? this.state.isRename || !this.isProjectManager() || this.state.taskDetails?.attributes.status === 'need_review' : true
  }

  openConfirmToMoveInCompleted = () => {
    this.setState({ openModal: true, modalType: 'move-to-completed' })
  }

  getTernaryBtnData = () => {
    if (this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review' || (this.isProjectManager() && this.state.taskDetails?.attributes.status === 'completed')) {
      return { label: 'Cancel', actionFn: () => this.onCancel() }
    } else {
      return undefined
    }
  }

  disabledForSubmitForReview = () => {
    return this.isAuthorized() ? this.state.taskDetails?.attributes.sub_tasks.findIndex((subtask) => (subtask.status === 'assigned' || subtask.status === "rejected")) !== -1 : true
  }

  isAuthorized = () => {
    const memberId = this.state.taskDetails?.attributes.member.id;
    const loginUserId = this.state.loginUserId;
    return (loginUserId === memberId || this.isProjectManager()) && !this.isProjectNotRunning()
  }

  isEnabledButton = () => {
    return this.isAuthorized() ? false : true
  }

  disabledOnOtherAction = () => {
    return this.isAuthorized() ? this.state.taskDetails?.attributes.status !== 'to_do' : true
  }

  disabledMarkAsCompleted = () => {
    return this.isAuthorized() ? this.state.taskDetails?.attributes.sub_tasks.every((subtask) => subtask.status === 'accepted') === false : true
  }

  disabledReassignedBtn = () => {
    return this.isAuthorized() ? this.isProjectNotRunning() : true
  }

  isProjectNotRunning = () => {
    return this.state.projectStatus !== 'In Progress'
  }

  getActionBtnLabel = () => {
    switch (true) {
      case !this.isProjectManager() && !this.state.isEditMode && this.state.taskDetails?.attributes.status === 'to_do':
        return { label: "Move to inprogress", actionFn: () => { this.changeCardStatus('in_progress'); this.setState({ isEditMode: true }) }, disabled: this.isEnabledButton() }
      case !this.isProjectManager() && !this.state.isEditMode && this.state.taskDetails?.attributes.status === 'in_progress':
        return { label: "View More Details", actionFn: () => this.action() };
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'in_progress':
        return { label: this.state.taskDetails?.attributes.is_reviewed ? "Resubmit for review" : "Submit for review", actionFn: () => { this.setState({ isSubmitForReview: true }); this.createTaskFromScratch() }, disabled: this.disabledForSubmitForReview() };
      case !this.isProjectManager() && !this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { label: "View More Details", actionFn: this.action }
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { label: 'Submitted for review', actionFn: () => { }, disabled: true }
      case this.isProjectManager() && !this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { label: 'View More Details', actionFn: this.action }
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { label: 'Mark as Completed', actionFn: this.openConfirmToMoveInCompleted, disabled: this.disabledMarkAsCompleted() }
      case this.state.taskDetails?.attributes.status === 'completed':
        return { label: 'Completed', actionFn: () => { }, disabled: true }
      case !this.state.isEditMode && this.state.isEdit:
        return { label: 'Edit Task', actionFn: this.action, disabled: this.disabledOnOtherAction() }
      case this.state.isEdit && this.state.isEditMode:
        return { label: 'Save', actionFn: () => { this.setState({ isSaveAction: true }, () => this.action()); }, disabled: this.disabledOnOtherAction() }
      case !this.state.isEditMode && this.state.isDuplicate:
        return { label: "View More Details", actionFn: this.action, disabled: this.isEnabledButton() };
      case this.state.isEditMode && this.state.isDuplicate:
        return { label: "Duplicate Task", actionFn: this.action, disabled: this.isEnabledButton() };
      case !this.state.isEditMode && this.state.isRename:
        return { label: "View More Details", actionFn: this.action };
      case this.state.isRename:
        return { label: "Rename Task", actionFn: this.action, disabled: this.isEnabledButton() };
      default:
        return { label: 'View More Details', actionFn: this.action, disabled: this.disabledOnOtherAction() };
    }
  }

  handleBrowseFiles = () => {
    this.PMAttachmentUploadRef.current?.click()
  }

  handleParticipantBrowseFiles = () => {
    this.ParticipantAttachmentUploadRef.current?.click()
  }

  removeAttachment = () => {
    this.setState({ attachment: null, selectedAttachment: '' })
  }

  AnswerFileChange = () => {
    const file = this.ParticipantAttachmentUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) { this.setState({ openMessage: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', messageType: "error" }); return; }
    if (file) {
      this.setState({ participantAttachmentData: [...this.state.participantAttachmentData, { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }], anchorEl: null, attachment: null })
    }

    if (this.ParticipantAttachmentUploadRef.current) {
      this.ParticipantAttachmentUploadRef.current.value = '';
    }
  }

  ProjectManagerAttachmentFileChange = () => {
    const file = this.PMAttachmentUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) {
      this.setState({ openMessage: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', messageType: "error" })
      return;
    }
    if (file) {
      this.setState({ projectManagerAttachmentData: [...this.state.projectManagerAttachmentData, { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }], anchorEl: null, attachment: null })
    }

    if (this.PMAttachmentUploadRef.current) {
      this.PMAttachmentUploadRef.current.value = '';
    }
  }

  removeParticipantArrayAttachment = () => {
    this.setState((prev) => {
      if (this.state.defaultAttachments.includes(this.state.attachmentToRemoveId as number)) {
        this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.attachmentToRemoveId as number] })
      }
      const filteredAttachment = prev.participantAttachmentData.filter((attachment) => attachment.id !== this.state.attachmentToRemoveId)
      return { participantAttachmentData: filteredAttachment }
    }, () => this.closeModal())
  }

  openAttachmentRemoveConfirmation = (attachmentId: number, type: "PM" | "PT") => {
    this.setState({ openModal: true, modalType: 'remove-attachment', attachmentToRemoveId: attachmentId, currentRemovingAttachmentType: type })
  }

  removePMArrayAttachment = () => {
    this.setState((prev) => {
      if (this.state.defaultAttachments.includes(this.state.attachmentToRemoveId as number)) {
        this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.attachmentToRemoveId as number] })
      }
      const filteredAttachment = prev.projectManagerAttachmentData.filter((attachment) => attachment.id !== this.state.attachmentToRemoveId)
      return { projectManagerAttachmentData: filteredAttachment }
    }, () => this.closeModal())
  }

  formatFileSize = (size: number) => {
    const killoBytes = size / 1024;
    const megaBytes = killoBytes / 1024;
    if (megaBytes >= 1) {
      return `${megaBytes.toFixed(2)} MB`;
    } else if (killoBytes >= 1) {
      return `${killoBytes.toFixed(2)} KB`;
    } else {
      return `${size} bytes`;
    }
  };

  isEmpty = (value: string) => {
    return value ? value.trim() === "" : Boolean(!value);
  }

  validateFormData = () => {
    let error = false;
    if (this.isEmpty(this.state.taskName)) {
      this.setState({ taskNameError: 'Task name is required!' })
      error = true;
    }

    if (this.isEmpty(this.state.taskDescription)) {
      this.setState({ taskDescriptionError: 'Task description is required!' })
      error = true;
    }

    if (this.state.labelDate as string === '') {
      this.setState({ dueDateError: 'Due date is required!' })
      error = true;
    }

    return error;
  }


  createTaskFromScratch = async () => {
    if (this.validateFormData()) {
      return;
    }
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createTaskFromScratchApiCallId = reqMessage.messageId;
    const formData = new FormData();
    formData.append('task[title]', this.state.taskName.trim());
    formData.append('task[description]', this.state.taskDescription.trim());
    const nonTouchedSubTasks = this.state.subTasksArray.filter((subtask) => !this.state.subtaskToBeDeletedArray.includes(subtask.id)).map((subtask) => ({ id: subtask.id, name: subtask.subtask }))
    const subtaskToBeDeleted = this.state.subtaskToBeDeletedArray.map((subtask) => ({ id: subtask, _destroy: true }))
    formData.append('task[sub_tasks_attributes]', JSON.stringify([...nonTouchedSubTasks, ...subtaskToBeDeleted]));
    formData.append('task[member_id]', JSON.stringify(this.state.selectedMember?.id));
    formData.append('task[due_date]', this.state.dueDate);

    if (this.state.projectManagerAttachmentData) {
      this.state.projectManagerAttachmentData.forEach((attachment) => {
        if (attachment.file)
          formData.append('task[new_pm_attachments][]', attachment.file as unknown as Blob)
      }
      )
    }

    if (this.state.participantAttachmentData) {
      this.state.participantAttachmentData.forEach((attachment) => {
        if (attachment.file)
          formData.append('task[new_participant_attachments][]', attachment.file as unknown as Blob)
      }
      )
    }
    if (this.state.attachmentsToRemove.length) {
      formData.append('task[remove_attachments]', JSON.stringify(this.state.attachmentsToRemove))
    }

    let endPoint = `bx_block_tasks/tasks/${this.state.taskId}`;
    let method = configJSON.PUT
    if (this.state.isDuplicate) {
      endPoint = `bx_block_tasks/tasks?project_id=${this.state.projectId}`;
      method = configJSON.POST;
    }
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  addSubtasks = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": 'application/json'
    };

    const payload = {
      "task": {
        "sub_tasks_names": this.state.subTasksArray.filter((subtask) => subtask.subtask && subtask.isNew).map((element) => element.subtask)
      }
    }

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addSubtaskApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/tasks/${this.state.taskId}/add_subtask`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PUT);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  addComment = async (type: string) => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": 'application/json'
    };
    const payload = {
      "comment": {
        "commentable_id": type === 'task' ? this.state.taskId : this.state.subTaskId,
        "commentable_type": type === 'task' ? "BxBlockTasks::Task" : "BxBlockTasks::SubTask",
        "comment": this.state.comment.trim()
      }
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    if (type === 'task') {
      this.addCommentApiCallId = reqMessage.messageId;
    } else {
      this.addSubtaskCommentApiCallId = reqMessage.messageId;
    }
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_comments/comments`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getComments = async (type: string) => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    let taskId = this.state.taskId;
    let page = this.state.commentPage;
    if (type === 'task') {
      this.getCommentsApiCallId = reqMessage.messageId;
    } else {
      this.getSubTaskCommentsApiCallId = reqMessage.messageId;
      taskId = this.state.subTaskId;
      page = this.state.subtaskCommentPage;
    }
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_comments/comments/${taskId}/task_comments/?page=${page}&per_page=10&query=${this.state.subTaskId ? "subtask" : ''}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  formatDueDate = (date: string | undefined) => {
    return moment(date).format('DD MMM, YYYY')
  }

  formatCommentTileTime = (date: string | undefined) => {
    return moment(date).format('MMM DD, YYYY h:mm A');
  }

  handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  getMenuStyle = (stateLabel: string, sortParameter: string) => {
    return stateLabel === sortParameter ? {
      backgroundColor: 'rgb(240, 232, 255)',
      borderRadius: '8px',
      fontSize: '14px',
      color: '#334155',
      fontFamily: "'Inter', sans-serif",
      lineHeight: '22px'
    } : {
      color: '#334155',
      lineHeight: '22px',
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
      minHeight: 'auto'
    };
  }

  clickSubTask = (subTaskId: number) => {
    const newUrl = `${window.location.pathname}?subtask=${subTaskId}`;
    window.history.pushState(null, '', newUrl);
    this.handleNavigateToSubTask(subTaskId)
  }

  // Subtask controller 

  openRemoveSubtaskAttachmentModal = (attachmentId: number) => {
    this.setState({ openModal: true, modalType: 'remove-subtask-attachment', subtaskAttachmentIdToRemove: attachmentId })
  }

  isInNeedReview = () => {
    return this.state.taskDetails?.attributes.status === 'need_review'
  }

  cancelBtnData = () => {
    return { label: 'Back to task', actionFn: this.onCancelSubTask }
  }

  onCancelSubTask = () => {
    this.leaveSubTaskPage()
  }

  browseFiles = () => {
    this.AttachmentRef.current?.click()
  }

  fileChange = () => {
    const file = this.AttachmentRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) { this.setState({ message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', openMessage: true, messageType: "error" }); return; }
    if (file) {
      this.setState({ attachments: [...this.state.attachments, { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }] })
    }

    if (this.AttachmentRef.current) {
      this.AttachmentRef.current.value = '';
    }
  }

  removeSubTaskAttachment = () => {
    this.setState((prev) => {
      if (this.state.defaultAttachments.includes(this.state.subtaskAttachmentIdToRemove as number)) {
        this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.subtaskAttachmentIdToRemove as number] })
      }
      const filteredAttachment = prev.attachments.filter((attachment) => attachment.id !== this.state.subtaskAttachmentIdToRemove)
      return { attachments: filteredAttachment }
    }, () => this.closeModal())
  }

  disabledSubmitBtn = () => {
    return this.isAuthorized() ? (this.state.taskDetails?.attributes.status === 'to_do' && !this.isProjectManager()) ||
      (this.state.taskDetails?.attributes.status === 'in_progress' && this.isProjectManager()) ||
      (this.state.subtaskStatus === 'accepted') : true
  }

  disabledBrowse = () => {
    return this.isAuthorized() ? this.isProjectManager() || this.state.taskDetails?.attributes.status === 'to_do' || this.state.taskDetails?.attributes.status === 'completed' || (this.state.subtaskStatus === 'accepted') || this.state.taskDetails?.attributes.status === 'need_review' || this.state.attachments.length === 7 : true
  }

  disabledFeedback = () => {
    return this.isAuthorized() ? !this.isProjectManager() || this.state.taskDetails?.attributes.status === 'in_progress' : true
  }

  disabledStatus = () => {
    return this.isAuthorized() ? !this.isProjectManager() : true
  }

  onSave = () => {
    if (!this.state.isSubmitEvent) {
      this.setState({ isSubmitEvent: !this.isProjectManager() })
    }
    this.updateSubTask()
  }

  handleOpenReviewActions = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorSubtaskEl: event.currentTarget })
  }

  handleClosePopover = () => {
    this.setState({ anchorEl: null, anchorSubtaskEl: null })
  }

  onChangeSubTaskName = (text: string) => {
    this.setState({ subtaskName: text, subtaskNameError: '' })
  }

  onChangeSubTaskDescription = (text: string) => {
    this.setState({ subtaskDescription: text })
  }

  onClickStatus = (status: string) => {
    this.setState({ subtaskStatus: status, anchorSubtaskEl: null }, () => { this.updateSubTask(); this.changeSubTaskStatus(); })
  }

  disabledSubTaskFields = () => {
    return this.isAuthorized() ? !this.isProjectManager() || this.state.subtaskStatus !== 'assigned' || (this.state.taskDetails?.attributes.status === 'in_progress' && this.isProjectManager()) : true;
  }

  getSubTaskDetails = async () => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSubTaskDetailsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/sub_tasks/${this.state.subTaskId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  updateSubTask = async () => {
    if (this.validateForm()) {
      return;
    }

    const header = {
      token: await getStorageData(configJSON.Token)
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateSubTaskApiCallId = reqMessage.messageId;
    const formData = new FormData();
    formData.append('sub_task[name]', this.state.subtaskName.trim());
    let status = this.state.subtaskStatus;
    if (!this.isProjectManager() && this.state.subtaskDetails?.attributes.status === 'assigned' ||
      this.state.subtaskStatus === 'rejected' && !this.isProjectManager()) {
      status = 'submitted_for_review'
    }

    formData.append('sub_task[status]', status);

    this.state.attachments.forEach((attach) => {
      if (attach.file)
        formData.append('sub_task[attachments][]', attach.file as unknown as Blob)
    })

    if (this.isProjectManager()) {
      formData.append('sub_task[description]', this.state.subtaskDescription?.trim());
    }


    if (this.state.attachmentsToRemove) {
      formData.append('sub_task[remove_attachments]', JSON.stringify(this.state.attachmentsToRemove));
    }
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/sub_tasks/${this.state.subTaskId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  changeSubTaskStatus = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": 'application/json'
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changeSubTaskStatusApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    const payload = {
      status: this.state.subtaskStatus
    }
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/sub_tasks/${this.state.subTaskId}/change_status`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  validateForm = () => {
    let error = false;
    if (this.isEmpty(this.state.subtaskName)) {
      this.setState({ subtaskNameError: 'Subtask name is required!' });
      error = true;
    }

    return error;
  }

  getPrimaryActionButtonLabel = () => {
    if (this.isProjectManager()) {
      return "Save"
    } else {
      const label = this.state.subtaskStatus === 'rejected' ? "Resubmit" : 'Submit'
      return label
    }
  }

  // Subtask controller end

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  showAddModal = () => {
    this.getTasks(this.state.token);
    this.setState({
      name: "",
      dropdownTasks: false,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.getTasks(this.state.token);
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.tasks,
      dropdownTasks: false,
    });
    this.showEditModal();
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };

  // Function to fetch task list from the API
  getTaskLists = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new task list and send it to API
  addTaskList = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch tasks from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import { IAccount, IGroup, Priority, Status, ITask } from "./types";
import { Avatar, MenuItem } from "@mui/material";
import { dueDateCalendar, redCalander } from "./assets";
import CommonPopover from "../../../components/src/CommonPopover";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start

// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <> <Box style={{ boxShadow: "0px 4px 8px 0px #00000008", padding: '24px', borderRadius: '8px', backgroundColor: 'white', cursor: 'move' }} onClick={this.props.taskProps?.onClickTask} >
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box data-test-id="title-wrapper" style={{ width: '80%', cursor: 'pointer' }} >
          <StyledTitle style={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '26px',
            color: '#334155'
          }} data-test-id='grid-card-title' >{this.props.taskProps?.title}</StyledTitle>
        </Box>
        <Box visibility={this.props.taskProps?.hideAction} style={{ marginLeft: 'auto' }} >
          <IconButton data-test-id='grid-kabab-menu' data-taskid={this.props.taskProps?.taskId} onClick={this.props.taskProps?.onClickThreeDots} >
            <MoreVert />
          </IconButton>
        </Box>
      </Box>
      <Box style={{ marginBottom: '20px' }} >
        <StyledCardDescription style={{
          color: '#64748B',
          lineHeight: '18px',
          fontSize: '12px',
          fontWeight: 400,
        }}>
          {this.props.taskProps?.description.slice(0, 120)}
        </StyledCardDescription>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }} >
          <img src={this.isPastDueDate() ? dueDateCalendar : redCalander} style={{ marginRight: '5px' }} alt="calendar_icon" />
          <Typography style={this.isPastDueDate() ? webStyles.timeStamp : webStyles.timeStampPast} >{configJSON.DueBy} {this.handleFormateTime(this.props.taskProps?.dueDate as string)}</Typography>
        </Box>
        <Box onMouseEnter={this.handleOpenMemberList} onMouseLeave={this.onClosePopover}>
          <Avatar alt={this.props.taskProps?.avatarProps.full_name} style={{ width: '24px', height: '24px', marginRight: '10px', textTransform: 'capitalize' }} src={this.props.taskProps?.avatarProps.image} />
        </Box>
      </Box>
    </Box>
      <CommonPopover isOnHover anchorEl={this.state.anchorEl} onClose={this.onClosePopover} menuList={<MenuItem style={{ color: '#334155', fontSize: '14px', lineHeight: '22px', minHeight: 'auto', padding: '6px 16px', fontFamily: "'Inter', sans-serif", }} >
        <Avatar style={{ width: '24px', height: '24px', marginRight: '10px' }} src={this.props.taskProps?.avatarProps.image} alt={this.props.taskProps?.avatarProps.image} />
        <Typography style={{ color: '#64748B', fontWeight: 400, fontSize: '12px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{this.props.taskProps?.avatarProps.full_name}</Typography>
      </MenuItem>}
      />
    </>
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  timeStamp: {
    color: '#64748B',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    whiteSpace: 'nowrap',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  timeStampPast: {
    color: '#f44336',
    fontSize: '12px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '18px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
};

const StyledTitle = styled(Typography)({
  width: '100%',
  whiteSpace: 'nowrap',
  fontFamily: "'Inter', sans-serif",
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:hover': {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  }
})

const StyledCardDescription = styled(Typography)({
  width: '100%',
  fontFamily: "'Inter', sans-serif",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: 'hidden',
  WebkitLineClamp: 3,
  wordBreak: 'break-all',
})
// Customizable Area End

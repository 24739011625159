// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { Member, IOrganization } from "./ProjectPortfolioController";

export const configJSON = require("./config");

interface ProjectAttributes {
  project_name: string;
  due_date: string;
  description: string;
  status: string;
  members_count: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  total_task_count: number;
  completed_task_count: number;
  members: {
    id: number;
    full_name: string;
    email: string;
    organization_id: {
      id: number;
      name: string;
    }
    profile_image: string;
  }[];
  customs_columns: {
    section: {
      id: number;
      name: string;
      tasks: Task[];
      count: number;
      position: number;
      section_type: string
    }
  }[]
}
interface Task {
  id: number;
  title: string;
  tool_id: number | null;
}
interface Project {
  id: string;
  type: string;
  attributes: ProjectAttributes;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  projectName: string;
  projectDescription: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  loading: boolean;
  startDate: string;
  endDate: string;
  images: { attributes: { public_url: string } }[];
  documents: { attributes: { file_name: string; public_url: string } }[];
  email: string;
  fullName: string;
  phoneNumber: string;
  isLoading: boolean;
  organization: string;
  organizationError: string;
  organizationList: Array<{ label: string, value: number }>;
  openModal: boolean;
  expanded: string | false
  projectDetails: Project | null;
  selectedOrganizationTitles: Array<string | undefined>;
  selectedOrganizations: Array<number | undefined>;
  selectedMemberTitles: Array<string | undefined>;
  selectedMembers: Array<number | undefined>,
  tempSelectedOrganizations: Array<number | undefined>;
  tempSelectedMembers: Array<number | undefined>,
  tempSelectedMemberTitles: Array<string | undefined>;
  tempSelectedOrganizationTitles: Array<string | undefined>;
  projectId: number | null;
  memberList: Array<{ label: string, value: number }>;
  selectedOrgError: string;
  selectedMemberError: string;
  showCommonMessage: boolean;
  message: string;
  messageType: "error" | "success" | "default";
  role: string;
  taskDescriptions: {
    id: number;
    title: string;
    tool_id: number | null
  }[]
  modalType: 'add' | 'remove';
  selectedMemberToRemove: string;
  selectedMemberIdToRemove: number | null
}

interface SS {
  id: any;
}

export default class ProjectPortfolioDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMembersListApiCallId: string = "";
  getMembersApiCallId: string = "";
  addMembersApiCallId: string = "";
  getOrganizationListApiCallId: string = "";
  getProjectDetailsApiCallId: string = "";
  getProjectTasksApiCallId: string = "";
  apiGetProjectbyIDListCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      projectName: "",
      projectDescription: "",
      clientDetails: "",
      teamSize: "",
      projectUrl: "",
      startDate: "",
      endDate: "",
      images: [],
      documents: [],
      email: "",
      fullName: "",
      phoneNumber: "",
      expanded: 'panel1',
      isLoading: false,
      selectedOrganizations: [],
      projectDetails: null,
      selectedMembers: [],
      selectedOrganizationTitles: [],
      tempSelectedOrganizationTitles: [],
      selectedMemberTitles: [],
      tempSelectedOrganizations: [],
      tempSelectedMemberTitles: [],
      tempSelectedMembers: [],
      projectId: null,
      memberList: [],
      organizationError: '',
      organization: '',
      openModal: false,
      organizationList: [],
      selectedMemberError: '',
      selectedOrgError: '',
      showCommonMessage: false,
      message: '',
      loading: false,
      messageType: "default",
      taskDescriptions: [],
      role: 'participant',
      selectedMemberToRemove: '',
      selectedMemberIdToRemove: null,
      modalType: 'add',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        //getName(MessageEnum.PropsData)
        "Dummy text because Enum does not exist");
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors?.[0]?.token) {
        removeStorageData('token');
        this.goToBlock('EmailAccountLoginBlock');
        return;
      }
      if (apiRequestCallId) {
        this.handleResponse(responseJson, apiRequestCallId)
      }
    }
  }

  handleResponse = (responseJson: { accounts: Member[]; message: string; data: Project | IOrganization[] }, apiRequestCallId: string) => {
    switch (apiRequestCallId) {
      case this.getProjectDetailsApiCallId:
        this.setState({ projectDetails: responseJson.data as Project, isLoading: false })
        break;
      case this.getProjectTasksApiCallId:
        this.getAllTasks(responseJson.data as Project)
        break;
      case this.getOrganizationListApiCallId:
        const organizationList: IOrganization[] = responseJson.data as IOrganization[]
        const formattedOrganizationList = organizationList.map((element: IOrganization) => ({ label: element.attributes.name, value: Number(element.id) }))
        this.setState({ organizationList: formattedOrganizationList, isLoading: false })
        break;
      case this.getMembersListApiCallId:
        if (responseJson.accounts) {
          const members = responseJson.accounts
          const formattedMembers = members.map((element: Member) => ({ label: element.email, value: Number(element.id) }));
          const memberValues = formattedMembers.map(member => member.value)
          const memberLabels = formattedMembers.map(member => member.label)
          const filteredValues = this.state.tempSelectedMembers.filter(value => memberValues.includes(value!))
          const filteredLabels = this.state.tempSelectedMemberTitles.filter(label => memberLabels.includes(label!))
          this.setState({ memberList: formattedMembers, isLoading: false, tempSelectedMemberTitles: filteredLabels, tempSelectedMembers: filteredValues, selectedMemberError: "" })
        }
        if (responseJson.message) {
          this.setState({ selectedMemberError: "No data found", memberList: [] })
        }
        break;
      case this.addMembersApiCallId:
        this.handleCloseModal();
        if (responseJson.message) {
          this.getProjectDetails(this.state.projectId as number);
        }
        break;
    }
  }

  async componentDidMount(): Promise<void> {
    const projectId = this.props.navigation.getParam('navigationBarTitleText')
    const encryptedRole = await getStorageData(configJSON.Role);
    const role = this.deCrypt(configJSON.Secretkey, encryptedRole);
    this.setState({ role: role, projectId: projectId });
    this.getOrganizationList()
    this.getProjectDetails(projectId);
    this.getProjectTasks();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.props.navigation.getParam('navigationBarTitleText') !== prevProps.navigation.getParam('navigationBarTitleText')){
      const projectId= this.props.navigation.getParam('navigationBarTitleText')
      this.setState({ projectId: projectId });
      this.getOrganizationList()
      this.getProjectDetails(projectId);
      this.getProjectTasks();
    }
    if (prevState.tempSelectedOrganizations !== this.state.tempSelectedOrganizations) {
      this.getMembersList();
    }
  }

  goBack() {
    this.props.navigation.goBack();
  }

  hideAction = () => {
    return this.state.role !== configJSON.ProjectManagerRole
  }

  deCrypt = (salt: string, encoded: string): string => {
    const textToChars = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
    const applySaltToChar = (code: number): number => textToChars(salt).reduce((first, second) => first ^ second, code);
    return encoded
      .match(/.{1,2}/g)
      ?.map((hexa) => parseInt(hexa, 16))
      ?.map(applySaltToChar)
      ?.map((charCode) => String.fromCharCode(charCode))
      .join("") ?? "";
  };

  getOrganizationList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token)
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrganizationListApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.organizationListEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProjectDetails = async (projectId: number) => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProjectDetailsApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod)
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${projectId}`)
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    runEngine.sendMessage(reqMessage.id, reqMessage)
  }

  getMembersList = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getMembersListApiCallId = requestMessage.messageId
    if (!this.state.tempSelectedOrganizations.length) {
      this.setState({ memberList: [], tempSelectedMembers: [] });
      return;
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod)
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.searchMembersEndPoint}?organization_ids=[${this.state.tempSelectedOrganizations}]&project_id=${this.state.projectId}`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  addMembers = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.addMembersApiCallId = requestMessage.messageId
    const httpBody = {
      member_ids: this.state.selectedMembers
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${this.state.projectId}/add_members`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putAPiEndMethod)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  removeMembers = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.addMembersApiCallId = requestMessage.messageId
    const httpBody = {
      member_id: this.state.selectedMemberIdToRemove
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${this.state.projectId}/remove_member`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiEndMethod)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getProjectTasks = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const endPoint = `${configJSON.customColumnEndPoint}/${this.state.projectId}/${configJSON.tasks_kanban_menu}`;

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectTasksApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod)
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  getAllTasks = (project: Project) => {
    const allTasks: Task[] = []
    project.attributes.customs_columns.forEach((column) => {
      if (column.section && column.section.tasks) {
        column.section.tasks.forEach(task => {
          allTasks.push(task)
        })
      }
    })
    this.setState({ taskDescriptions: allTasks })
  }
  handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName)
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(message)
  }

  goToWithParams = (block: string, param: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), block);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    this.send(message);
  }

  goToKanbanboard = async () => {
    await setStorageData('task-project-id', this.state.projectId);
    this.goToWithParams('KanbanBoard', JSON.stringify(this.state.projectId))
  }

  goToTaskDetails = async (taskId: number, toolId: number | null) => {
    await setStorageData('task-project-id', this.state.projectId)
    this.goToWithParams('KanbanBoard', JSON.stringify(this.state.projectId))
    const newUrl = `${window.location.pathname}?task=${taskId}&tool=${toolId}`;
    window.location.replace(newUrl)
  }

  handleGoToProjects = () => {
    this.goToBlock(configJSON.BlockProjects)
  }

  handleDeleteMember = () => {
    this.removeMembers()
  }

  handleAddMember = () => {
    let error = false;
    if (!this.state.tempSelectedOrganizations.length) {
      this.setState({ selectedOrgError: "Select atleast one organization" })
      error = true;
    }
    if (!this.state.tempSelectedMembers.length) {
      this.setState({ selectedMemberError: "Select atleast one member" })
      error = true;
    }
    if (!error) {
      this.addMembers();
      this.setState((prevState) => {
        return {
          openModal: false,
          selectedMembers: this.state.tempSelectedMembers,
          selectedMemberTitles: this.state.tempSelectedMemberTitles,
          selectedOrganizations: this.state.tempSelectedOrganizations,
          selectedOrganizationTitles: this.state.tempSelectedOrganizationTitles,
          showCommonMessage: true,
          messageType: "success",
          message: prevState.selectedMembers === this.state.tempSelectedMembers ? "Selected members are already added" : 'Members added successfully'
        }
      });
    }
  }

  onMultiOrganizationSelect = (values: Array<number | undefined>, labels: Array<string | undefined>, _selectedObject: ({ label: string; value: number; } | undefined)[]) => {
    this.setState({ tempSelectedOrganizations: values, selectedOrgError: '', tempSelectedOrganizationTitles: labels })
  }

  onMultiSelectChange = (values: Array<number | undefined>, labels: Array<string | undefined>, _selectedObject: ({ label: string; value: number; } | undefined)[]) => {
    this.setState({ tempSelectedMembers: values, selectedMemberError: '', tempSelectedMemberTitles: labels })
  };

  handleCancelAddMemberdModal = () => {
    this.setState({ openModal: false, selectedMemberError: '', selectedOrgError: '' })
  }

  handleOpenMemberModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    this.setState({ modalType: 'add', openModal: true })
  }

  handleOpenRemoveMemberModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, memberId: number, memberName: string) => {
    event.stopPropagation()
    this.setState({
      openModal: true,
      modalType: 'remove',
      selectedMemberToRemove: memberName,
      selectedMemberIdToRemove: memberId,
      selectedMembers: this.state.projectDetails!.attributes.members.filter(member => member.id !== memberId)?.map((element) => (element.id))
    })
  }
  handleCloseModal = () => {
    this.setState({
      openModal: false,
      tempSelectedMembers: [],
      tempSelectedMemberTitles: [],
      tempSelectedOrganizations: [],
      tempSelectedOrganizationTitles: [],
      selectedMembers: [],
      selectedMemberTitles: [],
      selectedOrganizations: [],
      selectedOrganizationTitles: [],
      selectedMemberToRemove: "",
      selectedMemberError: '',
      selectedOrgError: '',
    })
  }


  handleOpenModal = () => {
    this.setState({
      openModal: true,
      selectedMembers: [],
      selectedMemberTitles: [],
      selectedOrganizations: [],
      selectedOrganizationTitles: [],
    })
  }

  handleCloseCommonMessage = () => {
    this.resetMessage()
  }

  resetMessage = () => {
    this.setState({ showCommonMessage: false, message: '', messageType: 'default' })
  }

  handleFormateTime = (dateString: string) => {
    return moment(dateString).format(configJSON.DateFormat)
  }

}

// Customizable Area End

// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.settingsEndPoint = "/bx_block_kanbanboard/settings"
exports.sectionEndPoint = "/bx_block_kanbanboard/sections"
exports.Role = 'role';
exports.searchEndPoint = "bx_block_advanced_search/search/search"
exports.ProjectManagerRole = "project_manager";
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.Token = 'token';
exports.ParticipantRole = "participant";
exports.visible = "visible";
exports.hidden = "hidden";
exports.menuTypeColumn = 'column';
exports.menuTypeTaskActions = 'task-action';
exports.projectStartTimeFormat = "MMMM D, YYYY";
exports.taskDueDateFormat = "DD MMM, YYYY";
exports.AddColumn = "Add Column";
exports.TypeColumn = "COLUMN";
exports.TypeCustom = "custom";
exports.TypeCard = "CARD";
exports.CreatedBy = "Created by";
exports.ConfirmDeleteTask = "Are you sure you want to delete column from the task?";
exports.MoveTaskHere = "Move task here";
exports.AddTask = "Add Task";
exports.ModalTitleDelete = "Delete Column";
exports.AddColumnId = "add";
exports.NoTask = "No Task found !";
exports.Rename = "Rename";
exports.TryCreatingTasks = "Try creating a new task.";
exports.draggableIdPlaceholder = "card-placeholder";
exports.BtnCancel = "Cancel";
exports.DrapppableColId = "all-columns";
exports.DeleteColumn = "Delete";
exports.BtnDelete = "Delete";
exports.ColumnToDo = "to_do";
exports.Duplicate = "Duplicate";
exports.DueBy = "Due by -";
exports.Taskdesc = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corporis est sapiente, repellat incidunt et voluptate iure aliquam totam eius deleniti quam sed distinctio nihil, tenetur cum excepturi! Est, aliquid? Distinctio."
exports.ColumnNeedReview = "need_review";
exports.ColumnCompleted = "completed";
exports.DELETE = "DELETE";
exports.POST = "POST";
exports.GET = "GET";
exports.PATCH = "PATCH";
exports.PUT = "PUT";
exports.tasksEndPoint = "bx_block_tasks/tasks";
exports.ColumnInProgress = "in_progress";
exports.tasks_kanban_menu = "tasks_kanban_menu";
exports.ContentType = "application/json";
exports.columnDefault = "default";
exports.customColumnEndPoint = "bx_block_kanbanboard/sections";
exports.AddNewColumn = "Add new column";
exports.sectionableType = "BxBlockProjectportfolio::Project";
exports.TaskDescription = "task-description";
exports.PhaseTool = "phases-tool";
exports.AddMembers = "add-member";
exports.AddTaskDetails = "add-task-details";
exports.DatePicker = "date-picker";
exports.phase5DownloadLinkEndPoint = 'bx_block_projecttemplates/phases/phase5_tool_pdf_download?query=';
exports.AddParticipantMessage = "Please add participants to the project in order to create a new task!";
exports.NotStartedMessage = "The project has not started yet. Please change the status to 'In Progress' to add a new task!";
exports.OnHoldMessage = "The project is on hold; you can't add a new task at the moment!";
exports.CannotViewTaskError= "You cannot view the task details because the project is not active.";
exports.CompletedErrorMessage = "The project is completed; you can't add a new task now!";
// Customizable Area End

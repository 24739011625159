Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login/login";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
exports.clientID =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
  exports.googleButtonText = "Sign up With Google";
  exports.facebookButtonText = "Sign up With Facebook";
  
  exports.signUpButtonText = "Sign up with email and password";
  exports.loginGoogleButtonText = "Log in With Google";
  exports.loginFacebookButtonText = "Log in With Facebook";
  
  exports.loginButtonText = "Log in with email and password";
  exports.errorDescription = "#Error fetching data: ";
  exports.errorTitle = "Error";
exports.signUpTitleText = "Sign up";


exports.logInTitleText = "Log In";
exports.signUpBodyText = "Value proposition: why users should sign up.";
exports.orText = "or";
exports.loginFailed = "Something went wrong!"
exports.logInBodyText = "The reason why we require login.";
exports.facebookApiMethod = "GET";
exports.facebookBaseUrl = "https://graph.facebook.com/v2.5/me?fields=email,name,friends&access_token=";
exports.phoneNumberCodes = ["+93","+358","+355","+213","+1684","+376","+244","+1264","+672","+1268","+54","+374","+297","+61","+43","+994","+1242","+973","+880","+1246","+375","+32","+501","+229","+1441","+975","+591","+387","+267","+55","+246","+673","+359","+226","+257","+855","+237","+1","+238","+ 345","+236","+235","+56","+86","+57","+269","+242","+243","+682","+506","+225","+385","+53","+357","+420","+45","+253","+1767","+1849","+593","+20","+503","+240","+291","+372","+251","+500","+298","+679","+358","+33","+594","+689","+241","+220","+995","+49","+233","+350","+30","+299","+1473","+590","+1671","+502","+44","+224","+245","+595","+509","+379","+504","+852","+36","+354","+91","+62","+98","+964","+353","+44","+972","+39","+1876","+81","+44","+962","+77","+254","+686","+850","+82","+965","+996","+856","+371","+961","+266","+231","+218","+423","+370","+352","+853","+389","+261","+265","+60","+960","+223","+356","+692","+596","+222","+230","+262","+52","+691","+373","+377","+976","+382","+1664","+212","+258","+95","+264","+674","+977","+31","+599","+687","+64","+505","+227","+234","+683","+672","+1670","+47","+968","+92","+680","+970","+507","+675","+595","+51","+63","+872","+48","+351","+1939","+974","+40","+7","+250","+262","+590","+290","+1869","+1758","+590","+508","+1784","+685","+378","+239","+966","+221","+381","+248","+232","+65","+421","+386","+677","+252","+27","+211","+500","+34","+94","+249","+597","+47","+268","+46","+41","+963","+886","+992","+255","+66","+670","+228","+690","+676","+1868","+216","+90","+993","+1649","+688","+256","+380","+971","+44","+1","+598","+998","+678","+58","+84","+1284","+1340","+681","+967","+260","+263"];
exports.facebookAppId = "170982444234877";
exports.organizationListEndPoint = "account_block/accounts/get_organizations";
exports.baseUserEndPoint = "account_block/accounts";
exports.searchEndPoint = "bx_block_advanced_search/search/search"
exports.updateUserEndPoint = "edit_user";
exports.disabledUserEndPoint = "disable_user";
exports.downloadDummyCSVEndPoint = 'account_block/accounts/dummy_csv_download'
exports.BulkUploadEndPoint = "account_block/accounts/bulk_upload";
exports.listUsersEndPoint = "account_block/accounts/list_all_users";
exports.listUsersByOrg = "list_users_by_organization";
exports.MethodPost = "POST";
exports.MethodPut = "PUT";
exports.IdealEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.MethodGet = "GET";
exports.MethodDelete = "DELETE";
exports.DownloadReportEndPoint = "account_block/accounts/download_report_file";
exports.MessageTime = 4000;
exports.StringRegex = /\s+/g;
exports.FullNameRegex =  /^[a-zA-Z0-9\s]+$/;
exports.FullName = "Full Name";
exports.EnteredEmailInvalid = "Entered email is invalid!";
exports.DigitRegex = /\D/g;
exports.PhoneNumber = "Phone Number";
exports.Email = "Email";
exports.Organization = "Organization"
exports.Phonecode = "country phone code";
exports.AddUser = "addUser";
exports.Title = "Title";
exports.Token = 'token';
exports.BulkInvite = 'bulkInvite';
exports.FullNamePlaceHolder = "Enter Full Name";
exports.EmailPlaceholder = "Enter Email";
exports.TitlePlaceHolder = "Enter Title";
exports.UploadProgress = "uploadProgress";
exports.FullNameLabel = "Full Name";
exports.PhoneNumberPlaceholder = "Enter Phone Number";
exports.PhoneNumberLabel = "Phone Number";
exports.OrganizationPlaceholder = "Select Organization";
exports.Views = { List : 'list', Grid : 'grid' }; 
exports.TitleLabel = "Title";
exports.RoleLabel = "Role";
exports.Role = 'role';
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.EmailLabel = "Email";
exports.NoUserFound = "No User Found!";
exports.OrganizationLabel = "Organization Name";
exports.PleaseAddOneFileAtATime = "*Please add only one file at a time";
exports.TryCreatingNewUser = "Try creating a new user."
exports.GroupBy = "Group By :";
exports.EditUser = "editUser";
exports.DownloadTemplate = "Download Template";
exports.AttachYourFile = "Attach your File";
exports.UploadYourCSV = "Upload your CSV file here";
exports.DownloadCSVTemplate = "Download CSV Template";
exports.EditUserTitle = "Edit User";
exports.MessageTypes = {Error:'error',Success:'success'};
exports.Disable = "Disable";
exports.UploadLimitations = "Invite up to 500 members using downloadable CSV template. Bulk invites can be sent via Email.";
exports.UserBulkInvite = "User Bulk Invite";
exports.AddNewUser = "Add New User";
exports.Edit = "Edit";
exports.None = "None";
exports.FileUploadedSucessfully = "File Uploaded Successfully";
exports.YourFileHasBeenUploaded = "Your file has been uploaded successfully";
exports.FileNotUploaded = "File Not Uploaded";
exports.FileIsBeingUploaded = "File is being uploaded";
exports.FileUploading = "File Uploading"
exports.PleaseTryAgainUpload = "something went wrong, please retry";
exports.DownloadReport = "Download Report";
exports.PleaseWaitFileIsBeingUploaded = "Please wait your File is uploading";
exports.Cancel = "Cancel";
exports.FileUploadFaild = "Your file upload failed,";
exports.Update = "Update";
exports.Done = "Done";
exports.EnteredPhoneNumberInvalid = "Entered Phone Number is invalid!";
exports.AddUserLabel = "Add User";
exports.NewUserAddedSuccessfully = "New User Added Successfully";
exports.InviteBulkUser = "Invite Bulk User";
exports.CanCreateNewTaskLabel = "Can create new task";
exports.EnterEmailIsTaken = 'Entered email has already been taken!';
exports.UserInformationUpdatedSuccessfully = "User Information Updated Successfully";
exports.SuccessModal = "successModal";
exports.ErrorModal = "errorModal";
exports.errorMsgPhoneNumber = 'phone number should have at least length of 4 digits';
exports.AllUsers = "All Users";
exports.All = "All";

// Customizable Area End
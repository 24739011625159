import React from 'react';
import { IconButton, Typography, Box, InputAdornment, InputBaseComponentProps, InputLabel, TextField, styled } from '@material-ui/core';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface Props {
    onChange?: (text: string) => void;
    value?: unknown;
    type?: "number" | "text" | "email" | "password" | "date";
    placeholder?: string;
    disabled?: boolean;
    helperText?: string;
    error?: boolean;
    label?: string;
    inputProps?: InputBaseComponentProps;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
    multiline?: boolean;
    minRows?: string | number;
    style?: React.CSSProperties;
    fullWidth?: boolean;
    asterisk?: boolean;
    labelStyle?: GenericStyle;
    readOnly?: boolean;
    subLabel?: string;
    iButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
    ref?: ((instance: unknown) => void) | React.RefObject<unknown> | null | undefined
}

interface GenericStyle {
    [key: string]: string;
}

const StyledInputField = styled(TextField)({
    fontFamily: "'Inter', sans-serif",
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5B4595",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "0px",
        fontFamily: "'Inter', sans-serif",
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: "'Inter', sans-serif",
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '10px'
    }
});


const InputField = (props: Props) => {
    const {
        onChange,
        ref,
        value,
        type,
        placeholder,
        disabled,
        error,
        helperText,
        multiline,
        minRows,
        label,
        subLabel,
        inputProps,
        endAdornment,
        startAdornment,
        style,
        fullWidth = false,
        asterisk = false,
        labelStyle,
        iButtonClick = undefined,
        readOnly = false,
        ...rest
    } = props;
    return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
        {label && <Box style={{ display: 'flex', alignItems: 'center', }}>
            <InputLabel style={{ color: '#334155', fontWeight: 700, fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", ...labelStyle }} >
                {label} {asterisk && <span style={{ color: 'red' }} >*</span>}
            </InputLabel>
            {iButtonClick ? <IconButton data-test-id='i' onClick={iButtonClick}
                style={{ padding: '0 20px', width: '16px', height: '16px' }} >
                <InfoRoundedIcon style={{ color: '#64748B', width: '16px', height: '16px' }} />
            </IconButton> : null}
        </Box>}
        {subLabel ? <StyledSubLabel>{subLabel}</StyledSubLabel> : null}
        <StyledInputField
            variant='outlined'
            fullWidth={fullWidth}
            inputProps={inputProps}
            value={value}
            type={type}
            onChange={(event) => onChange && onChange(event.target.value)}
            placeholder={placeholder}
            disabled={disabled}
            helperText={helperText}
            error={error}
            multiline={multiline}
            minRows={minRows}
            style={style}
            {...rest}
            InputProps={{
                readOnly: readOnly,
                startAdornment:
                    <InputAdornment position='start'>
                        {startAdornment}
                    </InputAdornment>,
                endAdornment:
                    <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
            }}
        />
    </Box>
}

export default InputField;

const StyledSubLabel = styled(Typography)({
    '&.MuiTypography-root': {
        color: '#64748B',
        fontSize: '12px',
        lineHeight: '22px',
        width: '100%',
        fontFamily: "'Inter', sans-serif",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: 'hidden',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
    },
    '&:hover': {
        display: "block",
        height: "auto",
        WebkitLineClamp: "unset",
        overflow: "visible",
        whiteSpace: "normal",
    }
})
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Box, Divider, GridSize } from "@mui/material";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

interface BulkUploadAttributes {
  title: string;
  status: string;
  message: string;
  total_rows: number;
  filename: string;
  report_file: string;
  created_count: number;
  account_id: number;
  created_at: string;
}

interface BulkUpload {
  id: string;
  type: string;
  attributes: BulkUploadAttributes;
}

interface BulkUploadResponse {
  [key: string]: BulkUpload;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  currentView: "list" | "grid";
  breakPoints: GridSize;
  anchorEl: HTMLElement | null;
  sortParameterLabel: "Latest" | "Oldest" | "A to Z" | "Z to A",
  sortBy: 'time' | 'status' | 'a-z',
  menuType: "sort" | "download" | "default";
  uploadLogsData: BulkUpload[],
  page: number;
  totalPages: number;
  totalItems: number;
  isLoading: boolean;
  downloadFileLink: string;
  sortDir: "asc" | "desc"
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  getReportLogsDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      token: "",
      currentView: 'list',
      breakPoints: 12,
      anchorEl: null,
      sortParameterLabel: "Latest",
      menuType: "default",
      uploadLogsData: [],
      page: 1,
      totalPages: 1,
      totalItems: 0,
      isLoading: false,
      sortBy: 'time',
      downloadFileLink: '',
      sortDir: 'desc'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        this.handleResponse(apiRequestCallId, responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  handleResponse = (apiRequestCallId: string, responseJson: { data: BulkUpload[], meta: { total_count: number, total_pages: number } }) => {
    if (apiRequestCallId === this.getReportLogsDataApiCallId) {
      this.setState((prevState) => {
        const mergedArray = [...prevState.uploadLogsData, ...responseJson.data];
        const uniqueMap = new Map();
        mergedArray.forEach(object => {
          if (!uniqueMap.has(object.id)) {
            uniqueMap.set(object.id, object);
          }
        });
        const newArray = Array.from(uniqueMap.values());
        return { uploadLogsData: newArray as BulkUpload[], totalItems: responseJson.meta.total_count, totalPages: responseJson.meta.total_pages, isLoading: false }
      })
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getLogsData()
    const currentSelectedView = await getStorageData(configJSON.CurrentView);
    if (currentSelectedView) {
      this.setState({ currentView: currentSelectedView });
    } else {
      this.setCurrentView('list')
    }
  }

  async componentWillUnmount() {
    removeStorageData(configJSON.CurrentView)
  }

  handleScroll: React.UIEventHandler<HTMLDivElement> = async (event) => {
    const { isLoading } = this.state
    if (isLoading) return;
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if ((scrollTop + offsetHeight >= scrollHeight - 100) && this.state.page !== this.state.totalPages) {
      this.setState((prevState) => {
        return { isLoading: true, page: prevState.page + 1 }
      }, () => { this.getLogsData(); });
    }
  };

  handleClickDownload = (link: string) => {
    window.open(link, '_blank')
  }

  handleFormateTime = (dateString: string) => {
    return moment(dateString).format(configJSON.DateFormate);
  }

  getProgressStyle = (updateCount: number, totalCount: number) => {
    return updateCount === totalCount && totalCount !== 0 ? { color: '#475569', fontWeight: 700, fontFamily: "'Inter', sans-serif", } : { color: '#64748B', fontWeight: 400, fontFamily: "'Inter', sans-serif", }
  }

  getProgress = (updateCount: number, totalCount: number) => {
    return totalCount !== 0 ? (updateCount / totalCount) * 100 : 0
  }

  handleSortByTime = () => {
    this.setState((prevState) => {
      const isPrevAlphbeticalSelected = (prevState.sortParameterLabel === 'A to Z' || prevState.sortParameterLabel === "Z to A");
      const resetDir = prevState.sortDir === "asc" ? "desc" : "asc";
      return {
        sortParameterLabel: isPrevAlphbeticalSelected || prevState.sortParameterLabel === "Oldest" ? "Latest" : "Oldest",
        sortBy: configJSON.SortBy.Time.value,
        anchorEl: null,
        menuType: 'default',
        uploadLogsData: [],
        sortDir: isPrevAlphbeticalSelected ? "desc" : resetDir,
        page: 1
      }
    }, () => this.getLogsData())
  }

  handleSortByAZ = () => {
    this.setState((prevState) => {
      return {
        sortParameterLabel: prevState.sortParameterLabel === "A to Z" ? "Z to A" : "A to Z",
        sortBy: configJSON.SortBy.A2Z.value,
        anchorEl: null,
        menuType: 'default',
        uploadLogsData: [],
        sortDir: this.state.sortDir === "asc" && (prevState.sortParameterLabel === 'A to Z' || prevState.sortParameterLabel === "Z to A") ? "desc" : "asc",
        page: 1
      }
    }, () => this.getLogsData())
  }

  handleOpenSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget, menuType: "sort" })
  }

  setCurrentView = async (view: "list" | "grid") => {
    await setStorageData(configJSON.CurrentView, view)
    this.setState({ currentView: view })
  }

  handleCloseKebabMenu = () => {
    this.setState({ anchorEl: null, menuType: "default" })
  }

  handleOpenKebabMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget, downloadFileLink: event.currentTarget.dataset.filelink as string, menuType: 'download' })
  }

  handleDownloadFile = () => {
    window.open(this.state.downloadFileLink, '_blank');
  }

  renderContentLoadProgress = () => (<Box style={{ width: '100%', height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px 24px 24px 28px' }} >
    <Box style={{ width: '100%' }} >
      <Skeleton animation="wave" height={20} width={"50%"} style={{ marginBottom: 10 }} />
      <Skeleton animation="wave" height={16} width={"30%"} />
    </Box>
  </Box>)

  renderCardLoadProgress = () => (<Box style={{ boxShadow: "0px 4px 8px 0px #00000008", padding: '24px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
    <Box style={{ width: '100%' }} >
      <Skeleton animation="wave" height={20} width={"50%"} style={{ marginBottom: 10 }} />
      <Skeleton animation="wave" width={"15%"} style={{ marginBottom: 30, padding: '4px 8px', borderRadius: '18px', height: '38px' }} />
      <Skeleton animation="wave" height={10} width={"80%"} style={{ marginBottom: 10 }} />
      <Divider style={{ marginBottom: '10px' }} />
      <Skeleton animation="wave" height={20} width={"80%"} style={{ marginBottom: 10 }} />
    </Box>
  </Box>
  )

  getLogsData = async () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getReportLogsDataApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.BulkUploadLogsEndPoint}?query=${this.state.sortBy}&page=${this.state.page}&data_type=${this.state.sortDir}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getMenuStyle = (sortParameter: string) => {
    return this.state.sortParameterLabel === sortParameter ? {
      backgroundColor: 'rgb(240, 232, 255)',
      borderRadius: '8px',
      color: '#334155',
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
      lineHeight: '22px'
    } : {
      color: '#334155',
      fontSize: '14px',
      fontFamily: "'Inter', sans-serif",
      lineHeight: '22px',
      minHeight: 'auto'
    };
  }

  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.lastName = "Last name";
exports.labelFirstName = "First name";
exports.labelEmail = "Email";
exports.labelMobile = "Mobile";
exports.labelArea = "Area";
exports.labelCurrentPassword = "Current password";
exports.labelRePassword = "Re-Type Password";
exports.labelNewPassword = "New Password";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextCancelPasswordChange = "Cancel";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.btnTextChangePassword = "Change Password";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorTitle = "Error";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.errorBlankField = "can't be blank";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.PwdConstraints = ["Must contain at least 8 characters.", "Must contain an uppercase letter.", "Must contain a number."];
exports.DigitRegex = /\D/g;
exports.errorEmailNotValid = "Email not valid.";
exports.FullNameRegex = /^[a-zA-Z0-9\s]+$/;
exports.updateProfileEndPoint = "account_block/accounts/change_profile_picture";
exports.hintCountryCode = "Select Country";
exports.StringRegex = /\s+/g;
exports.PATCH = "PATCH";
exports.PasswordError = 'Entered New password is invalid!';
exports.EditProfileEndPoint = "account_block/accounts/my_profile_edit";
exports.ChangePasswordEndPoint = "bx_block_forgot_password/passwords/change_password";
exports.Token = "token";
exports.ConfirmNewPasswordError = 'The password entered do not match to new password';
exports.MyProfileEndPoint = "account_block/accounts/my_profile";
exports.profilePic = "profilePic";
exports.OldPasswordError = "Entered Old password is invalid!"
exports.ChangeProfilePicture = "Change Profile Picture";
exports.IdealPasswordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
exports.FullName = "Full Name";
exports.editProfile = "editProfile";
exports.EDITPROFILE = "Edit Profile";
exports.Phone = "Phone";
exports.Email = "E-mail";
exports.OrganizationName = "Organization Name";
exports.OldPassword = "Old Password";
exports.EnterPhoneNumber = "Enter Phone Number";
exports.NewPassword = "New Password";
exports.Role = "Role";
exports.EnterNewPassword = "Enter New Password";
exports.EnterOldPassword = "Enter Old Password";
exports.ConfirmPassword= "Confirm Password";
exports.EnterConfirmPassword = "Enter Confirm Password";
// Customizable Area End


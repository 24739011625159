import { Autocomplete, Avatar, InputLabel, ListItem, ListItemAvatar, ListItemText, TextField, styled } from '@mui/material';
import React from 'react';

interface Props {
    fullWidth?: boolean;
    dataList: Array<{ title: string, icon: string }>
    label?: string;
    onChange?: (value: { title: string, icon: string } | null) => void;
    value?: { title: string, icon: string } | null;
    placeholder?: string;
    error?: boolean;
    helperText?: string;
}

const CommonAutoComplete = (props: Props) => {
    const { dataList, label, onChange, value, placeholder, helperText, error, fullWidth = true } = props;
    return <>
        {label && <InputLabel style={{ color: '#334155', fontWeight: '700', fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{label} </InputLabel>}
        <Autocomplete
            fullWidth={fullWidth}
            data-test-id='auto-complete'
            options={dataList}
            value={value}
            onChange={(_, value) => onChange && onChange(value)}
            getOptionLabel={(option) => option.title}
            ListboxProps={{ style: { padding: '0', boxShadow: 'none' } }}
            slotProps={{ paper: { style: { boxShadow: "0px 2px 4px 0px #0000001A", background: "trasparent", borderRadius: '8px' } } }}
            renderOption={(props, option) => (
                <ListItem style={{ backgroundColor: option.title === value?.title ? '#DEDAEA' : 'white', color: '#475569', borderRadius: "8px", height: '56px', fontFamily: "'Inter', sans-serif", }} {...props}>
                    <ListItemAvatar>
                        <Avatar style={{ width: '32px', height: '32px', fontFamily: "'Inter', sans-serif", }} src={option.icon} />
                    </ListItemAvatar>
                    <ListItemText style={{ fontSize: '14px', color: '#475569', fontFamily: "'Inter', sans-serif", }} primary={option.title} />
                </ListItem>
            )}
            popupIcon={<></>}
            renderInput={(params) => <StyledInputField error={error} data-test-id='input' helperText={helperText} placeholder={placeholder} {...params} variant="outlined" />}
        />
    </>
}

export default CommonAutoComplete;

const StyledInputField = styled(TextField)({
    fontFamily: "'Inter', sans-serif",
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5B4595",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "10px"
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: "'Inter', sans-serif",
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '10px'
    }
});
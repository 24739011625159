import { Box, Typography, styled } from '@material-ui/core';
import React from 'react';

interface Props {
  children: React.ReactNode;
  title?: string | number;
  subTitle?: string | number;
  thumbnail?: string;
}

const StyledPageWrapper = styled(Box)({
  display: "flex",
  backgroundColor: "#EFECF4",
  width: '100%',
  padding: "64px",
  "@media(max-width:576px)": {
    padding: "0px",
  }
});

const StyledChildWrapper = styled(Box)({
  borderRadius: "16px",
  padding: "64px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: '50%',
  "@media(max-width:991px)": {
    borderRadius: "16px",
    height: "100%",
    padding: '0',
    width: '100%',
  },
  "@media(max-width:576px)": {
    minHeight: 'auto',
    height: "auto",
  }
});

const StyledChildWrapperParent = styled(Box)({
  display: 'flex',
  borderRadius: "16px",
  backgroundColor: "white",
  minHeight: "calc(100vh - 128px)",
  width: '100%',

  "@media(max-width:991px)": {
    padding: "64px",
  },
  "@media(max-width:576px)": {
    padding: "24px 24px 64px 24px",
    minHeight: 'auto'
  },
});

const StyledAsideWrapper = styled(Box)({
  backgroundColor: "#372959d4",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "50%",
  height: '100%',
  position: 'absolute',
  right: '0',
  "@media(max-width:991px)": {
    display: "none",
  },
});

const StyledDummyBox = styled(Box)({
  borderRadius: "16px",
  padding: "0px 64px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
  width: '50%',
  "@media(max-width:991px)": {
    display: "none",
  },
})



const UnAuthorizedUIWrapper = (props: Props) => {
  const { children, title, subTitle, thumbnail } = props;
  return <Box style={{ display: 'flex', position: 'absolute', width: '100%' }}>
    <StyledPageWrapper>
      <StyledChildWrapperParent>
        <StyledChildWrapper>
          {children}
        </StyledChildWrapper>
        <StyledDummyBox>
          <></>
        </StyledDummyBox>
      </StyledChildWrapperParent>
    </StyledPageWrapper>
    <StyledAsideWrapper >
      <Box sx={{ padding: '0 3%' }}>
        {title && <Typography style={{ marginBottom: '20px', fontSize: '30px', textAlign: 'center', fontWeight: '700', color: 'white' }}>{title}</Typography>}
        {subTitle && <Typography style={{ marginBottom: '100px', textAlign: 'center', fontSize: '18px', fontWeight: '400', color: '#94A3B8' }} >{subTitle}</Typography>}
      </Box>
      {thumbnail && <img style={{ width: '400px' }} src={thumbnail} alt="thumbnail_image" />}
    </StyledAsideWrapper>
  </Box>

}
export default UnAuthorizedUIWrapper;
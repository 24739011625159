import { Box, FormHelperText, IconButton, MenuItem, Select, Typography, styled } from '@material-ui/core';
import React from 'react';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface Props {
    value?: string | number | undefined | null;
    fullWidth?: boolean;
    mb?: string;
    onChange?: (text: unknown) => void;
    iButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
    list?: Array<{ label: string, value: string | number | undefined }>;
    asterisk?: boolean;
    placeholder?: string;
    label?: string;
    helperText?: string;
    error?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    subLabel?: string;
    mt?: string;
}

const CommonSelect = (props: Props) => {
    const { value = '', onChange, fullWidth = false, mb = "0px", mt = "0px", iButtonClick, subLabel, disabled = false, list, asterisk, placeholder, label, style, error = false, helperText } = props;

    const renderLabel = (value: unknown) => {
        const filteredNode = list?.filter((element) => element.value === value);
        return filteredNode?.[0]?.label
    }
    return <>
        {label && <Box style={{ display: 'flex', alignItems: 'center' }}> <Typography style={WebStyles.label} >{label} {asterisk && <span style={{ color: 'red' }} >*</span>}</Typography>
            {iButtonClick && <IconButton data-test-id='i' onClick={iButtonClick} style={{ padding: '0 20px', width: '16px', height: '16px', fontFamily: "'Inter', sans-serif", }} > <InfoRoundedIcon style={{ color: '#64748B', width: '16px', height: '16px' }} /></IconButton>}
        </Box>}
        {subLabel ? <Typography style={{ color: '#64748B', fontWeight: '12px', lineHeight: '22px' }}>{subLabel}</Typography> : null}
        <StyledSelect
            value={value}
            disabled={disabled}
            fullWidth={fullWidth}
            variant='outlined'
            onChange={(event) => onChange && onChange(event.target.value as string | number)}
            style={{ marginBottom: mb, marginTop: mt, ...style }}
            IconComponent={ExpandMoreRoundedIcon}
            displayEmpty
            error={error}
            MenuProps={{
                style: { maxHeight: '300px' },
                PaperProps: { style: WebStyles.menuListStyle },
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                getContentAnchorEl: null,
            }}
            renderValue={(value) => typeof value === 'undefined' || value === "" || value === null || !list?.length ? <Typography style={{ color: '#000', opacity: '0.38', fontSize: '16px', fontFamily: "'Inter', sans-serif", }} >{placeholder}</Typography> : <>{renderLabel(value)}</>}
        >
            {list && list?.length > 0 ? list?.map((listItem) => {
                return <MenuItem key={listItem.label} style={{ fontFamily: "'Inter', sans-serif", }} value={listItem.value} >{listItem.label}</MenuItem>
            }) : <MenuItem value={''} style={{ fontFamily: "'Inter', sans-serif", }} disabled >No Item</MenuItem>}
        </StyledSelect>
        {error && <FormHelperText style={{ color: '#f44336', margin: `-${mb} 0 ${mb} 0` }} > {helperText}</FormHelperText >}
    </>
}

export default CommonSelect;


const StyledSelect = styled(Select)({
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #5B4595'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px'
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: "1px solid #CBD5E1",
    },
    height: '56px',
    fontFamily: "'Inter', sans-serif",
})

const WebStyles = {
    label: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: '#334155'
    },
    menuListStyle: {
        border: '1px solid #E2E8F0',
        borderRadius: '8px',
        boxShadow: "0px 4px 8px 0px #00000008",
        backgroundColor: 'white',
        padding: '4px',
        color: '#334155'
    }
}
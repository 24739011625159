import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { activeUserIcon, dashboardActiveIcon, dashboardIcon, gridIcon, gridInActive, listActiveIcon, listIcon, projectActiveIcon, projectsIcon, settingsActiveIcon, settingsIcon, taskActiveIcon, taskIcon, usersIcon } from "./assets";
import React, { createRef } from "react";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

interface Profile {
    id: string;
    type: "account";
    attributes: {
        activated: boolean;
        country_code: string;
        full_name: string;
        email: string;
        phone_number: string;
        full_phone_number: string;
        title: string;
        created_at: string;
        updated_at: string;
        organization: Organization;
        device_id: null | string;
        profile_image: string;
        role: Role;
    };
};

interface Organization {
    name: string;
    id: number;
    created_at: string;
    status: string;
    updated_at: string;
}
interface Meta {
    message: string
    total_count: number
    total_pages: number
}
interface Role {
    id: number;
    created_at: string;
    name: string;
    updated_at: string;
}

interface IActionButton { buttonTxt: string, actionFn: () => void, testId: string }

interface List {
    index: number;
    icon: string;
    activeIcon: string;
    text: string;
    path: string;
    block: string;
    hasSubmenu: boolean;
    subMenu: {
        text: string;
        block: string;
        path: string;
    }[];
}
export interface INotifications {
    id: string;
    type: string;
    attributes: {
        id: number;
        created_by: {
            id: number;
            name: string;
            profile_image_url: string;
        };
        headings: string;
        contents: string;
        app_url: string | null;
        is_read: boolean;
        read_at: string | null
        created_at: string;
        updated_at: string;
        account_id: number;
        notificable_id: number;
        notificable_type: string
        organization_name: string;
        project_name: string
    }
}
const list = [
    { index: 1, icon: dashboardIcon, activeIcon: dashboardActiveIcon, text: configJSON.Dashboard, path: configJSON.pathDashboard, block: configJSON.BlockDashboard, hasSubmenu: false, subMenu: [] },
    { index: 2, icon: projectsIcon, activeIcon: projectActiveIcon, text: configJSON.Projects, path: configJSON.pathProject, block: configJSON.BlockProjects, hasSubmenu: false, subMenu: [] },
    { index: 3, icon: taskIcon, activeIcon: taskActiveIcon, text: configJSON.Tasks, path: configJSON.pathTask, block: configJSON.BlockTaskProjects, hasSubmenu: false, subMenu: [] },
    { index: 5, icon: settingsIcon, activeIcon: settingsActiveIcon, text: configJSON.Settings, path: configJSON.pathSettings, block: configJSON.BlockSettings, hasSubmenu: false, subMenu: [] }
]
export interface Props {
    // Customizable Area Start
    children: React.ReactNode;
    onSearch?: (value: string) => void;
    pageTopBarProps?: { tabProps?: { tabLabel: string, count?: number | null, subTabLabel?: string }, addUser?: IActionButton; bulkInvite?: IActionButton, hideViewControlls?: boolean } | null;
    setCurrentView?: (view: "list" | "grid") => void;
    profilePic?: string;
    setRole?: (value: string) => void;
    setNotificationList?: (data: INotifications[], total: number) => void
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isMobileScreen: boolean;
    openDrawer: boolean;
    clearSearch: boolean;
    searchValue: string;
    showSearchInMobile: boolean;
    activeTab: number;
    currentView: string;
    anchorEl: null | HTMLElement;
    anchorElSearch: null | HTMLElement;
    anchorElNotification: null | HTMLElement;
    openSubMenuIndex: number;
    profileData: Profile | null;
    profilePic: string;
    role: string;
    fullName: string;
    linkList: List[];
    pathName: string;
    notificationList: INotifications[],
    isLogoutModalOpen: boolean;
    page: number;
    scrollLoading: boolean;
    notificationCountNotRead: number;
    notificationTotalPages: number;
    isProjectSelected: boolean;
    isResetNotification: boolean
    // Customizable Area End
}
interface SS { }

export default class DashboardViewWrapperController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProfileDataApiCallId: string = "";
    notificationApiCallId: string = "";
    clearAllNotificiationsApiCallId: string = "";
    readAllNotificiationsApiCallId: string = "";
    listRef: React.RefObject<HTMLUListElement>;
    notificationIntervalId: NodeJS.Timeout | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            isMobileScreen: false,
            openDrawer: false,
            clearSearch: false,
            searchValue: '',
            showSearchInMobile: false,
            activeTab: 0,
            currentView: 'list',
            anchorEl: null,
            openSubMenuIndex: 0,
            profileData: null,
            profilePic: 'temp',
            fullName: '',
            role: 'participant',
            linkList: list,
            anchorElSearch: null,
            pathName: "",
            anchorElNotification: null,
            notificationList: [],
            isLogoutModalOpen: false,
            page: 1,
            scrollLoading: false,
            notificationCountNotRead: 0,
            notificationTotalPages: 0,
            isProjectSelected: false,
            isResetNotification: false
        };
        this.listRef = createRef()
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            if (responseJson?.errors?.[0]?.token) {
                removeStorageData(configJSON.Token);
                this.goToBlock(configJSON.BlockLogin);
                return;
            }

            if (responseJson && !responseJson.errors) {
                switch (apiRequestCallId) {
                    case this.getProfileDataApiCallId:
                        this.handleProfileDataResponse(responseJson)
                        return
                    case this.notificationApiCallId:
                        this.getNotificationResponse(responseJson.data as INotifications[], responseJson.meta as Meta)
                        return
                    case this.clearAllNotificiationsApiCallId:
                        this.props.setNotificationList && this.props.setNotificationList([], 0)
                        this.setState({ notificationList: [] })
                        return
                    case this.readAllNotificiationsApiCallId:
                        const readAllNotifications = this.state.notificationList.map(notification => ({ ...notification, attributes: { ...notification.attributes, is_read: true } }))
                        this.setState({ notificationList: readAllNotifications, notificationCountNotRead: 0 })
                        return
                }
            }
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        await this.handleRedirectOnForNonAuthorized();
        const projectId = await getStorageData('task-project-id');
        if (window.location.pathname.includes('reports')) {
            this.setState({ openSubMenuIndex: 1 })
        }
        this.setState({ pathName: window.location.pathname.slice(1), isProjectSelected: Boolean(projectId) })
        const currentSelectedView = await getStorageData(configJSON.CurrentView);

        this.getProfileDetails();

        if (currentSelectedView) {
            this.setState({ currentView: currentSelectedView })
        } else {
            this.handleChangeView('list')
        }
        this.fetchNotifications()
        this.notificationIntervalId = setInterval(this.fetchNotifications, 60000)
        window.addEventListener('resize', this.handleResize)
        // Customizable Area End
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.profilePic !== this.props.profilePic && this.props.profilePic) {
            this.setState({ profilePic: this.props.profilePic })
        }
        if (JSON.stringify(prevState.notificationList) !== JSON.stringify(this.state.notificationList)) {
            if (this.state.isResetNotification) {
                this.setState({ isResetNotification: false })
                this.setState({ page: 2 })

            } else {
                this.setState({ page: this.state.page + 1 })
            }
        }
    }

    async componentWillUnmount(): Promise<void> {
        if (this.notificationIntervalId) {
            clearInterval(this.notificationIntervalId)
        }
        await removeStorageData('currentView');
    }
    getNotificationResponse = (data: INotifications[], meta: Meta) => {
        let notificationList: INotifications[]
        if (this.state.scrollLoading) {
            notificationList = [...this.state.notificationList, ...data]
        } else {
            notificationList = data
            this.setState({ isResetNotification: true })
        }
        const notificationNotReadCount = notificationList.filter(item => !item.attributes.is_read).length
        if (this.state.page <= 1 || this.state.isResetNotification) {
            this.props.setNotificationList && this.props.setNotificationList(notificationList, meta.total_pages)
        }
        this.setState({ notificationList: notificationList, scrollLoading: false, notificationCountNotRead: notificationNotReadCount, notificationTotalPages: meta.total_pages })

    }
    fetchNotifications = async () => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await getStorageData(configJSON.Token),
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.notificationApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.notificationGetUrl}?per_page=10&page=1`);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    fetchMoreNotifications = async () => {
        this.setState({ scrollLoading: true })
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: await getStorageData(configJSON.Token),
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.notificationApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.notificationGetUrl}?per_page=10&page=${this.state.page}`);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    renderIntervalTime = (timestamp: string) => {
        const now = new Date()
        const date = new Date(timestamp)
        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)
        let interval: number = Math.floor(seconds / 31536000)
        if (interval > 1) return `${interval} years ago`
        if (interval === 1) return `${interval}  year ago`

        interval = Math.floor(seconds / 2592000)
        if (interval > 1) return `${interval}  mths ago`
        if (interval === 1) return `${interval}  mth ago`

        interval = Math.floor(seconds / 86400)
        if (interval > 1) return `${interval} days ago`
        if (interval === 1) return `${interval}  day ago`

        interval = Math.floor(seconds / 3600)
        if (interval > 1) return `${interval} hours ago`
        if (interval === 1) return `${interval}  hour ago`

        interval = Math.floor(seconds / 60)
        if (interval > 1) return `${interval} mins ago`
        if (interval === 1) return `${interval}  min ago`

        return 'just now'
    }
    handleScroll = () => {
        if (this.listRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = this.listRef.current
            if (scrollTop + clientHeight >= scrollHeight && !this.state.scrollLoading) {
                if (this.state.notificationTotalPages >= this.state.page) {
                    this.fetchMoreNotifications()
                }
            }
        }
    }
    goBack() {
        const message: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    deCrypt = (salt: string, encoded: string): string => {
        const TextToChars = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
        const ApplySaltToChar = (code: number): number => TextToChars(salt).reduce((first, second) => first ^ second, code);
        return encoded
            .match(/.{1,2}/g)
            ?.map((hexa) => parseInt(hexa, 16))
            ?.map(ApplySaltToChar)
            ?.map((charCode) => String.fromCharCode(charCode))
            .join("") ?? "";
    };
    handleTaskNavigatioinNotification = (type: string, notificationId: number) => {
        switch (type) {
            case configJSON.Task:
                this.goToBlock('KanbanBoard')
                const newUrl = `${window.location.pathname}?task=${notificationId}`;
                window.location.replace(newUrl)
                break
            case configJSON.Project:
                this.goToBlockWithParams('KanbanBoardWithParams', String(notificationId))
                break;
            case configJSON.SubTask:
                this.goToBlockWithParams('SubTaskDetails', String(notificationId))
                break;
            default:
                break
        }
    }
    handleNavigateRemoveFrom = (contents: string) => {
        if (contents.includes('task')) {
            this.goToBlock('TaskProjects')
        } else {
            this.goToBlock('Projects')
        }
    }
    handleNavigationNotification = async (type: string, heading: string, notificationId: number, contents: string) => {
        this.handleCloseNotification()
        switch (heading) {
            case configJSON.MemberRemoved:
                this.handleNavigateRemoveFrom(contents)
                break;
            case configJSON.PasswordChanged:
                this.goToBlock('UserProfile')
                break;
            case configJSON.ProjectAssigned:
            case configJSON.ProjectReminder:
                this.goToBlockWithParams('ProjectDetails', String(notificationId))
                break;
            case configJSON.TaskDeleted:
            case configJSON.TaskDelivered:
            case configJSON.TaskAccepted:
            case configJSON.TaskRejected:
            case configJSON.TaskAssigned:
            case configJSON.TaskReminder:
                this.handleTaskNavigatioinNotification(type, notificationId)
                break;
            default:
                break;
        }
    }
    goToBlockWithParams = (blockName: string, params: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), params);
        this.send(message);
    }
    handleProfileDataResponse = (responseJson: { data: Profile }) => {
        this.setState({ profileData: responseJson.data }, () => {
            if (this.state.profileData) {
                this.setState({
                    profilePic: this.state.profileData.attributes.profile_image,
                    fullName: this.state.profileData.attributes.full_name,
                    role: this.state.profileData.attributes.role.name,
                }, async () => {
                    await setStorageData("role", this.enCrypt(configJSON.Secretkey, this.state.role));
                    this.props.setRole && this.props.setRole(this.state.role)
                    if (this.state.role === 'project_manager' || this.isSuperAdmin()) {
                        this.handleLinkListInProjectManager()
                    } else {
                        this.handleLinkListInParticipant()
                    }
                })
            }
        })
    }

    handleLinkListInProjectManager = () => {
        if (this.state.linkList.length === 4) {
            list.splice(3, 0, { index: 4, icon: usersIcon, activeIcon: activeUserIcon, text: configJSON.Users, path: configJSON.pathUsers, block: configJSON.BlockUsers, hasSubmenu: false, subMenu: [] })
        }
        const updatedList = list.map((filterElement) => {
            if (filterElement.index === 1 && this.state.role === 'project_manager') {
                return { ...filterElement, hasSubmenu: true, subMenu: [{ text: configJSON.Reports, block: configJSON.BlockReports, path: configJSON.pathReports }] }
            }
            return filterElement;
        });
        this.setState({ linkList: updatedList })
    }

    handleLinkListInParticipant = () => {
        const updatedList = list.map((filterElement) => {
            if (filterElement.index === 1) {
                return { ...filterElement, hasSubmenu: false, subMenu: [] }
            }
            if (filterElement.index === 3) {
                return { ...filterElement, hasSubmenu: this.state.isProjectSelected, subMenu: this.state.isProjectSelected ? [{ text: 'My Tasks', block: 'MyTasks', path: '/tasks/project-list/my/project-tasks/' }, { text: 'All Tasks', block: 'KanbanBoard', path: '/tasks/project-list/all/project-tasks/' }] : [] }
            }
            return filterElement;
        })
        this.setState({ linkList: updatedList })
    }

    enCrypt = (salt: string, text: string): string => {
        const textToChars = (text: string): number[] => text.split("").map((char) => char.charCodeAt(0));
        const byteHex = (number: number): string => ("0" + Number(number).toString(16)).slice(-2);
        const applySaltToChar = (code: number, saltChars: number[]): number => saltChars.reduce((first, second) => first ^ second, code);
        const saltChars = textToChars(salt);
        return text
            .split("")
            .map(textToChars)
            .map((chars) => chars.map((char) => applySaltToChar(char, saltChars)))
            .map((encodedChars) => encodedChars.map(byteHex).join(""))
            .join("");
    };

    handleRedirectOnForNonAuthorized = async () => {
        const encryptedRole = await getStorageData('role');
        const token = await getStorageData(configJSON.Token);
        if (encryptedRole) {
            const role = this.deCrypt(configJSON.Secretkey, encryptedRole);
            if (role === 'participant' && (window.location.pathname === '/users' || window.location.pathname === '/dashboard/reports')) {
                this.goBack()
            }
        }
        if (!token) {
            this.goToBlock(configJSON.BlockLogin);
        }
    }

    handleOpenSubMenu = (menuIndex: number) => {
        if (this.state.openSubMenuIndex === menuIndex) {
            this.handleCloseSubmenu();
        } else {
            this.setState({ openSubMenuIndex: menuIndex })
        }
    }

    isSuperAdmin = () => {
        return this.state.role === 'super_admin'
    }

    handleCloseSubmenu = () => {
        this.setState({ openSubMenuIndex: 0 })
    }

    handleResize = () => {
        const screenWidthSmall = window.innerWidth < 991;
        this.setState({ isMobileScreen: screenWidthSmall, openDrawer: false, showSearchInMobile: false })
    }

    handleOpenDrawer = () => {
        this.setState({ openDrawer: !this.state.openDrawer })
    }

    handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ clearSearch: true, searchValue: event.target.value });
        if (this.state.searchValue && this.props.onSearch) {
            this.props.onSearch(event.target.value);
        }
        this.handleMenuSearchClose()
    }
    handleMenuSearchOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        this.setState({ anchorElSearch: event.currentTarget })
    }

    handleMenuSearchClose = () => {
        this.setState({ anchorElSearch: null })
    }
    handleChangeCurrentFilterSearch = (value: number, block: string) => {
        this.goToBlock(block)
    }
    handleKeyDownForSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && this.state.searchValue && this.props.onSearch) {
            this.props.onSearch(this.state.searchValue);
        }
    }

    handleOpenSearchInMobile = () => {
        this.setState({ showSearchInMobile: true });
    }

    handleClearSearch = () => {
        this.setState({ clearSearch: false, searchValue: "", showSearchInMobile: false })
        if (this.state.searchValue && this.props.onSearch) {
            this.props.onSearch("");
        }
    }
    handleChangeLogoutModal = () => {
        this.setState({ isLogoutModalOpen: !this.state.isLogoutModalOpen })
    }
    getListItemStyle = (listItemPath: string) => {
        const path = window.location.pathname;
        return {
            background: path.includes(listItemPath) ? 'linear-gradient(92.7deg, rgba(222, 218, 234, 0) 0%, #BFB2E8 100%)' : 'white',
            borderRight: path.includes(listItemPath) ? '4px solid #5B4595' : 'none',
            paddingLeft: '32px',
            paddingRight: '32px'
        }
    }

    isCurrentPageActive = (listItemPath: string) => {
        const path = window.location.pathname;
        return path.includes(listItemPath)
    }

    getListItemTextStyle = (listItemPath: string) => {
        const path = window.location.pathname;
        return {
            color: path.includes(listItemPath) ? "#5B4595" : '#94A3B8'
        } as React.CSSProperties
    }

    handleUserLogout = async () => {
        this.setState({ isLogoutModalOpen: false }, async () => {
            await removeStorageData('token');
            await removeStorageData('currentView');
            await removeStorageData('role');
            await removeStorageData('user_id');
            await removeStorageData('task-id');
            await removeStorageData('task-project-id');
            await removeStorageData('groupByParameter');
            this.goToBlock('EmailAccountLoginBlock');
            window.location.reload();
        })
    }

    getGridViewStyles = () => {
        return this.state.currentView === 'grid' ? { icon: gridIcon, backgroundColor: '#7C6AAA' } : { icon: gridInActive, backgroundColor: '#F1F5F9' };
    }

    getListViewStyles = () => {
        return this.state.currentView === "list" ? { icon: listActiveIcon, backgroundColor: '#7C6AAA' } : { icon: listIcon, backgroundColor: '#F1F5F9' };
    }

    goToBlock = (blockName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
        this.send(message);
    }

    a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue })
    }

    handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };
    handleOpenNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorElNotification: event.currentTarget })
    }
    handleCloseNotification = () => {
        this.setState({ anchorElNotification: null });
    };

    handleChangeView = async (view: "list" | "grid") => {
        await setStorageData('currentView', view)
        this.setState({ currentView: view });
        if (this.props.setCurrentView) {
            this.props.setCurrentView(view);
        }
    }

    handleGridView = () => {
        this.handleCloseMenu();
        this.handleChangeView('grid')
    }

    handleListView = () => {
        this.handleCloseMenu()
        this.handleChangeView('list')
    }

    handleAddUser = () => {
        this.handleCloseMenu()
        this.props.pageTopBarProps?.addUser?.actionFn();
    }

    handleBulkInvite = () => {
        this.handleCloseMenu()
        this.props.pageTopBarProps?.bulkInvite?.actionFn();
    }

    formatForPad = (number: string) => {
        return number.padStart(2, '0');
    }

    handleGoToProfile = () => {
        this.goToBlock("UserProfile")
    }
    handleClearAllNotifications = async () => {
        const header = {
            token: await getStorageData(configJSON.Token),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.clearAllNotificiationsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.clearAllNotificationsEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodTypeDelete);
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleReadAllNotifications = async () => {
        const header = {
            token: await getStorageData(configJSON.Token),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.readAllNotificiationsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.readAllNotificationsEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodTypePatch);
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    getProfileDetails = async () => {
        const header = {
            token: await getStorageData(configJSON.Token),
            "Content-Type": configJSON.dashboarContentType,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProfileDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.MyProfileEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area Start
    // Customizable Area End
}

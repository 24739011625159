export const usersIcon = require('../assets/users.png');
export const taskIcon = require('../assets/tasks.png');
export const logo = require('../assets/sidebar_logo.png');
export const settingsIcon = require('../assets/settings.png');
export const searchIcon = require('../assets/search.png');
export const projectsIcon = require('../assets/projects.png');
export const dashboardIcon = require('../assets/dashboard.png');
export const crossIcon = require('../assets/cross.png');
export const rightArrowIcon = require('../assets/right_arrow.png');
export const bellIcon = require('../assets/bell.png');
export const logoutIcon = require('../assets/logout.png');
export const avatarIcon = require('../assets/avatar.png');
export const addIcon = require('../assets/add.png');
export const gridIcon = require('../assets/grid.png');
export const listIcon = require('../assets/list.png');
export const listActiveIcon = require('../assets/listActive.png');
export const gridInActive = require('../assets/gridInactive.png');
export const activeUserIcon = require('../assets/activeUser.png');
export const settingsActiveIcon = require('../assets/settingsActive.png');
export const taskActiveIcon = require('../assets/taskActive.png');
export const projectActiveIcon = require('../assets/projectActive.png');
export const dashboardActiveIcon  = require('../assets/dashboardActive.png')
// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const UploadSvg = require("../assets/upload.png");
export const DeleteSvg = require("../assets/delete.png");
export const FileSvg = require("../assets/file.svg");
export const DownloadSvg = require("../assets/download.png");
export const GreenCheckSvg = require("../assets/greencheck.svg");
export const CalendarIcon = require("../assets/calendar.png");
export const CloseIcon = require("../assets/close.svg");
export const dueDateCalendar = require('../assets/dueDateCalendar.png');
export const cloudUpload = require('../assets/cloud_upload.png');
export const cloudUploadSvg = require('../assets/cloudUpload.svg');
export const arrowArcLeft = require('../assets/ArrowArcLeft.png');
export const arrowArcRight = require('../assets/ArrowArcRight.png');
export const downloadFileIcon = require('../assets/downloadFile.svg');
// Customizable Area End

import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  Tabs,
  Tab,
  MenuItem,
  styled,
  Select,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InputField from "../../../components/src/InputField";
import { Avatar, Grid } from "@mui/material";
import CommonModal from "../../../components/src/CommonModal";
import CommonMessage from "../../../components/src/CommonMessage";
import CommonPopover from "../../../components/src/CommonPopover";
import CommonPageActions from "../../../components/src/CommonPageActions";
import parse, { domToReact, HTMLReactParserOptions, Element as DOMElement } from 'html-react-parser';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { user } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderModalAction = () => {
    return <Box data-test-id='modal-action-btns' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <input
        accept="image/*"
        id="contained-button-file"
        data-test-id='file-upload'
        type="file"
        ref={this.fileUploadRef}
        style={{ display: 'none' }}
        onChange={this.handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <StyledPrimaryButton data-test-id='open-file-select' onClick={this.handleOpenFile}> <FileUploadOutlinedIcon />  Upload</StyledPrimaryButton>
      </label>
      <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
        <ModalSecondaryButton onClick={this.handleCloseModal} >Cancel</ModalSecondaryButton>
        <StyledPrimaryButton disabled={Boolean(this.state.selectedProfilePic === this.state.profilePic)} data-test-id='update' onClick={this.updateProfilePic} >Update</StyledPrimaryButton>
      </Box>
    </Box>
  }

  renderPopOverMenu = (type: string) => {
    return <MenuItem
      data-test-id='change-profile'
      style={{
        backgroundColor: 'rgb(240, 232, 255)',
        borderRadius: '8px',
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
        fontSize: '14px',
        lineHeight: '22px'
      }}
      onClick={this.handleOpenModal}
    >{configJSON.ChangeProfilePicture}</MenuItem>
  }

  renderphoneNumberInput = () => {
    return <StyledSelect
      style={{ display: 'none' }}
      data-test-id='countryCode'
      value={this.state.countryCode}
      onChange={this.handleSelectPhoneInputList}
    >
      {this.state.countryCodeList.length > 0 ? this.state.countryCodeList.map((listItem: { label: string }) => {
        return <MenuItem key={listItem.label} value={listItem.label} >{listItem.label}</MenuItem>
      }) : <MenuItem value={''} disabled >No Item</MenuItem>}
    </StyledSelect>

  }

  renderNewPasswordEye = () => {
    return {
      icon: !this.state.newPasswordEye ? <Visibility /> : < VisibilityOff />,
      type: this.state.newPasswordEye ? "text" : "password"
    }
  }

  renderOldPasswordEye = () => {
    return {
      icon: !this.state.oldPasswordEye ? <Visibility /> : < VisibilityOff />,
      type: this.state.oldPasswordEye ? "text" : "password"
    }
  }

  renderConfirmPasswordEye = () => {
    return {
      icon: !this.state.confirmPasswordEye ? <Visibility /> : < VisibilityOff />,
      type: this.state.confirmPasswordEye ? "text" : "password"
    }
  }

  parseOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof DOMElement) {
        if (domNode.name === 'h2' || domNode.name === 'h1' || domNode.name === 'h3') {
          return (
            <Box>
              <Typography style={{ fontSize: '16px', lineHeight: '26px', marginBottom: '15px', color: 'rgba(51, 65, 85, 1)', fontFamily: "'Inter', sans-serif", }}>
                {domToReact(domNode.children)}
              </Typography>
            </Box>
          );
        }
        if (domNode.name === 'p') {
          return (
            <Box>
              <Typography style={{ fontWeight: 400, fontSize: '14px', lineHeight: '26px', color: 'rgba(100, 116, 139, 1)', marginBottom: '10px', fontFamily: "'Inter', sans-serif", }} >
                {domToReact(domNode.children)}
              </Typography>
            </Box>
          );
        }
      }
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardViewWrapper {...this.props} pageTopBarProps={{ tabProps: { tabLabel: 'General Settings' }, hideViewControlls: true }} profilePic={this.state.profilePic} >
        <Box sx={webStyle.pageWrapper}>
          <Box sx={webStyle.tabsWrapper} >
            <StyledTabs
              TabIndicatorProps={{ hidden: true }}
              orientation={this.state.tabOrientation}
              variant="scrollable"
              data-test-id='tabs'
              value={this.state.currentTab}
              onChange={this.handleChangeTab}
              aria-label="Vertical tabs example"
            >
              <StyledTab style={this.state.currentTab === 0 ? webStyle.activeTabStyle : webStyle.tabStyle} label="Edit Profile" {...this.a11yProps(0)} />
              <StyledTab style={this.state.currentTab === 1 ? webStyle.activeTabStyle : webStyle.tabStyle} label="Change Password" {...this.a11yProps(1)} />
              <StyledTab style={this.state.currentTab === 2 ? webStyle.activeTabStyle : webStyle.tabStyle} label="Company Policy" {...this.a11yProps(2)} />
            </StyledTabs>
          </Box>
          <Box>
            {this.state.currentTab === 0 ?
              <Box>
                <Box sx={webStyle.profileAndNameWrapper}>
                  <Box sx={webStyle.avatarWrapper} >
                    <StyledProfile src={this.state.profilePic || user} alt={this.state.fullName} >
                    </StyledProfile>
                    <Box sx={webStyle.avatarVisibleText} data-test-id='profile' onClick={this.handleOpenProfilePopover} >
                      <CameraAltOutlinedIcon style={{ color: '#BDB5D5', fontWeight: 700 }} />
                      <StyledEditPicText>Edit picture</StyledEditPicText>
                    </Box>
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column' }} >
                    <StyledName >{this.state.fullName}</StyledName>
                    <Box style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                      <Typography style={{ color: "#000000", fontSize: '18px', lineHeight: '24px', fontWeight: 400, fontFamily: "'Inter', sans-serif", }} >Role : </Typography>
                      <Typography style={{ color: "#64748B", fontSize: '18px', lineHeight: '24px', fontWeight: 400, fontFamily: "'Inter', sans-serif", }} > &nbsp;{this.state.role} </Typography>
                    </Box>
                  </Box>
                </Box>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container columnSpacing={5} >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <InputField
                          fullWidth
                          inputProps={{ maxLength: 80 }}
                          data-test-id='full-name'
                          label={configJSON.FullName}
                          value={this.state.tempfullName}
                          onChange={this.onChangeFullName}
                          helperText={this.state.fullNameError}
                          error={Boolean(this.state.fullNameError)}
                          style={{ marginBottom: '20px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <InputField
                          fullWidth
                          label={configJSON.Email}
                          value={this.state.email}
                          endAdornment={
                            <IconButton onClick={this.handleCopyEmail} >
                              <ContentCopyIcon />
                            </IconButton>}
                          style={{ marginBottom: '20px' }}
                          disabled
                        />
                      </Grid>
                      {this.state.role !== 'Super Admin' && <>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                          <InputField
                            data-test-id='phoneNumber'
                            label={configJSON.Phone}
                            fullWidth
                            value={this.state.phoneNumber}
                            onChange={this.onChangePhoneNumber}
                            helperText={this.state.phoneNumberError}
                            error={Boolean(this.state.phoneNumberError)}
                            style={{ marginBottom: '20px' }}
                            inputProps={{ maxLength: 10, style: { paddingLeft: 0 } }}
                            placeholder={configJSON.EnterPhoneNumber}
                            startAdornment={<StyledSelect
                              data-test-id='countryCode'
                              variant="outlined"
                              IconComponent={() => <>-</>}
                              value={this.state.countryCode}
                              onChange={this.handleSelectPhoneInputList}
                              displayEmpty
                              MenuProps={{ style: { maxHeight: '400px' }, PaperProps: { style: webStyle.menuPaperStyle } }}
                            >
                              {this.state.countryCodeList.length > 0 ? this.state.countryCodeList.map((listItem: { label: string }) => {
                                return <MenuItem key={listItem.label} value={listItem.label} >{listItem.label}</MenuItem>
                              }) : <MenuItem value={''} disabled >No Item</MenuItem>}
                            </StyledSelect>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                          {this.state.role === 'Participant' ?
                            <InputField
                              fullWidth
                              label={configJSON.OrganizationName}
                              value={this.state.organization}
                              style={{ marginBottom: '30px' }}
                              disabled
                            /> : <>
                              <Typography style={webStyle.label} >
                                {configJSON.OrganizationName}
                              </Typography>
                              <Box style={webStyle.oranizationListWrapper} >
                                {this.state.organizationList.map((organization) => {
                                  return <li style={webStyle.organizationItem} > {organization}</li>
                                })}
                              </Box>
                            </>}
                        </Grid>
                      </>}
                    </Grid>
                  </Grid>
                  <Grid item ></Grid>
                </Grid>
              </Box>
              : null}
            {this.state.currentTab === 1 ? <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography style={{ fontSize: '16px', lineHeight: '24px', color: "#94A3B8", marginBottom: '10px', fontFamily: "'Inter', sans-serif", }} >In order to protect your account make sure your password  : </Typography>
                <Box style={{ display: 'flex', flexDirection: "column", alignItems: 'start', width: '100%', marginBottom: '20px' }}>
                  <ul style={{ paddingLeft: '20px', margin: '0' }} >
                    {configJSON.PwdConstraints.map((text: string) => <li key={text} style={{ color: '#475569', fontFamily: "'Inter', sans-serif", }} > <Typography style={{ color: "#475569", fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{text} </Typography></li>)}
                  </ul>
                </Box>
                <InputField
                  fullWidth
                  data-test-id='old-password'
                  label={configJSON.OldPassword}
                  type={this.renderOldPasswordEye().type as "password" | "text"}
                  value={this.state.oldPassword}
                  onChange={this.onChangeOldPassword}
                  helperText={this.state.oldPasswordError}
                  error={Boolean(this.state.oldPasswordError)}
                  style={{ marginBottom: '30px' }}
                  placeholder={configJSON.EnterOldPassword}
                  endAdornment={<IconButton
                    aria-label="toggle password visibility"
                    onClick={this.onOldPasswordEyeClick}
                    edge="end"
                  >
                    {this.renderOldPasswordEye().icon}
                  </IconButton>}
                  asterisk
                />
                <InputField
                  fullWidth
                  data-test-id='new-password'
                  label={configJSON.NewPassword}
                  type={this.renderNewPasswordEye().type as "password" | "text"}
                  value={this.state.newPassword}
                  helperText={this.state.newPasswordError}
                  onChange={this.onChangeNewPassword}
                  error={Boolean(this.state.newPasswordError)}
                  style={{ marginBottom: '30px' }}
                  placeholder={configJSON.EnterNewPassword}
                  asterisk
                  endAdornment={<IconButton
                    aria-label="toggle password visibility"
                    onClick={this.onNewPasswordEyeClick}
                    edge="end"
                  >
                    {this.renderNewPasswordEye().icon}
                  </IconButton>}
                />
                <InputField
                  fullWidth
                  data-test-id='confirm-password'
                  label={configJSON.ConfirmPassword}
                  type={this.renderConfirmPasswordEye().type as "password" | "text"}
                  onChange={this.onChangeConfirmPassoword}
                  value={this.state.confirmPassword}
                  helperText={this.state.confirmPasswordError}
                  error={Boolean(this.state.confirmPasswordError)}
                  style={{ marginBottom: '30px' }}
                  placeholder={configJSON.EnterConfirmPassword}
                  asterisk
                  endAdornment={<IconButton
                    aria-label="toggle password visibility"
                    onClick={this.onConfirmPasswordEyeClick}
                    edge="end"
                  >
                    {this.renderConfirmPasswordEye().icon}
                  </IconButton>}
                />
              </Grid>
            </Grid> : null}
            {this.state.currentTab === 2 ? <Box sx={{ fontFamily: 'sans-serif' }}>
              {this.state.companyPolicy && parse(this.state.companyPolicy.content, this.parseOptions)}
            </Box> : null}
          </Box>
        </Box>
        {
          this.state.currentTab !== 2 && <CommonPageActions
            data-test-id='common-actions'
            primary={{ label: this.saveBtnLabel(), actionFn: this.onSave, disabled: this.getIsSaveDisabled() }}
            secondary={{ label: 'Cancel', actionFn: this.cancelEdit, disabled: this.state.cancelDisabled }}
          />
        }

        {this.renderphoneNumberInput()}
        <Loader loading={this.state.isLoading} />

        <CommonMessage data-test-id='message' open={this.state.showCommonMessage} messageType={this.state.messageType} message={this.state.message} onClose={this.onCloseMessage} />

        <CommonPopover
          style={this.state.popOverStyle}
          data-test-id='popover'
          anchorOrigin={this.state.popOverAnchorOrigin}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClosePopOver}
          menuList={this.renderPopOverMenu(this.state.popOverMenuType)}
        />

        <CommonModal data-test-id='modal' modalTitle={configJSON.ChangeProfilePicture} open={this.state.openModal} onClose={this.handleCloseModal} closeIcon actionComponent={this.renderModalAction()} >
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: "200px" }}>
            <img style={{ maxWidth: '500px' }} src={this.state.selectedProfilePic || user} alt="" />
          </Box>
        </CommonModal>
      </DashboardViewWrapper >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  oranizationListWrapper: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    maxHeight: '100px',
    overflowY: 'auto',
  } as React.CSSProperties,
  organizationItem: {
    fontFamily: "'Inter', sans-serif",
    color: 'rgb(51, 65, 85)',
    margin: '10px 0 10px 20px'
  },
  label: {
    color: '#334155',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: "'Inter', sans-serif"
  },
  avatarVisibleText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '100%',
    backgroundColor: '#000000BF'
  } as React.CSSProperties,
  pageWrapper: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'flex-start',
    '@media(max-width:900px)': {
      flexDirection: 'column'
    }
  },
  tabsWrapper: {
    marginRight: '40px',
    '@media(max-width:900px)': {
      width: '100%',
    }
  },
  tabStyle: {
    justifyContent: 'flex-start',
    background: 'white',
    textTransform: 'capitalize',
    color: '#94A3B8',
    fontFamily: "'Inter', sans-serif",
  } as React.CSSProperties,
  activeTabStyle: {
    justifyContent: 'flex-start',
    background: 'linear-gradient(92.7deg, rgba(222, 218, 234, 0) 0%, #BFB2E8 100%)',
    textTransform: 'capitalize',
    color: '#5B4595',
    fontFamily: "'Inter', sans-serif",
  } as React.CSSProperties,
  profileAndNameWrapper: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media(max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '14px'
    }
  },
  menuPaperStyle: {
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    boxShadow: "0px 4px 8px 0px #00000008",
    backgroundColor: 'white',
    padding: '4px',
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
  },
  avatarWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '132px',
    borderRadius: '50%',
    border: '4px solid #EFECF4',
    marginRight: "32px",
    '@media(max-width:600px)': {
      width: '100px',
      marginRight: "0px",
    }
  }
};

const StyledEditPicText = styled(Typography)({
  color: '#BDB5D5',
  fontWeight: 700,
  fontFamily: "'Inter', sans-serif",
  marginTop: '10px',
  '@media(max-width:600px)': {
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '5px'
  }
})

const StyledTabs = styled(Tabs)({
  '&.MuiTabs-root': {
    width: '205px',
    '@media(max-width:900px)': {
      width: '100%',
      marginBottom: '20px'
    }
  }
})

const StyledTab = styled(Tab)({
  '& .MuiTab-wrapper': {
    fontFamily: "'Inter', sans-serif",
    alignItems: 'flex-start'
  },
  '@media(max-width:900px)': {
    width: '100%',
  }
})

const ModalSecondaryButton = styled(Button)({
  height: '56px',
  backgroundColor: '#DEDAEA',
  color: '#5B4595',
  fontFamily: "'Inter', sans-serif",
  textTransform: "capitalize",
  fontWeight: 700,
  width: '120px',
  padding: '16px',
  fontSize: '16px',
  borderRadius: '8px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: '#DEDAEA'
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#64748B',
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

const StyledProfile = styled(Avatar)({
  '& .MuiSvgIcon-root.MuiAvatar-fallback': {
    width: '100%'
  },
  '& .MuiAvatar-img': {
    backgroundColor: 'white'
  },
  '&.MuiAvatar-root': {
    width: '132px',
    height: "132px",
    fontWeight: 600,
    fontSize: '40px',
    textTransform: 'capitalize',
    '@media(max-width:600px)': {
      width: '100px',
      height: '100px'
    }
  }
})

const StyledName = styled(Typography)({
  color: 'black',
  fontWeight: 700,
  fontFamily: "'Inter', sans-serif",
  fontSize: '30px',
  lineHeight: '40px',
  marginBottom: "10px",
  textOverflow: "ellipsis",
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  '@media(max-width:768px)': {
    fontSize: '20px',
    lineHeight: '30px'
  }
})


const StyledPrimaryButton = styled(Button)({
  color: 'white',
  fontFamily: "'Inter', sans-serif",
  backgroundColor: '#5B4595',
  height: '56px',
  textTransform: "capitalize",
  fontWeight: 700,
  width: '120px',
  padding: '16px',
  fontSize: '16px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#5B4595'
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px'
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

const StyledSelect = styled(Select)({
  fontFamily: "'Inter', sans-serif",
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    paddingRight: '10px'
  },
  '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  '&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
  height: '56px'
})
// Customizable Area End

import React from 'react';
import { Backdrop, Box, Snackbar, Typography } from "@material-ui/core"
import { errorMsg, successMsg } from './assets';

interface Props {
    open: boolean;
    onClose: () => void;
    message?: string;
    autoHideDuration?: number;
    messageType?: "success" | "error" | "default"
}

const CommonMessage = (props: Props) => {
    const { open, message, onClose, autoHideDuration, messageType = "default" } = props;
    const elseIcon = messageType === 'error' ? errorMsg : '';
    const icon = messageType === 'success' ? successMsg : elseIcon;
    return <Backdrop open={open} onClick={onClose} style={webStyle.backdropStyle}>
        <Snackbar style={{ width: '100%' }} open={open} onClose={onClose} autoHideDuration={autoHideDuration ?? 3000} >
            {message ? <Box style={webStyle.messageWrapper}>
                <img src={icon} alt='message_icon' />
                <Typography style={{ fontFamily: "'Inter', sans-serif" }} >{message}</Typography>
            </Box> : undefined}
        </Snackbar>
    </Backdrop>
}
export default CommonMessage;

const webStyle = {
    backdropStyle: { zIndex: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: '30px' } as React.CSSProperties,
    messageWrapper: { display: 'flex', alignItems: 'center', background: 'white', boxShadow: "0px 6px 15px -3px #00000026", color: '#475569', padding: '8px 16px 8px 8px', gap: '8px', borderRadius: '8px', width: 'fit-content', maxWidth: '80%' } as React.CSSProperties
}
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { InputLabel } from "@mui/material"
import DateRangeIcon from '@mui/icons-material/DateRange';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FilledInputProps, InputProps, OutlinedInputProps, styled } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

interface Props {
    placeholder?: string;
    onClickOpen?: React.MouseEventHandler<HTMLDivElement>;
    anchorEl?: HTMLElement | null,
    error?: boolean;
    helperText?: string;
    onBlurClose?: () => void;
    value?: ParsableDate;
    disabled?: boolean;
    onChangeDate?: (date: Date) => void;
    label?: string;
    asterisk?: boolean;
    disablePast?: boolean;
    format?: string;
    disableToolbar?: boolean;
    fullWidth?: boolean;
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined;
    style?: React.CSSProperties,
    maxDate?: string;
}

const CommonDatePicker = (props: Props) => {
    const { placeholder, InputProps, style = {}, fullWidth = false, disableToolbar, maxDate, label, format = 'dd/MM/yyyy', disablePast, asterisk, onClickOpen, anchorEl, onBlurClose, error, helperText, value, disabled, onChangeDate } = props;
    return <>
        {label && <InputLabel style={{ color: '#334155', fontWeight: '700', fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{label}  {asterisk && <span style={{ color: 'red' }} >*</span>} </InputLabel>}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StyledDatePicker
                fullWidth={fullWidth}
                disableToolbar={disableToolbar}
                variant="inline"
                clearLabel
                format={format}
                maxDate={maxDate}
                style={style}
                margin="normal"
                data-test-id='date'
                placeholder={placeholder}
                onClick={onClickOpen}
                disablePast={disablePast}
                PopoverProps={{
                    anchorEl: anchorEl,
                    open: Boolean(anchorEl),
                    onClose: onBlurClose,
                }}
                InputProps={InputProps}
                error={Boolean(error)}
                helperText={helperText}
                keyboardIcon={<DateRangeIcon />}
                value={value}
                disabled={disabled}
                onChange={(date) => onChangeDate && onChangeDate(date as unknown as Date)}
            />
        </MuiPickersUtilsProvider>
    </>
}

export default CommonDatePicker;

const StyledDatePicker = styled(KeyboardDatePicker)({
    "& .MuiInput-root": {
        marginTop: "4px",
        fontFamily: "'Inter', sans-serif",
        "@media(max-width:818px)": {
            top: "0",
            margin: "18 0"
        }
    },
    "& .MuiInputAdornment-positionEnd": {
        marginRight: "8px"
    },
    "& .MuiInputBase-root": {
        flexDirection: "row",
        marginBottom: '10px',
        fontFamily: "'Inter', sans-serif",
    },
    "& .MuiInputBase-input": {
        padding: '0 0 0 10px',
        fontFamily: "'Inter', sans-serif",
    },
    "& .MuiFormHelperText-root": {
        position: "relative",
        top: "7px",
        marginBottom: '10px',
        "@media(max-width:818px)": {
            top: "0"
        }
    },
    "& .MuiInput-underline::before": {
        content: "",
        position: "absolute",
        height: "56px",
        bottom: "-18px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
        border: "1px solid #5B4595"
    },
    "& .MuiInput-underline::after": {
        content: "",
        position: "absolute",
        height: "56px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        bottom: "-18px",
        transition: '0s'
    },
    "& .MuiInput-underline.Mui-focused::after": {
        border: "1px solid #CBD5E1",
        transition: '0s'
    },
    "& .MuiInput-underline.Mui-error::after": {
        border: "1px solid red",
        transition: '0s'
    }
})
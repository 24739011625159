import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { PopoverOrigin } from "@mui/material";
import { configJSON as phoneCodes } from '../../social-media-account/src/AllUsersController.web'
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";


interface Profile {
  id: string;
  type: "account";
  attributes: {
    activated: boolean;
    country_code: string;
    email: string;
    full_name: string;
    full_phone_number: string;
    phone_number: string;
    created_at: string;
    title: string;
    assigned_organizations: { id: number; name: string; status: string }[];
    organization: Organization;
    updated_at: string;
    device_id: null | string;
    role: Role;
    profile_image: string;
  };
};


interface Organization {
  id: number;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CompanyPolicy {
  id: number;
  content: string;
  created_at: string;
  updated_at: string;
}

interface PolicyApiResponse {
  company_ploicy: CompanyPolicy;
  message: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentTab: number;
  countryCodeList: { label: string }[];
  countryCode: string;
  anchorEl: HTMLElement | null;
  openModal: boolean;
  selectedProfilePic: string | ArrayBuffer | null | undefined;
  profilePicToUpload: File | null;
  isEditMode: boolean;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  oldPasswordError: string;
  newPasswordError: string;
  confirmPasswordError: string;
  email: string;
  phoneNumberError: string;
  phoneNumber: string;
  fullName: string;
  tempfullName: string;
  fullNameError: string;
  organizationList: string[];
  organization: string;
  profilePic: string;
  role: string;
  profileData: Profile | null;
  showCommonMessage: boolean;
  message: string;
  messageType: "success" | "error" | "default";
  oldPasswordEye: boolean;
  newPasswordEye: boolean;
  confirmPasswordEye: boolean;
  popOverMenuType: string;
  popOverStyle: React.CSSProperties | undefined;
  popOverAnchorOrigin: PopoverOrigin | undefined;
  companyPolicy: CompanyPolicy | null;
  tabOrientation: 'vertical' | "horizontal",
  cancelDisabled: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  updateProfilePicApiCallId: string = "";
  getProfileDataApiCallId: string = "";
  changePasswordApiCallId: string = "";
  getCompanyPolicyApiCallId: string = "";
  updateProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentTab: 0,
      countryCodeList: [],
      countryCode: '+91',
      anchorEl: null,
      openModal: false,
      selectedProfilePic: null,
      isEditMode: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      email: '',
      phoneNumberError: '',
      phoneNumber: '',
      fullName: '',
      tempfullName: '',
      fullNameError: '',
      organization: '',
      organizationList: [],
      profilePic: 'temp',
      role: '',
      profilePicToUpload: null,
      profileData: null,
      showCommonMessage: false,
      message: "",
      messageType: "default",
      oldPasswordEye: false,
      newPasswordEye: false,
      confirmPasswordEye: false,
      popOverMenuType: 'profilePic',
      popOverStyle: {},
      popOverAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      companyPolicy: null,
      tabOrientation: 'vertical',
      cancelDisabled: true,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.handleResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const codeList = phoneCodes.phoneNumberCodes.map((element: string) => ({ label: element, value: element }))
    this.setState({ countryCodeList: codeList, tabOrientation: window.innerWidth <= 900 ? 'horizontal' : 'vertical' })
    this.getProfileDetails();
    this.getCompanyPolicy();
    window.addEventListener('resize', this.handleResize);
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ tabOrientation: window.innerWidth <= 900 ? 'horizontal' : 'vertical' });
  };

  handleResponse = (apiRequestCallId: string, responseJson: { data: Profile; errors: Array<{ [keyName: string]: string }> & { [keyName: string]: unknown } } & PolicyApiResponse) => {
    switch (apiRequestCallId) {
      case this.updateProfileApiCallId:
        this.handlePrePopulate(responseJson)
        break;
      case this.updateProfilePicApiCallId:
        this.setState({ profileData: responseJson.data, isLoading: false }, () => {
          this.setState({ profilePic: this.state.profileData?.attributes.profile_image as string, openModal: false })
        })
        break;
      case this.getProfileDataApiCallId:
        this.handleSettingsProfileResp(responseJson)
        break;
      case this.changePasswordApiCallId:
        const passwordError = responseJson?.errors?.[0]?.password
        this.handlePasswordError(passwordError, responseJson);
        break;
      case this.getCompanyPolicyApiCallId:
        this.setState({ companyPolicy: responseJson.company_ploicy, isLoading: false })
        break;
    }
  }

  handlePasswordError = (passwordError: string, responseJson: { errors: Array<{ [keyName: string]: string }> & { [keyName: string]: unknown } }) => {
    if (/password you entered/.test(passwordError)) {
      this.setState({ isLoading: false, oldPasswordError: responseJson.errors[0].password })
    } else if (/new Password must be different/.test(passwordError)) {
      this.setState({ isLoading: false, newPasswordError: passwordError })
    } else {
      this.setState({ showCommonMessage: true, message: 'Password Changed Successfully', messageType: 'success', oldPassword: "", oldPasswordError: "", newPassword: '', newPasswordError: "", confirmPassword: "", confirmPasswordError: "", isEditMode: false, isLoading: false })
    }
  }

  handleSettingsProfileResp = (responseJson: { data: Profile }) => {
    this.setState({ profileData: responseJson.data, isLoading: false }, () => {
      if (this.state.profileData) {
        const is_ProjectManager = this.state.profileData.attributes.role.name === 'project_manager';
        const is_SuperAdmin = this.state.profileData.attributes.role.name === 'super_admin';
        let role = "Participant";
        let organization = `${this.state.profileData.attributes?.organization?.name}`;
        let organizationList: string[] = [];
        if (is_ProjectManager) {
          organizationList = this.state.profileData.attributes.assigned_organizations.map((orgn) => orgn.name);
          role = "Project Manager";
        }
        if (is_SuperAdmin) {
          role = 'Super Admin'
        }
        this.setState({
          fullName: this.state.profileData.attributes.full_name,
          tempfullName: this.state.profileData.attributes.full_name,
          email: this.state.profileData.attributes.email,
          countryCode: `+${this.state.profileData.attributes.country_code}`,
          phoneNumber: this.state.profileData.attributes.phone_number,
          role: role,
          organization: organization,
          organizationList: organizationList,
          profilePic: this.state.profileData.attributes.profile_image,
        })
      }
    })
  }

  handlePhoneNumberError = (responseJson: { errors: Array<{ [keyName: string]: string }> & { [keyName: string]: unknown } }) => {
    if (responseJson.errors.phone_number) {
      this.setState({ phoneNumberError: "Entered Phone number is invalid!", isLoading: false })
    }
  }


  handlePrePopulate = (responseJson: { data: Profile; errors: Array<{ [keyName: string]: string }> & { [keyName: string]: unknown } }) => {
    if (responseJson.errors) {
      this.handlePhoneNumberError(responseJson)
      return;
    }
    this.setState({ profileData: responseJson.data, isEditMode: false, showCommonMessage: true, message: "Profile changes saved successfully", messageType: "success", isLoading: false }, () => {
      if (this.state.profileData) {
        const IsProjectManager = this.state.profileData.attributes.role.name === 'project_manager';
        const is_SuperAdmin = this.state.profileData.attributes.role.name === 'super_admin';
        let role = "Participant";
        let organization = `${this.state.profileData.attributes?.organization?.name}`;
        if (IsProjectManager) {
          organization = this.state.profileData.attributes.assigned_organizations.map((orgn) => orgn.name).join(', ');
          role = "Project Manager";
        }
        if (is_SuperAdmin) {
          role = 'Super Admin'
        }
        this.setState({
          tempfullName: this.state.profileData.attributes.full_name,
          fullName: this.state.profileData.attributes.full_name,
          countryCode: `+${this.state.profileData.attributes.country_code}`,
          email: this.state.profileData.attributes.email,
          organization: organization,
          phoneNumber: this.state.profileData.attributes.phone_number,
          profilePic: this.state.profileData.attributes.profile_image,
          role: role,
        })
      }
    })
  }

  a11yProps(index: any) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    if (this.state.profileData) {
      this.setState({
        cancelDisabled: true,
        currentTab: newValue,
        confirmPassword: '',
        confirmPasswordError: '',
        newPassword: '',
        newPasswordError: '',
        oldPassword: '',
        oldPasswordError: '',
        tempfullName: this.state.profileData.attributes.full_name,
        fullName: this.state.profileData?.attributes.full_name,
        fullNameError: "",
        phoneNumber: this.state.profileData?.attributes.phone_number,
        phoneNumberError: '',
        countryCode: `+${this.state.profileData?.attributes.country_code}`
      })
    }
  }

  showLoader = () => {
    this.setState({ isLoading: true });
  }

  handleCopyEmail = () => {
    navigator.clipboard.writeText(this.state.email)
  }

  handleSelectPhoneInputList = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ countryCode: event.target.value as string, phoneNumberError: '', cancelDisabled: false })
  }

  onChangePhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text.replace(configJSON.DigitRegex, ''), phoneNumberError: '', cancelDisabled: false })
  }

  onChangeFullName = (text: string) => {
    if (text === '' || configJSON.FullNameRegex.test(text)) {
      this.setState({ tempfullName: text, fullNameError: '', cancelDisabled: false })
    }
  }

  onChangeOldPassword = (text: string) => {
    this.setState({ oldPassword: text, oldPasswordError: "", cancelDisabled: false })
  }

  onChangeNewPassword = (text: string) => {
    this.setState({ newPassword: text, newPasswordError: "", cancelDisabled: false })
  }

  onChangeConfirmPassoword = (text: string) => {
    this.setState({ confirmPassword: text, confirmPasswordError: "", cancelDisabled: false })
  }

  onOldPasswordEyeClick = () => {
    this.setState({ oldPasswordEye: !this.state.oldPasswordEye })
  }

  onNewPasswordEyeClick = () => {
    this.setState({ newPasswordEye: !this.state.newPasswordEye })
  }

  onConfirmPasswordEyeClick = () => {
    this.setState({ confirmPasswordEye: !this.state.confirmPasswordEye })
  }

  onCloseMessage = () => {
    this.setState({ showCommonMessage: false, message: "", messageType: 'default' })
  }

  handleOpenProfilePopover = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, popOverMenuType: configJSON.profilePic, popOverAnchorOrigin: { vertical: 'bottom', horizontal: 'right' }, popOverStyle: { top: '10px', left: '30px' } });
  }

  handleOpenEditProfilePopOver = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, popOverMenuType: configJSON.editProfile, popOverAnchorOrigin: { vertical: 'bottom', horizontal: 'center' }, popOverStyle: {} });
  }

  isEmpty = (entity: string) => {
    return entity.trim() === ''
  }

  saveBtnLabel = () => {
    return this.state.currentTab === 1 ? "Change Password" : "Save";
  }

  handleClosePopOver = () => {
    this.setState({ anchorEl: null, popOverMenuType: '' })
  }

  handleOpenModal = () => {
    this.setState({ openModal: true, anchorEl: null, selectedProfilePic: this.state.profilePic });
  }

  handleCloseModal = () => {
    this.setState({ openModal: false, selectedProfilePic: this.state.profilePic });
  }

  handleOpenFile = () => {
    if (this.fileUploadRef.current) {
      this.fileUploadRef.current.click();
    }
  }

  handleFileChange = () => {
    if (this.fileUploadRef.current) {
      const file = this.fileUploadRef.current.files?.[0] ?? null;
      if (file) {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          this.setState({ selectedProfilePic: event.target?.result, profilePicToUpload: file });
        };
        reader.readAsDataURL(file as Blob);
      }
    }
  }

  getIsSaveDisabled = () => {
    return this.state.currentTab === 1 && (this.isEmpty(this.state.oldPassword) || this.isEmpty(this.state.newPassword) || this.isEmpty(this.state.confirmPassword))
  }

  onSave = () => {
    if (this.state.currentTab === 0) {
      this.handleUpdateProfile();
    } else {
      this.handleChangePassword();
    }
  }

  cancelEdit = () => {
    if (this.state.profileData) {
      this.setState({
        cancelDisabled: true,
        isEditMode: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        oldPasswordError: '',
        newPasswordError: '',
        confirmPasswordError: '',
        tempfullName: this.state.profileData.attributes.full_name,
        fullName: this.state.profileData.attributes.full_name,
        fullNameError: "",
        phoneNumber: this.state.profileData.attributes.phone_number,
        phoneNumberError: '',
        countryCode: `+${this.state.profileData.attributes.country_code}`
      })
    }
  }

  getEmptyErrorMsg = (fieldName: string) => {
    return `${fieldName} is required!`
  }

  getProfileDetails = async () => {
    this.showLoader();
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData(configJSON.Token),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getProfileDataApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.MyProfileEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getCompanyPolicy = async () => {
    this.showLoader();
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData(configJSON.Token),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCompanyPolicyApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_settings/company_policies');
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  updateProfilePic = async () => {
    this.showLoader();
    const header = {
      token: await getStorageData(configJSON.Token)
    };

    const formData = new FormData();
    formData.append("profile_image", this.state.profilePicToUpload as Blob);

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfilePicApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateProfileEndPoint);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleChangePassword = async () => {
    let error = false;
    if (!configJSON.IdealPasswordRegex.test(this.state.oldPassword)) {
      error = true
      this.setState({ oldPasswordError: configJSON.OldPasswordError })
    }
    if (!configJSON.IdealPasswordRegex.test(this.state.newPassword)) {
      error = true
      this.setState({ newPasswordError: configJSON.PasswordError })
    } else if (this.state.confirmPassword !== this.state.newPassword) {
      error = true;
      this.setState({ confirmPasswordError: configJSON.ConfirmNewPasswordError })
    }
    if (error) {
      return;
    }

    this.showLoader();

    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.exampleApiContentType
    };

    const payloadData = {
      new_password: this.state.newPassword,
      old_password: this.state.oldPassword
    };

    const httpBody = {
      data: payloadData
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.changePasswordApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ChangePasswordEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleUpdateProfile = async () => {
    let error = false

    if (this.isEmpty(this.state.tempfullName)) {
      error = true
      this.setState({ fullNameError: this.getEmptyErrorMsg("Full name") })
    }

    if (this.state.role !== 'Super Admin' && this.isEmpty(this.state.phoneNumber)) {
      error = true
      this.setState({ phoneNumberError: this.getEmptyErrorMsg("Phone number") })
    } else if (this.state.role !== 'Super Admin' && this.state.phoneNumber.length < 4) {
      error = true;
      this.setState({ phoneNumberError: 'Phone number should have at least length of 4 digits' });
    }

    if (error) {
      return
    }

    this.showLoader();

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData(configJSON.Token)
    };

    const payloadData = {
      full_phone_number: this.state.countryCode.substring(1).concat(this.state.phoneNumber),
      full_name: this.state.tempfullName.trim().replace(configJSON.StringRegex, ' '),
    };

    const superAdminPayloadData = {
      full_name: this.state.tempfullName.trim().replace(configJSON.StringRegex, ' '),
    };

    const httpBody = {
      data: this.state.role == 'Super Admin' ? superAdminPayloadData : payloadData,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProfileApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.EditProfileEndPoint);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  // Customizable Area End
}

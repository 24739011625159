import { Message } from "../../../../packages/framework/src/Message";
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { IBlock } from "../../../../packages/framework/src/IBlock";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showPassword: boolean;
    showConfirmPassword: boolean;
    passwordChanged: boolean;
    newPassword: string;
    confirmPassowrd: string;
    newPasswordError: string;
    confirmPasswordError: string;
    token: string | null;
    tokenUsedTwiceError: string;
    isLoading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start

    // Customizable Area End
}

export default class NewPasswordWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    changePasswordApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            showPassword: false,
            showConfirmPassword: false,
            passwordChanged: false,
            newPassword: '',
            confirmPassowrd: '',
            newPasswordError: '',
            confirmPasswordError: '',
            token: null,
            tokenUsedTwiceError: '',
            isLoading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        this.setState({ token: token })
        const authToken = await getStorageData("token");
        if (authToken) {
            this.goToBlock('Dashboard')
        }
    }

    handleShowPassword = (name: string) => {
        if (name === 'password') {
            this.setState({ showPassword: !this.state.showPassword })
        } else {
            this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
        }
    }

    getIsBtnShouldDisabled = () => {
        return Boolean(this.state.confirmPassowrd && this.state.newPassword)
    }

    handleChangeNewPassword = (text: string) => {
        this.setState({ newPassword: text, newPasswordError: '' })
    }

    handleChangeConfirmPassword = (text: string) => {
        this.setState({ confirmPassowrd: text, confirmPasswordError: '' })
    }

    backToLogin = () => {
        this.goToBlock('EmailAccountLoginBlock')
    }

    goToBlock = (blockName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    changePassword = () => {
        let error = false;
        if (!configJSON.IdealPasswordRegex.test(this.state.newPassword)) {
            error = true;
            this.setState({ newPasswordError: configJSON.PasswordError })
        } else if (this.state.confirmPassowrd !== this.state.newPassword) {
            error = true;
            this.setState({ confirmPasswordError: configJSON.ConfirmNewPasswordError })
        }
        if (error) return;
        this.setState({ isLoading: true })
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
        };

        const payloadData = {
            token: this.state.token,
            new_password: this.state.newPassword,
        };

        const httpBody = {
            data: payloadData,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.changePasswordApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ChangePasswordEndPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleError = (errors: Array<{ [keyName: string]: string }>) => {
        if (errors?.[0]?.password) {
            this.setState({ newPasswordError: errors[0].password, passwordChanged: false, isLoading: false })
        } else {
            this.setState({ tokenUsedTwiceError: errors?.[0]?.account, passwordChanged: false, isLoading: false })
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.changePasswordApiCallId) {
                    if (responseJson.data) {
                        this.setState({ passwordChanged: true, isLoading: false })
                    } else {
                        this.handleError(responseJson?.errors)
                    }
                    this.parseApiCatchErrorResponse(errorReponse);
                }
            }
        }
        // Customizable Area End
    }
}
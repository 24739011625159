Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiMethodType = "GET";
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.exampleApiContentType = "application/json";
exports.labelBodyText = "settings2 Body";
exports.labelTitleText = "settings2";
exports.btnExampleTitle = "CLICK ME";
exports.errorEmailNotValid = "Email not valid.";
exports.contentTypeApiEditProfile = "application/json";
exports.DigitRegex = /\D/g;
exports.FullNameRegex = /^[a-zA-Z0-9\s]+$/;
exports.hintCountryCode = "Select Country";
exports.updateProfileEndPoint = "account_block/accounts/change_profile_picture";
exports.StringRegex = /\s+/g;
exports.PasswordError = 'Entered new password is invalid!';
exports.PATCH = "PATCH";
exports.EditProfileEndPoint = "account_block/accounts/my_profile_edit";
exports.Token = "token";
exports.ChangePasswordEndPoint = "bx_block_forgot_password/passwords/change_password";
exports.MyProfileEndPoint = "account_block/accounts/my_profile";
exports.ConfirmNewPasswordError = 'The password entered do not match to new password';
exports.OldPasswordError = "Entered old password is invalid!"
exports.IdealPasswordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
exports.editProfile = "editProfile";
exports.profilePic = "profilePic";
exports.Phone = "Phone";
exports.ChangeProfilePicture = "Change Profile Picture";
exports.OrganizationName = "Organization Name";
exports.FullName = "Full Name";
exports.EnterPhoneNumber = "Enter Phone Number";
exports.EDITPROFILE = "EDIT PROFILE";
exports.Role = "Role";
exports.Email = "E-mail";
exports.EnterOldPassword = "Enter Old Password";
exports.OldPassword = "Old Password";
exports.NewPassword = "New Password";
exports.EnterConfirmPassword = "Enter Confirm Password";
exports.PwdConstraints = ["Must contain at least 8 characters.", "Must contain an uppercase letter.", "Must contain a number."];
exports.EnterNewPassword = "Enter New Password";
exports.ConfirmPassword= "Confirm Password";
// Customizable Area End
import { IBlock } from "../../../../packages/framework/src/IBlock";
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { Message } from "../../../../packages/framework/src/Message";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    emailSent: boolean;
    email: string;
    emailError: string;
    isLoading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start

    // Customizable Area End
}

export default class ForgotPasswordWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    forgotPasswordApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            emailSent: false,
            email: '',
            emailError: '',
            isLoading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const token = await getStorageData("token");
        if (token) {
            this.goToBlock('Dashboard')
        }
    }

    handleChangeEmail = (text: string) => {
        this.setState({ email: text, emailError: '' })
    }

    goToBlock = (blockName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(getName(MessageEnum.NavigationTargetMessage), blockName)
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        )
        this.send(message)
    }

    backToLogin = () => {
        this.goToBlock('EmailAccountLoginBlock')
    }

    resetPassword = () => {
        let error = false
        if (!configJSON.IdealEmailRegex.test(this.state.email)) {
            error = true
            this.setState({ emailError: configJSON.EmailError });
        }
        if (error) {
            return;
        }
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
        };

        const attrs = {
            email: this.state.email
        };

        const payloadData = {
            attributes: attrs
        };

        const httpBody = {
            data: payloadData
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.forgotPasswordApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ResetPasswordEndPoint)
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod)
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId && responseJson) {

                if (apiRequestCallId === this.forgotPasswordApiCallId) {
                    if (responseJson.message) {
                        this.setState({ emailSent: true, isLoading: false })
                    } else {
                        this.setState({ emailError: responseJson?.errors?.[0]?.message, emailSent: false, isLoading: false })
                    }
                    this.parseApiCatchErrorResponse(errorReponse);
                }
            }
        }
        // Customizable Area End
    }
}
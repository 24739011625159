import React, { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, Typography, Box, Divider, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    open: boolean;
    children: ReactNode,
    onClose: () => void;
    closeIcon?: boolean;
    actionComponent?: ReactNode;
    modalTitle?: string;
    subTitle?: string;
    hideTopDivider?: boolean;
    hideBottomDivider?: boolean;
    contentBoxStyle?: React.CSSProperties;
    titleBoxStyle?: React.CSSProperties;
}

const CommonModal = (props: Props) => {
    const { children, onClose, subTitle = '', contentBoxStyle = {}, titleBoxStyle = {}, open, closeIcon, actionComponent, modalTitle, hideTopDivider = false, hideBottomDivider = false } = props;
    return <Dialog open={open} onClose={onClose} PaperProps={{ style: { width: '100%', borderRadius: '16px' } }}   >
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px 16px 16px 40px', ...titleBoxStyle }}>
            <Box>
                <Typography style={{ color: '#334155', fontSize: '18px', fontWeight: 700, lineHeight: '26px', fontFamily: "'Inter', sans-serif", }} >{modalTitle}</Typography>
                {subTitle && <Typography style={{ color: '#64748B', fontSize: '16px', lineHeight: '26px', fontFamily: "'Inter', sans-serif", }} >{subTitle}</Typography>}
            </Box>
            {closeIcon && <IconButton data-test-id='closeIcon' aria-label="close" onClick={onClose}>
                <CloseIcon style={{ color: 'black' }} />
            </IconButton>}
        </Box>
        {!hideTopDivider && <Divider />}
        <StyledDialogContent style={contentBoxStyle}>
            {children}
        </StyledDialogContent>
        {!hideBottomDivider && <Divider />}
        <DialogActions style={{ padding: '16px' }} >{actionComponent}</DialogActions>
    </Dialog>
}
export default CommonModal;

const StyledDialogContent = styled(DialogContent)({
    padding: '16px 40px',
})
import React, { useEffect, useState } from 'react';
import { Checkbox, FormHelperText, InputBase, InputLabel, MenuItem, Select, Typography, styled } from "@material-ui/core"
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { checkedIcon, uncheckedIcon, searchIcon } from './assets';

interface Props {
    selectedValues: Array<number | undefined>;
    selectedTitles: Array<string | undefined>;
    fullWidth?: boolean;
    mb?: string;
    onChange: (values: (number | undefined)[], labels: (string | undefined)[], selectedObject: ({ label: string; value: number; } | undefined)[]) => void;
    list: Array<{ label: string, value: number }>;
    asterisk?: boolean;
    placeholder?: string;
    label?: string;
    helperText?: string;
    error?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    isMultiple?: boolean
}


const renderIcon = (icon: string) => {
    switch (icon) {
        case 'uncheck': return <img src={uncheckedIcon} />;
        case 'checked': return <img src={checkedIcon} />;
        case 'search': return <img style={{ width: '25px', marginRight: '8px' }} src={searchIcon} alt="" />
    }
}

const CommonSearchableSelect = (props: Props) => {
    const { label, asterisk, placeholder, mb = '0px', style, selectedValues, selectedTitles, onChange, list, fullWidth, error, helperText, disabled, isMultiple = true } = props;
    const [searchedList, setSearchedList] = useState(list);
    const [selectedIds, setSelectedIds] = useState<Array<number | undefined>>(selectedValues);
    const [selectedLabels, setSelectedLabels] = useState<Array<string | undefined>>(selectedTitles)
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const {
            target: { value },
        } = event;
        const selectedValues = isMultiple ? value as number[] : [value as number];
        const selectedObjects = selectedValues.map((values) => list.find((listItem) => listItem.value === values))
        const selectedVal = selectedObjects.map((item) => item?.value).filter(Boolean);
        const selectedLabel = selectedObjects.map((item) => item?.label).filter(Boolean);
        setSelectedIds(selectedVal);
        setSelectedLabels(selectedLabel);
        onChange(selectedVal, selectedLabel, selectedObjects);
    };

    const handleSearchChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        let filteredNames = list!.filter((name) =>
            name.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchedList(filteredNames)
    }, [searchTerm])

    useEffect(() => {
        const selectedObjects = selectedValues.map((values) => list.find((listItem) => listItem.value === values))
        const selectedVal = selectedObjects.map((item) => item?.value).filter(Boolean);
        const selectedLabel = selectedObjects.map((item) => item?.label).filter(Boolean);
        setSelectedIds(selectedVal);
        setSelectedLabels(selectedLabel);
    }, [selectedTitles, selectedTitles, list])
    return <>
        {label && <InputLabel style={{ color: '#334155', fontWeight: '700', fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{label} {asterisk && <span style={{ color: 'red' }} >*</span>} </InputLabel>}
        <StyledMultiSelect
            disabled={disabled}
            IconComponent={ExpandMoreRoundedIcon}
            multiple={isMultiple}
            error={error}
            fullWidth={fullWidth}
            variant='outlined'
            style={{ marginBottom: mb, ...style }}
            value={selectedIds}
            onChange={handleChange}
            displayEmpty
            renderValue={(selected) => {
                if ((selected as string[]).length === 0) {
                    return <Typography style={{ color: '#000', opacity: '0.38', fontSize: '16px', fontFamily: "'Inter', sans-serif", }}>{placeholder}</Typography>;
                }
                return selectedLabels.join(', ');;
            }}
            MenuProps={{
                style: { maxHeight: '280px' },
                PaperProps: { style: webStyles.menuListStyle },
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                getContentAnchorEl: null,
            }}
            onOpen={() => setSearchedList(list)}
            onClose={() => setSearchedList(list)}
        >
            <InputBase
                onClick={(event) => event.stopPropagation()}
                onKeyDown={(event) => event.stopPropagation()}
                value={searchTerm}
                onChange={handleSearchChange}
                startAdornment={renderIcon('search')}
                style={{ paddingLeft: '15px' }}
                placeholder='Search...'
            />
            {searchedList && searchedList?.length > 0 ? searchedList.map((item) => (
                <StyledMenuItems key={item.value} value={item.value}>
                    <StyledCheckbox disableRipple icon={renderIcon('uncheck')} checkedIcon={renderIcon('checked')} style={{ color: "#5B4595" }} checked={selectedIds.includes(item.value)} />
                    {item.label}
                </StyledMenuItems>

            )) : <MenuItem value={''} disabled >No Item</MenuItem>
            }
        </StyledMultiSelect>
        {error && <FormHelperText style={{ color: '#f44336', fontFamily: "'Inter', sans-serif", margin: `-${mb} 0 ${mb} 0` }} > {helperText}</FormHelperText >}
    </>
}

const StyledMultiSelect = styled(Select)({
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #5B4595'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px'
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: "1px solid #CBD5E1",
    },
    height: '56px',
    fontFamily: "'Inter', sans-serif",
})

const StyledCheckbox = styled(Checkbox)({
    "&.MuiIconButton-root": {
        padding: '0 12px 0 0'
    }
})

const StyledMenuItems = styled(MenuItem)({
    height: '36px',
    padding: "9px 20px 9px 16px",
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
        backgroundColor: '#EFECF4'
    }
})

const webStyles = {
    menuListStyle: {
        border: '1px solid #E2E8F0',
        borderRadius: '8px',
        fontFamily: "'Inter', sans-serif",
        boxShadow: "0px 4px 8px 0px #00000008",
        backgroundColor: 'white',
        padding: '4px',
        color: '#334155'
    }
}


export default CommonSearchableSelect;
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.textInputPlaceHolder = "Enter Text";
exports.exampleApiContentType = "application/json";
exports.labelBodyText = "ImportExportData Body";
exports.labelTitleText = "ImportExportData";


exports.btnExampleTitle = "CLICK ME";
exports.exportCsvLabel = "Export CSV";
exports.exportSuccess = "Your file exported to path: ";
exports.loadingText = "Loading...";
exports.exportJsonLabel = "Export JSON";

exports.csvApiContentType = "text/csv";
exports.jsonApiContentType = "application/json";
exports.exportDataMethod = "GET";
exports.exportDataEndpoint = "data_import_export/export";
exports.BulkUploadLogsEndPoint = "account_block/bulk_uploads/sort_bulk_uploads";
exports.errorMsg = "Something went wrong.";
exports.MethodGet = "GET";
exports.DateFormate = "DD MMM, YYYY, hh:mm:ss A";
exports.Token = "token";
exports.Download = "Download";
exports.UserBulkReports = "User Bulk Reports";
exports.SortBy = {
  A2Z: {
    label: "A to Z",
    value: 'a-z'
  },
  Time: {
    label: "Time",
    value: 'time'
  },
  Status: {
    label: "Status",
    value: 'status'
  }
}
exports.CurrentView = "currentView"
// Customizable Area End
import React from 'react'
import AllUsersController, { IOrganizationAcc, S, configJSON } from "./AllUsersController.web";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import { Accordion, AccordionDetails, CircularProgress, AccordionSummary, Avatar, Box, Button, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, MenuItem, Select, Switch, Typography, styled } from '@material-ui/core'
import { clipIcon, downloadIcon, errorIcon, successIcon } from "./assets";
import CommonModal from '../../../components/src/CommonModal'
import CommonSelect from "../../../components/src/CommonSelect";
import InputField from "../../../components/src/InputField";
import { MoreVert } from "@material-ui/icons";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Skeleton } from "@material-ui/lab";
import CommonMessage from "../../../components/src/CommonMessage";
import CommonPopover from "../../../components/src/CommonPopover";
import Loader from "../../../components/src/Loader.web";

interface Props {
    id: string;
    name: string;
    role: string;
    isActive: boolean;
    userInfo: string[],
    profileImage: string,
    phoneNumber: string | null;
    menuOpenFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const BulkUploadCircularProgress = () => {
    return <Box style={{ position: 'relative', width: '80px', height: "80px", borderRadius: '50%', backgroundColor: '#DEDAEA', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
        <CircularProgress variant="determinate" style={{ position: 'absolute', color: 'rgba(73, 55, 119,0.2)', fontFamily: "'Inter', sans-serif", }} value={100} />
        <CircularProgress variant="indeterminate" style={{ color: '#493777' }} disableShrink value={30} />
    </Box>
}

const UploadInProgressActionComponent = ({ modalType, onClose, downloadReport, enableDownloadReport }: { modalType: string, enableDownloadReport?: boolean, onClose: () => void, downloadReport: () => void }) => (<Box style={{ display: 'flex', justifyContent: 'space-between' }} >
    <StyledSecondaryButton disabled={(modalType === configJSON.ErrorModal || modalType === configJSON.UploadProgress) && !enableDownloadReport} onClick={downloadReport} data-test-id='download-report' >{configJSON.DownloadReport}</StyledSecondaryButton>
    <StyledButton onClick={onClose} > {configJSON.Done} </StyledButton>
</Box>)

const ContentLoadProgress = () => (<Box style={{ width: '100%', height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px 24px 24px 28px' }} >
    <CircularProgress style={{ color: '#5B4595' }} />
</Box>)

const UsersCard = (props: Props) => (<Box sx={WebStyles.userCardWrapper} >
    <Box sx={WebStyles.userPicAndDetailsWrapper}>
        <Avatar style={{ width: '56px', height: '56px', marginRight: "10px" }} src={props.profileImage} />
        <Box sx={WebStyles.cardInfoWrapper} >
            <Box sx={{ width: '100%' }} >
                <StyledUserCardTitle> {props.name} </StyledUserCardTitle>
            </Box>
            <Box sx={WebStyles.userInfoWrapper} >
                <Typography style={{ textTransform: "capitalize" }} >{props.role}</Typography>
                {props.userInfo.map((info, index) => (
                    <Box key={info} style={{ display: 'flex', alignItems: 'flex-start' }} >
                        <Box style={{ margin: '10px 10px 0 10px', width: '6px', height: '6px', backgroundColor: '#059669', borderRadius: '50%' }} ></Box><Typography style={{ wordBreak: 'break-word' }}> {info}</Typography>
                    </Box>
                ))}
                {props.phoneNumber && <Box style={{ display: 'flex', alignItems: 'flex-start' }} >
                    <Box style={{ margin: '10px 10px 0 10px', width: '6px', height: '6px', backgroundColor: '#059669', borderRadius: '50%' }} ></Box><Typography style={{ wordBreak: 'break-word' }} > {props.phoneNumber}</Typography>
                </Box>}
            </Box>
        </Box>
    </Box>
    {props.role === "project_manager" && <IconButton data-test-id='actionBtn' id={props.id} data-status={props.isActive} onClick={props.menuOpenFn} >
        <MoreVert />
    </IconButton>}
</Box>)

const NoOfItems = ({ text }: { text: string }) => (
    <Typography style={{ backgroundColor: '#D9B051', lineHeight: '32px', height: '32px', borderRadius: '100px', padding: "5px 16px", color: 'white', fontFamily: "'Inter', sans-serif", }} >{text}</Typography>
);

const UserMenuList = ({ getUser, isSelectedUserActive, disableUser }: { getUser: () => void, isSelectedUserActive: boolean, disableUser: () => void }) => (<>
    <MenuItem data-test-id='edit-user' style={{ backgroundColor: 'rgb(240, 232, 255)', borderRadius: '8px', ...WebStyles.menuItemStyle }} onClick={getUser} >{configJSON.Edit}</MenuItem>
    <MenuItem data-test-id='disable-user' style={WebStyles.menuItemStyle} onClick={disableUser} >{configJSON.Disable}</MenuItem>
</>);

const GroupByMenuList = ({ groupByParameter, handleGroupByNone, handleGroupByOrganization }: { groupByParameter: string, handleGroupByNone: () => void, handleGroupByOrganization: () => void }) => (<>
    <MenuItem data-test-id='groupByOrganization' style={groupByParameter === 'organization' ? WebStyles.groupByMenuActive : WebStyles.menuItemStyle} onClick={handleGroupByOrganization}  >{configJSON.Organization}</MenuItem>
    <MenuItem data-test-id='groupByNone' style={groupByParameter === 'none' ? WebStyles.groupByMenuActive : WebStyles.menuItemStyle} onClick={handleGroupByNone} >{configJSON.All}</MenuItem>
</>)

const UploadInProgress = () => (<Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', width: '100%', minHeight: '180px', marginBottom: '10px' }} >
    <BulkUploadCircularProgress />
    <Typography style={{ fontSize: '20px', fontWeight: 400, lineHeight: '28px', color: '#5B4595', fontFamily: "'Inter', sans-serif", }} > {configJSON.FileUploading} </Typography>
    <Typography style={{ fontSize: '16px', lineHeight: '24px', color: '#94A3B8', fontWeight: 400, fontFamily: "'Inter', sans-serif", }}>{configJSON.PleaseWaitFileIsBeingUploaded} </Typography>
</Box>)

const AddUserActionComponent = ({ handleCancelModal, addUser }: { handleCancelModal: () => void, addUser: () => void }) => (<Box style={{ display: 'flex', justifyContent: 'space-between' }} >
    <StyledSecondaryButton data-test-id='cancel' onClick={handleCancelModal} >{configJSON.Cancel}</StyledSecondaryButton>
    <StyledButton data-test-id='addUserBtn' onClick={addUser} >{"Add User"} </StyledButton>
</Box>)

const EditUserActionComponent = ({ handleCancelModal, updateUser }: { handleCancelModal: () => void, updateUser: () => void }) => (<Box style={{ display: 'flex', justifyContent: 'space-between' }} >
    <StyledSecondaryButton data-test-id='cancel' onClick={handleCancelModal} >{configJSON.Cancel}</StyledSecondaryButton>
    <StyledButton data-test-id='editUserBtn' onClick={updateUser} > {configJSON.Update} </StyledButton>
</Box>)

const BulkUpload = ({ fileUploadRef, handleFileChange, csvFile, handleClickUpload, handleDownloadDummyCSV }: { fileUploadRef: React.RefObject<HTMLInputElement>, handleFileChange: () => void, csvFile: File | null, handleClickUpload: () => void, handleDownloadDummyCSV: () => void }) => (<>
    <Typography style={{ ...WebStyles.modalText, textAlign: 'center', margin: '20px auto', maxWidth: '460px' }} >{configJSON.UploadLimitations}</Typography>
    <Box>
        <Typography style={WebStyles.modalText} >{configJSON.DownloadTemplate}</Typography>
        <Button style={{ backgroundColor: '#7C6AAA', color: 'white', textTransform: "capitalize", marginBottom: '40px', height: '44px', fontFamily: "'Inter', sans-serif", }} data-test-id='download-sample' onClick={handleDownloadDummyCSV} >
            <img src={downloadIcon} alt="" style={{ marginRight: '10px' }} />{configJSON.DownloadCSVTemplate}
        </Button>
        <Typography style={WebStyles.modalText}>{configJSON.AttachYourFile}</Typography>
        <input
            accept=".csv"
            id="contained-button-file"
            data-test-id='file-upload'
            type="file"
            ref={fileUploadRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
            <Button data-test-id='uploadCSV' style={{ border: '1px solid #7C6AAA', color: csvFile ? 'white' : '#7C6AAA', backgroundColor: csvFile ? '#7C6AAA' : 'white', textTransform: "capitalize", height: '44px', fontFamily: "'Inter', sans-serif", }} onClick={handleClickUpload} >
                {!csvFile && <img src={clipIcon} alt="" style={{ marginRight: '10px' }} />}{csvFile?.name ?? configJSON.UploadYourCSV}
            </Button>
        </label>
        {!csvFile && <FormHelperText>{configJSON.PleaseAddOneFileAtATime}</FormHelperText>}
    </Box>
</>)

const BulkUploadActionComponent = ({ csvFile, csvFormatError, handleBulkUpload }: { csvFile: File | null, csvFormatError: boolean, handleBulkUpload: () => void }) => <StyledButton disabled={!csvFile || Boolean(csvFormatError)} data-test-id='invite' onClick={handleBulkUpload} > {configJSON.InviteBulkUser} </StyledButton>

const SuccessBulkUpload = ({ handleGoToReports }: { handleGoToReports: () => void }) => (<Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', width: '100%', minHeight: '180px', marginBottom: '10px' }} >
    <img src={successIcon} alt="success_icon" />
    <Typography style={{ fontSize: '20px', fontWeight: 400, lineHeight: '28px', color: '#5B4595', fontFamily: "'Inter', sans-serif", }} > {configJSON.FileUploadedSucessfully} </Typography>
    <Typography style={{ fontSize: '16px', lineHeight: '24px', color: '#94A3B8', fontWeight: 400, fontFamily: "'Inter', sans-serif", }}>{configJSON.YourFileHasBeenUploaded} </Typography>
    <Typography style={{ textDecoration: 'underline', color: '#5B4595', cursor: 'pointer', fontFamily: "'Inter', sans-serif", }} onClick={handleGoToReports} >Click here to view detailed summary</Typography>
</Box>);

const ErrorBulkUpload = () => (<Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', width: '100%', minHeight: '180px', marginBottom: '10px' }} >
    <img src={errorIcon} alt="error_icon" />
    <Typography style={{ fontSize: '20px', fontWeight: 400, lineHeight: '28px', color: '#5B4595', textAlign: 'center', fontFamily: "'Inter', sans-serif", }} > {configJSON.FileNotUploaded}  </Typography>
    <Typography style={{ fontSize: '16px', lineHeight: '24px', color: '#94A3B8', fontWeight: 400, textAlign: 'center', fontFamily: "'Inter', sans-serif", }}>{configJSON.FileUploadFaild}<br /> {configJSON.PleaseTryAgainUpload} </Typography>
</Box>)


interface AddUserFormProps {
    states: S,
    capitalizeString: (string: string) => string;
    onChangeFullName: (text: string) => void;
    onChangeEmail: (text: string) => void;
    handleSelectPhoneInputList: (event: React.ChangeEvent<{ value: unknown }>) => void;
    onChangePhoneNumber: (text: string) => void;
    onChangeTitle: (text: string) => void;
    onChangeOrgnization: (text: unknown) => void;
    handleChangeCanCreate: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AddUserForm = ({ states, capitalizeString, onChangeFullName, onChangeEmail, handleSelectPhoneInputList, onChangePhoneNumber, onChangeTitle, onChangeOrgnization, handleChangeCanCreate }: AddUserFormProps) => (
    <Box style={{ rowGap: '20px' }} >
        <InputField
            data-test-id='fullName'
            fullWidth
            label={configJSON.FullNameLabel}
            style={{ marginBottom: '20px' }}
            placeholder={configJSON.FullNamePlaceHolder}
            value={states.fullName}
            helperText={states.fullNameError}
            error={Boolean(states.fullNameError)}
            onChange={onChangeFullName}
            asterisk
            inputProps={{ maxLength: 80 }}
        />
        <InputField
            data-test-id='email'
            fullWidth
            disabled={states.isActivated}
            label={configJSON.EmailLabel}
            style={{ marginBottom: '20px' }}
            placeholder={configJSON.EmailPlaceholder}
            value={states.email}
            helperText={states.emailError}
            error={Boolean(states.emailError)}
            onChange={onChangeEmail}
            asterisk
            inputProps={{ maxLength: 80 }}
        />
        <InputField
            data-test-id='phoneNumber'
            style={{ marginBottom: '20px' }}
            inputProps={{ maxLength: 17 }}
            startAdornment={<StyledSelect
                data-test-id='countryCode'
                variant="outlined"
                IconComponent={ExpandMoreRoundedIcon}
                value={states.phonePrefix}
                onChange={handleSelectPhoneInputList}
                displayEmpty
                MenuProps={{ style: { maxHeight: '400px' }, PaperProps: { style: WebStyles.menuPaperStyle } }}
            >
                {states.countryCodeList.length > 0 ? states.countryCodeList.map((listItem: { label: string }) => {
                    return <MenuItem key={listItem.label} value={listItem.label} >{listItem.label}</MenuItem>
                }) : <MenuItem value={''} disabled >No Item</MenuItem>}
            </StyledSelect>
            }
            fullWidth
            helperText={states.phoneNumberError}
            error={Boolean(states.phoneNumberError)}
            label={configJSON.PhoneNumberLabel}
            placeholder={configJSON.PhoneNumberPlaceholder}
            value={states.phoneNumber}
            onChange={onChangePhoneNumber}
            asterisk />
        <InputField
            data-test-id='title'
            style={{ marginBottom: '20px' }}
            fullWidth
            label={configJSON.TitleLabel}
            helperText={states.titleError}
            error={Boolean(states.titleError)}
            inputProps={{ maxLength: 80 }}
            value={states.title}
            onChange={onChangeTitle}
            placeholder={configJSON.TitlePlaceHolder} />
        <CommonSelect
            data-test-id='organization'
            mb={"20px"}
            fullWidth
            asterisk
            disabled={states.isActivated}
            helperText={states.organizationError}
            error={Boolean(states.organizationError)}
            list={states.organizationList}
            value={states.organization}
            onChange={onChangeOrgnization}
            placeholder={configJSON.OrganizationPlaceholder}
            label={configJSON.OrganizationLabel} />
        <FormGroup row>
            <FormControlLabel style={{ marginLeft: 0 }} control={<StyledSwitch disabled checked={true} onChange={handleChangeCanCreate} />} label={configJSON.CanCreateNewTaskLabel} />
        </FormGroup>
    </Box>)

const PopoverMenu = ({ groupByMenu, groupByParameter, handleGroupByNone, handleGroupByOrganization, getUser, disableUser, isSelectedUserActive }: { groupByMenu: boolean, groupByParameter: string, handleGroupByNone: () => void, handleGroupByOrganization: () => void, getUser: () => void, disableUser: () => void, isSelectedUserActive: boolean }) => {
    return groupByMenu ?
        <GroupByMenuList data-test-id='groupByMenu' groupByParameter={groupByParameter} handleGroupByNone={handleGroupByNone} handleGroupByOrganization={handleGroupByOrganization} />
        : <UserMenuList data-test-id='userMenu' getUser={getUser} isSelectedUserActive={isSelectedUserActive} disableUser={disableUser} />
}
const ContentLoad = ({ isLoading }: { isLoading: boolean }) => {
    return isLoading ? <ContentLoadProgress /> : null
}

class AllUsers extends AllUsersController {

    renderNoUserScreen = ({ dataLength }: { dataLength: number }) => {
        return dataLength === 0 && !this.state.isLoading ? <Box data-test-id='pageWrapper' style={WebStyles.emptyScreenWrapper} >
            <Typography style={WebStyles.notFoundText} >{configJSON.NoUserFound}</Typography>
            <Typography style={WebStyles.notFoundSubMsg} >{configJSON.TryCreatingNewUser}</Typography>
        </Box> : <ContentLoadProgress />
    }

    renderUserList = () => {
        return <>{
            this.state.userList?.length > 0 ?
                <Grid container spacing={2} ref={this.gridComponentRef} id='grid' >
                    {this.state.userList.map((element) => (<Grid key={element.id} item xs={12} sm={12} md={this.getUIBreakPoints().mdBreakPoint} lg={this.getUIBreakPoints().currenViewBreakPoint} xl={this.getUIBreakPoints().currenViewBreakPoint} >
                        <UsersCard data-test-id='userCard' id={element.id} name={element.attributes.full_name} role={element.attributes.role.name} profileImage={element.attributes.profile_image} isActive={element.attributes.activated} userInfo={this.state.currentView === configJSON.Views.List ? [element.attributes.organization.name, element.attributes.email] : []} phoneNumber={this.state.currentView === configJSON.Views.List ? `+${element.attributes.country_code} ${element.attributes.phone_number}` : null} menuOpenFn={this.handleOpenUserMenu} />
                    </Grid>))}
                    {this.state.isLoading && <ContentLoadProgress />}
                </Grid>
                :
                this.renderNoUserScreen({ dataLength: this.state.userList.length })
        }
        </>
    }
    render() {
        let modalContent = <></>;
        let actionComponent = <></>;
        let modalTitle = "";
        switch (this.state.modalType) {
            case configJSON.AddUser:
                actionComponent = <AddUserActionComponent data-test-id='addUserAction' handleCancelModal={this.handleCancelModal} addUser={this.addUser} />
                modalContent = <AddUserForm data-test-id='addUser' states={this.state} onChangeFullName={this.onChangeFullName} capitalizeString={this.capitalizeString} onChangeEmail={this.onChangeEmail} handleSelectPhoneInputList={this.handleSelectPhoneInputList} onChangePhoneNumber={this.onChangePhoneNumber} onChangeTitle={this.onChangeTitle} onChangeOrgnization={this.onChangeOrgnization} handleChangeCanCreate={this.handleChangeCanCreate} />
                modalTitle = configJSON.AddNewUser;
                break;
            case configJSON.EditUser:
                actionComponent = <EditUserActionComponent data-test-id='editUserAction' handleCancelModal={this.handleCancelModal} updateUser={this.updateUser} />
                modalContent = <AddUserForm data-test-id='editUser' states={this.state} onChangeFullName={this.onChangeFullName} capitalizeString={this.capitalizeString} onChangeEmail={this.onChangeEmail} handleSelectPhoneInputList={this.handleSelectPhoneInputList} onChangePhoneNumber={this.onChangePhoneNumber} onChangeTitle={this.onChangeTitle} onChangeOrgnization={this.onChangeOrgnization} handleChangeCanCreate={this.handleChangeCanCreate} />
                modalTitle = configJSON.EditUserTitle;
                break;
            case configJSON.UploadProgress:
                modalContent = <UploadInProgress data-test-id='uploadProgress' />;
                actionComponent = <UploadInProgressActionComponent data-test-id='uploadActions' modalType={this.state.modalType} downloadReport={this.downloadReport} onClose={this.handleCloseModal} />
                break;
            case configJSON.BulkInvite:
                actionComponent = <BulkUploadActionComponent data-test-id='bulkUploadAction' csvFormatError={this.state.csvFormatError} csvFile={this.state.csvFile} handleBulkUpload={this.handleBulkUpload} />;
                modalContent = <BulkUpload data-test-id='bulkUpload' handleDownloadDummyCSV={this.handleDownloadDummyCSV} csvFile={this.state.csvFile} handleClickUpload={this.handleClickUpload} handleFileChange={this.handleFileChange} fileUploadRef={this.fileUploadRef} />
                modalTitle = configJSON.UserBulkInvite
                break;
            case configJSON.SuccessModal:
                actionComponent = <UploadInProgressActionComponent data-test-id='uploadProgressSuccess' enableDownloadReport modalType={this.state.modalType} downloadReport={this.downloadReport} onClose={this.handleCloseModal} />
                modalContent = <SuccessBulkUpload handleGoToReports={this.handleGoToReports} data-test-id='successUpload' />
                break;
            case configJSON.ErrorModal:
                modalContent = <ErrorBulkUpload data-test-id='errorUpload' />
                actionComponent = <UploadInProgressActionComponent data-test-id='uploadProgressError' downloadReport={this.downloadReport} modalType={this.state.modalType} onClose={this.handleCloseModal} />
                break;
        }

        return <DashboardViewWrapper data-test-id='wrapper' {...this.props} onSearch={this.handleSearch} setCurrentView={this.setCurrentView} pageTopBarProps={{ tabProps: { tabLabel: configJSON.AllUsers, count: this.state.totalUsers }, bulkInvite: this.state.role === 'project_manager' ? { testId: configJSON.BulkInvite, actionFn: this.handleBulkInviteModal, buttonTxt: 'User Bulk Invite' } : undefined, addUser: this.state.role === 'project_manager' ? { testId: 'addUser', buttonTxt: 'New User', actionFn: this.handleOpenAddUser } : undefined }} >
            <Loader loading={this.state.showLoader} />
            <CommonMessage data-test-id='common-msg' open={this.state.showCommonMessage} message={this.state.message} messageType={this.state.messageType} onClose={this.handleCloseCommonMessage} />
            {this.shouldGroupByVisible() && <Box style={{ display: 'flex', marginLeft: 'auto' }}>
                <Typography style={{ color: '#94A3B8', fontSize: '16px', fontFamily: "'Inter', sans-serif", }} >{configJSON.GroupBy}</Typography> <Button data-test-id='groupBy' disableRipple style={WebStyles.groupByBtn} onClick={this.handleOpenGroupByMenu} > {this.state.labelGroupBy} <ExpandMoreRoundedIcon style={{ color: '#475569' }} /> </Button>
            </Box >}
            <Box sx={WebStyles.userListWrapper} onScroll={this.handleScroll}>
                {this.state.groupByParameter === 'none' ?
                    this.renderUserList() :
                    <React.Fragment>
                        {
                            this.state.organizationWiseInfo.length > 0 ?
                                this.state.organizationWiseInfo.map((element: IOrganizationAcc, index: number) => (
                                    <Box>
                                        <Accordion style={{ boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '8px', border: '1px solid #E2E8F0' }} data-test-id="userByOrganization" expanded={this.state.expanded === `panel${index + 1}`} onChange={this.handleAccordionChange(`panel${index + 1}`, element.id)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreRoundedIcon />}
                                                aria-controls="userByOrganization"
                                                id="userByOrganization"
                                                style={{ padding: '7px 20px', }}
                                            >
                                                <Box style={{ display: 'flex', alignItems: 'center' }}><Typography style={WebStyles.accordionLabel} data-test-id="summary" >{element.attributes.name}</Typography><NoOfItems text={this.formatForPad(`${element.attributes.users_count}`)} /> </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container data-test-id='accordion-details' onScroll={this.handleLoadOrganizationWiseUser} style={{ backgroundColor: 'rgb(248, 250, 252)', maxHeight: '376px', overflowY: 'auto' }} spacing={2}>
                                                    {this.state.userList.map((element) => {
                                                        const dataPerView = this.state.currentView === configJSON.Views.List ? { userInfo: [element.attributes.organization.name, element.attributes.email], phoneNumber: `+${element.attributes.country_code} ${element.attributes.phone_number}` } : { userInfo: [], phoneNumber: null };
                                                        return <Grid item xs={12} sm={12} md={this.getUIBreakPoints().mdBreakPoint} lg={this.getUIBreakPoints().lgXlBreakPoints} xl={this.getUIBreakPoints().lgXlBreakPoints} >
                                                            <UsersCard data-test-id='userCard' id={element.id} name={element.attributes.full_name} role={element.attributes.role.name} profileImage={element.attributes.profile_image} isActive={element.attributes.activated} userInfo={dataPerView.userInfo} phoneNumber={dataPerView.phoneNumber} menuOpenFn={this.handleOpenUserMenu} />
                                                        </Grid>
                                                    })}
                                                    <ContentLoad isLoading={this.state.isLoading} />
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                ))
                                : this.renderNoUserScreen({ dataLength: this.state.organizationWiseInfo.length })
                        }

                    </React.Fragment>
                }
            </Box>
            <Button style={{ display: 'none' }} data-test-id='set-pm' onClick={this.setPMRole}></Button>
            <CommonModal data-test-id='modal' modalTitle={modalTitle} hideTopDivider={this.state.uploading} closeIcon open={this.state.openModal} onClose={this.handleCloseModal} actionComponent={actionComponent} >
                {modalContent}
            </CommonModal >
            <CommonPopover
                data-test-id='popover'
                anchorEl={this.state.anchorEl}
                menuList={<PopoverMenu
                    data-test-id='popoverMenu'
                    groupByMenu={this.state.groupByMenu}
                    groupByParameter={this.state.groupByParameter}
                    handleGroupByNone={this.handleGroupByNone}
                    handleGroupByOrganization={this.handleGroupByOrganization} getUser={this.getUser} disableUser={this.disableUser} isSelectedUserActive={this.state.isSelectedUserActive}
                />
                }
                onClose={this.handleCloseUserMenu} />
        </DashboardViewWrapper >
    }
}
export default AllUsers;

const WebStyles = {
    userListWrapper: {
        height: "calc(100vh - 364px)",
        overflowY: 'auto',
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        '@media(max-width:960px)': {
            width: '100%',
        }
    },
    modalText: {
        color: '#64748B',
        fontFamily: "'Inter', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    menuPaperStyle: {
        border: '1px solid #E2E8F0',
        borderRadius: '8px',
        boxShadow: "0px 4px 8px 0px #00000008",
        backgroundColor: 'white',
        padding: '4px',
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
    },
    notFoundText: {
        color: '#241C3C',
        fontFamily: "'Inter', sans-serif",
        fontSize: '30px',
        lineHeight: '40px',
        fontWeight: 700,
        textAlign: 'center'
    } as React.CSSProperties,
    notFoundSubMsg: {
        color: '#94A3B8',
        fontFamily: "'Inter', sans-serif",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    },
    groupByBtn: {
        color: '#475569',
        fontFamily: "'Inter', sans-serif",
        textTransform: 'capitalize',
        padding: '0 10px',
        fontSize: '16px'
    } as React.CSSProperties,
    emptyScreenWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    } as React.CSSProperties,
    userInfoWrapper: {
        color: '#64748B',
        fontFamily: "'Inter', sans-serif",
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '@media(max-width:576px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    userCardWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 16px 16px 20px',
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: "0px 4px 8px 0px #00000008",
        borderRadius: '6px',
        borderLeft: '4px solid #5B4595',
        backgroundColor: 'white',
        '@media(max-width:576px)': {
            alignItems: 'flex-start'
        }
    },
    userPicAndDetailsWrapper: {
        display: 'flex',
        width: "80%",
        '@media(max-width:576px)': {
            flexDirection: 'column',
        }
    },
    cardInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: 'calc(100% - 60px)',
        '@media(max-width:576px)': {
            marginTop: "20px",
            width: '100%'
        }
    },
    groupByMenuActive: {
        backgroundColor: 'rgb(240, 232, 255)',
        borderRadius: '8px',
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: 'auto'
    } as React.CSSProperties,
    accordionLabel: {
        color: '#0F172A',
        fontFamily: "'Inter', sans-serif",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        marginRight: '16px'
    },
    menuItemStyle: {
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: 'auto'
    },
}

const StyledButton = styled(Button)({
    backgroundColor: '#5B4595',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    height: '56px',
    fontWeight: 700,
    padding: '16px',
    minWidth: '133px',
    borderRadius: '8px',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#5B4595'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#F1F5F9",
        color: '#64748B',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px'
    }
})

const StyledSecondaryButton = styled(Button)({
    backgroundColor: '#DEDAEA',
    color: '#5B4595',
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    height: '56px',
    fontWeight: 700,
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    marginRight: '20px',
    '&:hover': {
        backgroundColor: '#DEDAEA'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#DEDAEA",
        color: '#BDB5D5',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px'
    }
})

const StyledSwitch = styled(Switch)({
    "&.MuiSwitch-root": {
        padding: '6px'
    },
    "& .MuiSwitch-track": {
        borderRadius: '14px'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: "white",
        fontFamily: "'Inter', sans-serif",
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#5B4595',
        opacity: 1
    },

})

const StyledSelect = styled(Select)({
    '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderTop: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        border: "1px solid #CBD5E1",
    },
    height: '56px'
})

const StyledUserCardTitle = styled(Typography)({
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    marginBottom: '7px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
        textOverflow: 'unset',
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    '@media(max-width:576px)': {
        overflow: 'hidden',
        textOverflow: 'unset',
        whiteSpace: 'normal',
    }
})
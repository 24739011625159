// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const dueDateCalendar = require('../assets/dueDateCalendar.png');
export const assigned = require('../assets/assigned.png');
export const submitted = require('../assets/submitted.png');
export const accepted = require('../assets/accepted.png');
export const rejected = require('../assets/rejected.png');
export const redCalander = require('../assets/red_cal.png');
// Customizable Area End
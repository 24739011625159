import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Checkbox,
  styled
} from "@material-ui/core";
import { StyleSheet } from "react-native";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import UnAuthorizedUIWrapper from '../../../components/src/UnAuthorizedUIWrapper';
import InputField from '../../../components/src/InputField';
import { checkedIcon, loginPageThumbnail, logo, unCheckIcon } from './assets';
const configJSON = require('./config');
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCheckedIcon = () => {
    return <img src={checkedIcon} style={{ width: '20px' }} alt='checked' />
  }
  renderUnCheckedIcon = () => {
    return <img src={unCheckIcon} style={{ width: '20px' }} alt='unchecked' />
  }

  StyledButton = styled(Button)({
    backgroundColor: "#5B4595",
    height: "56px", width: '100%',
    borderRadius: '8px',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: "#5B4595",
    },
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#F1F5F9',
      color: '#64748B'
    }
  })
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
        <UnAuthorizedUIWrapper thumbnail={loginPageThumbnail} title={configJSON.WelcomeTxt} subTitle={configJSON.WelcomeSubtitle} >
          <Loader  loading={this.state.isLoading} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <img style={{ width: '255px', marginBottom: '50px' }} src={logo} alt="company_logo" />
          </Box>
          <Box>
            <Typography style={{ textAlign: 'center',fontFamily: "'Inter', sans-serif", color: '#5B4595', fontSize: '20px', lineHeight: '28px', marginBottom: '40px', fontWeight: 600 }}>{configJSON.Login}</Typography>
            <InputField
              data-test-id='email'
              style={{ marginBottom: '30px' }}
              fullWidth
              value={this.state.email}
              onChange={this.setEmail}
              label={configJSON.Email}
              error={Boolean(this.state.emailError)}
              helperText={this.state.emailError}
              placeholder={configJSON.EnterYourEmailID}
            />
            <InputField
              style={{ marginBottom: '10px' }}
              fullWidth
              data-test-id='password'
              value={this.state.password}
              onChange={this.setPassword}
              type={this.state.enablePasswordField ? "text" : "password"}
              label={configJSON.Password}
              error={Boolean(this.state.passwordError)}
              helperText={this.state.passwordError}
              placeholder={configJSON.EnterYourPassword}
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  edge="end"
                >
                  {!this.state.enablePasswordField ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              } />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox data-test-id='rememberme' style={{ padding: '0', marginRight: '5px' }} checked={this.state.checkedRememberMe} onChange={this.setRememberMe} disableRipple checkedIcon={<this.renderCheckedIcon />} icon={<this.renderUnCheckedIcon />} />
                <Typography style={{ color: '#64748B',fontFamily: "'Inter', sans-serif", fontSize: '14px', lineHeight: '24px', fontWeight: 600 }} > {configJSON.labelRememberMe} </Typography>
              </Box>
              <Typography style={{ color: "#5B4595",fontFamily: "'Inter', sans-serif", fontWeight: '700', fontSize: '14px', lineHeight: '22px', cursor: 'pointer' }} data-test-id='forgotpassword' onClick={() => this.goToBlock('ForgotPassword')} >{configJSON.ForgotPassword}</Typography>
            </Box>

            <this.StyledButton variant="contained"
              data-test-id='login'
              disabled={!(this.state.email && this.state.password)}
              onClick={this.loginUser}
            >{configJSON.LoginBtnTxt}</this.StyledButton>
          </Box>
        </UnAuthorizedUIWrapper>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});

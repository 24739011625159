import React from "react";
// Customizable Area Start
import { Box, Grid, Typography, LinearProgress, CircularProgress, styled, Table, TableHead, TableRow, TableCell, TableBody, Button, TableContainer, Paper, List, Tooltip,  } from "@material-ui/core";
import { PieChart } from '@mui/x-charts'
import DashboardViewWrapper from "./DashboardViewWrapper.web";
import { Circle } from "@mui/icons-material";
import CommonSelect from "../../../components/src/CommonSelect";
import { Skeleton } from "@material-ui/lab";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderTitle = (title: string, link:string) => {
    return(
      <Box style={{ display: "flex", justifyContent: "space-between"}}>
        <Typography style={webStyles.titleStyle} data-test-id={`title${title}`}>{title}</Typography>
        { link && <Button component="button" onClick={() => this.goToBlock(link)} data-test-id={`viewAll${title}`}>
          <Typography style={webStyles.viewAllStyle}>View all</Typography>
        </Button>}
      </Box>
    )
  }
  renderBox = (title: string, value: number) => {
    return (
    <Box data-test-id={`box${title}`} style={{...webStyles.containerBoxStyle}}>
        <Typography style={webStyles.smallBoxTitleText}>{ title }</Typography>
        <Typography style={webStyles.titleStyle}>{ value }</Typography>
    </Box>
    )
  }
  
  renderPieChart = () => {
    const { tasks_completed, tasks_in_progress, tasks_need_review, tasks_to_do } = this.state.dashboardData
    const hasData = tasks_completed || tasks_in_progress || tasks_need_review || tasks_to_do
    return (
      <Box style={{...webStyles.containerBoxStyle, height: "300px",}}>
      { this.renderTitle("Tasks", "TaskProjects") }
        <Box style ={{ display: "flex", height: "280px", alignItems: "center" }}>
        {hasData ? <PieChart
          data-test-id="pieChartData"
          colors={["#5B4595", "#7C6AAA","#9D8FBF","#BDB5D5",]}
          series={[{
            data: [
              { id:0, value: tasks_completed, label:"Completed"},
              { id:1, value: tasks_in_progress, label:"In progress"},
              { id:2, value: tasks_to_do, label:"To do"},
              { id:3, value: tasks_need_review, label:"Need review"},
            ],
          }]}
          height={200}
          sx={{
            '.MuiChartsLegend-mark': {
              clipPath: 'circle()',

            }
          }}
          slotProps={{
            legend: {
              itemMarkHeight: 7,
              itemMarkWidth: 7,
              labelStyle: {
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "'Inter', sans-serif",
                color: "#697077"
              }
            }
          }}
        /> : 
        <Box style={{width: "100%"}}>
        <Typography data-test-id="noDataToDisplay" style={webStyles.noData} >No data to display</Typography>
        </Box>
      }
        </Box>
      </Box>
    )
  }
  renderProjectComponent = () => {
    const { completed_projects, total_projects  } = this.state.dashboardData
    const calculatePercent = total_projects === 0? total_projects : parseFloat(((completed_projects/total_projects)*100).toFixed(2));
    return (
      <Box data-test-id='progressCircularBar' style={{...webStyles.containerBoxStyle, height: "300px",}}>
        { this.renderTitle("Projects", "Projects") }

        <Box style={{ paddingTop: "20px"}}>
          <Box style={{ position:"relative"}}>
            <StyledCircularProgress variant="determinate" size="lg" style={{ transform: "translate(-50%) rotate(145deg)", color: "#5B4595", zIndex: "1"}} value={calculatePercent} />
            <StyledCircularProgress variant="determinate" size="lg" style={{ transform: "translate(-50%) rotate(145deg)", color: "#DEDAEA"}} value={70} />
          </Box>
        </Box>
        <Box>
        <Box style={{display: "flex", justifyContent: "center", alignItems: "end", height: "220px" }}>
          <Typography style={{...webStyles.titleStyleProjects}} data-test-id="circularValue">{ calculatePercent }%</Typography>
        </Box></Box>
      </Box>
    )
  }
  renderUsersTable = () => {
    return(
      <Box style={{...webStyles.containerBoxStyle, height: "300px"}}> 
        { this.renderTitle("Projects By Organization", "") }
        {this.state.dashboardData.projects_by_organization?.length > 0 ? (<TableContainer style={{ maxHeight: '270px'}}>
          <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={webStyles.tableHeadTitle}>ID</TableCell>
              <TableCell style={webStyles.tableHeadTitle}>Name</TableCell>
              <TableCell style={webStyles.tableHeadTitle}>Progress</TableCell>
              <TableCell style={webStyles.tableHeadTitle}>Projects</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state.dashboardData.projects_by_organization.map((project, idx) => {
                const linearValue = project.total_projects === 0 ? project.total_projects : (project.inprogress_projects/project.total_projects)*100 
                return (
                  <TableRow key={project.organization_name}>
                    <TableCell style={webStyles.tableBodyText}>{ idx+1 }</TableCell>
                    <TableCell style={webStyles.tableBodyText}>{ project.organization_name }</TableCell>
                    <TableCell><LinearProgress style={{ color:"#5B4595", width:"150px"}} variant="determinate" value={linearValue }/></TableCell>
                    <TableCell>
                      <Box style={webStyles.progressCount}>
                        {project.total_projects}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        </TableContainer>) :
        <Typography data-test-id="noProjectByOrg" style={webStyles.noData} >No data found</Typography>
          }
      </Box>
    )
  }
  renderNotificaitions= () => {
    return (
      <Box data-test-id="notificationTable" style={{...webStyles.containerBoxStyle, height: "300px"}}>
        { this.renderTitle("Notifications", "") }
        {this.state.notificationsData.length > 0 ? 
          <List ref={this.listRef} data-test-id="notificationList" onScroll={this.handleScroll} style={{ maxHeight: "260px", overflowX: 'auto',overflowY: 'auto', paddingRight:"5px" }}>
          {
            this.state.notificationsData.map((item)=> {
              const { contents, notificable_id , notificable_type, headings } =  item.attributes
              const  notiicationType = notificable_type.split("::")[1] || ""
              return (
                <Box key={item.id} display="flex" style={{ justifyContent:"space-between", margin: "20px 0"}}>
                  <Box display="flex" style={{ margin:"0", padding:"0"}}>
                    <Circle style={{ fontSize: "10px", marginTop:"5px", color: "#5B4595" }} />
                    <Typography data-test-id={`listNotification${item.id}`} style={webStyles.notificationText}>{ contents }</Typography>
                  </Box>
                  <Box>
                  <Button data-test-id={`listButtonNotification${item.id}`} onClick={() => this.handleNavigationNotification(notiicationType, headings, notificable_id, contents)} style={webStyles.viewDetailsButtonStyle}>
                    { webConfigJSON.viewDetailsBtn }
                  </Button>
                  </Box>
                </Box>
              )
            })
          }
          {
            this.state.scrollLoading &&  <Box style={{display: "flex", justifyContent: "center"}}>
            <CircularProgress style={{color:'#5B4595'}} /> 
            </Box>
          }
          </List>
          :
          <Typography style={webStyles.noData} data-test-id="noNotification">No Notifications found</Typography>}
      </Box>
    )
  }
  renderDashboardCount = () => {
    const mdGridSize = this.state.role.toLowerCase() === 'participant'? 6 : 3
    return (
      <Box>
        <Grid container spacing={3} data-test-id="dashboardCountGrid">
          {
            this.state.dashboardCountData.map((item, idx) => {
              return (
                <Grid data-test-id={`dashboardCount${item.label}`} key={idx} item xs={12} sm={6} md={mdGridSize}>
                    { this.renderBox(item.label, item.count) }
                </Grid>
              )
            })
          }
      </Grid>
    </Box>
    )
  }
  renderSuperAdminTable = () => {
    return (
      <Box data-test-id="superAdminTable">
        { !this.state.isLoading ? <>
        <Typography style={{ margin: "10px 0px", ...webStyles.tableBodyText}}>{ this.state.filterSuperAdminTable.length } records found</Typography>
          <Paper elevation={1} style={{ width: "100%", overflow:"hidden", borderRadius:"10px" }}>
          <TableContainer style={{ maxHeight: '50vh'}}>
              <Table stickyHeader>
              <TableHead >
                <TableRow>
                  <TableCell style={{backgroundColor: "#F1F5F9", ...webStyles.tableBodyText}}>Organization</TableCell>
                  <TableCell style={{backgroundColor: "#F1F5F9", ...webStyles.tableBodyText}}>Project</TableCell>
                  <TableCell style={{backgroundColor: "#F1F5F9", ...webStyles.tableBodyText}}>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.state.filterSuperAdminTable.map((data,idx) => {
                    return (
                      <StyledTableRow data-test-id={`row${data.project_id}`} key={data.project_id} onClick={() => this.goToBlockWithParams('DashboardPhase', String(data.project_id))}>
                        <TableCell style={{...webStyles.tableBodyText, }}>{ data.organization_name }</TableCell>
                        <TableCell style={{...webStyles.tableBodyText, }}><Box style={{maxWidth: "500px"}}>
                        <Tooltip
                          key={data.project_id}
                         data-test-id={`toolTip${idx}`}
                         title={data.project}
                         onMouseEnter={(event) => this.handleCheckOverflow(event, idx)}
                         disableHoverListener={this.state.currentHover !== undefined ? idx !== this.state.currentHover : true}
                        >
                           <Typography noWrap> { data.project }</Typography>
                        </Tooltip>
                        </Box></TableCell>
                        <TableCell style={{...webStyles.tableBodyText, }}><Typography style={{maxWidth: "50px"}}>{data.count}</Typography>  </TableCell>
                      </StyledTableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
            </TableContainer>
            </Paper>
            </> : this.renderTableLoadProgress() 
            }
        </Box>
    )
  }
  renderTableLoadProgress = () => {
    return <Box style={{ width: '100%' }} >
      <Skeleton animation="wave" height={50} width={"100%"} style={{ marginBottom: 3 }} />
      <Skeleton animation="wave" height={30} width={"90%"} />
      <Skeleton animation="wave" height={30} width={"90%"} />
    </Box>  
  }
  renderContentLoadProgress =  () => (
    <>
  <Box style={{ width: '100%', height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px 0' }} >
      <Skeleton style={webStyles.containerLoadingBoxStyle} animation="wave" />
      <Skeleton style={webStyles.containerLoadingBoxStyle} animation="wave" />
      <Skeleton style={webStyles.containerLoadingBoxStyle} animation="wave" />
      <Skeleton style={webStyles.containerLoadingBoxStyle} animation="wave" />
  </Box>
  {this.renderTableLoadProgress()}
  </>
)
  renderClearFilter() { 
    const isDisabled = !(this.state.selectedOrganization || this.state.selectedPhase || this.state.selectedProject || this.state.selectedTool)
    return (
      <>
     {!isDisabled &&  (<Box style={{width: "100%", display: "flex", justifyContent: "right"}} >
        <Button component="button" disabled={isDisabled} onClick={this.clearAllFilters} data-test-id="clearAllFilters"><Typography style={webStyles.viewAllStyle}> Clear all Filters </Typography></Button>
      </Box>)}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <DashboardViewWrapper {...this.props} setRole={this.changeRole} setNotificationList={(data, total) => this.handleUpdateNotification(data,total)}>
        { this.state.loading ? 
        this.renderContentLoadProgress()
        
        :<Box style={{height: '80%'}}>
          { this.renderDashboardCount()}
          {
            this.state.role === "super_admin" && (
              <Box style={{ marginBottom: "20px"}}>
              { this.renderClearFilter() }
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}  md={3}>
                  <CommonSelect
                    fullWidth
                    mt="10px"
                    data-test-id='organization'
                    // error={Boolean(this.state.phaseError)}
                    // helperText={this.state.phaseError}
                    list={this.state.organizationList}
                    placeholder="Select organization"
                    label="Organization"
                    value={this.state.selectedOrganization}
                    onChange={(value) => this.onChangeOrganization(value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CommonSelect
                    fullWidth
                    mt="10px"
                    data-test-id='project'
                    // error={Boolean(this.state.phaseError)}
                    // helperText={this.state.phaseError}
                    list={this.state.projectList}
                    placeholder="Select project"
                    label="Project"
                    value={this.state.selectedProject}
                    onChange={this.onChangeProject}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CommonSelect
                    fullWidth
                    mt="10px"
                    data-test-id='phase'
                    // error={Boolean(this.state.phaseError)}
                    // helperText={this.state.phaseError}
                    list={this.state.phaseList}
                    placeholder="Select phase"
                    label="Phase"
                    value={this.state.selectedPhase}
                    onChange={this.onChangePhase}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CommonSelect
                    fullWidth
                    mt="10px"
                    data-test-id='tool'
                    // error={Boolean(this.state.phaseError)}
                    // helperText={this.state.phaseError}
                    list={this.state.toolList}
                    placeholder="Select tool"
                    label="Tool"
                    value={this.state.selectedTool}
                    onChange={this.onChangeTool}
                  />
                </Grid>
                </Grid>
                {
                  this.state.filterSuperAdminTable.length ? (
                   this.renderSuperAdminTable()
                  ) :(

                  <Box style={webStyles.superAdminResults} data-test-id="noFilter">
                    <Box>
                      <Typography style={webStyles.resultTable}>Select filter to get results</Typography>
                      <Typography style={webStyles.resultTableSubTitle}>Try creating a filter</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            )
          }
          {
            this.state.role !== "super_admin" && (
              <>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    { this.renderProjectComponent() }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    { this.renderPieChart() }
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container spacing={3} style={{ marginBottom: "8px" }}>
                  <Grid item xs={12} sm={6}>
                    {this.renderUsersTable()}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    { this.renderNotificaitions()}
                  </Grid>
                </Grid>
              </Box>
              </>
            )
          }
        </Box>}
      </DashboardViewWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  absoluteCenter: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
  } as const,
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  containerBoxStyle: {
      maxWidth: "100%",
      borderRadius: "15px",
      backgroundColor: "white",
      border: "1px groove #CBD5E1",
      padding: "24px",
  },
  containerLoadingBoxStyle: {
    borderRadius: "15px", 
    marginRight: '10px', 
    height: '200px', 
    width: '100%',
  },
  titleStyle: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#5B4595",
    fontFamily: "'Inter', sans-serif"
  },
  titleStyleProjects: {
    fontWeight: "700",
    fontSize: "25px",
    fontFamily: "'Inter', sans-serif",
    color: "#5B4595",
  },
  resultTable: {
    fontWeight: "700",
    fontSize: "30px",
    fontFamily: "'Inter', sans-serif",
    color: "#241C3C",
    textAlign: "center" as const,
  },
  resultTableSubTitle: { 
    fontSize: "20px",
    fontFamily: "'Inter', sans-serif",
    color: "#64748B",
    textAlign: "center" as const,
  },
  viewAllStyle: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#5B4595",
    textTransform: "none" as const,
    fontFamily: "'Inter', sans-serif",
    borderBottom: "1px solid",
    borderColor: "#5B4595",
    borderRadius: "0"
  },
  tableHeadTitle: {
    color: "#96A5B8",
    fontWeight: "400",
    fontSize: "13px",
    fontFamily: "'Inter', sans-serif",

  },
  tableBodyText: {
    color: "#444A6D",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
    width: "33%"
  },
  notificationText: {
    color: "#697077",
    fontWeight: "400",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    padding: "0 5px"
  },
  smallBoxTitleText: {
    color: "#697077",
    fontWeight: "400",
    fontSize: "16px",
  },
  viewDetailsButtonStyle: {
    whiteSpace: "noWrap",
    padding:"5px 10px",
    border: "1px solid #5B4595",
    borderRadius: "5px",
    fontSize: "10px",
    fontWeight: "400",
    color: "#5B4595",
    textTransform: "none",
  } as const,
  progressCount: { 
    width: "47px", 
    height: "24px", 
    borderRadius: "4px", 
    backgroundColor: "#DEDAEA", 
    color: "#5B4595", 
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "5px"
  },
  superAdminResults: {
    height: "90vh", 
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
  },
  noData: {
    fontWeight: "700px", 
    height: "300px", 
    display:"flex", 
    justifyContent: "center", 
    alignItems: "center",
    color: "#697077",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    padding: "0 5px"
  }
};
const StyledCircularProgress = styled(CircularProgress)({
  ...webStyles.absoluteCenter,
  width:"250px",
  height:"250px", 
});
const StyledTableRow= styled(TableRow)({
  width: "100%",
  '&:hover': {
      cursor: "pointer",
      backgroundColor: "#F1F5F9",
  }
});
// Customizable Area End

import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { configJSON as phoneCodes } from '../../social-media-account/src/AllUsersController.web'
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import { MenuItem, PopoverOrigin } from "@material-ui/core";

export const configJSON = require("./config");

interface Profile {
    id: string;
    type: "account";
    attributes: {
        activated: boolean;
        country_code: string;
        full_name: string;
        email: string;
        phone_number: string;
        full_phone_number: string;
        title: string;
        created_at: string;
        organization: Organization;
        assigned_organizations: { id: number; name: string; status: string }[];
        device_id: null | string;
        updated_at: string;
        profile_image: string;
        role: Role;
    };
};


interface Organization {
    id: number;
    status: string;
    name: string;
    updated_at: string;
    created_at: string;
}

interface Role {
    id: number;
    created_at: string;
    name: string;
    updated_at: string;
}


export interface Props {
    id: string;
}

interface S {
    activeTab: number;
    countryCode: string;
    countryCodeList: { label: string }[];
    openModal: boolean;
    anchorEl: HTMLElement | null;
    profilePicToUpload: File | null;
    selectedProfilePic: string | ArrayBuffer | null | undefined;
    isEditMode: boolean;
    newPassword: string;
    oldPassword: string;
    confirmPassword: string;
    oldPasswordError: string;
    newPasswordError: string;
    email: string;
    confirmPasswordError: string;
    phoneNumber: string;
    phoneNumberError: string;
    tempfullName: string;
    fullName: string;
    fullNameError: string;
    organization: string;
    assignedOrganizationList: string[];
    role: string;
    profilePic: string;
    profileData: Profile | null;
    message: string;
    showCommonMessage: boolean;
    messageType: "success" | "error" | "default";
    newPasswordEye: boolean;
    oldPasswordEye: boolean;
    confirmPasswordEye: boolean;
    popOverMenuType: string;
    popOverStyle: React.CSSProperties | undefined;
    popOverAnchorOrigin: PopoverOrigin | undefined;
    isLoading: boolean;
}

interface SS {
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    updateProfilePicApiCallId: string = "";
    fileUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
    updateProfileApiCallId: string = "";
    changePasswordApiCallId: string = "";
    getProfileDataApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            activeTab: 0,
            countryCodeList: [],
            countryCode: '+91',
            anchorEl: null,
            selectedProfilePic: null,
            oldPassword: '',
            isEditMode: false,
            newPassword: '',
            openModal: false,
            confirmPassword: '',
            oldPasswordError: '',
            confirmPasswordError: '',
            newPasswordError: '',
            email: '',
            phoneNumberError: '',
            phoneNumber: '',
            tempfullName: '',
            fullName: '',
            fullNameError: '',
            organization: '',
            role: '',
            profilePic: 'temp',
            profilePicToUpload: null,
            profileData: null,
            showCommonMessage: false,
            messageType: "default",
            message: "",
            oldPasswordEye: false,
            confirmPasswordEye: false,
            newPasswordEye: false,
            popOverStyle: {},
            popOverMenuType: 'profilePic',
            popOverAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            isLoading: false,
            assignedOrganizationList: []
        };

        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.getProfileDetails();
        const codeList = phoneCodes.phoneNumberCodes.map((element: string) => ({ label: element, value: element }))
        this.setState({ countryCodeList: codeList })
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            if (responseJson?.errors?.[0]?.token) {
                removeStorageData('token');
                this.goToBlock('EmailAccountLoginBlock');
                return;
            }

            if (responseJson) {
                this.handleResponse(apiRequestCallId, responseJson)
            }
        }
    }

    handleResponse = (apiRequestCallId: string, responseJson: { data: Profile; errors: Array<{ [keyName: string]: string }> & { [keyName: string]: unknown } }) => {
        switch (apiRequestCallId) {
            case this.updateProfilePicApiCallId:
                this.setState({ profileData: responseJson.data, isLoading: false }, () => {
                    this.setState({ profilePic: this.state.profileData?.attributes.profile_image as string, openModal: false })
                })
                break;
            case this.getProfileDataApiCallId:
                this.handleProfileDataAPIResp(responseJson)
                break;
            case this.updateProfileApiCallId:
                this.handlePrePopulate(responseJson)
                break;
            case this.changePasswordApiCallId:
                const passwordError = responseJson?.errors?.[0]?.password
                if (/password you entered/.test(passwordError)) {
                    this.setState({ oldPasswordError: responseJson.errors[0].password, isLoading: false })
                } else if (/new Password must be different/.test(passwordError)) {
                    this.setState({ newPasswordError: passwordError, isLoading: false })
                } else {
                    this.setState({ showCommonMessage: true, message: 'Password Changed Successfully', messageType: 'success', oldPassword: "", oldPasswordError: "", newPassword: '', newPasswordError: "", confirmPassword: "", confirmPasswordError: "", isEditMode: false, isLoading: false })
                }
                break;
        }
    }

    handleProfileDataAPIResp = (responseJson: { data: Profile }) => {
        this.setState({ profileData: responseJson.data, isLoading: false }, () => {
            if (this.state.profileData) {
                const isProjectManager = this.state.profileData.attributes.role.name === 'project_manager';
                const is_SuperAdmin = this.state.profileData.attributes.role.name === 'super_admin';
                let role = "Participant";
                let Organization = `${this.state.profileData.attributes?.organization?.name}`;
                let assignedOrganizationList: string[] = [];
                if (isProjectManager) {
                    role = "Project Manager";
                    assignedOrganizationList = this.state.profileData.attributes.assigned_organizations.map((orgn) => orgn.name);
                }
                if (is_SuperAdmin) {
                    role = 'Super Admin'
                }
                this.setState({
                    fullName: this.state.profileData.attributes.full_name,
                    tempfullName: this.state.profileData.attributes.full_name,
                    email: this.state.profileData.attributes.email,
                    countryCode: `+${this.state.profileData.attributes.country_code}`,
                    phoneNumber: this.state.profileData.attributes.phone_number,
                    organization: Organization,
                    assignedOrganizationList: assignedOrganizationList,
                    role: role,
                    profilePic: this.state.profileData.attributes.profile_image,
                })
            }
        })
    }


    handlePrePopulate = (responseJson: { data: Profile; errors: Array<{ [keyName: string]: string }> & { [keyName: string]: unknown } }) => {
        if (responseJson.errors) {
            if (responseJson.errors.phone_number) {
                this.setState({ phoneNumberError: "Entered Phone number is invalid!", isLoading: false })
            }
            return;
        }
        this.setState({ profileData: responseJson.data, isEditMode: false, showCommonMessage: true, message: "Profile changes saved successfully", messageType: "success", isLoading: false }, () => {
            if (this.state.profileData) {
                const isProjectManager = this.state.profileData.attributes.role.name === 'project_manager';
                const is_SuperAdmin = this.state.profileData.attributes.role.name === 'super_admin';
                let Organization = `${this.state.profileData.attributes?.organization?.name}`;
                let role = "Participant";
                if (isProjectManager) {
                    Organization = this.state.profileData.attributes.assigned_organizations.map((orgn) => orgn.name).join(', ');
                    role = "Project Manager";
                }
                if (is_SuperAdmin) {
                    role = 'Super Admin'
                }
                this.setState({
                    tempfullName: this.state.profileData.attributes.full_name,
                    fullName: this.state.profileData.attributes.full_name,
                    countryCode: `+${this.state.profileData.attributes.country_code}`,
                    email: this.state.profileData.attributes.email,
                    organization: Organization,
                    phoneNumber: this.state.profileData.attributes.phone_number,
                    profilePic: this.state.profileData.attributes.profile_image,
                    role: role,
                })
            }
        })
    }

    onCloseMessage = () => {
        this.setState({ showCommonMessage: false, message: "", messageType: 'default' })
    }

    onOldPasswordEyeClick = () => {
        this.setState({ oldPasswordEye: !this.state.oldPasswordEye })
    }

    onNewPasswordEyeClick = () => {
        this.setState({ newPasswordEye: !this.state.newPasswordEye })
    }

    onConfirmPasswordEyeClick = () => {
        this.setState({ confirmPasswordEye: !this.state.confirmPasswordEye })
    }

    goToBlock = (blockName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(getName(MessageEnum.NavigationTargetMessage), blockName)
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        )
        this.send(message)
    }

    handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
        if (this.state.profileData) {
            this.setState({
                activeTab: newValue,
                confirmPassword: '',
                confirmPasswordError: '',
                newPassword: '',
                newPasswordError: '',
                oldPassword: '',
                oldPasswordError: '',
                tempfullName: this.state.profileData.attributes.full_name,
                fullName: this.state.profileData?.attributes.full_name,
                fullNameError: "",
                phoneNumber: this.state.profileData?.attributes.phone_number,
                phoneNumberError: '',
                countryCode: `+${this.state.profileData?.attributes.country_code}`
            })
        }
    }

    a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    handleOpenProfilePopover = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget, popOverMenuType: configJSON.profilePic, popOverAnchorOrigin: { vertical: 'bottom', horizontal: 'right' }, popOverStyle: { top: '10px', left: '30px' } });
    }

    handleOpenEditProfilePopOver = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget, popOverMenuType: configJSON.editProfile, popOverAnchorOrigin: { vertical: 'bottom', horizontal: 'center' }, popOverStyle: {} });
    }

    renderPopOverMenu = (type: string) => {
        if (type === configJSON.profilePic) {
            return <MenuItem
                data-test-id='change-profile'
                style={{
                    backgroundColor: 'rgb(240, 232, 255)',
                    borderRadius: '8px',
                    color: '#334155',
                    fontFamily: "'Inter', sans-serif",
                    fontSize: '14px',
                    lineHeight: '22px'
                }}
                onClick={this.handleOpenModal}
            >{configJSON.ChangeProfilePicture}</MenuItem>
        } else if (type === configJSON.editProfile) {
            return <MenuItem
                data-test-id='change-profile'
                style={{
                    backgroundColor: 'rgb(240, 232, 255)',
                    borderRadius: '8px',
                    color: '#334155',
                    fontFamily: "'Inter', sans-serif",
                    fontSize: '14px',
                    lineHeight: '22px'
                }}
                onClick={this.editMode}
            >{configJSON.EDITPROFILE}</MenuItem>
        }


    }

    handleClosePopOver = () => {
        this.setState({ anchorEl: null, popOverMenuType: '' })
    }

    handleOpenModal = () => {
        this.setState({ openModal: true, anchorEl: null });
    }

    handleCloseModal = () => {
        this.setState({ openModal: false, selectedProfilePic: '' });
    }

    handleOpenFile = () => {
        if (this.fileUploadRef.current) {
            this.fileUploadRef.current.click();
        }
    }

    handleFileChange = () => {
        if (this.fileUploadRef.current) {
            const file = this.fileUploadRef.current.files?.[0] ?? null;
            if (file) {
                const reader = new FileReader();
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    this.setState({ selectedProfilePic: event.target?.result, profilePicToUpload: file });
                };
                reader.readAsDataURL(file as Blob);
            }
        }
    }

    editMode = () => {
        this.setState({ isEditMode: true, anchorEl: null })
    }

    offEditMode = () => {
        this.setState({ isEditMode: false })
    }

    onSave = () => {
        if (this.state.activeTab === 0) {
            this.handleUpdateProfile();
        } else {
            this.handleChangePassword();
        }
    }

    cancelEdit = () => {
        this.setState({
            isEditMode: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: ''
        })
    }

    handleCopyEmail = () => {
        navigator.clipboard.writeText(this.state.email)
    }

    handleSelectPhoneInputList = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ countryCode: event.target.value as string, phoneNumberError: '' })
    }

    isEmpty = (entity: string) => {
        return entity.trim() === ''
    }

    getIsSaveDisabled = () => {
        return this.state.activeTab === 1 && (this.isEmpty(this.state.oldPassword) || this.isEmpty(this.state.newPassword) || this.isEmpty(this.state.confirmPassword))
    }

    getEmptyErrorMsg = (fieldName: string) => {
        return `${fieldName} is required!`
    }

    onChangePhoneNumber = (text: string) => {
        this.setState({ phoneNumber: text.replace(configJSON.DigitRegex, ''), phoneNumberError: '' })
    }

    onChangeFullName = (text: string) => {
        if (text === '' || configJSON.FullNameRegex.test(text)) {
            this.setState({ tempfullName: text, fullNameError: '' })
        }
    }

    onChangeOldPassword = (text: string) => {
        this.setState({ oldPassword: text, oldPasswordError: "" })
    }

    onChangeNewPassword = (text: string) => {
        this.setState({ newPassword: text, newPasswordError: "" })
    }

    onChangeConfirmPassoword = (text: string) => {
        this.setState({ confirmPassword: text, confirmPasswordError: "" })
    }

    renderNewPasswordEye = () => {
        return {
            icon: !this.state.newPasswordEye ? <Visibility /> : < VisibilityOff />,
            type: this.state.newPasswordEye ? "text" : "password"
        }
    }

    renderOldPasswordEye = () => {
        return {
            icon: !this.state.oldPasswordEye ? <Visibility /> : < VisibilityOff />,
            type: this.state.oldPasswordEye ? "text" : "password"
        }
    }

    renderConfirmPasswordEye = () => {
        return {
            icon: !this.state.confirmPasswordEye ? <Visibility /> : < VisibilityOff />,
            type: this.state.confirmPasswordEye ? "text" : "password"
        }
    }

    saveBtnLabel = () => {
        return this.state.activeTab === 1 ? "Change Password" : "Save";
    }

    isSuperAdmin = () => {
        return this.state.role === 'Super Admin'
    }

    handleUpdateProfile = async () => {
        let error = false

        if (this.isEmpty(this.state.tempfullName)) {
            error = true
            this.setState({ fullNameError: this.getEmptyErrorMsg("Full name") })
        }

        if (!this.isSuperAdmin() && this.isEmpty(this.state.phoneNumber)) {
            error = true
            this.setState({ phoneNumberError: this.getEmptyErrorMsg("Phone number") })
        } else if (!this.isSuperAdmin() && this.state.phoneNumber.length < 4) {
            error = true;
            this.setState({ phoneNumberError: 'Phone number should have at least length of 4 digits' });
        }

        if (error) {
            return
        }

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            token: await getStorageData(configJSON.Token)
        };

        const payloadData = {
            full_phone_number: this.state.countryCode.substring(1).concat(this.state.phoneNumber),
            full_name: this.state.tempfullName.trim().replace(configJSON.StringRegex, ' '),
        };

        const superAdminPayloadData = {
            full_name: this.state.tempfullName.trim().replace(configJSON.StringRegex, ' '),
        };

        const httpBody = {
            data: this.isSuperAdmin() ? superAdminPayloadData : payloadData,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateProfileApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.EditProfileEndPoint);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    updateProfilePic = async () => {
        this.setState({ isLoading: true });

        const header = {
            token: await getStorageData(configJSON.Token),
        };

        const formData = new FormData();
        formData.append("profile_image", this.state.profilePicToUpload as Blob);
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateProfilePicApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateProfileEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getProfileDetails = async () => {
        this.setState({ isLoading: true })
        const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            token: await getStorageData(configJSON.Token),
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getProfileDataApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.MyProfileEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.methodTypeApiGetUserProfile);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    handlePasswordErrorCondition = () => {
        let error = false;
        if (!configJSON.IdealPasswordRegex.test(this.state.oldPassword)) {
            error = true
            this.setState({ oldPasswordError: configJSON.OldPasswordError })
        }
        if (!configJSON.IdealPasswordRegex.test(this.state.newPassword)) {
            error = true
            this.setState({ newPasswordError: configJSON.PasswordError })
        } else if (this.state.confirmPassword !== this.state.newPassword) {
            error = true;
            this.setState({ confirmPasswordError: configJSON.ConfirmNewPasswordError })
        }
        return error;
    }

    handleChangePassword = async () => {
        let error = false;
        error = this.handlePasswordErrorCondition();

        if (error) {
            return;
        }

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            token: await getStorageData(configJSON.Token),
        };

        const payloadData = {
            old_password: this.state.oldPassword,
            new_password: this.state.newPassword,
        };

        const httpBody = {
            data: payloadData
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.changePasswordApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ChangePasswordEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Phase, Tool, Organization } from "../../../blocks/kanbanboard/src/KanbanBoardController";
import React, { createRef } from "react";
import { INotifications } from "./DashboardViewWrapperController.web";
import { IOrganization } from "../../../blocks/projectportfolio/src/ProjectPortfolioController";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    total_users: number;
    total_organizations: number;
    total_projects: number;
    completed_projects:number;
    total_tasks: number; 
    tasks_to_do: number;
    tasks_in_progress: number;
    tasks_need_review: number;
    tasks_completed: number;
    projects_by_organization:{
      organization_name: string;
      total_projects: number;
      inprogress_projects: number;
    }[]
  };
  superAdminTable: {
    organization_id: number;
    organization_name: string;
    project_id: number;
    project: string;
    count: number;
  }[];
  filterSuperAdminTable: {
    organization_id: number;
    organization_name: string;
    project_id: number;
    project: string;
    count: number;
  }[];
  pieChartData: {
    id: string;
    data: string;
    label: string;
  }[],
  usersDataByOrg: {
    id: string;
    name: string;
    progress: string;
    projects: string;
  }[],
  notificationsData: {
    id: string;
    type: string;
    attributes: {
      id: number;
      created_by: {
        id:number;
        name: string;
        profile_image_url: string;
      };
      headings: string;
      contents: string;
      app_url: string | null;
      is_read: boolean;
      read_at: string | null
      created_at: string;
      updated_at: string;
      account_id: number;
      notificable_id:number;
      notificable_type: string
    }
  }[],
  totalCandidates: string;
  totalUsers: string;
  totalProjects: string;
  totalTasks: string;
  totalOrganizations: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  role: string;
  dashboardCountData: {
    label: string;
    count: number
  }[];
  phases: Phase[];
  organizations: Organization[];
  tools: Tool[]
  organizationList: Array<{ label: string, value: number }>;
  projectList: Array<{ label: string, value: number }>;
  phaseList: Array<{ label: string, value: number }>;
  toolList: Array<{ label: string, value: number }>;
  selectedOrganization: number | undefined | '';
  selectedProject: number | undefined;
  selectedTool: number | undefined;
  selectedPhase: number | undefined;
  isLoading: boolean
  scrollLoading: boolean
  page: number
  currentHover:number | undefined
  notificationTotalPages: number
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  notificationApiCallId: string = "";
  getOrganizationsApiCallId: string = "";
  getPhasesApiCallId: string = "";
  getSuperAdminProjectsListApiCAllId: string = "";
  getMoreNotificationApiCallId: string = "";
  listRef: React.RefObject<HTMLUListElement>;
  getOrganizationListApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      currentHover: undefined,
      type: "",
      dashboardData: {
        completed_projects: 0,
        projects_by_organization: [],
        tasks_completed: 0,
        tasks_in_progress: 0,
        tasks_need_review: 0,
        tasks_to_do: 0,
        total_organizations: 0,
        total_projects: 0,
        total_tasks: 0,
        total_users: 0,
      },
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: true,
      notificationsData: [],
      usersDataByOrg: [{
        id: '1', name: "Builder.ai", progress: "80", projects: "45"
      },
      { id: '2', name: "Tatvasoft", progress: "60", projects: "29" },
      { id: '3', name: "Infosys", progress: "50", projects: "18" },
      {
        id: '4', name: "TCS", progress: "30", projects: "25",
      }],
      totalOrganizations: "",
      totalProjects: "",
      totalTasks: "",
      totalUsers: "",
      pieChartData: [],
      role: "",
      dashboardCountData: [],
      organizationList: [],
      projectList: [],
      phaseList: [],
      toolList: [],
      selectedPhase: undefined,
      selectedTool: undefined,
      selectedOrganization: undefined,
      selectedProject: undefined,
      phases: [],
      organizations: [],
      tools: [],
      superAdminTable: [],
      filterSuperAdminTable: [],
      isLoading: false,
      scrollLoading: false,
      page: 1,
      notificationTotalPages: 0,

    };
    this.listRef = createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.queryParamsTestforSuperAdmin();
    this.getOrganizationList();
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        switch(webApiRequestCallId) {
          case this.apiDashboardItemCallId:
            this.setState({
              dashboardData: webResponseJson,
              errorMsg: "",
              loading: false
            });
            this.getDashboardCountByRole(webResponseJson)
            return 
          case this.getMoreNotificationApiCallId:
            this.setState({ notificationsData: [...this.state.notificationsData, ...webResponseJson.data], scrollLoading: false, notificationTotalPages: webResponseJson.meta.total_pages })
            return
          case this.getSuperAdminProjectsListApiCAllId:
            const selectOrganization = this.createUniqueArray(webResponseJson, 'organization')
            const selectProject = this.createUniqueArray(webResponseJson, 'project')
            const orgList = selectOrganization.map(item => ({ label: item.organization_name, value: item.organization_id }))
            const projList = selectProject.map(item => ({ label: item.project, value: item.project_id }))
            this.setState({ 
                superAdminTable: webResponseJson, 
                filterSuperAdminTable: webResponseJson, 
                projectList: projList,
                isLoading: false,
              })
            return
          case this.getPhasesApiCallId:
            const phasesResponse = webResponseJson.data as Phase[]
              this.setState({
                phases: phasesResponse,
                phaseList: phasesResponse.map(item => ({ label: item.attributes.title, value: item.id })), 
                toolList: this.getToolList(phasesResponse)
              })
            return
            case this.getOrganizationListApiCallId:
                const organizationList: IOrganization[] = webResponseJson.data as IOrganization[];
                const formattedOrganizationList = organizationList.map((element: IOrganization) => ({ label: element.attributes.name, value: Number(element.id) }))
                this.setState({ organizationList: formattedOrganizationList });
            return;
          }
      } else if (webResponseJson && webResponseJson.errors) {

        switch(webApiRequestCallId) {
          case this.apiDashboardItemCallId:
          case this.notificationApiCallId:
            this.setState({
              errorMsg: webErrorReponse,
              loading: false
            });
            return
          }
      } 
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(prevState.role !== this.state.role){
      this.changeLoadingStatus()
      this.fetchDashboardDetails()
    }
    if(JSON.stringify(prevState.notificationsData) !== JSON.stringify(this.state.notificationsData)) {
        this.setState({page: this.state.page+1})
    }
  }
  handleUpdateNotification = (data: INotifications[], total: number) => {
    this.setState({ notificationsData: data, notificationTotalPages: total, page:1 })
  }
  handleCheckOverflow = (event: React.MouseEvent<Element>, idx: number) => {
    this.setState({currentHover: undefined})
    if(event.currentTarget.scrollWidth >= event.currentTarget.clientWidth) {
      this.setState({currentHover: idx})
    }
  }
  getDashboardCountByRole = (webResponseJson: {
    total_users: number;
    total_organizations: number;
    total_projects: number;
    completed_projects:number;
    total_tasks: number; 
    tasks_to_do: number;
    tasks_in_progress: number;
    tasks_need_review: number;
    tasks_completed: number;
    projects_by_organization:{
      organization_name: string;
      total_projects: number;
      inprogress_projects: number;
    }[]
  }) => {

    const orgData =  { count: webResponseJson.total_organizations, label: "Organization"}
    const countData = [
      { count: webResponseJson.total_projects, label: "Projects"},
      { count: webResponseJson.total_tasks, label: "Tasks"},
    ]
    if (this.state.role === "super_admin") {
      this.getPhases()
      this.getOrganizationProjectsList('')
      this.setState({
        dashboardCountData: [ orgData, ...countData, { count: webResponseJson.total_users, label: "Participants"},]
      })
    } else if(this.state.role === "project_manager") {
      this.setState({
        dashboardCountData: [{ count:webResponseJson.total_users, label: "Users"}, ...countData, orgData],
      })
    } else {
      this.setState({
        dashboardCountData: [...countData],
      })
    }
  }
  changeRole = (value: string) => {
    this.setState({ role: value })
  }
  deCrypt = (salt: string, encoded: string): string => {
    if (!encoded) return ''
    const TextToChars = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
    const ApplySaltToChar = (code: number): number => TextToChars(salt).reduce((first, second) => first ^ second, code);
    return encoded
        .match(/.{1,2}/g)
        ?.map((hexa) => parseInt(hexa, 16))
        ?.map(ApplySaltToChar)
        ?.map((charCode) => String.fromCharCode(charCode))
        .join("") ?? "";
  };
  handleScroll = () => {
    if(this.listRef.current){
        const { scrollTop, clientHeight, scrollHeight } = this.listRef.current
        if(scrollTop + clientHeight >= scrollHeight -100 && !this.state.scrollLoading){
          if(this.state.notificationTotalPages >= this.state.page){
            this.setState({ scrollLoading: true })
            this.fetchMoreNotifications()
          }
        }
    }
}
  getToolList = (phases: Phase[]) => {
    const finalTools: Tool[] = []
    phases.forEach((phase) => {
      finalTools.push(...phase.attributes.tools)
    })
    return finalTools.map(tool => ({ label: tool.title, value: tool.id }))
  }
  createUniqueArray = (data: {organization_id: number, organization_name: string, project: string, project_id: number}[], type: 'organization' | 'project') => {
    const uniqueMap = new Map<string, {organization_id: number, organization_name: string, project: string, project_id: number}>()
    if (!data) return []
    if (type === 'organization') {
      data && data.forEach((item) => {
        const key = `${item.organization_id}-${item.organization_name}`
        if(!uniqueMap.has(key)){
          uniqueMap.set(key, item)
      }
      })
    }
    if (type=== 'project') {
      data && data.forEach((item) => {
        const key = `${item.project_id}-${item.project}`
        if(!uniqueMap.has(key)){
          uniqueMap.set(key, item)
      }
      })
    }
    return Array.from(uniqueMap.values())
  }

  queryParamsTestforSuperAdmin = async () => {
    const encryptedRole = await getStorageData('role');
    const role = this.deCrypt(webConfigJSON.Secretkey, encryptedRole);
    this.setState({ role: role })

  }

  fetchDashboardDetails = async() => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: await getStorageData(webConfigJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiDashboardItemCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${webConfigJSON.dashboardGetUrl}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.dashboarApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  fetchNotifications = async() => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: await getStorageData(webConfigJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.notificationApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${webConfigJSON.notificationGetUrl}?per_page=10&page=1`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.dashboarApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  getOrganizationProjectsList = async (type: string) => {
    const queryParams = this.addParamsForData(type)
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: await getStorageData(webConfigJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSuperAdminProjectsListApiCAllId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${webConfigJSON.organizationProjectListEndPoint}${queryParams}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.dashboarApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
    
  }
  getPhases = async () => {
    const header = {
      token: await getStorageData(webConfigJSON.Token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPhasesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.dashboarApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projecttemplates/phases`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  fetchMoreNotifications = async() => {
    this.setState({ scrollLoading: true })
    const header = {
        "Content-Type": webConfigJSON.dashboarContentType,
        token: await getStorageData(webConfigJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMoreNotificationApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${webConfigJSON.notificationGetUrl}?per_page=10&page=${this.state.page}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.dashboarApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
}

getOrganizationList = async () => {
  const header = {
    "Content-Type": webConfigJSON.validationApiContentType,
    token: await getStorageData(webConfigJSON.Token),
  };
  const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getOrganizationListApiCallId = reqMessage.messageId;
  reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), webConfigJSON.organizationListEndPoint);
  reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.dashboarApiMethodType);
  runEngine.sendMessage(reqMessage.id, reqMessage);
}
  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }
  goToBlockWithParams = (blockName: string, params: string) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), params);
      this.send(message);
  }
  changeLoadingStatus = () => {
    this.setState({ isLoading: this.state.isLoading})
  }
  addParamsForData = (type: string) => {
    switch(type) {
      case 'organization':
        return `?organization_id=${this.state.selectedOrganization}`
      case 'project':
        return `?project_id=${this.state.selectedProject}`
      case 'phase':
        return `?phase_id=${this.state.selectedPhase}`
      case 'tool':
        return `?tool_id=${this.state.selectedTool}`
      default:
        return ''
    }
  }
  onChangePhase = async (phaseId: unknown) => {
    this.changeLoadingStatus()
    this.setState({ 
      selectedProject: this.state.selectedProject,
      selectedOrganization:  this.state.selectedOrganization,
      selectedTool: undefined,
      selectedPhase: phaseId as number, 
      toolList: this.getToolList(this.state.phases.filter(phase => phase.id === phaseId)),
      isLoading: true,
    }, () => {
      this.getOrganizationProjectsList('phase');
    });

  }

  onChangeTool = async (toolId: unknown) => {
    this.changeLoadingStatus()
    this.setState({ selectedTool: toolId as number }, () => {
      this.getOrganizationProjectsList('tool');
    });

  }
  onChangeOrganization = (organizationId: unknown) => {
    const projectByOrganization = this.state.superAdminTable.filter(item => item.organization_id === organizationId as number)
    const newProjectList = this.createUniqueArray(projectByOrganization, 'project').map(item => ({ label: item.project, value: item.project_id }))
    this.setState({ selectedOrganization: organizationId as number, projectList: newProjectList, selectedProject: undefined, filterSuperAdminTable: projectByOrganization,  })
  }
  onChangeProject = (projectId: unknown) => {
    const newProjectId = projectId as number
    const dataByProject = this.state.superAdminTable.filter(item => item.project_id === newProjectId)

    this.setState({ selectedProject: newProjectId, filterSuperAdminTable: dataByProject })
  }
  clearAllFilters = () => {
    this.setState({ 
      selectedOrganization: '', 
      selectedProject: undefined, 
      selectedPhase: undefined, 
      selectedTool: undefined, 
    }, () => {
      this.getOrganizationProjectsList('');
    });
  }
  handleTaskNavigatioinNotification = (type: string, notificationId: number) => {
    switch(type){
        case webConfigJSON.Task:
            this.goToBlock('KanbanBoard')
            const newUrl = `${window.location.pathname}?task=${notificationId}`;
            window.location.replace(newUrl)
            break
        case webConfigJSON.Project:
            this.goToBlockWithParams('KanbanBoardWithParams', String(notificationId))
            break;
        case webConfigJSON.SubTask:
            this.goToBlockWithParams('SubTaskDetails', String(notificationId))
            break;
        default:
            break
    }
  }
  handleNavigateRemoveFrom = (contents: string) => {
    if(contents.includes('task')){
      this.goToBlock('TaskProjects')
    } else {
      this.goToBlock('Projects')
    }
  }
  handleNavigationNotification = async(type: string, heading: string, notificationId: number, contents: string) => {
      switch(heading) {
          case webConfigJSON.MemberRemoved:
              this.handleNavigateRemoveFrom(contents)
              break;
          case webConfigJSON.PasswordChanged:
              this.goToBlock('UserProfile')
              break;
          case webConfigJSON.ProjectAssigned:
          case webConfigJSON.ProjectReminder:
              this.goToBlockWithParams('ProjectDetails', String(notificationId))
              break;
          case webConfigJSON.TaskDeleted:
          case webConfigJSON.TaskDelivered:
          case webConfigJSON.TaskAccepted:
          case webConfigJSON.TaskRejected:
          case webConfigJSON.TaskAssigned:
          case webConfigJSON.TaskReminder:
              this.handleTaskNavigatioinNotification(type, notificationId)
              break;
          default:
              break;
      }
  }
  // Customizable Area End
}

import React from 'react';
import { Button, styled } from '@material-ui/core';
import { Box } from '@mui/material';

interface Props {
    primary?: { actionFn: () => void, label: string, disabled?: boolean };
    secondary?: { actionFn: () => void, label: string, disabled?: boolean };
    ternary?: { actionFn: () => void, label: string, disabled?: boolean; } | undefined;
}

const CommonPageActions = (props: Props) => {
    const { primary, secondary, ternary } = props;
    return <Box sx={webStyles.actionButtonWrapper}>
        {ternary ? <StyledSecondaryButton disabled={Boolean(ternary.disabled)} data-test-id='ternary' onClick={ternary.actionFn} >{ternary.label}</StyledSecondaryButton> : null}
        <Box sx={webStyles.mainActionBtns} >
            {secondary ? <StyledSecondaryButton disabled={Boolean(secondary.disabled)} data-test-id='cancel' onClick={secondary.actionFn} >{secondary.label}</StyledSecondaryButton> : null}
            {primary ? <StyledPrimaryButton disabled={Boolean(primary.disabled)} data-test-id='submit' onClick={primary.actionFn} >{primary.label}</StyledPrimaryButton> : null}
        </Box>
    </Box>
}

export default CommonPageActions;

const webStyles = {
    actionButtonWrapper: {
        padding: '40px 31px',
        borderRadius: '12px',
        backgroundColor: 'white',
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media(max-width:600px)': {
            padding: '20px 0px',
        }
    },
    mainActionBtns: {
        marginLeft: 'auto',
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end',
        gap: "20px",
        "@media(max-width:600px)": {
            gap: '10px',
        }
    },
}

const StyledPrimaryButton = styled(Button)({
    backgroundColor: '#5B4595',
    color: 'white',
    textTransform: "capitalize",
    fontFamily: "'Inter', sans-serif",
    height: '56px',
    fontWeight: 400,
    padding: '16px',
    minWidth: '240px',
    borderRadius: '8px',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#5B4595'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#F1F5F9",
        color: '#64748B',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        "@media(max-width:800px)": {
            width: 'fit-content',
            minWidth: 'auto',
            fontSize: '14px',
        }
    },
    "@media(max-width:800px)": {
        width: 'fit-content',
        minWidth: 'auto',
        fontSize: '14px',
    },
    "@media(max-width:600px)": {
        fontSize: '12px',
    }
})

const StyledSecondaryButton = styled(Button)({
    backgroundColor: '#DEDAEA',
    color: '#5B4595',
    textTransform: "capitalize",
    height: '56px',
    fontWeight: 400,
    padding: '16px',
    fontFamily: "'Inter', sans-serif",
    minWidth: '240px',
    borderRadius: '8px',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#DEDAEA'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#F1F5F9",
        color: '#64748B',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        "@media(max-width:800px)": {
            minWidth: 'auto',
            width: 'fit-content',
            fontSize: '14px',
        }
    },
    "@media(max-width:800px)": {
        minWidth: 'auto',
        width: 'fit-content',
        fontSize: '14px',
    },
    "@media(max-width:600px)": {
        fontSize: '12px',
    }
})
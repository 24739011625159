import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, InputAdornment, InputLabel, TextField, styled } from '@material-ui/core';
import { Button, Typography } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InputField from './InputField';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface Props {
    onChange?: (text: Object, isRelationFieldChange: boolean) => void;
    addMoreFields?: () => void;
    removeField?: (key: number) => void;
    iButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onChangeDefaultField?: (value: string, key: number) => void;
    relation?: { key: number, content: string; ans_type: string; error: string },
    onLoadAddFields?: () => void;
    value?: { key: number, content: string; ans_type: string; error: string }[];
    placeholder?: string;
    disabled?: boolean;
    helperText?: string;
    error?: boolean;
    label?: string;
    style?: React.CSSProperties;
    placeHolderPrefix?: string;
    fullWidth?: boolean;
    asterisk?: boolean;
    disabledAddMore?: boolean;
    labelStyle?: GenericStyle;
    readOnly?: boolean;
    subLabel?: string;
    isHavingRelation?: boolean;
}

interface GenericStyle {
    [key: string]: string;
}

const StyledInputField = styled(TextField)({
    '& .MuiInput-underline:hover::before': {
        borderBottom: "1px solid #CBD5E1"
    },
    "& .MuiInput-underline:hover": {
        borderBottom: "1px solid #CBD5E1",
    },
    "& .MuiInput-underline::before": {
        display: 'none',
    },
    '& .MuiInput-underline::after': {
        borderWidth: '1px'
    },
    "& .MuiInput-underline.Mui-focused": {
        borderBottom: "1px solid #5B4595",
    },
    '& .MuiInput-underline': {
        borderBottom: "1px solid #CBD5E1",
    },
    "& .MuiInputBase-root": {
        paddingLeft: "0px"
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
    },
    '& .MuiInput-underline.Mui-focused::after': {
        display: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
        borderBottom: '1px solid #f44336'
    }
});


const CommonMultipleAnswerField = (props: Props) => {
    const {
        onChange,
        value,
        placeholder,
        disabledAddMore,
        onLoadAddFields,
        disabled,
        error,
        helperText,
        placeHolderPrefix,
        label,
        subLabel,
        style,
        addMoreFields,
        relation,
        onChangeDefaultField,
        removeField,
        fullWidth = false,
        asterisk = false,
        labelStyle,
        isHavingRelation = false,
        readOnly = false,
        iButtonClick,
        ...rest
    } = props;

    useEffect(() => {
        if (onLoadAddFields) {
            onLoadAddFields();
        }
    }, [])
    return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
        {label &&
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel style={{ ...webStyles.label, ...labelStyle }} >{label} {asterisk && <span style={{ color: 'red' }} >*</span>}
                </InputLabel>
                {iButtonClick && <IconButton data-test-id='i' onClick={iButtonClick} style={{ width: '16px', height: '16px', padding: '0 20px' }} > <InfoRoundedIcon style={{ color: '#64748B', width: '16px', height: '16px' }} /> </IconButton>}
            </Box>
        }
        {subLabel ? <StyledSubLabel>{subLabel}</StyledSubLabel> : null}
        <Box style={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '8px', padding: '18.5px 14px', ...style }}>
            {value?.map((fieldData, indx) =>
                <StyledInputField
                    data-test-id='default-field'
                    disabled={disabled}
                    key={fieldData.key}
                    style={{ marginBottom: '10px' }}
                    fullWidth
                    value={fieldData.content}
                    onChange={(event) => onChangeDefaultField && onChangeDefaultField(event.target.value, fieldData.key)}
                    variant='standard'
                    inputProps={{ maxLength: 80 }}
                    error={Boolean(fieldData.error)}
                    helperText={fieldData.error}
                    InputProps={{
                        readOnly: readOnly,
                        startAdornment:
                            <InputAdornment position="end">
                                <Typography style={{ color: '#64748B' }}>{placeHolderPrefix ?? ''} {indx + 1} : &nbsp;</Typography>
                            </InputAdornment>,
                        endAdornment:
                            <InputAdornment position="end">
                                {(isHavingRelation && indx <= 1) ? null :
                                    (!isHavingRelation && indx === 0) ? null :
                                        !disabled && <IconButton disabled={disabled} onClick={() => removeField && removeField(fieldData.key)} >
                                            <DeleteOutlineRoundedIcon />
                                        </IconButton>}
                            </InputAdornment>
                    }}
                />)}
            <Button data-test-id='add-field' disabled={disabled || disabledAddMore} onClick={() => addMoreFields && addMoreFields()} style={{ ...webStyles.addMoreBtn, color: disabledAddMore || disabled ? 'rgb(185, 185, 185)' : '#5B4595' }}>
                <AddCircleOutlineRoundedIcon style={{ marginRight: "5px" }} /> Add More
            </Button>
            {isHavingRelation && <InputField
                value={relation?.content}
                disabled={disabled}
                data-test-id='relation-field'
                inputProps={{ maxLength: 80 }}
                error={Boolean(relation?.error)}
                helperText={relation?.error}
                onChange={(text) => onChangeDefaultField && onChangeDefaultField(text, relation!.key)}
                label='Relationship :'
                placeholder='Enter the relationship'
            />}
        </Box>
    </Box >
}

export default CommonMultipleAnswerField;

const StyledSubLabel = styled(Typography)({
    '&.MuiTypography-root': {
        color: '#64748B',
        fontSize: '12px',
        lineHeight: '22px',
        fontFamily: "'Inter', sans-serif",
        width: '100%',
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: 'hidden',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
    },
    '&:hover': {
        display: "block",
        height: "auto",
        WebkitLineClamp: "unset",
        overflow: "visible",
        whiteSpace: "normal",
    }
})

const webStyles = {
    addMoreBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        color: '#5B4595',
        fontFamily: "'Inter', sans-serif",
        textTransform: 'capitalize',
        textAlign: 'start',
        paddingLeft: '0',
        fontWeight: 700
    } as React.CSSProperties,
    label: {
        color: '#334155',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        fontFamily: "'Inter', sans-serif",
    }
}
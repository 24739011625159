// Customizable Area Start
import React from "react";

import {
  Button,
  Typography,
  Grid,
  ImageListItem,
  ImageList,
  Box,
  List,
  ListItem,
} from "@material-ui/core";

import { styled } from "@material-ui/core/styles";

import ProjectPortfolioDetailController, {
  Props,
  configJSON,
} from "./ProjectPortfolioDetailController";
import Loader from "../../../components/src/Loader";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import { Accordion, AccordionDetails, AccordionSummary, Avatar } from "@mui/material";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CommonModal from "../../../components/src/CommonModal";
import CommonSearchableSelect from "../../../components/src/CommonSearchableSelect";
import CommonMessage from "../../../components/src/CommonMessage";
import { ModalSecondaryButton, StyledButton, StyledPrimaryButton } from "./ProjectPortfolio.web";

export default class ProjectPortfolioDetail extends ProjectPortfolioDetailController {
  constructor(props: Props) {
    super(props);
  }

  renderActionButtons = () => {
    return <Box data-test-id='modal-action' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <ModalSecondaryButton onClick={this.handleCancelAddMemberdModal} data-test-id='cancel-modal' >{configJSON.Cancel}</ModalSecondaryButton>
      {
        this.state.modalType === "add" ?
          <StyledPrimaryButton onClick={this.handleAddMember} data-test-id='add-member' >{configJSON.Add}</StyledPrimaryButton>
          :
          <StyledPrimaryButton onClick={this.handleDeleteMember} data-test-id='remove-member' >{configJSON.Remove}</StyledPrimaryButton>
      }
    </Box>
  }

  render() {
    return (
      <DashboardViewWrapper {...this.props} >
        <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }} >
          <Box sx={webStyle.projectDetailsWrapper}>
            <Typography data-test-id='project-title' style={webStyle.projectName} >Project Details</Typography>

            <Box style={webStyle.projectDetailsItemWrapper} >
              <Typography style={webStyle.title} >Project Name</Typography>
              <Typography style={webStyle.value} >{this.state.projectDetails?.attributes.project_name}</Typography>
            </Box>

            <Box style={webStyle.projectDetailsItemWrapper} >
              <Typography style={webStyle.title} >{configJSON.ProjectDescription}</Typography>
              <Typography style={webStyle.value} >{this.state.projectDetails?.attributes.description}</Typography>
            </Box>            

            <Box style={webStyle.projectDetailsItemWrapper} >
              <Typography style={webStyle.title} >{configJSON.ProjectStatus}</Typography>
              <Typography style={webStyle.value} >{this.state.projectDetails?.attributes.status}</Typography>
            </Box>

            <Box style={webStyle.projectDetailsItemWrapper} >
              <Typography style={webStyle.title} >{configJSON.ProjectDueDate}</Typography>
              <Typography style={webStyle.value} >{this.handleFormateTime(this.state.projectDetails?.attributes.due_date as string)}</Typography>
            </Box>

            <Box style={webStyle.projectDetailsItemWrapper} >
              <Typography style={webStyle.title} >{configJSON.CompletedTasks}</Typography>
              <Typography style={webStyle.value} >{this.state.projectDetails?.attributes.completed_task_count}/{this.state.projectDetails?.attributes.total_task_count}</Typography>
            </Box>

            <Box style={webStyle.projectDetailsItemWrapper} >
              <Typography style={webStyle.title} >Organization Name</Typography>
              <Typography style={webStyle.value} >{this.state.organizationList.find((organization) => organization.value === this.state.projectDetails?.attributes.organization_id)?.label}</Typography>
            </Box>

            <Box>
              <StyledAccordion data-test-id='members' expanded={this.state.expanded === 'panel1'} onChange={this.handleChangeAccordion('panel1')}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreRoundedIcon sx={webStyle.expandIcon} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <StyledAccordionLabel >
                    {"Members"}
                  </StyledAccordionLabel>
                  <Button data-test-id='add-members-modal' style={{ textTransform: 'capitalize', display: this.hideAction() ? 'none' : 'block' }} onClick={this.handleOpenMemberModal}  >
                    <StyledAddBtnLabel > <AddCircleOutlineRoundedIcon sx={webStyle.addIcon} /> Add More Members</StyledAddBtnLabel>
                  </Button>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {this.state.projectDetails?.attributes.members.map((member) => {
                    return <Box data-test-id="particpant" key={member.id} style={webStyle.memberWrapper} >
                      <Avatar src={member.profile_image} style={webStyle.profileWrapper} />
                      <Typography style={webStyle.memberName} >
                        {member.full_name}
                      </Typography>
                      {this.state.role === configJSON.Roles.PM && (
                        <Button data-test-id={`removeMember${member.id}`} variant="text" style={webStyle.removeMember}
                          onClick={(event) => this.handleOpenRemoveMemberModal(event, member.id, member.full_name)}>
                          {configJSON.Remove}
                        </Button>)}
                    </Box>
                  })}
                  {!this.state.projectDetails?.attributes.members_count && <Typography style={{ color: 'rgb(51, 65, 85)', fontFamily: "'Inter', sans-serif", }}>{configJSON.NoParticipant}</Typography>}
                </StyledAccordionDetails>
              </StyledAccordion>
              <StyledAccordion data-test-id='tasks' expanded={this.state.expanded === 'panel2'} onChange={this.handleChangeAccordion('panel2')}>
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreRoundedIcon sx={webStyle.expandIcon} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <StyledAccordionLabel>
                    {configJSON.Tasks}
                  </StyledAccordionLabel>
                  <Button data-test-id='add-more-task' style={{ textTransform: 'capitalize', display: this.hideAction() ? 'none' : 'block' }} onClick={this.goToKanbanboard} >
                    <StyledAddBtnLabel > <AddCircleOutlineRoundedIcon sx={webStyle.addIcon} /> {configJSON.AddMoreTasks} </StyledAddBtnLabel>
                  </Button>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <List style={{ listStyleType: "disc", marginTop: "0", padding: "0", marginLeft: "5px" }}>
                    {this.state.taskDescriptions.length > 0 ? this.state.taskDescriptions.map((task) => {
                      return <ListItem onClick={() => this.goToTaskDetails(task.id, task.tool_id)} data-test-id={`taskDescription${task.id}`} key={task.id} style={webStyle.listItem} >
                        <Typography style={webStyle.task} >
                          {task.title}
                        </Typography>
                      </ListItem>
                    }) :
                      <Typography data-test-id="noTask" style={{ color: 'rgb(51, 65, 85)', fontFamily: "'Inter', sans-serif", }}>
                        {configJSON.NoTask}
                      </Typography>}
                  </List>
                </StyledAccordionDetails>
              </StyledAccordion>
            </Box>
          </Box>
          <Box style={webStyle.actionWrapper}>
            <Box style={{ marginLeft: 'auto', display: "flex", justifyContent: 'flex-end' }} >
              <StyledButton onClick={this.handleGoToProjects} data-test-id='back-to-listing' >{configJSON.BackProjectListing}</StyledButton>
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.isLoading} />
        <CommonMessage data-test-id='common-msg' open={this.state.showCommonMessage} message={this.state.message} messageType={this.state.messageType} onClose={this.handleCloseCommonMessage} />
        <CommonModal data-test-id='modal' open={this.state.openModal} onClose={this.handleCloseModal} closeIcon modalTitle={this.state.modalType === configJSON.ModalType.Add ? configJSON.AddMembers : configJSON.RemoveMember} actionComponent={this.renderActionButtons()} >
          <Box style={{ display: 'flex', flexDirection: 'column', padding: "10px 0" }} >
            {this.state.modalType === configJSON.ModalType.Remove ?
              <Typography data-test-id="removeText" style={webStyle.modalDescriptionText} >{configJSON.Modal.Decription.RemoveMember}</Typography>
              : <>
                <CommonSearchableSelect
                  label={configJSON.Labels.Organization}
                  data-test-id='organization-multiselect'
                  selectedValues={this.state.selectedOrganizations}
                  selectedTitles={this.state.selectedOrganizationTitles}
                  placeholder={configJSON.PlaceHolder.SelectOrganization}
                  onChange={this.onMultiOrganizationSelect}
                  error={Boolean(this.state.selectedOrgError)}
                  helperText={this.state.selectedOrgError}
                  fullWidth
                  mb={'20px'}
                  list={this.state.organizationList}
                />
                <CommonSearchableSelect
                  label={configJSON.Labels.Participant}
                  data-test-id='participant-multiselect'
                  selectedValues={this.state.selectedMembers}
                  selectedTitles={this.state.selectedMemberTitles}
                  placeholder={configJSON.PlaceHolder.SelectParticipant}
                  helperText={this.state.selectedMemberError}
                  error={Boolean(this.state.selectedMemberError)}
                  onChange={this.onMultiSelectChange}
                  fullWidth
                  list={this.state.memberList}
                />
              </>
            }
          </Box>
        </CommonModal>
      </DashboardViewWrapper>
    );
  }
}

const StyledAccordionLabel = styled(Typography)({
  color: '#334155',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '26px',
  fontFamily: "'Inter', sans-serif",
  "@media(max-width:600px)": {
    fontSize: '12px',
    fontWeight: 600
  }
})

const StyledAddBtnLabel = styled(Typography)({
  color: '#5B4595',
  fontFamily: "'Inter', sans-serif",
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  "@media(max-width:600px)": {
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '18px',
  }
})

const StyledAccordion = styled(Accordion)({
  '&.MuiPaper-root.MuiAccordion-root': {
    borderTop: '1px solid #E4DEDE',
    borderRadius: '0',
    boxShadow: 'none',
  },
  '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
    margin: 0,
  }
})

const StyledAccordionSummary = styled(AccordionSummary)({
  '&.MuiButtonBase-root.MuiAccordionSummary-root': {
    minHeight: '100px',
    padding: '0',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '40px',
    "@media(max-width:600px)": {
      marginRight: '0px',
    }
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '20px 40px 20px 0',
    "@media(max-width:600px)": {
      margin: '0',
    }
  }
});

const StyledAccordionDetails = styled(AccordionDetails)({
  '&.MuiAccordionDetails-root': {
    padding: "8px 0px 16px"
  }
})

const webStyle = {
  addIcon: {
    fontWeight: 700,
    marginRight: '10px',
    "@media(max-width:600px)": {
      fontSize: '16px',
      marginRight: '5px'
    }
  },
  expandIcon: {
    color: '#0F172A',
    fontSize: '40px',
    "@media(max-width:600px)": {
      fontSize: '24px'
    }
  },
  value: {
    color: '#64748B',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
  },
  title: {
    color: '#334155',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
  },
  task: {
    color: '#334155',
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: '10px',
    cursor: "pointer",
    fontFamily: "'Inter', sans-serif",
  },
  listItem: {
    display: 'list-item',
    alignItems: 'center',
    cursor: "pointer"
  },
  projectDetailsItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  projectName: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '700', fontSize: '18px',
    lineHeight: '26px'
  },
  projectDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px',
    "@media(max-width:600px)": {
      padding: '20px',
    }
  },
  addMoreMemberButton: {

  },
  participantWrapper: { color: '#334155', fontSize: '18px', fontWeight: 700, lineHeight: '26px', fontFamily: "'Inter', sans-serif", },
  memberName: { color: '#334155', fontSize: "12px", textTransform: "capitalize", fontFamily: "'Inter', sans-serif", fontWeight: 700, lineHeight: '26px' } as React.CSSProperties,
  removeMember: { marginLeft: "auto", textTransform: "none", color: "#5B4595", textDecoration: "underline", fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
  taskWrapper: { color: '#334155', fontSize: '18px', fontWeight: 700, lineHeight: '26px', fontFamily: "'Inter', sans-serif", },
  memberWrapper: { display: 'flex', alignItems: 'center', marginBottom: '10px' },
  profileWrapper: { width: '24px', height: "24px", marginRight: "5px" },
  modalDescriptionText: { color: '#64748B', fontSize: '20px', lineHeight: '25px', fontFamily: "'Inter', sans-serif", },
  actionWrapper: { padding: '40px 31px 40px 31px', borderRadius: '12px', backgroundColor: 'white' },
};

// Customizable Area End

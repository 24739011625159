// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from '../../../framework/src/Utilities';

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
  }
export interface IAssignees {
    profile_image: string
    name: string
    email: string
    member_id: string
}
interface IPredefinedTasks {
    task_id: number;
    project_id: number;
    task_name: string;
    status: string
    assignee: IAssignees[]
}
interface IPredefinedTasksList {
  task_id: number;
  project_id: number;
  task_name: string;
  status: string
  assignee: IAssignees
}
interface IOrganization {
  organization_id: number, 
  organization_name: string, 
  project: string, 
  project_id: number
}
interface S {
    phase: IPredefinedTasks[]
  paperStyle: React.CSSProperties;
  ancherEl: HTMLElement | null;
  errorMsg: string;
  isLoading: boolean;
  projectName: string;
  projectId: number;
  anchorEl: HTMLElement | null;
  currentTaskId: number,
  organizationName: string,
}

interface SS {}

export default class DashboardPhaseController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiDeleteProjectsListCallId: string | null = "";
  getPredefinedTaskApiCallId: string = "";
  getOrganizationDataApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      phase: [],
      projectName: "",
      projectId: 0,
      ancherEl: null,
      paperStyle: {},
      errorMsg: "",
      isLoading: true,
      anchorEl: null,
      currentTaskId: 0,
      organizationName: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    const projectId = this.props.navigation.getParam('navigationBarTitleText')
    if (projectId) {
      this.setState({ projectId: projectId })
      this.getOrganizationProjectsList(projectId)
      this.getOrganizationData(projectId)
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if(webApiRequestCallId===this.getPredefinedTaskApiCallId) {
            const predefinedTasks = webResponseJson.data as IPredefinedTasksList[]
            this.setState({
              phase: predefinedTasks.map((item) => ({ ...item,  assignee: [item.assignee]})),
              errorMsg: "",
              isLoading: false,
              projectName: webResponseJson.meta.project_name
            });
            return 
        } else if (webApiRequestCallId === this.getOrganizationDataApiCallId) {
            const orgData = webResponseJson as IOrganization[]
            this.setState({ organizationName: orgData.find(data => data.project_id == this.state.projectId)?.organization_name || ""})
        }
      } else if (webResponseJson && webResponseJson.errors) {

        if(webApiRequestCallId===this.getPredefinedTaskApiCallId) {
            this.setState({
              errorMsg: webErrorReponse,
              isLoading: false
            });
            return
          }
      } else {
        this.setState({
          isLoading: false
        })
      }
    }
    
  }
  getOrganizationProjectsList = async (projectId: string) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPredefinedTaskApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.projectPreDefinedTasksListEndPoint}/${projectId}/project_predefined_tasks`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
    
  }
  getOrganizationData = async (projectId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrganizationDataApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.organizationProjectListEndPoint}?project_id=${projectId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
    runEngine.sendMessage(reqMessage.id, reqMessage);
    
  }
  handleOpenMemberList = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, currentTaskId: Number(event.currentTarget.dataset.projectid) });
  }

  handleCloseKebabMenu = () => {
    this.setState({ anchorEl: null, currentTaskId: 0 })
  }
}

// Customizable Area End

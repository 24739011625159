// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";

//API CONSTANTS
exports.getAllProjectsAPIEndPoint = "bx_block_projectportfolio/project_portfolios";
exports.getMyProjectsAPIEndPoint = "bx_block_projectportfolio/my_project";
exports.createProjectEndPoint = "bx_block_projectportfolio/project_portfolios";
exports.viewProjectsAPIEndPoint = "bx_block_projectportfolio/project_portfolios/";
exports.deleteProjectAPIEndPoint = "bx_block_projectportfolio/project_portfolios/";
exports.uploadMediaPresignUrlAPIEndPoint = "upload_media";
exports.bulkUploadAPIEndPoint = "bx_block_upload_media/bulk_upload";
exports.deleteUploadMediaAPIEndPoint = "upload_media/"
exports.searchEndPoint = "bx_block_advanced_search/search/search"
exports.tasks_kanban_menu = "tasks_kanban_menu";
exports.customColumnEndPoint = "bx_block_kanbanboard/sections";

exports.postAPiEndMethod = "POST"
exports.getAPiEndMethod = "GET"
exports.putAPiEndMethod = "PUT"
exports.patchAPiEndMethod = "PATCH"
exports.deleteAPiEndMethod = "DELETE"
//API CONSTANTS

//Labels
exports.labelAllProjectText = "All Project";
exports.labelTitleText = "Project Portfolio";
exports.labelProjectNameText = "Project Name";
exports.labelMyProjectText = "My Project";
exports.labelEndDateNameText = "End Date";
exports.labelStartDateNameText = "Start Date";
exports.labelDeleteBtnText = "Delete";
exports.labelEditBtnText = "Edit";
exports.labelRejectBtnBtnText = "Nzo";
exports.labelConfirmBtnText = "Yes";
exports.labelAddProjectText = "Add Project";
exports.labelSubmitBtnBtnText = "Submit";
exports.labelVizewProjectText = "View Project Details";
exports.labelEditProjectText = "Edit Project";
exports.labelSearzchInputText = "Add Project";
exports.labelsCreateFormFields = {
  projectName: { label: "Project Name", placeHolder: "Enter Project Name" },
  projectDescription: { label: "Project Description", placeHolder: "Enter Project Description" },
  clientDetails: { label: "Client Details", placeHolder: "Enter Client Details" },
  teamSize: { label: "Team Size", placeHolder: "Enter Team Size" },
  projectUrl: { label: "Project URL", placeHolder: "Enter Team Size" },
  developerEmail: { label: "Email", placeHolder: "Enter Email" },
  developerFullName: { label: "Full Name", placeHolder: "Enter Full Name" },
  phoneNumber: { label: "Phone Number", placeHolder: "Enter Phone Number" },
  startDate: { label: "Start Date", placeHolder: "Enter Start Date" },
  endDate: { label: "End Date", placeHolder: "Enter End Date" },
  projectImages: { label: "Project Images", placeHolder: "" },
  projectDocuments: { label: "Project Documents", placeHolder: "" },
}

exports.Token = 'token';
exports.addProjectEndPoint = "bx_block_projectportfolio/projects";
exports.deleteConfirmDialogDetailText = "Are you sure you want to delete this project?";
exports.SelectDueDate = "Select Due Date";
exports.deleteConfirmDialogTitle = "Delete Project Confirmation";
exports.organizationListEndPoint = "account_block/accounts/get_organizations";
exports.searchMembersEndPoint = "bx_block_projectportfolio/projects/search_members";
exports.currentView = "currentView";
exports.sortProjectEndPoint = "bx_block_projectportfolio/projects/sort_projects";
exports.ProjectName = "Enter your project/challenge name";
exports.SelectParticipant = "Select Participant";
exports.ProjectDetails = "Enter the project/challenge details";
exports.SelectOrg = "Select your organization";
exports.sortBy = "sortBy";
exports.filterLabelValue = {
  all: {
    label: 'All Projects',
    value: '',
  },
  notStarted: {
    label: 'Not Started',
    value: 'not_started'
  },
  inProgress: {
    label: 'In Progress',
    value: 'in_progress'
  },
  onHold: {
    label: 'On Hold',
    value: 'on_hold'
  },
  completed: {
    label: 'Completed',
    value: 'completed'
  }
};
exports.sortByValue = {
  time: 'time',
  status: 'status',
  a2z: 'a-z'
};
exports.sortLabel = {
  time: 'Time',
  status: 'Status',
  a2z: 'A to Z'
}
exports.ProjectNameDuplicateError = "Project name should be different from previous name";
exports.ErrorProjectName = "Project name";
exports.ErrorProjectDescription = "Project description";
exports.ErrorDueDate = "Due date";
exports.NoProjectFound = "No project found !";
exports.TryCreatingProj = "Try creating a new project.";
exports.EDITPROJECTNAME = "Edit Project Details";
exports.RENAMEPROJECTNAME = "Rename Project Name";
exports.Rename = "Rename";
exports.CREATENEWPROJECT = "Create New Project";
exports.Edit = "Edit";
exports.Duplicate = "Duplicate";
exports.Delete = "Delete";
exports.BackProjectListing = "Back to Project Listing";
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.Tasks = "Tasks";
exports.ProjectDescription = "Project Description";
exports.Members = "Members";
exports.ProjectStatus = "Project Status";
exports.CompletedTasks = "Completed Tasks";
exports.ProjectDueDate = "Project Due Date";
exports.BlockProjects = "Projects";
exports.Remove = "Remove";
exports.ProjectManagerRole = "project_manager";
exports.Add = "Add";
exports.DateFormat = "DD MMM, YYYY";
exports.Role = "role";
exports.Cancel = "Cancel";
exports.PlaceHolder = {
  SelectOrganization:"Select Organization",
  SelectParticipant:"Select Participant"
};
exports.Modal = {
  Decription:{
    WantToDeleteProject:"Do you want to delete this project?",
    RemoveMember:"Are you sure you want to remove this member from the project?"
  }
};
exports.TASKS = "TASKS";
exports.NOMEMBER = 'No Member';
exports.DueBy = "Due by -";
exports.PROJECTID = "Project Id -";
exports.Status = "Status :";
exports.SortBy = "Sort By :";
exports.ModalType = {
  Delete:"Delete",
  Add:"add",
  Remove:"remove"
};
exports.DueDate = "Due Date";
exports.ProjectDetails = "Project Details";
exports.Labels ={
  OrganizationName:"Organization Name",
  ProjectName:"Project Name",
  ProjectDescription:"Project Description",
  Organization:'Organization',
  Participant:"Participant"
};
exports.Participant = "Participant";
exports.Roles = {
  PM:"project_manager"
};
exports.AddMoreTasks = "Add More Tasks";
exports.NoTask = "No Tasks";
exports.AddMembers = "Add Members";
exports.NoParticipant = "No Participant";
exports.RemoveMember= "Remove Member";
//Labels


// Customizable Area End

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { projectCarouselData, projectDataType } from "./types";
import {
  ProjectList2,
  QCInitiated,
  sectionList,
  settingList1,
  ProjectInQueue,
  settingList,
} from '../__mocks__/KanbanInterface';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform } from "react-native";
import moment from "moment";
import { DropResult } from "react-beautiful-dnd";
import { Box, IconButton, Typography } from "@mui/material";
import { Divider } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactNode } from 'react';
import { ModalSecondaryButton, StyledPrimaryButton } from "./KanbanBoard.web";
import { debounce } from "lodash";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface ApiData {
  contentType: string,
  method: string,
  endPoint: string,
  body?: object
}
export const configJSON = require("./config");

export interface Field {
  key: number;
  content: string;
  ans_type: string;
  error: string;
}

export interface SubQuestion {
  question: string;
  preFix: string;
  fields: Field[];
}

export interface CustomPhase {
  value: string;
  error: string;
  questions: SubQuestion[];
}

interface CommentorAccount {
  id: number;
  full_name: string;
  profile_image: string;
}

interface CommentAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  created_at: string;
  updated_at: string;
  account: CommentorAccount;
}

interface CommentData {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface CommentsMeta {
  total_comments: number;
  total_pages: number;
}

interface CommentsResponse {
  data: CommentData[];
  meta: CommentsMeta;
}

export interface Question {
  id: number;
  content: string;
  answer_type: string;
  key: string;
  custom: true | null;
  answers: { id: number, content: string; type: string, answer_attachment?: Attachment, prototype_attachment?: Attachment }[];
  comments: { id: number, content: string; type: string, question_attachment?: Attachment, prototype_attachment?: Attachment }[];
}

export interface SectionsArray {
  id: number;
  name: string;
  questions: Question[];
  section_type: null | 'custom';
  sub_sections: ISubSection[]
}

interface ISubSection {
  id: number;
  name: string;
  error: string;
  questions: Question[];
  section_type: null | 'custom'
}
export interface PredefinedFormAttributes {
  id: number;
  phase_name: string;
  tool_name: string;
  default_section_1: string | null;
  default_section_2: string | null;
  sections: SectionsArray[];
  questions: Question[];
}

interface PredefinedForm {
  id: string;
  type: string;
  attributes: PredefinedFormAttributes;
}

export interface Tool {
  id: number;
  title: string;
  description: string | null;
  phase_id: number;
  created_at: string;
  updated_at: string;
  position: number;
}

interface PhaseAttributes {
  title: string;
  description: string;
  tools: Tool[];
}

export interface Phase {
  id: number;
  type: string;
  attributes: PhaseAttributes;
}

export interface Organization {
  id: number;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface Member {
  id: number;
  full_name: string;
  email: string;
  profile_image: string;
  organization_id: Organization;
}

interface SubTask {
  id: number;
  name: string;
  status: string;
  bx_block_tasks_task_id: number;
  created_at: string;
  updated_at: string;
}

export interface Attachment {
  id: number;
  file_name: string;
  url: string;
  byte_size: number;
}

interface TaskAttributes {
  id: number;
  account_id: number;
  title: string;
  due_date: string;
  is_reviewed: boolean;
  feedback: string;
  description: string;
  status: string;
  priority: string;
  created_at: string;
  task_type: string;
  updated_at: string;
  task_list_ids: number[];
  sub_tasks: SubTask[];
  section_id: number;
  project_id: number;
  is_creater: boolean;
  member_count: number;
  member: Member;
  pm_attachments: Attachment[];
  participant_attachments: Attachment[];
  tool_details: { data: PredefinedForm }
}

interface TaskData {
  id: string;
  type: string;
  attributes: TaskAttributes;
}

type PredefinedParticipantAnswerType = {
  question_id: number;
  answer: { content: string, ans_type: string }[]
}[]

export interface Task {
  id: number;
  task_list_id: number | null;
  title: string;
  assigned_to_id: number | null;
  assigned_to_type: string | null;
  due_date: string | null;
  description: string;
  status: string | null;
  tool_id: number;
  priority: string | null;
  created_at: string;
  task_type: string;
  updated_at: string;
  account_id: number;
  project_id: number;
  member_id: number;
  section_id: number | null;
  member_full_name: string;
  member_profile_image: string;
}

export interface Column {
  to_do?: Task[];
  in_progress?: Task[];
  need_review?: Task[];
  completed?: Task[];
  count: number;
}

interface Section {
  id: number;
  name: string;
  tasks: Task[];
  position: number;
  section_type: "default" | "custom"
}

interface CustomColumn {
  section: Section;
}

interface ISelectionList {
  id: number;
  value: string
}

interface Attributes {
  project_name: string;
  due_date: string;
  description: string;
  status: string;
  members_count: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  members: IMember[];
  creater_details: { name: string; id: number; }
  customs_columns: CustomColumn[];
}

interface IMember {
  id: number;
  full_name: string;
  email: string;
  profile_image: string;
}

interface ProjectKanban {
  id: string;
  type: string;
  attributes: Attributes;
}

interface IMemberList {
  id: number;
  full_name: string;
  profile_image: string;
  email: string;
}

interface IPhase4List {
  label: string;
  value: string;
}

interface AddColumn {
  id: string;
  type: string;
  attributes: {
    name: string;
    sectionable_type: string;
    sectionable_id: number;
    position: number;
    section_type: string;
  };
}

export interface IColumns {
  id: string;
  type: string;
  title: string;
  position: number;
  count: number | null;
  cards: Task[];
}

type DueDate = string | Date | null;

interface IAttachment {
  id: number;
  fileName: string;
  size: string;
  file: File | null;
  isLocal: boolean;
  url: string
}

interface IPredefinedAnsType {
  [key: number]: {
    content: string;
    ans_type: string;
    error: string;
  }[]
}
interface IRenameSection { section_id: number; new_name: string }

export interface Props {
  navigation: any;
  id: string;
  projects: projectDataType,
  statusFlow: string[],
  data: {
    history: { location: { pathname: string } },
    projectStatusColors: projectCarouselData["projectStatusColors"],
    projects: projectDataType
  },
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  ActiveKeyBoardControl: boolean,
  currentSlide: number,
  prevSlide: number,
  isVertical: boolean
  startDate: string,
  endDate: string,
  email: string,
  password: string,
  selectedStartDate: string;
  selectedEndDate: string;
  modalVisible: boolean;
  project_in_Queue: Array<ProjectInQueue>
  qc_Initiated: Array<QCInitiated>;
  statusFlow: string[]
  statusData: { attributes: { color: string, section_type: string; name: string } }[]
  settingData: { name: string, direction: string, theme: string, active: boolean }
  isHorizontal: boolean
  theam: string
  dataLength: number,
  columns: IColumns[];
  anchorEl: HTMLElement | null;
  openPopover: boolean;
  openEditColumnId: string;
  openModal: boolean;
  modalType: "delete" | "remove-attachment" | "start-add-new-task" | "create-from-scratch" | "add-member" | "delete-task" | "phases-tool" | "task-description" | "add-predefined-task-details" | "move-to-completed" | "remove-sketch" | "remove-phase5-attachment" | "delete-default-phase" | "delete-custom-phase" | "delete-default-round" | "delete-custom-round" | "delete-custom-idea" | "delete-default-idea";
  tempColumnName: string;
  menuType: "column" | "task-action" | "memberList" | "add-member" | "date-picker" | "attachment" | "change-member" | "comment",
  editColumnMenu: boolean;
  addColumnMenu: boolean;
  projectTaskDetails: ProjectKanban | null;
  role: string;
  projectId: number | undefined;
  loginUserId: number | undefined;
  dragResult: DropResult | null;
  isLoading: boolean;
  tempCompareColumnId: string;
  openMessage: boolean;
  messageType: "success" | "error" | 'default';
  message: string;
  dueDateAnchorEl: HTMLElement | null;
  dueDate: string;
  labelDate: DueDate;
  tempLabelDate: DueDate;
  subTasksArray: Array<{ subtask: string; id: number, error: string }>;
  attachment: File | null,
  attachmentSize: string;
  attachmentData: { id: number, fileName: string, size: string, file: File | null }[]
  attachmentError: string;
  autoCompleteValue: { title: string, icon: string } | null;
  selectedMember: IMemberList | null;
  memberList: IMemberList[];
  taskName: string;
  taskNameError: string;
  taskDescription: string;
  taskDescriptionError: string;
  dueDateError: string;
  taskId: number | null;
  selectedAttachment: string;
  noMemberSelectedError: string;
  paperStyle: React.CSSProperties;
  hide3dots: boolean;
  movedCardStatus: string;
  draggableId: number | null;
  droppableId: string | undefined;
  phases: Phase[];
  selectedPhase: number | undefined;
  selectedPhaseObj: Phase | null;
  selectedToolObj: Tool | null;
  phaseError: string;
  selectedTool: number | undefined;
  toolError: string;
  selectedDescription: string;
  toolList: Array<{ label: string, value: number }>;
  phaseList: Array<{ label: string, value: number }>;
  predefinedForm: PredefinedForm | null;
  isPredefinedFormFilling: boolean;
  isPredefinedCreation: boolean;
  predefinedPMAnswer: { [key: number]: { key: number, content: string; ans_type: string; error: string }[] } | null;
  predefinedParticipantAnswer: { [key: number]: { key: number, content: string; ans_type: string; error: string }[] } | null;
  taskDetails: TaskData | null;
  isEditMode: boolean;
  participantAttachmentData: IAttachment[];
  projectManagerAttachmentData: IAttachment[];
  tempParticipantAttachmentData: IAttachment[];
  tempProjectManagerAttachmentData: IAttachment[];
  protoTypeAttachment: IAttachment | null;
  PMPrototypeAttachment: IAttachment | null;
  sketchAttachment: IAttachment | null;
  participantProtoTypeAttachmentAnswer: IAttachment | null | undefined;
  attachmentsToRemove: number[];
  defaultAttachments: number[];
  attachmentToRemoveId: number | null;
  currentRemovingAttachmentType: 'PM' | "PT";
  feedback: string;
  selectedCardId: number | null;
  columnStatusAndId: { id: number; status: string }[];
  isReassign: boolean;
  isSubmitForReview: boolean;
  isComplete: boolean;
  taskType: string;
  commentQuestionData: Question | null;
  currentTab: number;
  redoStack: string[];
  undoStack: string[];
  isDrawing: boolean;
  phase4dropdownList: IPhase4List[];
  isSketchPrefilled: boolean;
  allTasks: Task[];
  sketchQuestionId: number | null;
  selectedPhaseName: string;
  selectedToolName: string;
  openToolTip: boolean;
  toolTipMessage: string;
  commentPage: number;
  totalCommentPage: number;
  isCommentLoading: boolean;
  comment: string;
  allComments: CommentData[];
  customPhaseList: CustomPhase[];
  customPhasesToDelete: number[];
  defaultPhasesToDelete: number[];
  renamedSections: IRenameSection[];
  defaultPhaseToDelete: number | null;
  customPhaseToDelete: number | null;
  phase3Tool1Fields: {
    action: string;
    user: string;
    problem: string;
    outcome: string;
    finalStatement: string;
    finalStatementId: number | null;
  },
  addColumnTooltip: boolean;
  columnActionTooltip: boolean;
  customIdeas: { question: string, placeHolder: string }[];
  customIdeaToDelete: number | null;
  defaultIdeaToDelete: number | null;
  ideasToDelete: number[];
  roundsToDelete: number[];
  customRoundToDelete: number | null;
  defaultRoundToDelete: number | null;
  roundSectionQuestionId: number | null;
  customRounds: { round: string, subQuestion: SubQuestion }[];
}

interface SS {
  id: any;
}

export default class KanbanBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  sectionListApiId: string = ""
  sectionAPIID: string = ""
  projectListApiId: string = ""
  settingListApiId: string = ""
  fetchTaskApiCallId: string = "";
  settingsAPIID: string = "";
  getProjectTasksApiCallId: string = "";
  changeTaskStatusApiCallId: string = "";
  renameColumnApiCallId: string = "";
  deleteColumnApiCallId: string = "";
  addColumnApiCallId: string = "";
  createTaskFromScratchApiCallId: string = "";
  getProjectMembersApiCallId: string = "";
  getTaskDetailsBySearchApiCallId: string = "";
  getTaskDetailsApiCallId: string = "";
  updateColumnPosition: string = "";
  deleteTaskApiCallId: string = "";
  getPredefinedTaskFormApiCallId: string = "";
  getPhasesApiCallId: string = "";
  createPredefinedTaskApiCallId: string = "";
  updatePredefinedTaskDetailsApiCallId: string = "";
  updatePredefinedTaskCommentApiCallId: string = "";
  changeTaskStatusFromBtnApiCallId: string = "";
  getCommentsApiCallId: string = "";
  addCommentApiCallId: string = "";
  downloadPhase5PDF: string = "";
  fileUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  getPhase4DropdownApiCallId: string = "";
  addColumnInputRef: React.RefObject<HTMLInputElement> = React.createRef();
  sketchImageUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  ParticipantAttachmentUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  saveableCanvas: React.RefObject<any> = React.createRef();
  PMAttachmentUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
  protoTypeAttachmentRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      ActiveKeyBoardControl: false,
      currentSlide: 0,
      prevSlide: 0,
      isVertical: false,
      startDate: '19/02/2023',
      endDate: '25/03/2023',
      email: "",
      password: "",
      selectedStartDate: '',
      selectedEndDate: '',
      modalVisible: false,
      qc_Initiated: [],
      project_in_Queue: [],
      statusFlow: this.props.statusFlow,
      statusData: [],
      settingData: { name: "", direction: "", theme: "", active: false },
      isHorizontal: false,
      theam: '',
      dataLength: 0,
      columns: [],
      openPopover: false,
      anchorEl: null,
      openEditColumnId: '',
      tempColumnName: '',
      menuType: "task-action",
      editColumnMenu: false,
      addColumnMenu: false,
      openModal: false,
      projectTaskDetails: null,
      role: "participant",
      projectId: undefined,
      dragResult: null,
      isLoading: false,
      tempCompareColumnId: '',
      openMessage: false,
      messageType: 'error',
      message: '',
      modalType: 'start-add-new-task',
      dueDateAnchorEl: null,
      dueDate: '',
      labelDate: '',
      subTasksArray: [],
      attachment: null,
      attachmentSize: '',
      attachmentData: [],
      attachmentError: '',
      autoCompleteValue: null,
      selectedMember: null,
      memberList: [],
      predefinedParticipantAnswer: null,
      taskName: '',
      taskNameError: '',
      taskDescription: '',
      taskDescriptionError: '',
      dueDateError: '',
      taskId: null,
      selectedAttachment: '',
      noMemberSelectedError: '',
      paperStyle: {},
      hide3dots: false,
      movedCardStatus: 'to_do',
      draggableId: null,
      droppableId: undefined,
      phases: [],
      selectedPhase: undefined,
      selectedTool: undefined,
      selectedDescription: '',
      phaseError: '',
      toolError: '',
      toolList: [],
      phaseList: [],
      predefinedForm: null,
      isPredefinedFormFilling: false,
      isPredefinedCreation: false,
      selectedPhaseObj: null,
      selectedToolObj: null,
      predefinedPMAnswer: null,
      tempLabelDate: '',
      taskDetails: null,
      isEditMode: false,
      participantAttachmentData: [],
      projectManagerAttachmentData: [],
      tempParticipantAttachmentData: [],
      tempProjectManagerAttachmentData: [],
      attachmentsToRemove: [],
      defaultAttachments: [],
      attachmentToRemoveId: null,
      currentRemovingAttachmentType: "PT",
      feedback: '',
      selectedCardId: null,
      columnStatusAndId: [],
      isReassign: false,
      isComplete: false,
      isSubmitForReview: false,
      taskType: 'scratch',
      commentQuestionData: null,
      currentTab: 0,
      redoStack: [],
      undoStack: [],
      isDrawing: false,
      phase4dropdownList: [],
      sketchAttachment: null,
      isSketchPrefilled: false,
      allTasks: [],
      loginUserId: undefined,
      sketchQuestionId: null,
      selectedPhaseName: '',
      selectedToolName: '',
      openToolTip: false,
      toolTipMessage: '',
      protoTypeAttachment: null,
      participantProtoTypeAttachmentAnswer: null,
      allComments: [],
      isCommentLoading: false,
      comment: '',
      commentPage: 1,
      totalCommentPage: 1,
      PMPrototypeAttachment: null,
      customPhaseList: [],
      customPhasesToDelete: [],
      defaultPhasesToDelete: [],
      renamedSections: [],
      defaultPhaseToDelete: null,
      customPhaseToDelete: null,
      phase3Tool1Fields: {
        action: '',
        user: '',
        problem: '',
        outcome: '',
        finalStatement: "",
        finalStatementId: null
      },
      addColumnTooltip: false,
      columnActionTooltip: false,
      customIdeas: [],
      ideasToDelete: [],
      customRounds: [],
      roundsToDelete: [],
      customIdeaToDelete: null,
      defaultIdeaToDelete: null,
      customRoundToDelete: null,
      defaultRoundToDelete: null,
      roundSectionQuestionId: null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async componentDidMount() {
    if (Platform.OS != "web") {
      this.projectList()
      this.sectionList()
      this.settingList()
      await getStorageData("USER_TOKEN")
    }
    await removeStorageData('task-id')
    const projectId = this.props.navigation.getParam('navigationBarTitleText')
    if (projectId) {
      this.fetchDetails(projectId)
    } else {
      const projectId = await getStorageData('task-project-id');
      this.fetchDetails(projectId)
    }

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.errors?.[0]?.token) {
        removeStorageData(configJSON.Token);
        this.goToBlock('EmailAccountLoginBlock');
        return;
      }

      if (apiRequestCallId === this.projectListApiId) {
        await this.projectListResponse(responseJson)
      }
      if (apiRequestCallId === this.sectionListApiId) {
        this.sectionListResponse(responseJson)
      }
      if (apiRequestCallId === this.settingListApiId) {
        this.settingListResponse(responseJson)
      }

      else if (apiRequestCallId === this.sectionAPIID) {
        let tempArr: string[] = []
        responseJson.data.map((item: { attributes: { name: string } }) => {
          tempArr.push(item.attributes.name)
        })
        this.setState({ statusFlow: tempArr, statusData: responseJson.data })
      }
      else if (apiRequestCallId === this.settingsAPIID && responseJson.data) {
        const activeSetting = responseJson.data.find((item: { attributes: { active: boolean } }) => item.attributes.active === true);
        this.setState({ settingData: activeSetting.attributes, isVertical: activeSetting.attributes.direction.toLowerCase() == "horizontal" })
      }
      if (responseJson.status === 500) {
        this.setState({ openMessage: true, messageType: 'error', message: 'unknown error occured', openModal: false, anchorEl: null, dueDateAnchorEl: null })
        return;
      }
      this.handleResponse(apiRequestCallId, responseJson)
    }
  }
  fetchDetails = async (projectId: string) => {

    const queryParams = new URLSearchParams(window.location.search);

    const loginUserId = await getStorageData('user_id')

    if (!projectId) {
      this.setState({ projectId: undefined })
    } else {
      const encryptedRole = await getStorageData(configJSON.Role);
      const role = this.deCrypt(configJSON.Secretkey, encryptedRole);
      this.setState({
        role: role,
        loginUserId: Number(loginUserId),
        projectId: Number(projectId),
        isPredefinedCreation: Boolean(queryParams.get('phase')),
        isPredefinedFormFilling: Boolean(queryParams.get('task')) || Boolean(queryParams.get('phase')),
        selectedCardId: Number(queryParams.get('task')) === 0 ? null : Number(queryParams.get('task')),
        selectedTool: Number(queryParams.get('tool')) === 0 ? undefined : Number(queryParams.get('tool')),
        selectedPhase: Number(queryParams.get('phase')) === 0 ? undefined : Number(queryParams.get('phase')),
      }, () => {
        if (this.state.selectedCardId) {
          this.getTaskDetails(this.state.selectedCardId);
        }

        if (this.state.selectedTool) {
          this.getPredefinedTaskForm()
        }
      });
      this.getProjectTasks();
      this.getProjectMembers();
      this.getPhase4Drodowns()
    }
  }
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (this.props.navigation.getParam('navigationBarTitleText') !== prevProps.navigation.getParam('navigationBarTitleText')) {
      const projectId = this.props.navigation.getParam('navigationBarTitleText')
      if (projectId) {
        this.fetchDetails(projectId)
      }
    }
  }

  handleResponse = async (apiRequestCallId: string, responseJson: { data: ProjectKanban | AddColumn | TaskData | Phase[] | PredefinedForm } & IMemberList[] & IPhase4List[] & ISelectionList[] & CommentsResponse & string) => {
    switch (apiRequestCallId) {
      case this.getProjectTasksApiCallId:
      case this.getTaskDetailsBySearchApiCallId:
        const filteredColumndata = (responseJson.data as ProjectKanban).attributes.customs_columns.filter((column) => column.section.section_type === 'default').map((column) => ({ id: column.section.id, status: column.section.name }))
        const kanbanBoardResponse = responseJson.data as ProjectKanban;
        const sections = kanbanBoardResponse.attributes.customs_columns;
        const allTasks = sections.flatMap(section => section.section.tasks);
        this.setState({ columns: this.mapColumns(kanbanBoardResponse), projectTaskDetails: kanbanBoardResponse, isLoading: false, projectId: this.state.projectId, columnStatusAndId: filteredColumndata, allTasks: allTasks, droppableId: undefined })
        break;
      case this.addColumnApiCallId:
        const tempColumns = [...this.state.columns]
        const response = responseJson.data as AddColumn;
        tempColumns.splice(tempColumns.length - 1, 0, { id: `custom_${tempColumns.length + 1}`, title: this.state.tempColumnName, count: 0, position: response?.attributes?.position, type: configJSON.TypeCustom, cards: [] });
        this.setState({ columns: tempColumns, openEditColumnId: '', tempCompareColumnId: '', tempColumnName: '', addColumnMenu: false, editColumnMenu: false, hide3dots: false })
        this.getProjectTasks()
        break;
      case this.renameColumnApiCallId:
        const copyColumns = [...this.state.columns]
        const updatedColumns = copyColumns.map((column) => {
          if (column.id === this.state.openEditColumnId) {
            return { ...column, title: this.state.tempColumnName }
          }
          return column
        })
        this.setState({ columns: updatedColumns, tempCompareColumnId: '', openEditColumnId: '', addColumnMenu: false, editColumnMenu: false, hide3dots: false });
        this.getProjectTasks()
        break;
      case this.deleteColumnApiCallId:
        const tempArrayOfColumn = [...this.state.columns]
        const updatedColumnsArray = tempArrayOfColumn.filter((column) => column.id !== this.state.openEditColumnId);
        this.setState({ columns: updatedColumnsArray, openEditColumnId: '', tempCompareColumnId: '', addColumnMenu: false, editColumnMenu: false, anchorEl: null, openMessage: true, message: 'column deleted successfully', messageType: 'success' });
        this.closeModal()
        this.getProjectTasks()
        break;
      case this.changeTaskStatusApiCallId:
        this.getProjectTasks();
        break;
      case this.changeTaskStatusFromBtnApiCallId:
        this.handleTaskStatusChangeFromBtnResponse()
        break;
      case this.getProjectMembersApiCallId:
        this.setState({ memberList: responseJson as IMemberList[] })
        break;
      case this.createTaskFromScratchApiCallId:
        this.handleResponseForCreateTaskFromScratch();
        break;
      case this.deleteTaskApiCallId:
        this.setState({ openModal: false, anchorEl: null, modalType: 'create-from-scratch', openMessage: true, messageType: 'success', message: 'Task deleted successfully' })
        this.getProjectTasks();
        break;
      case this.updateColumnPosition:
        this.getProjectTasks();
        break;
      case this.getTaskDetailsApiCallId:
        this.handleTaskDetails(responseJson.data as TaskData)
        break;
      case this.getPhasesApiCallId:
        this.setState({ phases: responseJson.data as Phase[] }, () => {
          this.setState((prev) => {
            return { phaseList: prev.phases.map((phase) => ({ label: phase.attributes.title, value: phase.id })), projectId: this.state.projectId }
          })
        })
        break;
      case this.getPredefinedTaskFormApiCallId:
        const predefinedForm = responseJson.data as PredefinedForm;
        this.handleTaskPredefinedTaskFormResponse(predefinedForm);
        break;
      case this.createPredefinedTaskApiCallId:
        this.handleResponseForCreatePredefinedTask()
        break;
      case this.updatePredefinedTaskCommentApiCallId:
        this.leaveTheForm()
        this.cancelPredefinedTask();
        this.getProjectTasks()
        break;
      case this.updatePredefinedTaskDetailsApiCallId:
        this.leaveTheForm()
        let isStatusChange = false;
        if (this.state.isComplete) {
          isStatusChange = true;
          this.changeTaskStatus("completed");
        }
        if (this.state.isReassign) {
          isStatusChange = true;
          this.changeTaskStatus('in_progress');
        }
        if(!isStatusChange){
          this.cancelPredefinedTask();
        }
        this.getProjectTasks()
        break;
      case this.getPhase4DropdownApiCallId:
        const responsePhase4List = responseJson as IPhase4List[];
        this.setState({ phase4dropdownList: responsePhase4List.map((element) => ({ label: element.value, value: element.value })) });
        break;
      case this.getCommentsApiCallId:
        this.setState((prevState) => {
          const mergedArray = [...prevState.allComments, ...responseJson.data];
          mergedArray.sort((first, second) => (moment(second.attributes.created_at).diff(first.attributes.created_at)))
          const uniqueMap = new Map();
          mergedArray.forEach(object => {
            if (!uniqueMap.has(object.id)) {
              uniqueMap.set(object.id, object)
            }
          });
          const newCommentArray = Array.from(uniqueMap.values())
          return { allComments: newCommentArray, totalCommentPage: responseJson.meta.total_pages, comment: '', isLoading: false, isCommentLoading: false }
        });
        break;
      case this.addCommentApiCallId:
        this.setState({ commentPage: 1, comment: '' }, () => this.getComments());
        break;
    }
  }

  handleTaskPredefinedTaskFormResponse = (predefinedForm: PredefinedForm) => {
    this.setState({
      predefinedForm: predefinedForm.attributes.tool_name === "Tool 3 - Customer Journey Map" ?
        {
          ...predefinedForm,
          attributes: {
            ...predefinedForm?.attributes,
            sections: predefinedForm.attributes.sections.map((section, sectionIndx) => {
              if (sectionIndx === 1) {
                return {
                  ...section, sub_sections: section.sub_sections.map((subSection, indx) => {
                    if (indx < 2 && subSection.name === '........') {
                      return { ...subSection, name: '', error: '' }
                    }
                    return subSection
                  })
                }
              }
              return section
            })
          }
        }
        : predefinedForm, isLoading: false,
      selectedPhaseName: predefinedForm.attributes.phase_name
    }, () => {
      if (this.state.predefinedForm?.type === 'phase_tool_questions') {
        let predefinedPMAnswer = null;
        let predefinedParticipantAnswer = null;
        predefinedPMAnswer = this.SectionPrefillAnswers("PM");
        predefinedParticipantAnswer = this.SectionPrefillAnswers("PT");
        this.setState({ predefinedPMAnswer, predefinedParticipantAnswer })
      }
    }
    )
  }
  
  handleTaskDetails = (data: TaskData) => {
    if (data) {
      this.handleTaskDetailsResponse(data)
      if (this.state.selectedCardId) {
        this.getComments();
      }
    }
  }
  leaveTheForm = () => {
    window.history.pushState(null, '', `${window.location.pathname}`);
  }

  handleTaskStatusChangeFromBtnResponse = () => {
    if (this.state.isComplete || this.state.isReassign || this.state.isSubmitForReview) {
      this.leaveTheForm()
      this.cancelPredefinedTask()
    }
    this.setState({ isLoading: false, isReassign: false, isComplete: false, isSubmitForReview: false })
    if (this.state.selectedCardId) {
      this.getTaskDetails(this.state.selectedCardId)
    }
    this.getProjectTasks()
  }

  handleTaskDetailsResponse = (taskresponse: TaskData) => {
    this.setState(() => {
      return {
        isLoading: false,
        movedCardStatus: taskresponse.attributes.status,
        taskDetails: taskresponse?.attributes?.tool_details?.data?.attributes?.tool_name === "Tool 3 - Customer Journey Map" ?
          {
            ...taskresponse,
            attributes: {
              ...taskresponse?.attributes,
              tool_details: {
                ...taskresponse?.attributes.tool_details,
                data: {
                  ...taskresponse?.attributes.tool_details.data,
                  attributes: {
                    ...taskresponse?.attributes.tool_details.data.attributes,
                    sections: taskresponse?.attributes.tool_details.data.attributes.sections.map((section, sectionIndx) => {
                      if (sectionIndx === 1) {
                        return {
                          ...section, sub_sections: section.sub_sections.map((subSection, indx) => {
                            if (indx < 2) {
                              return { ...subSection, name: indx === 0 ? taskresponse.attributes.tool_details.data.attributes.default_section_1 : taskresponse.attributes.tool_details.data.attributes.default_section_2, error: '' }
                            }
                            return subSection
                          })
                        }
                      }
                      return section
                    })
                  }
                }
              }
            }
          } as TaskData : taskresponse,
      }
    }, () => {
      if (this.state.taskDetails) {
        const taskData = this.state.taskDetails;
        let participantAttachments = taskData.attributes.participant_attachments.map((attachment) => (attachment.id));
        let pmAttachments = taskData.attributes.pm_attachments.map((attachment) => (attachment.id));
        let labelDate: string | Date = taskData.attributes.due_date;
        let dueDate = moment(taskData.attributes.due_date).format('DD/MM/YYYY');
        let formattedParticipantAttachments = taskData.attributes.participant_attachments.map((attachment) => {
          return {
            id: attachment.id,
            url: attachment.url,
            file: null,
            fileName: attachment.file_name,
            isLocal: false,
            size: this.formatFileSize(attachment.byte_size)
          }
        })
        let formattedPMAttachments = taskData.attributes.pm_attachments.map((attachment) => {
          return {
            id: attachment.id,
            url: attachment.url,
            file: null,
            fileName: attachment.file_name,
            isLocal: false,
            size: this.formatFileSize(attachment.byte_size)
          }
        })

        let predefinedPMAnswer = null;
        let predefinedParticipantAnswer = null;
        if (taskData.attributes.task_type === 'predefined') {
          predefinedPMAnswer = this.SectionPrefillAnswers("PM");
          predefinedParticipantAnswer = this.SectionPrefillAnswers("PT");
        }

        this.setState({
          taskName: this.state.taskDetails.attributes.title,
          taskDescription: this.state.taskDetails.attributes.description,
          defaultAttachments: [...participantAttachments, ...pmAttachments],
          feedback: this.state.taskDetails.attributes.feedback ?? "",
          labelDate: labelDate,
          dueDate: dueDate,
          selectedPhaseName: this.state.taskDetails.attributes?.tool_details?.data?.attributes?.phase_name,
          predefinedPMAnswer: predefinedPMAnswer,
          predefinedParticipantAnswer: predefinedParticipantAnswer,
          participantProtoTypeAttachmentAnswer: this.handlePhase5Response().participantProtoTypeAttachmentAnswer,
          protoTypeAttachment: this.handlePhase5Response().protoTypeAttachment,
          PMPrototypeAttachment: this.handlePhase5Response().PMPrototypeAttachment,
          projectManagerAttachmentData: formattedPMAttachments,
          participantAttachmentData: formattedParticipantAttachments,
          selectedMember: {
            id: taskData.attributes.member.id,
            full_name: taskData.attributes.member.full_name,
            profile_image: taskData.attributes.member.profile_image,
            email: taskData.attributes.member.email
          }
        })
      }
      const filteredColumn = this.state.columns.filter((column) => column.id === this.state.droppableId);
      if (filteredColumn.length > 0) {
        this.changeCardStatus(Number(this.state.draggableId), filteredColumn[0])
      }
    })
  }

  formatAttachment = (attachment: Attachment) => {
    if (attachment) {
      return {
        id: attachment.id,
        url: attachment.url,
        file: null,
        fileName: attachment.file_name,
        isLocal: false,
        size: this.formatFileSize(attachment.byte_size)
      }
    }
    return null
  }

  handlePhase5Response = () => {
    const taskData = this.state.taskDetails;
    const taskDataAttributes = taskData?.attributes.tool_details.data;
    const PMPrototypeAttachment = taskDataAttributes?.attributes?.sections?.[0]?.questions?.[0]?.comments?.[0]?.prototype_attachment!;
    const ParticipantPrototypeAttachment = taskDataAttributes?.attributes?.sections?.[0]?.questions?.[0]?.answers?.[0]?.prototype_attachment!;
    const prototypeAttachment = this.isProjectManager() ? this.formatAttachment(PMPrototypeAttachment) : this.formatAttachment(ParticipantPrototypeAttachment);
    return {
      participantProtoTypeAttachmentAnswer: this.formatAttachment(ParticipantPrototypeAttachment),
      protoTypeAttachment: prototypeAttachment,
      PMPrototypeAttachment: this.formatAttachment(PMPrototypeAttachment)
    }
  }

  SectionPrefillAnswers = (type: string) => {
    const taskDataAttributes = this.state.taskDetails ? this.state.taskDetails.attributes?.tool_details?.data : this.state.predefinedForm;

    let PMComments: { [key: number]: { key: number, content: string; ans_type: string, error: string }[] } = {};
    let ParticipantComments: { [key: number]: { key: number, content: string; ans_type: string, error: string }[] } = {};
    let questionArray: Question[] = [];

    if (taskDataAttributes) {
      const isSectionTask = taskDataAttributes.attributes.sections.length > 0;
      questionArray = taskDataAttributes.attributes.questions || [];

      if (isSectionTask) {
        taskDataAttributes.attributes.sections.forEach((section) => {
          section.questions.forEach((question) => {
            this.handlePhase3First4QuestionsValidation(question)
            PMComments = this.formatePrefillPredefinedFormData(PMComments, question, "PM");
            ParticipantComments = this.formatePrefillPredefinedFormData(ParticipantComments, question, "PT");
          });

          if (section?.sub_sections?.length > 0) {
            section.sub_sections.forEach((subSection) => {
              subSection.questions.forEach((question) => {
                PMComments = this.formatePrefillPredefinedFormData(PMComments, question, "PM");
                ParticipantComments = this.formatePrefillPredefinedFormData(ParticipantComments, question, "PT");
              });
            });
          }
        });
      }
    }

    const ProjectManagerComments = Object.keys(PMComments).length > 0 ? PMComments : questionArray.reduce((ansObj, question) => {
      return this.formatePrefillPredefinedFormData(ansObj, question, "PM");
    }, {});

    const ParticipantAnswer = Object.keys(ParticipantComments).length > 0 ? ParticipantComments : questionArray.reduce((ansObj, question) => {
      return this.formatePrefillPredefinedFormData(ansObj, question, "PT");
    }, {});

    return type === "PM" ? ProjectManagerComments : ParticipantAnswer;
  }

  handlePhase3First4QuestionsValidation = (question: Question) => {
    if (this.isProjectManager()) {
      this.handlePhase3First4Questions(question, question.comments[0]?.content ? question.comments[0]?.content : '')
    }
  }

  formatePrefillPredefinedFormData = (
    ansObj: { [key: number]: { key: number; content: string; ans_type: string; error: string }[] },
    question: Question,
    type: string
  ) => {

    ansObj[question.id] = ansObj[question.id] || [];

    const answers = this.getAnswersBasedOnType(question, type);

    this.populateAnswers(ansObj, question, answers);

    return ansObj;
  };

  getAnswersBasedOnType = (question: Question, type: string) => {
    return type === "PM" ? question.comments || [] : question.answers || [];
  };

  initializeAnswers = (ansObj: { [key: number]: { key: number; content: string; ans_type: string; error: string }[] }, questionId: number, count: number, ansType: string) => {
    for (let i = 0; i < count; i++) {
      ansObj[questionId].push({
        key: ansObj[questionId].length + 1,
        content: '',
        ans_type: ansType,
        error: ''
      });
    }
  };

  populateAnswers = (
    ansObj: { [key: number]: { key: number; content: string; ans_type: string; error: string }[] },
    question: Question,
    answers: { id: number; content: string; type: string; question_attachment?: Attachment; answer_attachment?: Attachment }[]
  ) => {
    const { id: questionId, answer_type } = question;

    if (answers.length > 0) {
      answers.forEach((ques) => {
        const attachment = this.isProjectManager() ? ques.question_attachment : ques.answer_attachment;
        ansObj[questionId].push({
          key: ansObj[questionId].length + 1,
          content: answer_type === "sketch" ? attachment?.url || '' : ques.content,
          ans_type: ques.type,
          error: ''
        });
      });
      this.handleSketchPrefill(question);
    } else {
      switch (answer_type) {
        case 'multiple_with_relation':
          this.initializeAnswers(ansObj, questionId, 2, 'default');
          ansObj[questionId].push({
            key: ansObj[questionId].length + 1,
            content: '',
            ans_type: 'relationship',
            error: ''
          });
          break;
        case 'multiple_answer':
          this.initializeAnswers(ansObj, questionId, 1, 'default');
          break;
        default:
          ansObj[questionId].push({
            key: ansObj[questionId].length + 1,
            content: '',
            ans_type: answer_type,
            error: ''
          });
      }
    }
  };

  disabledShortAnswer = (question: Question) => {
    return this.disablePredefinedFormFields() || (!this.isProjectManager() && question.key === 'restrict_participant' && question.content !== 'Final Statement:')
  }

  disabledDrodownforphase3 = (question: Question) => {
    return this.disablePredefinedFormFields() || (!this.isProjectManager() && question.key === 'restrict_participant')
  }

  renderShortQuestionLabel = (question: Question, questionIndex: number) => {
    const formAttributes = this.state.taskDetails ? this.state.taskDetails.attributes?.tool_details?.data?.attributes : this.state.predefinedForm?.attributes
    return !(questionIndex % 3) && formAttributes?.tool_name === 'Tool 2 - 2x2 Matrix' ? `${question.content} ${(questionIndex / 3) + 1}` : question.content
  }

  renderShortQuestionValue = (question: Question, inputValue: string | null) => {
    return this.shouldRenderFinalAnswer(question) ? `How might we ${this.state.phase3Tool1Fields.action} the ${this.state.phase3Tool1Fields.user} ${this.state.phase3Tool1Fields.problem} to ${this.state.phase3Tool1Fields.outcome}?` : inputValue
  }

  disableShortAnswer = (question: Question) => {
    return this.disabledShortAnswer(question) || this.disabledDrodownforphase3(question)
  }

  handleSketchPrefill = (question: Question) => {
    let answers = question?.answers?.[0]?.answer_attachment;
    if (this.isProjectManager()) {
      answers = question?.comments?.[0]?.question_attachment;
    }
    if (question.answer_type === "sketch" && answers?.url) {
      let formattedParticipantAttachments = {
        id: answers?.id!,
        url: answers?.url!,
        file: null,
        fileName: answers?.file_name!,
        isLocal: false,
        size: this.formatFileSize(answers?.byte_size || 0)
      }
      this.setState({ sketchAttachment: formattedParticipantAttachments })
    }
  }

  handleResponseForCreatePredefinedTask = () => {
    let isNeedReview = false;
    if (!this.isProjectManager()) {
      isNeedReview = true
      this.changeTaskStatus('need_review')
    }
    if (this.isProjectManager()) {
      this.setState({ openMessage: true, messageType: 'success', message: `${this.state.selectedPhaseName} is assigned successfully`, openModal: false, isReassign: false, isComplete: false })
    }
    if (!isNeedReview) {
      this.cancelPredefinedTask();
    }
    this.getProjectTasks();
    this.leaveTheForm()
  }

  handleResponseForCreateTaskFromScratch = () => {
    if (this.state.isReassign) {
      this.changeTaskStatus('in_progress')
    }
    if (this.state.isComplete) {
      this.changeTaskStatus('completed')
    }
    if (!(this.state.isComplete || this.state.isReassign)) {
      this.setState({ openMessage: true, messageType: 'success', message: 'Invite sent', openModal: false, isReassign: false, isComplete: false, isLoading: false })
    }
    this.clearFields();
    this.getProjectTasks();
  }

  handleSearch = debounce((query: string) => {
    this.getTaskDetailsBySearch(query)
  }, 500)

  setProjectId = () => {
    this.setState({ projectId: 1 })
  }

  setPMrole = () => {
    this.setState({ role: configJSON.ProjectManagerRole })
  }

  onCardClickInOnHold = () => {
    if (this.isProjectOnHoldOrNotStarted()) {
      this.showErrorForTaskClickOnHold();
      this.disableDrag();
      this.showErrorForTaskClickOnHold()
    }
  }

  setParticipantRole = () => {
    this.setState({ role: 'Participant' })
  }

  clickPredefinedTaskCard = () => {
    const newUrl = `${window.location.pathname}?task=${1}&tool=${43}`;
    this.getTaskDetails(Number(1));
    window.history.pushState(null, '', newUrl);
    this.setState({ isPredefinedFormFilling: true, selectedTool: 43, selectedCardId: 1 }, () => this.getPredefinedTaskForm())
  }

  hideElement = () => {
    return this.state.role === configJSON.ProjectManagerRole ? configJSON.visible : configJSON.hidden;
  }

  isDragDisabled = () => {
    return this.state.role !== configJSON.ProjectManagerRole || this.disableDrag()
  }

  isDefaultColumn = (columnName: string) => {
    const defaultColumnNames = ["To Do", "In Progress", "Need Review", "Completed", "Add new column"];
    return defaultColumnNames.includes(columnName);
  }

  deCrypt = (salt: string, encoded: string): string => {
    const textToChars = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
    const applySaltToChar = (code: number): number => textToChars(salt).reduce((first, second) => first ^ second, code);
    return encoded
      .match(/.{1,2}/g)
      ?.map((hexa) => parseInt(hexa, 16))
      ?.map(applySaltToChar)
      ?.map((charCode) => String.fromCharCode(charCode))
      .join("") ?? "";
  };

  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  goToWithParams = (block: string, param: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), block);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    this.send(message);
  }

  onClickPredefinedCard = async (card: Task) => {
    this.getTaskDetails(Number(card.id));
    const newUrl = `${window.location.pathname}?task=${card.id}&tool=${card.tool_id}`;
    window.history.pushState(null, '', newUrl);
    this.setState({ isPredefinedFormFilling: true, selectedTool: card.tool_id, selectedCardId: card.id }, () => this.getPredefinedTaskForm())
  }

  onClosePopOver = () => {
    this.setState({ anchorEl: null, paperStyle: {}, attachment: null, attachmentSize: '', dueDateError: '', commentQuestionData: null })
  }

  handleColumnOpenPopOver = (event: React.MouseEvent<HTMLElement>) => {
    if (this.isProjectNotRunning()) {
      return
    }
    this.setState({ anchorEl: event.currentTarget, menuType: configJSON.menuTypeColumn, tempCompareColumnId: event.currentTarget.dataset.columnid!, openEditColumnId: event.currentTarget.dataset.columnid!.split("_")[1], tempColumnName: event.currentTarget.dataset.columnname!, addColumnMenu: false })
  }

  handleOpenTaskActions = (event: React.MouseEvent<HTMLElement>, type: string) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, taskId: Number(event.currentTarget.dataset.taskid), menuType: configJSON.menuTypeTaskActions, taskType: type });
  }

  openAddColumnView = (event: React.MouseEvent<HTMLElement>) => {
    if (this.isProjectNotRunning()) {
      return;
    }
    if (event.currentTarget.dataset.columnid) {
      this.setState({ openEditColumnId: event.currentTarget.dataset.columnid, tempCompareColumnId: event.currentTarget.dataset.columnid!, addColumnMenu: true, tempColumnName: '' }, () => this.addColumnInputRef?.current?.focus())
    }
  }

  isProjectNotRunning = () => {
    return this.state.projectTaskDetails?.attributes.status !== 'In Progress'
  }

  renameColumn = () => {
    this.setState({ editColumnMenu: true, anchorEl: null, hide3dots: true })
  }

  closeAddEditColumnView = () => {
    this.setState({ editColumnMenu: false, addColumnMenu: false, openEditColumnId: '', tempCompareColumnId: '', hide3dots: false })
  }

  onChangeColumnName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ tempColumnName: event.target.value })
  }

  addColumn = () => {
    if (this.state.tempColumnName.trim() !== "") {
      const existingColumnNames = this.state.projectTaskDetails?.attributes.customs_columns.map((column) => column.section.name);
      const existsIndex = existingColumnNames!.indexOf(this.state.tempColumnName);
      if (existsIndex === -1) {
        this.addColumnApiCall(this.state.tempColumnName);
      } else {
        this.setState({ openMessage: true, messageType: 'error', message: `column with the name ${this.state.tempColumnName} already exists!` })
      }
    }
  }

  renameColumnName = () => {
    if (this.state.tempColumnName.trim() !== "") {
      const existingColumnNames = this.state.projectTaskDetails!.attributes.customs_columns.filter((column) => column.section.name === this.state.tempColumnName);
      if (!existingColumnNames.length || existingColumnNames?.[0]?.section.id === Number(this.state.openEditColumnId)) {
        this.renameColumnApiCall(this.state.tempColumnName)
      } else {
        this.setState({ openMessage: true, messageType: 'error', message: `column with the name ${this.state.tempColumnName} already exists!` })
      }
    }
  }

  deleteColumn = () => {
    this.deleteColumnApiCall()
  }

  openDeleteTaskModal = () => {
    this.setState({ openModal: true, anchorEl: null, modalType: 'delete' })
  }

  closeModal = () => {
    this.setState({ openModal: false, anchorEl: null });
  }

  handlePhaseNameValidation = () => {
    let error = false;

    const validateSubSections = (subSections: ISubSection[]) => {
      return subSections.map((subSection) => {
        const errorMsg = this.getErrorForPhaseValidation(subSection.name, "Phase name is required!");
        if (errorMsg) error = true;
        return { ...subSection, error: errorMsg };
      });
    };

    const getUpdatedSections = (sections: SectionsArray[]) => {
      return sections.map((section) => {
        if (section?.sub_sections?.length) {
          const updatedSubSections = validateSubSections(section.sub_sections);
          return { ...section, sub_sections: updatedSubSections };
        }
        return section;
      });
    };

    const updatedPredefinedFormSection = !this.state.isPredefinedCreation
      ? getUpdatedSections(this.state.taskDetails?.attributes?.tool_details?.data?.attributes.sections || [])
      : getUpdatedSections(this.state.predefinedForm?.attributes.sections || []);

    const updatedPhases = this.state.customPhaseList.map((phase: CustomPhase) => {
      const errorMsg = this.getErrorForPhaseValidation(phase.value, "Phase name is required!");
      if (errorMsg) error = true;
      return { ...phase, error: errorMsg };
    });

    return { updatedPredefinedFormSection, error, updatedPhases };
  };

  getErrorForPhaseValidation = (value: string, error: string) => {
    if (this.isEmpty(value)) {
      return error
    }
    return "";
  }


  assignPredefinedTask = () => {
    // check fields fro multiple_with_relation where user might leave field empty and some field filled
    //for relation it is mandatory to fill all the field because it has relation and also for multiple input field
    let defaultAnswers: IPredefinedAnsType | null = this.state.predefinedPMAnswer;
    if (!this.isProjectManager()) {
      defaultAnswers = this.state.predefinedParticipantAnswer;
    }
    let isHavingProperData = true, arePhaseNamesProper = true;
    let updatedAnswer: IPredefinedAnsType = {};

    const validateFields = (
      fields: { content: string; ans_type: string; error: string; }[],
      errorMessage: string
    ) => {
      return fields.map(item => {
        if (item.content.trim() === "") {
          isHavingProperData = false;
          return { ...item, error: errorMessage };
        }
        return item;
      });
    };

    if (defaultAnswers) {
      this.validatePredefineObj(updatedAnswer, defaultAnswers, validateFields);
      const { error, updatedPhases, updatedPredefinedFormSection } = this.handlePhaseNameValidation();
      const validatePhase3 = this.handleFormValidationForTool1Phase3(defaultAnswers, isHavingProperData, updatedAnswer);

      isHavingProperData = validatePhase3.isHavingProperData;
      updatedAnswer = validatePhase3.updatedAnswer;
      let validatePhase6 = this.handlePhase6Tool1Validation(defaultAnswers, isHavingProperData, updatedAnswer)
      updatedAnswer = validatePhase6.updatedAnswer;
      isHavingProperData = validatePhase6.isHavingProperData;
      arePhaseNamesProper = !error;

      this.setState((prevState) => {
        let mainObj = "predefinedPMAnswer";
        let mainPrevAnswerObj = prevState.predefinedPMAnswer;
        if (!this.isProjectManager()) {
          mainObj = "predefinedParticipantAnswer"
          mainPrevAnswerObj = prevState.predefinedParticipantAnswer;
        }

        return {
          [mainObj]: {
            ...mainPrevAnswerObj,
            ...updatedAnswer
          },
          predefinedForm: this.state.isPredefinedCreation ? {
            ...prevState.predefinedForm,
            attributes: {
              ...prevState.predefinedForm?.attributes,
              sections: updatedPredefinedFormSection
            }
          } as PredefinedForm : prevState.predefinedForm as PredefinedForm,
          taskDetails: !this.state.isPredefinedCreation ? {
            ...prevState.taskDetails,
            attributes: {
              ...prevState.taskDetails?.attributes,
              tool_details: {
                ...prevState.taskDetails?.attributes.tool_details,
                data: {
                  ...prevState.taskDetails?.attributes.tool_details.data,
                  attributes: {
                    ...prevState.taskDetails?.attributes.tool_details.data.attributes,
                    sections: updatedPredefinedFormSection
                  }
                }
              }
            }
          } as TaskData : prevState.taskDetails as TaskData,
          customPhaseList: updatedPhases
        } as unknown
      })
    }

    return isHavingProperData && arePhaseNamesProper;
  }

  handlePhase6Tool1Validation = (
    defaultAnswers: IPredefinedAnsType,
    isHavingProperData: boolean,
    updatedAnswer: IPredefinedAnsType
  ) => {

    const formAttributes = this.state.taskDetails ? this.state.taskDetails.attributes?.tool_details?.data?.attributes : this.state.predefinedForm?.attributes;
    if ((formAttributes?.tool_name === 'Tool 1 - Feedback capture grid' && this.isProjectManager())) {
      if (defaultAnswers) {
        const firstKey: number = Number(Object.keys(defaultAnswers)[0]);
        if (firstKey) {
          const answersArray = defaultAnswers[firstKey];
          if (answersArray.length > 0 && answersArray[0].content.trim() === "") {
            isHavingProperData = false;
            answersArray[0].error = "please fill this field";
          }
        }
      }

      updatedAnswer = defaultAnswers;
    }
    return { isHavingProperData, updatedAnswer };
  }

  handleFormValidationForTool1Phase3 = (
    defaultAnswers: IPredefinedAnsType,
    isHavingProperData: boolean,
    updatedAnswer: IPredefinedAnsType
  ) => {
    const formAttributes = this.state.taskDetails ? this.state.taskDetails.attributes?.tool_details?.data?.attributes : this.state.predefinedForm?.attributes;

    if (((formAttributes?.tool_name === 'Tool 1 - \"How might we...\" Question' && this.isProjectManager()) || formAttributes?.tool_name === 'Tool 2 - 2x2 Matrix' || formAttributes?.tool_name === "Tool 1 - Brainstorming")) {
      const validatePhase3 = this.handlePhase3Tool1Validation(defaultAnswers, isHavingProperData);
      updatedAnswer = validatePhase3.defaultAnswers;
      isHavingProperData = validatePhase3.isHavingProperData;

    }
    return { isHavingProperData, updatedAnswer };
  };

  validatePredefineObj = (updatedAnswer: IPredefinedAnsType, defaultAnswers: IPredefinedAnsType, validateFields: (fields: {
    content: string;
    ans_type: string;
    error: string;
  }[], errorMessage: string) => {
    content: string;
    ans_type: string;
    error: string;
  }[]) => {

    for (const questionId in defaultAnswers) {
      const items = defaultAnswers[questionId];
      const defaultFields = items.filter(item => item.ans_type === "default");
      const relationshipFields = items.filter(item => item.ans_type === "relationship");
      const defaultFilled = defaultFields.every(item => item.content.trim() !== "");
      const anyDefaultFilled = defaultFields.some(item => item.content.trim() !== "");
      const relationshipFilled = relationshipFields.every(item => item.content.trim() !== "");

      let updatedDefaultField = defaultFields;
      if (this.isRelationFilledButDefaultFieldNot(relationshipFilled, defaultFilled, relationshipFields.length > 0)) {
        updatedDefaultField = validateFields(defaultFields, 'please fill this field')
      }
      let updatedRelationshipField = relationshipFields;
      if (this.isRelationFieldValid(anyDefaultFilled, defaultFilled, relationshipFields.length > 0, relationshipFilled)) {
        updatedRelationshipField = validateFields(relationshipFields, 'relationship is required!')
      }

      if (this.ifAnyDefaultFilled(defaultFilled, anyDefaultFilled)) {
        updatedDefaultField = validateFields(defaultFields, 'please fill this field')
      }
      updatedAnswer[questionId] = [...updatedDefaultField, ...updatedRelationshipField]
    }
  }

  handlePhase3Tool1Validation = (defaultAnswers: IPredefinedAnsType, isHavingProperData: boolean) => {
    if (defaultAnswers) {
      for (const key in defaultAnswers) {
        if (defaultAnswers.hasOwnProperty(key)) {
          const answersArray = defaultAnswers[key];
          answersArray.forEach(answer => {
            if (answer.content.trim() === "") {
              isHavingProperData = false
              answer.error = "please fill this field";
            }
          });
        }
      }
    }
    return { defaultAnswers, isHavingProperData }
  }

  isRelationFilledButDefaultFieldNot = (relationshipFilled: boolean, defaultFilled: boolean, relationshipFields: boolean) => {
    return relationshipFilled && !defaultFilled && relationshipFields
  }

  isRelationFieldValid = (anyDefaultFilled: boolean, defaultFilled: boolean, relationshipFields: boolean, relationshipFilled: boolean,) => {
    return (anyDefaultFilled || defaultFilled) && relationshipFields && !relationshipFilled
  }

  ifAnyDefaultFilled = (defaultFilled: boolean, anyDefaultFilled: boolean) => {
    return !defaultFilled && anyDefaultFilled
  }

  openAssignTaskModalIfValidate = () => {
    if (this.assignPredefinedTask()) {
      this.setState({ openModal: true, isPredefinedFormFilling: false, isPredefinedCreation: false, modalType: 'add-predefined-task-details' })
    }
  }

  numberToWords = (num: number) => {
    const ones = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    if (num < 10) return ones[num];
    if (num > 10 && num < 20) return teens[num - 11];
    if (num % 10 === 0) return tens[Math.floor(num / 10) - 1];
    return tens[Math.floor(num / 10) - 1] + '-' + ones[num % 10];
  }

  cancelPredefinedTask = (closeModal = true as boolean) => {
    this.setState({
      taskName: '',
      taskNameError: '',
      taskDescription: '',
      taskDescriptionError: '',
      dueDate: '',
      labelDate: '',
      taskDetails: null,
      subTasksArray: [],
      attachment: null,
      attachmentSize: '',
      autoCompleteValue: null,
      selectedMember: null,
      attachmentsToRemove: [],
      openModal: !closeModal,
      isEditMode: false,
      isPredefinedFormFilling: false,
      isPredefinedCreation: false,
      selectedTool: undefined,
      selectedPhase: undefined,
      selectedPhaseObj: null,
      selectedToolObj: null,
      dueDateError: '',
      selectedDescription: '',
      selectedAttachment: '',
      projectManagerAttachmentData: [],
      tempParticipantAttachmentData: [],
      participantAttachmentData: [],
      predefinedPMAnswer: null,
      predefinedParticipantAnswer: null,
      tempLabelDate: null,
      attachmentError: '',
      sketchAttachment: null,
      protoTypeAttachment: null,
      PMPrototypeAttachment: null,
      participantProtoTypeAttachmentAnswer: null,
      allComments: [],
      isDrawing: false,
      isReassign: false,
      isComplete: false,
      isSketchPrefilled: false,
      selectedPhaseName: '',
      selectedEndDate: '',
      selectedToolName: '',
      selectedStartDate: "",
      isCommentLoading: false,
      commentPage: 1,
      totalCommentPage: 1,
      customPhaseList: [],
      customPhasesToDelete: [],
      defaultPhasesToDelete: [],
      predefinedForm: null,
      defaultPhaseToDelete: null,
      customPhaseToDelete: null,
      toolList: [],
      phaseList: [],
      customIdeas: [],
      ideasToDelete: [],
      customRounds: [],
      roundsToDelete: [],
      customIdeaToDelete: null,
      defaultIdeaToDelete: null,
      customRoundToDelete: null,
      defaultRoundToDelete: null,
      roundSectionQuestionId: null,
      selectedCardId: null

    }, () => this.getProjectTasks())
  }

  cancelPredefinedEdit = () => {
    this.cancelPredefinedTask()
    this.setState({
      isEditMode: false,
      predefinedPMAnswer: null,
      isPredefinedCreation: false,
      predefinedParticipantAnswer: null,
      dueDate: '',
      labelDate: null,
      attachment: null,
      taskDetails: null,
      selectedCardId: null,
      tempLabelDate: null,
      attachmentError: '',
    })
  }

  handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      currentTab: newValue,
      isDrawing: false
    })
  }

  handleSaveCanvas = (questionId: number) => {
    if (this.isProjectManager()) {
      this.setState((prev) => {
        return { predefinedPMAnswer: { ...prev.predefinedPMAnswer, [questionId]: [{ key: 1, content: '', ans_type: 'default', error: '' }] } }
      })
    } else {
      this.setState((prev) => { return { predefinedParticipantAnswer: { ...prev.predefinedParticipantAnswer, [questionId]: [{ key: 1, content: '', ans_type: 'default', error: '' }] } } })
    }
    if (this.saveableCanvas.current) {
      this.setState({ isDrawing: true, sketchQuestionId: questionId })
      const canvasContainer = this.saveableCanvas.current?.canvasContainer;
      if (canvasContainer) {
        const canvas = canvasContainer.children[1] as HTMLCanvasElement;
        if (canvas) {
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], 'sketch.png', { type: 'image/png' });
              this.setState({ sketchAttachment: { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }, anchorEl: null, attachment: null })
            }
          })
        }
      }
    }
  };


  handleUndoCanvas = () => {
    if (this.saveableCanvas.current) {
      const currentData = this.saveableCanvas.current.getSaveData();
      if (Boolean(JSON.parse(currentData).lines.length)) {
        this.setState(
          prevState => ({
            redoStack: [...prevState.redoStack, currentData],
          }),
          () => {
            this.saveableCanvas.current?.undo();
            const currentData = this.saveableCanvas.current?.getSaveData();
            if (currentData) {
              this.setState({ isDrawing: Boolean(JSON.parse(currentData).lines.length) })
            }
          }
        );
      }
    }
  };

  handleRedoCanvas = () => {
    if (this.saveableCanvas.current) {
      if (this.saveableCanvas && this.state.redoStack.length > 0) {
        const redoData = this.state.redoStack.pop();
        if (redoData) {
          this.saveableCanvas.current.loadSaveData(redoData, true);
        }
      }
    }
  };

  handleClearCanvas = () => {
    if (this.saveableCanvas.current) {
      this.saveableCanvas.current.clear()
      this.setState({ isDrawing: false, sketchAttachment: null })
    }
  }

  formatDueDate = (date: string | undefined) => {
    return moment(date).format('DD MMM, YYYY')
  }

  disabledFields = () => {
    return !this.isProjectManager() || this.state.taskDetails?.attributes.status === 'need_review'
  }

  determineIButtonClick = (question: Question): ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined => {
    return (this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'to_do' && this.state.selectedCardId) || !this.isProjectManager()
      ? (event) => this.openCommentPopover(event, question)
      : undefined;
  };

  shouldRenderFinalAnswer = (question: Question) => {
    const formAttributes = this.state.taskDetails && this.state.taskDetails.attributes;
    return question.content === 'Final Statement:' && this.isProjectManager() && (this.state.isPredefinedCreation || formAttributes?.status === 'to_do')
  }

  showShortAnswerErrors = (question: Question, error: string) => {
    return { error: question.content === 'Final Statement:' ? false : Boolean(error), erroMsg: question.content === 'Final Statement:' ? "" : error }
  }

  disabledSortAnswer = (question: Question) => {
    return this.disablePredefinedFormFields() || (!this.isProjectManager() && question.key === 'restrict_participant' && question.content !== 'Final Statement:')
  }

  handleLastQuestionForPhase3 = (question: Question) => {
    if (question.content === 'Final Statement:' && !this.state.phase3Tool1Fields.finalStatementId) {
      this.setState((prevState) => {
        return { phase3Tool1Fields: { ...prevState.phase3Tool1Fields, finalStatementId: question.id } }
      })
    }
  }

  handlePhase3First4Questions = (question: Question, value: string | null) => {
    switch (question.content) {
      case 'Action selection lists:':
        this.setState((prevState) => {
          return {
            phase3Tool1Fields: {
              ...prevState.phase3Tool1Fields,
              action: value as string
            },
            predefinedPMAnswer: {
              ...prevState.predefinedPMAnswer,
              [Number(prevState.phase3Tool1Fields.finalStatementId)]: [
                { key: 1, content: `How might we ${prevState.phase3Tool1Fields.action} the ${prevState.phase3Tool1Fields.user} ${prevState.phase3Tool1Fields.problem} to ${prevState.phase3Tool1Fields.outcome}?`, ans_type: 'default', error: '' }
              ]
            }
          }
        })
        break;
      case "User Name:":
        this.setState((prevState) => {
          return {
            phase3Tool1Fields: {
              ...prevState.phase3Tool1Fields,
              user: value as string
            },
            predefinedPMAnswer: {
              ...prevState.predefinedPMAnswer,
              [Number(prevState.phase3Tool1Fields.finalStatementId)]: [
                { key: 1, content: `How might we ${prevState.phase3Tool1Fields.action} the ${prevState.phase3Tool1Fields.user} ${prevState.phase3Tool1Fields.problem} to ${prevState.phase3Tool1Fields.outcome}?`, ans_type: 'default', error: '' }
              ]
            }
          }
        })
        break;
      case "Need/Problem:":
        this.setState((prevState) => {
          return {
            phase3Tool1Fields: {
              ...prevState.phase3Tool1Fields,
              problem: value as string
            },
            predefinedPMAnswer: {
              ...prevState.predefinedPMAnswer,
              [Number(prevState.phase3Tool1Fields.finalStatementId)]: [
                { key: 1, content: `How might we ${prevState.phase3Tool1Fields.action} the ${prevState.phase3Tool1Fields.user} ${prevState.phase3Tool1Fields.problem} to ${prevState.phase3Tool1Fields.outcome}?`, ans_type: 'default', error: '' }
              ]
            }
          }
        })
        break;
      case "Outcome/Results?:":
        this.setState((prevState) => {
          return {
            phase3Tool1Fields: {
              ...prevState.phase3Tool1Fields,
              outcome: value as string
            },
            predefinedPMAnswer: {
              ...prevState.predefinedPMAnswer,
              [Number(prevState.phase3Tool1Fields.finalStatementId)]: [
                { key: 1, content: `How might we ${prevState.phase3Tool1Fields.action} the ${prevState.phase3Tool1Fields.user} ${prevState.phase3Tool1Fields.problem} to ${prevState.phase3Tool1Fields.outcome}?`, ans_type: 'default', error: '' }
              ]
            }
          }
        })
        break;
      default: break;
    }
  }

  handlePredefinedFormAnswer = (question: Question, value: string) => {

    this.handlePhase3First4Questions(question, value)
    if (this.isProjectManager()) {
      this.setState((prev) => {
        return { predefinedPMAnswer: { ...prev.predefinedPMAnswer, [question.id]: [{ key: 1, content: value, ans_type: 'default', error: '' }] } }
      })
    } else {
      this.setState((prev) => { return { predefinedParticipantAnswer: { ...prev.predefinedParticipantAnswer, [question.id]: [{ key: 1, content: value, ans_type: 'default', error: '' }] } } })
    }
  }

  handleAddeFieldsOnLoad = (question: Question) => {
    let defaultAnswers = this.state.predefinedPMAnswer?.[question.id];
    if (!this.isProjectManager()) {
      defaultAnswers = this.state.predefinedParticipantAnswer?.[question.id];
    }
    if (defaultAnswers) {
      const defaultFields = defaultAnswers.filter(item => item.ans_type === "default");
      const defaultFilled = defaultFields.every(item => item.content.trim() !== "");
      if (!defaultFilled) {
        return;
      }
    }
    this.setState((prev) => {
      let mainObj = "predefinedPMAnswer";
      let mainPrevAnswerObj = prev.predefinedPMAnswer;
      let mainQuestionObject: { key: number, content: string, error: string, ans_type: string }[] = []
      if (!this.isProjectManager()) {
        mainObj = "predefinedParticipantAnswer"
        mainPrevAnswerObj = prev.predefinedParticipantAnswer;
      }
      if (question.answer_type === 'multiple_with_relation') {
        for (let start = 0; start < 2; start++) {
          mainQuestionObject.push({ key: start + 1, content: '', ans_type: 'default', error: '' })
        }
        mainQuestionObject.push({ key: mainQuestionObject.length + 1, content: '', ans_type: 'relationship', error: '' })
      } else {
        for (let start = 0; start < 1; start++) {
          mainQuestionObject.push({ key: start + 1, content: '', ans_type: 'default', error: '' })
        }
      }
      return {
        [mainObj]: {
          ...mainPrevAnswerObj,
          [question.id]: [...mainQuestionObject]
        }
      } as unknown
    })
  }

  handleAddMoreFields = (questionId: number) => {
    let defaultAnswers = this.state.predefinedPMAnswer?.[questionId];
    if (!this.isProjectManager()) {
      defaultAnswers = this.state.predefinedParticipantAnswer?.[questionId];
    }
    if (defaultAnswers) {
      const defaultFields = defaultAnswers.filter(item => item.ans_type === "default");
      const defaultFilled = defaultFields.every(item => item.content.trim() !== "");
      if (!defaultFilled) {
        return;
      }
    }
    this.setState((prev) => {
      let mainObj = "predefinedPMAnswer";
      let mainPrevAnswerObj = prev.predefinedPMAnswer;
      let mainQuestionObject = prev.predefinedPMAnswer![questionId]
      if (!this.isProjectManager()) {
        mainObj = "predefinedParticipantAnswer"
        mainPrevAnswerObj = prev.predefinedParticipantAnswer;
        mainQuestionObject = prev.predefinedParticipantAnswer![questionId]
      }
      return {
        [mainObj]: {
          ...mainPrevAnswerObj,
          [questionId]: [...mainQuestionObject, {
            key: mainQuestionObject.length + 1,
            content: '',
            ans_type: 'default',
            error: ''
          }]
        }
      } as unknown
    })
  }

  handleChangeDefaultField = (value: string, key: number, question: Question) => {
    this.setState((prev) => {
      let defaultAnswers = prev.predefinedPMAnswer?.[question.id];
      if (!this.isProjectManager()) {
        defaultAnswers = prev.predefinedParticipantAnswer?.[question.id];
      }

      const updatedAnswer = defaultAnswers?.map((answer) => {
        if (answer.key === key) {
          return { ...answer, content: value, error: "" }
        }
        return answer
      })

      let mainObj = "predefinedPMAnswer";
      let mainPrevAnswerObj = prev.predefinedPMAnswer;
      if (!this.isProjectManager()) {
        mainObj = "predefinedParticipantAnswer"
        mainPrevAnswerObj = prev.predefinedParticipantAnswer;
      }

      return {
        [mainObj]: {
          ...mainPrevAnswerObj,
          [question.id]: updatedAnswer
        }
      } as unknown;
    })
  }

  handleRemoveField = (key: number, question: Question) => {
    this.setState((prev) => {
      let defaultAnswers = prev.predefinedPMAnswer?.[question.id];
      if (!this.isProjectManager()) {
        defaultAnswers = prev.predefinedParticipantAnswer?.[question.id];
      }

      const updatedAnswer = defaultAnswers?.filter((answer) => {
        return answer.key !== key;
      })

      let mainObj = "predefinedPMAnswer";
      let mainPrevAnswerObj = prev.predefinedPMAnswer;
      if (!this.isProjectManager()) {
        mainObj = "predefinedParticipantAnswer"
        mainPrevAnswerObj = prev.predefinedParticipantAnswer;
      }
      return {
        [mainObj]: {
          ...mainPrevAnswerObj,
          [question.id]: updatedAnswer
        }
      } as unknown;
    })
  }

  handleCloseDeleteConfirmationModal = () => {
    this.setState({ openModal: false, defaultIdeaToDelete: null, customIdeaToDelete: null, defaultRoundToDelete: null, customRoundToDelete: null })
  }

  openDeleteDefaultIdea = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true, modalType: "delete-default-idea", defaultIdeaToDelete: Number(event.currentTarget.dataset.questionid) })
  }

  openDeleteCustomIdea = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true, modalType: "delete-custom-idea", customIdeaToDelete: Number(event.currentTarget.dataset.questionid) })
  }

  deleteCustomIdeas = () => {
    const ideaIndex = this.state.customIdeaToDelete!;
    const indexToBeDeleted = [ideaIndex, ideaIndex + 1, ideaIndex + 2]
    const tempIdeas = [...this.state.customIdeas];
    const updatedIdeas = tempIdeas.filter((_idea, index) => !indexToBeDeleted.includes(index))
    this.setState({ customIdeas: updatedIdeas, openModal: false, customIdeaToDelete: null })
  }

  deleteDefaultIdeas = () => {
    const questionIndex = this.state.defaultIdeaToDelete!;
    const indexToBeDeleted = [questionIndex, questionIndex + 1, questionIndex + 2];
    let questionsIdToRemove: number[] = [];
    const updatedQuestions = this.state.taskDetails?.attributes?.tool_details?.data?.attributes.questions.filter((question, index) => {
      if (indexToBeDeleted.includes(index)) {
        questionsIdToRemove.push(question.id);
      }
      return !indexToBeDeleted.includes(index);
    });

    const { updatedPredefinedParticipantAnswer, updatedPredefinedPMAnswer } = this.updatePredefinedAnswers(questionsIdToRemove);

    this.setState((prevState) => ({
      taskDetails: {
        ...prevState.taskDetails,
        attributes: {
          ...prevState.taskDetails?.attributes,
          tool_details: {
            ...prevState.taskDetails?.attributes.tool_details,
            data: {
              ...prevState.taskDetails?.attributes.tool_details.data,
              attributes: {
                ...prevState.taskDetails?.attributes.tool_details.data.attributes,
                questions: updatedQuestions,
              },
            },
          },
        },
      } as TaskData,
      predefinedPMAnswer: updatedPredefinedPMAnswer,
      predefinedParticipantAnswer: updatedPredefinedParticipantAnswer,
      ideasToDelete: [...prevState.ideasToDelete, ...questionsIdToRemove],
      openModal: false,
      defaultIdeaToDelete: null,
    }));
  };


  updatePredefinedAnswers = (questionsIdToRemove: number[]) => {
    const updatedPredefinedParticipantAnswer = Object.keys(this.state.predefinedParticipantAnswer!)
      .filter((questionId) => !questionsIdToRemove.includes(Number(questionId)))
      .reduce((result, questionId: string) => {
        result[Number(questionId)] = this.state.predefinedParticipantAnswer![Number(questionId)];
        return result;
      }, {} as { [key: number]: { key: number; content: string; ans_type: string; error: string }[] });

    const updatedPredefinedPMAnswer = Object.keys(this.state.predefinedPMAnswer!)
      .filter((questionId) => !questionsIdToRemove.includes(Number(questionId)))
      .reduce((result, questionId: string) => {
        result[Number(questionId)] = this.state.predefinedPMAnswer![Number(questionId)];
        return result;
      }, {} as { [key: number]: { key: number; content: string; ans_type: string; error: string }[] });

    return { updatedPredefinedParticipantAnswer, updatedPredefinedPMAnswer };
  };


  addMoreIdeas = () => {
    const ideas = [
      {
        question: "Idea",
        placeHolder: "Enter your idea...",
      },
      {
        question: "factor 1",
        placeHolder: "Enter the factor1...",
      },
      {
        question: "factor 2",
        placeHolder: "Enter the factor2...",
      }
    ]

    const defaultAnswers = this.state.predefinedPMAnswer;
    let error = false;

    if (defaultAnswers) {
      const { answers, hasError } = this.validatePredefinedAnswers(defaultAnswers);
      error = hasError;
      this.setState({ predefinedPMAnswer: answers });
    }

    if (!error) {
      this.setState((prevState) => {
        return {
          customIdeas: [
            ...prevState.customIdeas,
            ...ideas
          ]
        }
      })
    }
  }

  addMoreRounds = () => {
    const round = {
      round: "Round",
      subQuestion: {
        question: "Write your ideas",
        preFix: '1',
        fields: [
          {
            key: 1,
            content: '',
            ans_type: "default",
            error: ''
          }
        ]
      }
    }


    const defaultAnswers = this.state.predefinedPMAnswer;
    let error = false;

    if (defaultAnswers) {
      const { answers, hasError } = this.validatePredefinedAnswers(defaultAnswers);
      error = hasError;
      this.setState({ predefinedPMAnswer: answers });
    }
    if (!error) {
      this.setState((prevState) => {
        return { customRounds: [...prevState.customRounds, round] }
      })
    }
  }

  validatePredefinedAnswers = (answers: { [key: number]: { key: number; content: string; ans_type: string; error: string }[] }) => {
    let hasError = false;

    for (const key in answers) {
      if (answers.hasOwnProperty(key)) {
        const answersArray = answers[key];
        answersArray.forEach(answer => {
          if (answer.content.trim() === "") {
            hasError = true;
            answer.error = "please fill this field";
          }
        });
      }
    }

    return { answers, hasError };
  };


  openDeleteDefaultRound = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true, modalType: "delete-default-round", defaultRoundToDelete: Number(event.currentTarget.dataset.subsectionid), roundSectionQuestionId: Number(event.currentTarget.dataset.sectionquestionid) })
  }

  openDeleteCustomRound = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true, modalType: "delete-custom-round", customRoundToDelete: Number(event.currentTarget.dataset.subsectionid) })
  }

  deleteCustomRounds = () => {
    const tempRound = [...this.state.customRounds];
    const updatedRound = tempRound.filter((_round, roundIndex: number) => roundIndex !== this.state.customRoundToDelete)
    this.setState({ customRounds: updatedRound, openModal: false, customRoundToDelete: null })
  }

  deleteDefaultRounds = () => {
    const sectionId = this.state.defaultRoundToDelete!;
    const sectionQuestionId = this.state.roundSectionQuestionId!;
    const questionsIdToRemove = [sectionId];

    const updatedSections = this.state.taskDetails?.attributes?.tool_details?.data?.attributes.sections.filter(
      (section) => section.id !== sectionId
    );

    const { updatedPredefinedParticipantAnswer, updatedPredefinedPMAnswer } = this.updatePredefinedAnswers([sectionQuestionId]);

    this.setState((prevState) => ({
      taskDetails: {
        ...prevState.taskDetails,
        attributes: {
          ...prevState.taskDetails?.attributes,
          tool_details: {
            ...prevState.taskDetails?.attributes.tool_details,
            data: {
              ...prevState.taskDetails?.attributes.tool_details.data,
              attributes: {
                ...prevState.taskDetails?.attributes.tool_details.data.attributes,
                sections: updatedSections,
              },
            },
          },
        },
      } as TaskData,
      predefinedPMAnswer: updatedPredefinedPMAnswer,
      predefinedParticipantAnswer: updatedPredefinedParticipantAnswer,
      roundsToDelete: [...prevState.roundsToDelete, ...questionsIdToRemove],
      openModal: false,
      defaultRoundToDelete: null,
      roundSectionQuestionId: null,
    }));
  };


  addMorePhases = () => {
    const questionArray = [
      {
        question: "Write the actions in this phase : ",
        preFix: 'Action',
        fields: [
          {
            key: 1,
            content: '',
            ans_type: "default",
            error: ''
          }
        ]
      },
      {
        question: "Write all the thoughts of the user in this phase : ",
        preFix: 'Thought',
        fields: [
          {
            key: 1,
            content: '',
            ans_type: "default",
            error: ''
          }
        ]
      },
      {
        question: "Write all the feelings of the user in this phase : ",
        preFix: 'Feeling',
        fields: [
          {
            key: 1,
            content: '',
            ans_type: "default",
            error: ''
          }
        ]
      }
    ]

    const sampleNewPhase = [
      {
        value: "",
        error: "",
        questions: questionArray
      }
    ]

    const tempPhaseList = [...this.state.customPhaseList]
    let error = false;
    const updatedPhases = tempPhaseList.map((phase: CustomPhase) => {
      if (this.isEmpty(phase.value)) {
        error = true;
        return {
          ...phase, error: 'Phase name is required!'
        }
      }
      return phase;
    })
    this.setState({ customPhaseList: updatedPhases })
    if (!error)
      this.setState((prevState) => {
        return {
          customPhaseList: [
            ...prevState.customPhaseList,
            ...sampleNewPhase
          ]
        }
      })
  }

  openDeleteDefaultConfirmPhase = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true, modalType: "delete-default-phase", defaultPhaseToDelete: Number(event.currentTarget.dataset.subsectionid) })
  }

  openDeleteCustomConfirmPhase = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openModal: true, modalType: "delete-custom-phase", customPhaseToDelete: Number(event.currentTarget.dataset.phaseindex) })
  }

  removeCustomPhase = () => {
    const subSectionId = Number(this.state.customPhaseToDelete);
    const tempPhaseList = [...this.state.customPhaseList]
    const updatedPhases = tempPhaseList.filter((_phase: CustomPhase, subSectionIndex: number) => {
      return subSectionIndex !== subSectionId;
    })
    this.setState((prevState) => { return { openModal: false, customPhaseToDelete: null, customPhaseList: updatedPhases, customPhasesToDelete: [...prevState.customPhasesToDelete, subSectionId] } })
  }

  removeDefaultPhases = () => {
    const subSectionId = Number(this.state.defaultPhaseToDelete);
    let questionsIdToRemove: number[] = [];

    const updatedSections = this.state.taskDetails?.attributes?.tool_details?.data?.attributes.sections.map((sectionElement) => {
      return {
        ...sectionElement,
        sub_sections: sectionElement.sub_sections.filter((subSectionElement) => {
          if (subSectionElement.id === subSectionId) {
            subSectionElement.questions.forEach(question => questionsIdToRemove.push(question.id));
          }
          return subSectionElement.id !== subSectionId;
        })
      };
    });

    const { updatedPredefinedParticipantAnswer, updatedPredefinedPMAnswer } = this.updatePredefinedAnswers(questionsIdToRemove);

    this.setState((prevState) => ({
      taskDetails: {
        ...prevState.taskDetails,
        attributes: {
          ...prevState.taskDetails?.attributes,
          tool_details: {
            ...prevState.taskDetails?.attributes.tool_details,
            data: {
              ...prevState.taskDetails?.attributes.tool_details.data,
              attributes: {
                ...prevState.taskDetails?.attributes.tool_details.data.attributes,
                sections: updatedSections,
              },
            },
          },
        },
      } as TaskData,
      predefinedPMAnswer: updatedPredefinedPMAnswer,
      predefinedParticipantAnswer: updatedPredefinedParticipantAnswer,
      defaultPhasesToDelete: [...prevState.defaultPhasesToDelete, subSectionId],
      openModal: false,
      defaultPhaseToDelete: null,
    }));
  };


  handleChangeDefaultPhase = (value: string, section: SectionsArray, subSection: ISubSection, subSectionIndex: number) => {
    this.setState((prevState) => {
      let updatedPredefinedFormSection = this.handlePredefinedFormForPhaseNameChange(prevState, value, section, subSection)

      if (!this.state.isPredefinedCreation) {
        updatedPredefinedFormSection = this.handleTaskDetailsDataForPhaseNameChange(prevState, value, section, subSection)
      }

      let updatedRenameSection = this.handleRenameSection(subSection, subSectionIndex, value);

      return {
        predefinedForm: this.state.isPredefinedCreation ? {
          ...prevState.predefinedForm,
          attributes: {
            ...prevState.predefinedForm?.attributes,
            sections: updatedPredefinedFormSection
          }
        } as unknown as PredefinedForm : prevState.predefinedForm as PredefinedForm,
        renamedSections: updatedRenameSection,
        taskDetails: !this.state.isPredefinedCreation ? {
          ...prevState.taskDetails,
          attributes: {
            ...prevState.taskDetails?.attributes,
            tool_details: {
              ...prevState.taskDetails?.attributes.tool_details,
              data: {
                ...prevState.taskDetails?.attributes.tool_details.data,
                attributes: {
                  ...prevState.taskDetails?.attributes.tool_details.data.attributes,
                  sections: updatedPredefinedFormSection
                }
              }
            }
          }
        } as unknown as TaskData : prevState.taskDetails as TaskData,
      };
    });
  }

  handlePredefinedFormForPhaseNameChange = (prevState: S, value: string, section: SectionsArray, subSection: ISubSection) => {
    return prevState.predefinedForm?.attributes.sections.map((sectionElement) => {
      if (sectionElement.id === section.id) {
        const updatedSubSections = sectionElement.sub_sections.map((subSectionElement) => {
          if (subSectionElement.id === subSection.id) {
            return { ...subSectionElement, name: value, error: this.isEmpty(value) ? "Phase name is required!" : "" };
          }
          return subSectionElement;
        });
        return { ...sectionElement, sub_sections: updatedSubSections };
      }
      return sectionElement;
    });
  }

  handleTaskDetailsDataForPhaseNameChange = (prevState: S, value: string, section: SectionsArray, subSection: ISubSection) => {
    return prevState.taskDetails?.attributes?.tool_details?.data?.attributes.sections.map((sectionElement) => {
      if (sectionElement.id === section.id) {
        const updatedSubSections = sectionElement.sub_sections.map((subSectionElement) => {
          if (subSectionElement.id === subSection.id) {
            return { ...subSectionElement, name: value, error: this.isEmpty(value) ? "Phase name is required!" : "" };
          }
          return subSectionElement;
        });

        return { ...sectionElement, sub_sections: updatedSubSections };
      }
      return sectionElement;
    });
  }

  handleRenameSection = (subSection: ISubSection, subSectionIndex: number, value: string) => {
    const isPresent = this.state.renamedSections.findIndex((element) => element.section_id === subSection.id);
    let tempRenameSection = [...this.state.renamedSections];
    let updatedRenameSection: IRenameSection[] = tempRenameSection;
    if (subSectionIndex > 1) {
      if (isPresent !== -1) {
        updatedRenameSection = tempRenameSection.map((section) => {
          if (section.section_id === subSection.id) {
            return { ...section, new_name: value }
          }
          return section;
        })
      } else {
        tempRenameSection.push({ section_id: subSection.id, new_name: value })
      }
    }
    return updatedRenameSection;
  }

  handleChangeCustomPhase = (value: string, questionIndex: number) => {
    const tempCustomPhaseArray = [...this.state.customPhaseList];
    const updatedPhases = tempCustomPhaseArray.map((element: CustomPhase, index: number) => {
      if (index === questionIndex) {
        return { ...element, value: value, error: this.isEmpty(value) ? "Phase name is required!" : "" }
      }
      return element;
    })
    this.setState({ customPhaseList: updatedPhases })
  }

  showFileSizeLimitError = () => {
    this.setState({ openMessage: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', messageType: "error" })
  }

  AnswerFileChange = () => {
    const file = this.ParticipantAttachmentUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) {
      this.showFileSizeLimitError();
      return;
    }
    if (file) {
      this.setState({ participantAttachmentData: [...this.state.participantAttachmentData, { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }], anchorEl: null, attachment: null })
    }

    if (this.ParticipantAttachmentUploadRef.current) {
      this.ParticipantAttachmentUploadRef.current.value = '';
    }
  }

  openLocalImageInNewTab = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const imageUrl = fileReader.result;
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`<img src="${imageUrl}" alt="Selected Image">`);
      }
    };
    fileReader.readAsDataURL(file);
  }

  openSketch = () => {
    return this.state.sketchAttachment?.isLocal ? this.openLocalImageInNewTab(this.state.sketchAttachment.file!) : window.open(this.state.sketchAttachment?.url, 'blank')
  }

  sketchUploadFileChange = (question: Question) => {
    const file = this.sketchImageUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) {
      this.setState({ message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', openMessage: true, messageType: "error" }); return;
    }
    if (file) {
      if (this.isProjectManager()) {
        this.setState((prev) => {
          return { predefinedPMAnswer: { ...prev.predefinedPMAnswer, [question.id]: [{ key: 1, content: '', ans_type: 'default', error: '' }] } }
        })
      } else {
        this.setState((prev) => { return { predefinedParticipantAnswer: { ...prev.predefinedParticipantAnswer, [question.id]: [{ key: 1, content: '', ans_type: 'default', error: '' }] } } })
      }
      this.setState({ sketchAttachment: { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }, anchorEl: null, attachment: null })
    }

    if (this.sketchImageUploadRef.current) {
      this.sketchImageUploadRef.current.value = '';
    }
  }

  ProjectManagerAttachmentFileChange = () => {
    const file = this.PMAttachmentUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) {
      this.setState({ message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', messageType: "error", openMessage: true }); return;
    }
    if (file) {
      this.setState({ projectManagerAttachmentData: [...this.state.projectManagerAttachmentData, { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }], anchorEl: null, attachment: null })
    }

    if (this.PMAttachmentUploadRef.current) {
      this.PMAttachmentUploadRef.current.value = '';
    }
  }

  removeParticipantArrayAttachment = () => {
    this.setState((prev) => {
      const filteredAttachment = prev.participantAttachmentData.filter((attachment) => attachment.id !== this.state.attachmentToRemoveId)
      if (this.state.defaultAttachments.includes(this.state.attachmentToRemoveId as number)) {
        this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.attachmentToRemoveId as number] })
      }
      return { participantAttachmentData: filteredAttachment }
    }, () => this.closeModal())

  }

  openAttachmentRemoveConfirmation = (attachmentId: number, type: "PM" | "PT") => {
    this.setState({
      openModal: true,
      modalType: 'remove-attachment',
      currentRemovingAttachmentType: type,
      attachmentToRemoveId: attachmentId,
    })
  }

  removeSketchImage = () => {
    this.saveableCanvas.current?.clear()
    this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.sketchAttachment?.id as number], isDrawing: false, sketchAttachment: null, openModal: false })
  }

  removePhase5Attachment = () => {
    this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.protoTypeAttachment?.id as number], protoTypeAttachment: null, openModal: false })
  }

  openRemovePhase5AttachmentModal = () => {
    this.setState({
      openModal: true,
      modalType: "remove-phase5-attachment",
    })
  }

  openRemoveSketchModal = () => {
    this.setState({
      openModal: true,
      modalType: "remove-sketch",
    })
  }

  removePMArrayAttachment = () => {
    this.setState((prev) => {
      const filteredAttachment = prev.projectManagerAttachmentData.filter((attachment) => attachment.id !== this.state.attachmentToRemoveId)
      if (this.state.defaultAttachments.includes(this.state.attachmentToRemoveId as number)) {
        this.setState({ attachmentsToRemove: [...this.state.attachmentsToRemove, this.state.attachmentToRemoveId as number] })
      }
      return { projectManagerAttachmentData: filteredAttachment }
    }, () => this.closeModal())
  }

  setNewMember = (member: IMemberList) => {
    this.setState({ selectedMember: { id: member.id, full_name: member.full_name, profile_image: member.profile_image, email: member.email }, anchorEl: null })
  }

  disabledAddMore = (questionId: number) => {
    let defaultAnswers = this.state.predefinedPMAnswer?.[questionId];
    if (!this.isProjectManager()) {
      defaultAnswers = this.state.predefinedParticipantAnswer?.[questionId];
    }
    const defaultFields = defaultAnswers?.filter(item => item.ans_type === "default");
    const defaultFilled = defaultFields?.every(item => item.content.trim() !== "");
    return !defaultFilled
  }

  disablePredefinedFormFields = () => {
    return this.isAuthorized() ? (this.isProjectManager() && this.state.taskDetails && this.state.taskDetails?.attributes.status !== 'to_do') || (!this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'in_progress') : true
  }

  hideCustomPhaseElements = () => {
    return this.isAuthorized() ? (this.isProjectManager() && this.state.taskDetails && this.state.taskDetails?.attributes.status !== 'to_do') || !this.isProjectManager() : true
  }

  hideSketchAction = () => {
    return this.state.sketchAttachment && this.isAuthorized() ? (this.isProjectManager() && this.state.taskDetails && this.state.taskDetails?.attributes.status !== 'to_do') || (!this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'in_progress') : true
  }

  getMenuStyle = (stateLabel: string, sortParameter: string) => {
    return stateLabel === sortParameter ? {
      backgroundColor: 'rgb(240, 232, 255)',
      borderRadius: '8px',
      fontSize: '14px',
      color: '#334155',
      fontFamily: "'Inter', sans-serif",
      lineHeight: '22px'
    } : {
      color: '#334155',
      fontFamily: "'Inter', sans-serif",
      lineHeight: '22px',
      fontSize: '14px',
      minHeight: 'auto'
    };
  }

  getTernaryBtnData = () => {
    if (this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review' || (this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'completed')) {
      return { label: 'Cancel', actionFn: () => this.cancelPredefinedEdit() }
    } else {
      return undefined
    }
  }

  handleUpdateTask = () => {
    if (this.assignPredefinedTask()) {
      this.updatePredefinedTaskDetails();
      this.updatePredefinedPMComment()
    }
  }

  handleSubmitForReview = () => {
    if (this.assignPredefinedTask()) {
      this.setState({ isSubmitForReview: true });
      this.createPredefinedTask()
    }
  }

  isSuperAdmin = () => {
    return this.state.role === 'super_admin';
  }

  isAuthorized = () => {
    const memberId = this.state.taskDetails?.attributes.member.id;
    const loginUserId = this.state.loginUserId;
    return (loginUserId === memberId || this.isProjectManager()) && !this.isProjectNotRunning()
  }

  isEnabledButton = () => {
    return this.isAuthorized() ? false : true
  }

  disabledIfPhase5CreateTask = () => {
    if (this.state.selectedPhaseName === 'Phase 5 -Prototype') {
      return !(this.state.protoTypeAttachment?.isLocal || this.state.protoTypeAttachment?.url)
    } else {
      return false;
    }
  }

  disabledUpdateDetailsForTask = () => {
    return this.disabledIfPhase5CreateTask() ? true : this.isEnabledButton()
  }

  disableComment = () => {
    return !this.state.comment.trim() || this.isSuperAdmin()
  }

  getPrimaryButtonData = () => {
    switch (true) {
      case this.isProjectManager() && this.state.isPredefinedCreation:
        return { actionFn: () => this.openAssignTaskModalIfValidate(), label: 'Assign', disabled: this.disabledIfPhase5CreateTask() }
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'to_do':
        return { actionFn: () => { this.handleUpdateTask() }, label: 'Save', disabled: this.disabledUpdateDetailsForTask() };
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'to_do':
        return { actionFn: () => { this.changeTaskStatus('in_progress') }, label: 'Move to inprogress', disabled: this.isEnabledButton() };
      case !this.state.isEditMode:
        return {
          actionFn: () => {
            this.setState({ isEditMode: true });
            if (this.state.selectedCardId) {
              this.getTaskDetails(this.state.selectedCardId)
            }
          }, label: 'View More Details'
        };
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'in_progress':
        return { actionFn: () => { }, disabled: true, label: 'Save' }
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'in_progress':
        return { actionFn: () => { this.handleSubmitForReview() }, label: this.state.taskDetails?.attributes.is_reviewed ? "Resubmit for review" : "Submit for review", disabled: this.isEnabledButton() };
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { actionFn: () => { this.setState({ openModal: true, modalType: 'move-to-completed' }) }, label: 'Mark As Completed', disabled: this.isEnabledButton() };
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { actionFn: () => { }, label: 'Submitted for review', disabled: true };
      case this.state.isEditMode && this.state.taskDetails?.attributes.status === 'completed':
        return { actionFn: () => { }, label: 'Completed', disabled: true };
      default:
        return { label: 'Save', actionFn: () => { }, disabled: true };
    }
  }

  cancelPredefinedFormFilling = () => {
    const newUrl = `${window.location.pathname}`;
    window.history.pushState(null, '', newUrl);
    this.setState({ isPredefinedFormFilling: false, selectedCardId: null, selectedTool: undefined, isEditMode: false })
    this.cancelPredefinedTask()
  }

  getSecondaryButtonData = () => {
    switch (true) {
      case this.isProjectManager() && this.state.isPredefinedCreation:
        return { actionFn: () => { this.leaveTheForm(); this.cancelPredefinedTask() }, label: 'Cancel' }
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'to_do':
        return { actionFn: () => { this.setState({ isEditMode: false }) }, label: 'Cancel', disabled: false };
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'to_do':
        return { actionFn: () => { this.setState({ isEditMode: false }) }, label: 'Cancel', disabled: false };
      case !this.state.isEditMode:
        return { actionFn: () => { this.cancelPredefinedFormFilling() }, label: 'Cancel' };
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'in_progress':
        return { actionFn: () => { this.setState({ isEditMode: false }) }, disabled: false, label: 'Cancel' }
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'in_progress':
        return { actionFn: () => { this.setState({ isEditMode: false }) }, label: 'Cancel' };
      case this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { actionFn: () => { this.setState({ isReassign: true }); this.updatePredefinedTaskDetails(); }, label: 'Re-assign Task', disabled: this.isEnabledButton() };
      case !this.isProjectManager() && this.state.isEditMode && this.state.taskDetails?.attributes.status === 'need_review':
        return { actionFn: () => { this.setState({ isEditMode: false }) }, label: 'Cancel', disabled: false };
      case this.state.isEditMode && this.state.taskDetails?.attributes.status === 'completed' && this.isProjectManager():
        return { actionFn: () => { this.setState({ isEditMode: false, isReassign: true }); this.updatePredefinedTaskDetails(); }, label: 'Re-assign Task', disabled: this.isEnabledButton() };
      case this.state.isEditMode && this.state.taskDetails?.attributes.status === 'completed' && !this.isProjectManager():
        return { actionFn: () => { this.setState({ isEditMode: false }) }, label: 'Cancel', disabled: false };
      default:
        return { label: 'Edit Task', actionFn: () => { this.setState({ isEditMode: false }) }, disabled: false };
    }
  }


  changeTaskStatus = async (changeTo: string) => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.ContentType,
    };
    const sectionId = this.state.columnStatusAndId.filter((column) => column.status === changeTo)
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changeTaskStatusFromBtnApiCallId = requestMessage.messageId;
    const httpBody = {
      "task": {
        "status": changeTo,
        "section_id": sectionId[0].id
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PUT);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.tasksEndPoint}/${this.state.selectedCardId}/status_change`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openCreatePredefinedTaskModal = () => {
    this.setState({ openModal: true, modalType: configJSON.PhaseTool }, () => { this.getPhases(); this.cancelPredefinedTask(false); })
  }

  openTaskDescriptionModal = () => {
    let error = false;
    if (this.state.selectedPhase === undefined) {
      this.setState({ phaseError: 'Please select the phase!' })
      error = true;
    }

    if (this.state.selectedTool === undefined) {
      this.setState({ toolError: 'Please select the tool!' })
      error = true;
    }
    if (error) {
      return;
    }

    this.setState({ openModal: true, modalType: configJSON.TaskDescription })
  }

  openPredefinedTaskform = () => {
    const newUrl = `${window.location.pathname}?phase=${this.state.selectedPhase}&tool=${this.state.selectedTool}`;
    window.history.pushState(null, '', newUrl);
    this.setState({ isPredefinedFormFilling: true, isPredefinedCreation: true, openModal: false, isEditMode: false })
    this.getPredefinedTaskForm()
  }

  openAddTaskDetailsModal = () => {
    this.setState({ openModal: true, modalType: configJSON.AddTaskDetails })
  }

  openAddMemberPopover = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, menuType: configJSON.AddMembers, paperStyle: { borderRadius: "16px" } })
  }

  openDueDatePopover = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, menuType: configJSON.DatePicker, tempLabelDate: this.state.labelDate, paperStyle: { borderRadius: "16px" } })
  }

  openAttachmentPopover = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, menuType: "attachment", attachmentError: '', paperStyle: { borderRadius: "16px" } })
  }

  openCommentPopover = (event: React.MouseEvent<HTMLElement>, question: Question) => {
    this.setState({ anchorEl: event.currentTarget, menuType: "comment", commentQuestionData: question, paperStyle: { borderRadius: "16px", maxWidth: '470px' } })
  }

  onChangeFeedback = (text: string) => {
    this.setState({ feedback: text })
  }

  clearFields = () => {
    this.setState({ openModal: false, anchorEl: null, dueDateAnchorEl: null, taskName: '', taskNameError: '', taskDescription: '', taskDescriptionError: '', dueDate: '', labelDate: '', subTasksArray: [], attachment: null, attachmentSize: '', autoCompleteValue: null, selectedMember: null, attachmentsToRemove: [], isLoading: false })
  }

  clearScratchModal = () => {
    this.setState({
      anchorEl: null,
      dueDateAnchorEl: null,
      taskName: '',
      taskNameError: '',
      taskDescription: '',
      taskDescriptionError: '',
      dueDate: '',
      labelDate: '',
      subTasksArray: [],
      attachment: null,
      projectManagerAttachmentData: [],
      attachmentSize: '',
      autoCompleteValue: null,
      selectedMember: null,
      attachmentsToRemove: [],
      allComments: [],
      participantAttachmentData: [],
      taskId: null,
      selectedCardId: null,
      isComplete: false,
      isEditMode: false,
      isCommentLoading: false,
      isReassign: false,
      commentPage: 1,
      totalCommentPage: 1
    })
  }

  handleFormateTime = (dateString: string) => {
    return moment(dateString).format(configJSON.taskDueDateFormat);
  }

  formatForPad = (number: string) => {
    return number.padStart(2, '0');
  }

  isEditOrAddModeOn = () => {
    return !this.state.addColumnMenu && !this.state.editColumnMenu
  }

  handleFormateProjectStartTime = (dateString: string) => {
    return moment(dateString).format(configJSON.projectStartTimeFormat)
  }

  handleCloseMessage = () => {
    this.setState({ openMessage: false, message: '', messageType: 'default' })
  }

  handleOpenStartAddNewTaskModal = () => {
    if (this.state.projectTaskDetails?.attributes.members_count !== 0 && this.state.projectTaskDetails?.attributes.status === 'In Progress') {
      this.setState({ openModal: true, modalType: 'start-add-new-task' })
    }
  }

  openAddTaskButtonPopover = () => {
    let message = "";
    if (this.state.projectTaskDetails?.attributes.members_count === 0) {
      message = configJSON.AddParticipantMessage
      this.setState({ openToolTip: true, toolTipMessage: message })
      return;
    }
    switch (this.state.projectTaskDetails?.attributes.status) {
      case 'Not Started':
        message = configJSON.NotStartedMessage;
        break;
      case 'On Hold':
        message = configJSON.OnHoldMessage;
        break;
      case 'Completed':
        message = configJSON.CompletedErrorMessage
        break;
    }
    this.setState({ openToolTip: true, toolTipMessage: message })
  }

  isProjectOnHoldOrNotStarted = () => {
    return this.state.projectTaskDetails?.attributes.status === 'On Hold' || this.state.projectTaskDetails?.attributes.status === 'Not Started'
  }

  disableDrag = () => {
    return this.state.projectTaskDetails?.attributes.status !== 'In Progress'
  }

  showErrorForTaskClickOnHold = () => {
    this.setState({ openMessage: true, messageType: 'error', message: configJSON.CannotViewTaskError })
  }

  closeAddTaskButtonPopover = () => {
    this.setState({ openToolTip: false })
  }

  openAddColumnTooltip = () => {
    const shouldShowTooltip = ["Not Started", "On Hold", "Completed"].includes(this.state.projectTaskDetails?.attributes.status!);
    if (shouldShowTooltip) {
      this.setState({ addColumnTooltip: true })
    }
  }

  closeAddColumnTooltip = () => {
    this.setState({ addColumnTooltip: false })
  }

  openColumnActionTooltip = () => {
    const shouldShowTooltip = ["Not Started", "On Hold", "Completed"].includes(this.state.projectTaskDetails?.attributes.status!);
    if (shouldShowTooltip) {
      this.setState({ columnActionTooltip: true })
    }
  }

  closeColumnActionTooltip = () => {
    this.setState({ columnActionTooltip: false })
  }

  handleOpenCreateTaskFromScratchModal = () => {
    this.setState({
      openModal: true,
      modalType: 'create-from-scratch',
      dueDate: '',
      labelDate: '',
    }, () => this.clearScratchModal())
  }

  openDatePicker: React.MouseEventHandler<HTMLDivElement> = (event) => {
    this.setState({ dueDateAnchorEl: event.currentTarget })
  }

  closeDatePickerOnBlur = () => {
    this.setState({ dueDateAnchorEl: null })
  }

  onChangeTaskTitle = (value: string) => {
    this.setState({ taskName: value, taskNameError: '' })
  }

  onChangeTaskDescription = (value: string) => {
    this.setState({ taskDescription: value, taskDescriptionError: '' })
  }

  onChangeDueDate = (date: Date) => {
    this.setState({ tempLabelDate: date, dueDateAnchorEl: null, dueDateError: '' })
  }

  onChangeScratchDueDate = (date: Date) => {
    this.setState({ labelDate: date, dueDate: moment(date).format('DD/MM/YYYY'), dueDateAnchorEl: null, anchorEl: null, dueDateError: '' })
  }

  onSaveDueDate = () => {
    if (!this.state.tempLabelDate) {
      this.setState({ dueDateError: 'Please select due date' })
      return;
    }
    this.setState({ labelDate: this.state.tempLabelDate, dueDate: moment(this.state.tempLabelDate).format('DD/MM/YYYY'), dueDateAnchorEl: null, anchorEl: null })
  }

  onChangePhase = (phaseId: unknown) => {
    this.setState({ selectedPhase: phaseId as number, phaseError: '' }, () => {
      this.setState((prev) => {
        const selectedPhase = prev.phases.filter((phase) => phase.id === prev.selectedPhase);
        return {
          toolList: selectedPhase[0].attributes.tools.map((tool) => ({ label: tool.title, value: tool.id })),
          selectedDescription: selectedPhase[0].attributes.description,
          selectedPhaseObj: selectedPhase[0],
          selectedPhaseName: selectedPhase[0].attributes.title,
          selectedTool: undefined,
          selectedToolObj: null
        }
      })
    })
  }

  onChangeTool = (toolId: unknown) => {
    this.setState((prev) => {
      if (prev.selectedPhaseObj) {
        const selectedTool = prev.selectedPhaseObj.attributes.tools.filter((tool) => tool.id === prev.selectedTool);
        return { selectedTool: toolId as number, toolError: '', selectedToolObj: selectedTool[0] }
      }
      return { ...prev }
    })
  }

  addMoreSubTask = () => {
    const isAllField = this.state.subTasksArray.every((task) => task.subtask.trim() !== "")
    if (isAllField) {
      this.setState((prev) => { return { subTasksArray: [...prev.subTasksArray, { subtask: '', id: prev.subTasksArray.length + 1, error: '' }] } })
    } else {
      this.setState((prev) => {
        const updatedSubtasks = prev.subTasksArray.map((task) => {
          if (task.subtask.trim() === "") { return { ...task, error: 'please fill the empty subtask to add more' } }
          return task;
        })
        return { subTasksArray: updatedSubtasks }
      })
    }
  }

  onChangeSubTask = (text: string, taskId: number) => {
    this.setState((prev) => {
      const updatedSubTasks = prev.subTasksArray.map((subtask) => {
        if (subtask.id === taskId) { return { ...subtask, subtask: text, error: '' } }
        return subtask;
      })
      return { subTasksArray: updatedSubTasks }
    })
  }

  removeSubtask = (subtaskId: number) => {
    this.setState((prev) => { const updatedArray = prev.subTasksArray.filter((subtask) => subtask.id !== subtaskId); return { subTasksArray: updatedArray } })
  }

  handleBrowseFiles = () => {
    this.fileUploadRef.current?.click()
  }

  handleProjectManagerBrowseFiles = () => {
    this.PMAttachmentUploadRef.current?.click()
  }

  handleParticipantBrowseFiles = () => {
    this.ParticipantAttachmentUploadRef.current?.click()
  }

  handleSketchImageBrowseFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ sketchQuestionId: Number(event.currentTarget.dataset.question) })
    this.sketchImageUploadRef.current?.click()
  }

  handleBrowsePrototypeFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.protoTypeAttachmentRef.current?.click()
  }

  handleChangeProtoTypeFileChange = (question: Question) => {
    const file = this.protoTypeAttachmentRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) { this.setState({ openMessage: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', messageType: "error" }); return; }
    if (file) {
      if (this.isProjectManager()) {
        this.setState((prev) => {
          return { predefinedPMAnswer: { ...prev.predefinedPMAnswer, [question.id]: [{ key: 1, content: '', ans_type: 'default', error: '' }] } }
        })
      } else {
        this.setState((prev) => { return { predefinedParticipantAnswer: { ...prev.predefinedParticipantAnswer, [question.id]: [{ key: 1, content: '', ans_type: 'default', error: '' }] } } })
      }
      this.setState({ protoTypeAttachment: { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, isLocal: true, url: '' }, anchorEl: null, attachment: null })
    }

    if (this.protoTypeAttachmentRef.current) {
      this.protoTypeAttachmentRef.current.value = '';
    }
  }

  handleChangeFiles = () => {
    const file = this.fileUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) {
      this.setState({ messageType: "error", openMessage: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.' }); return;
    }
    if (file) {
      this.setState({ attachment: file, attachmentSize: this.formatFileSize(file.size), attachmentError: '' });
    }

    if (this.fileUploadRef.current) {
      this.fileUploadRef.current.value = '';
    }
  }

  finalAttachmentArray = () => {
    if (this.state.attachment) {
      this.setState({
        projectManagerAttachmentData: [...this.state.projectManagerAttachmentData,
        {
          id: new Date().getTime(),
          fileName: this.state.attachment.name,
          isLocal: true,
          size: this.formatFileSize(this.state.attachment.size),
          file: this.state.attachment,
          url: ''
        }],
        anchorEl: null,
        attachment: null,
        attachmentSize: '',
        attachmentError: ''
      })
    } else {
      this.setState({ attachmentError: "Please select attachment" })
    }
  }

  handleChangeFromSratchFileChange = () => {
    const file = this.fileUploadRef.current?.files?.[0] ?? null;
    if (file && file.size > 5 * 1024 * 1024) {
      this.setState({ openMessage: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.', messageType: "error" })
      return;
    }

    if (file) {
      this.setState({ projectManagerAttachmentData: [...this.state.projectManagerAttachmentData, { id: new Date().getTime(), fileName: file.name, size: this.formatFileSize(file.size), file: file, url: '', isLocal: true }], anchorEl: null, attachment: null, attachmentSize: '' })
    }

    if (this.fileUploadRef.current) {
      this.fileUploadRef.current.value = '';
    }
  }

  removeArrayAttachment = (attachmentId: number) => {
    this.setState((prev) => {
      const filteredAttachment = prev.projectManagerAttachmentData.filter((attachment) => attachment.id !== attachmentId)
      return { projectManagerAttachmentData: filteredAttachment }
    })
  }

  formatFileSize = (size: number) => {
    const killoBytes = size / 1024;
    const megaBytes = killoBytes / 1024;
    if (megaBytes >= 1) {
      return `${megaBytes.toFixed(2)} MB`;
    } else if (killoBytes >= 1) {
      return `${killoBytes.toFixed(2)} KB`;
    } else {
      return `${size} bytes`;
    }
  };

  removeAttachment = () => {
    this.setState({ attachment: null, attachmentSize: '' })
  }

  openAssignMembersModal = () => {
    this.setState({ openModal: true, modalType: 'add-member' })
  }

  onChangeAutoComplete = (value: { title: string, icon: string } | null) => {
    if (value === null) {
      this.setState({ selectedMember: null, autoCompleteValue: null })
      return;
    }
    this.setState({ autoCompleteValue: value, noMemberSelectedError: '' },
      () => {
        if (this.state.autoCompleteValue) {
          const filteredMember = this.state.memberList.filter((member) => member.email === this.state.autoCompleteValue!.title);
          this.setState({ selectedMember: filteredMember[0], anchorEl: null })
        }
      }
    )
  }

  onClickDelete = () => {
    this.setState({ openModal: true, modalType: 'delete-task' })
  }

  onClickDuplicate = () => {
    this.goToWithParams('TaskDetailsDuplicate', JSON.stringify(this.state.taskId))
  }

  onClickRename = () => {
    this.goToWithParams("TaskDetailsRename", JSON.stringify(this.state.taskId))
  }

  isEmpty = (value: string | null) => {
    return value ? value.trim() === "" : true;
  }

  handleAddTaskDetails = () => {
    let error = false;
    if (this.isEmpty(this.state.taskName)) {
      this.setState({ taskNameError: 'Task name is required!', taskName: '' });
      error = true;
    }
    if (this.isEmpty(this.state.taskDescription)) {
      this.setState({ taskDescriptionError: 'Task description is required!', taskDescription: '' });
      error = true;
    }
    if (this.isEmpty(this.state.dueDate)) {
      this.setState({ dueDateError: 'Due date is required!' })
      error = true;
    }

    const subtaskErrors = this.state.subTasksArray.map((task) => {
      if (task.subtask.trim() === "") {
        error = true;
        return { ...task, error: 'subtask is required!', subtask: '' }
      }
      return task
    })

    this.setState({ subTasksArray: subtaskErrors });

    if (error) {
      return;
    }
    this.openAssignMembersModal();
  }

  formatPredefinedFormData = (defaultAnswers: { [key: number]: { content: string; ans_type: string; error: string; }[] } | null) => {
    const result: { [key: number]: { content: string; ans_type: string; }[] } | null = {};
    for (const keyID in defaultAnswers) {
      const ObjKey = Number(keyID)
      if (defaultAnswers[ObjKey].length > 0) {
        result[ObjKey] = defaultAnswers[ObjKey];
      }
    }
    return result
  }

  onChangeComment = (text: string) => {
    this.setState({ comment: text })
  }

  handleLoadCommentsOnScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    if (this.state.isCommentLoading) {
      return;
    }
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + offsetHeight >= scrollHeight - 100 && this.state.commentPage !== this.state.totalCommentPage) {
      this.setState((prevState) => {
        return { isCommentLoading: true, commentPage: prevState.commentPage + 1 }
      }, () => this.getComments())
    }
  };

  downloadPhase5ToolPDF = async () => {
    const formAttributes = this.state.taskDetails ? this.state.taskDetails.attributes?.tool_details?.data?.attributes : this.state.predefinedForm?.attributes;
    const query = formAttributes?.tool_name === 'Tool 1 - Simulation' ? 'simulation_tool' : 'user_journey_tool'
    if (this.isProjectManager()) {
      window.open(baseURL + '/' + `${configJSON.phase5DownloadLinkEndPoint}${query}`, '_blank')
    } else {
      window.open(this.state.PMPrototypeAttachment?.url, 'Blank')
    }
  }

  addComment = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": 'application/json'
    };
    const payload = {
      "comment": {
        "commentable_id": this.state.selectedCardId,
        "commentable_type": "BxBlockTasks::Task",
        "comment": this.state.comment.trim()
      }
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addCommentApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_comments/comments`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getComments = async () => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCommentsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_comments/comments/${this.state.selectedCardId}/task_comments/?page=${this.state.commentPage}&per_page=10`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  projectManagerPredefinedFormData = () => {
    const formDataForManager = new FormData();
    formDataForManager.append('task[tool_id]', JSON.stringify(this.state.selectedTool));
    const answer = Object.entries(this.formatPredefinedFormData(this.state.predefinedPMAnswer)).reduce((finalObj: { question_id: number; answer: { content: string | Object; ans_type: string }[] }[], [key, value], indx) => {
      finalObj[indx] = { question_id: Number(key), answer: value }
      return finalObj;
    }, [])
    formDataForManager.append('task[comments]', JSON.stringify(answer));
    formDataForManager.append('task[member_id]', JSON.stringify(this.state.selectedMember?.id));
    formDataForManager.append('task[due_date]', this.state.dueDate);
    formDataForManager.append('task[custom_sections]', JSON.stringify(this.state.customPhaseList.map((phase) => phase.value)));
    if (this.state.projectManagerAttachmentData) {
      this.state.projectManagerAttachmentData.forEach((attachment) => {
        if (attachment.file) {
          formDataForManager.append('task[pm_attachments][]', attachment.file as unknown as Blob)
        }
      }
      )
    }
    if (this.state.sketchAttachment?.file) {
      formDataForManager.append('task[question_attachment]', this.state.sketchAttachment.file as unknown as Blob)
    }

    if (this.state.protoTypeAttachment?.file) {
      formDataForManager.append('task[prototype_attachment]', this.state.protoTypeAttachment.file as unknown as Blob)
    }

    if (this.state.predefinedForm?.attributes.tool_name === 'Tool 2 - 2x2 Matrix') {
      formDataForManager.append('task[new_phase4_tool2_ideas]', JSON.stringify(this.state.customIdeas.length / 3))
    }

    if (this.state.customRounds.length) {
      formDataForManager.append('task[new_phase4_tool1_rounds]', JSON.stringify(this.state.customRounds.length))
    }

    if (this.state.predefinedForm?.attributes.tool_name === "Tool 3 - Customer Journey Map") {
      formDataForManager.append('task[default_section_1]', this.state.predefinedForm?.attributes?.sections?.[1]?.sub_sections?.[0].name)
      formDataForManager.append('task[default_section_2]', this.state.predefinedForm?.attributes?.sections?.[1]?.sub_sections?.[1].name)
    }
    return formDataForManager
  }

  participantPredefinedFormData = () => {
    const formDataForParticipant = new FormData();
    if (this.state.participantAttachmentData) {
      this.state.participantAttachmentData.forEach((attachment) => {
        if (attachment.file) {
          formDataForParticipant.append('new_participant_attachments[]', attachment.file as unknown as Blob)
        }
      })
    }
    const answer = Object.entries(this.formatPredefinedFormData(this.state.predefinedParticipantAnswer)).reduce((finalObj: { question_id: number; answer: { content: string | Object; ans_type: string }[] }[], [key, value], indx) => {
      finalObj[indx] = { question_id: Number(key), answer: value }
      return finalObj;
    }, [])
    formDataForParticipant.append('answers', JSON.stringify(answer));
    if (this.state.attachmentsToRemove.length) {
      formDataForParticipant.append('remove_attachments', JSON.stringify(this.state.attachmentsToRemove))
    }
    if (this.state.sketchAttachment?.file) {
      formDataForParticipant.append('answer_attachment', this.state.sketchAttachment.file as unknown as Blob)
    }
    if (this.state.protoTypeAttachment?.file) {
      formDataForParticipant.append('prototype_attachment', this.state.protoTypeAttachment.file as unknown as Blob)
    }
    return formDataForParticipant
  }

  createPredefinedTask = async () => {
    this.setState({ isLoading: true, openModal: false })
    const header = {
      token: await getStorageData(configJSON.Token)
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPredefinedTaskApiCallId = reqMessage.messageId;
    let endPoint = `bx_block_tasks/tasks/create_predefined_task?project_id=${this.state.projectId}`
    if (!this.isProjectManager()) {
      endPoint = `bx_block_tasks/tasks/${this.state.selectedCardId}/answer_predefined_task`
    }
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), this.isProjectManager() ? this.projectManagerPredefinedFormData() : this.participantPredefinedFormData());
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  updatePredefinedPMComment = async () => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updatePredefinedTaskCommentApiCallId = reqMessage.messageId;
    const formDataForManager = new FormData();
    const endPoint = `/bx_block_tasks/tasks/${this.state.selectedCardId}/edit_pm_comment`
    const answer = Object.entries(this.formatPredefinedFormData(this.state.predefinedPMAnswer)).reduce((finalObj: { question_id: number; answer: { content: string | Object; ans_type: string }[] }[], [key, value], indx) => {
      finalObj[indx] = { question_id: Number(key), answer: value }
      return finalObj;
    }, [])

    formDataForManager.append('comments', JSON.stringify(answer));

    if (this.state.projectManagerAttachmentData) {
      this.state.projectManagerAttachmentData.forEach((attachment) => {
        if (attachment.file) {
          formDataForManager.append('new_pm_attachments[]', attachment.file as unknown as Blob)
        }
      })
    }

    if (this.state.attachmentsToRemove.length) {
      formDataForManager.append('remove_attachments', JSON.stringify(this.state.attachmentsToRemove))
    }

    if (this.state.sketchAttachment?.file) {
      formDataForManager.append('question_attachment', this.state.sketchAttachment.file as unknown as Blob)
    }

    if (this.state.protoTypeAttachment?.file) {
      formDataForManager.append('prototype_attachment', this.state.protoTypeAttachment.file as unknown as Blob)
    }

    if (this.state.customPhaseList.length) {
      formDataForManager.append('new_custom_sections', JSON.stringify(this.state.customPhaseList.map((phase) => phase.value)));
    }

    if (this.state.renamedSections.length) {
      formDataForManager.append('custom_sections', JSON.stringify(this.state.renamedSections));
    }

    if (this.state.defaultPhasesToDelete.length) {
      formDataForManager.append('remove_custom_sections', JSON.stringify(this.state.defaultPhasesToDelete));
    }

    if (this.state.ideasToDelete.length) {
      formDataForManager.append('delete_tool_custom_questions', JSON.stringify(this.state.ideasToDelete));
    }

    if (this.state.roundsToDelete.length) {
      formDataForManager.append('remove_custom_sections', JSON.stringify(this.state.roundsToDelete));
    }

    if (this.state.customIdeas.length) {
      formDataForManager.append('new_phase4_tool2_ideas', JSON.stringify(this.state.customIdeas.length / 3))
    }

    if (this.state.customRounds.length) {
      formDataForManager.append('new_phase4_tool1_rounds', JSON.stringify(this.state.customRounds.length))
    }

    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formDataForManager);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  updatePredefinedTaskDetails = async () => {
    // this is to update the basic details like due date and attachments
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updatePredefinedTaskDetailsApiCallId = reqMessage.messageId;
    const formData = new FormData();
    formData.append('task[title]', this.state.taskName.trim());
    formData.append('task[description]', this.state.taskDescription.trim());
    formData.append('task[member_id]', JSON.stringify(this.state.selectedMember?.id));
    formData.append('task[due_date]', this.state.dueDate);
    if (this.state.taskDetails?.attributes?.tool_details?.data?.attributes.tool_name === "Tool 3 - Customer Journey Map") {
      formData.append('task[default_section_1]', this.state.taskDetails?.attributes?.tool_details?.data?.attributes.sections?.[1]?.sub_sections?.[0].name)
      formData.append('task[default_section_2]', this.state.taskDetails?.attributes?.tool_details?.data?.attributes.sections?.[1]?.sub_sections?.[1].name)
    }
    let endPoint = `bx_block_tasks/tasks/${this.state.selectedCardId}`;
    let method = configJSON.PUT
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }


  createTaskFromScratch = async () => {
    this.setState({ isLoading: true, openModal: false })
    if (!this.state.selectedMember?.id) {
      this.setState({ noMemberSelectedError: 'please select a member to create the task!' })
      return;
    }
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createTaskFromScratchApiCallId = reqMessage.messageId;
    const formData = new FormData();
    formData.append('task[title]', this.state.taskName);
    formData.append('task[description]', this.state.taskDescription);
    formData.append('task[sub_tasks_names]', JSON.stringify(this.state.subTasksArray.map((subtask) => subtask.subtask)));
    formData.append('task[member_id]', JSON.stringify(this.state.selectedMember?.id));
    formData.append('task[due_date]', this.state.dueDate);
    if (this.state.projectManagerAttachmentData) {
      this.state.projectManagerAttachmentData.forEach((attachment) =>
        formData.append('task[pm_attachments][]', attachment.file as unknown as Blob)
      )
    }

    if (this.isProjectManager()) {
      formData.append('task[feedback]', this.state.feedback?.trim());
    }

    if (this.state.participantAttachmentData) {
      this.state.participantAttachmentData.forEach((attachment) => {
        if (attachment.file)
          formData.append('task[new_participant_attachments][]', attachment.file as unknown as Blob)
      }
      )
    }

    if (this.state.attachmentsToRemove.length) {
      formData.append('task[remove_attachments]', JSON.stringify(this.state.attachmentsToRemove))
    }

    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), this.state.selectedCardId ? configJSON.PUT : configJSON.POST);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), this.state.selectedCardId ? `bx_block_tasks/tasks/${this.state.selectedCardId}` : `bx_block_tasks/tasks?project_id=${this.state.projectId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  deleteTask = async () => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteTaskApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.DELETE);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/tasks/${this.state.taskId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  changeColumnPosition = async (sectionId: number, destionation: number) => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": 'application/json'
    };
    const payload = {
      new_position: destionation + 1
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateColumnPosition = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_kanbanboard/sections/${sectionId}/update_position`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload))
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  mapColumns = (data: ProjectKanban) => {
    const { customs_columns } = data.attributes;

    const customColumns = customs_columns.map((customCol, index) => {
      return {
        id: `custom_${customCol.section.id}`,
        type: customCol.section.section_type,
        count: customCol.section.tasks.length,
        position: customCol.section.position,
        title: customCol.section.section_type === "custom" ? customCol.section.name : customCol.section.name.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase()),
        cards: customCol.section.tasks.map(task => ({
          ...task
        }))
      };
    });
    let allColumns = [...customColumns] as IColumns[];
    allColumns.sort((first, second) => first.position - second.position);
    return allColumns;
  };

  onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }
    this.setState({ dragResult: result });
    const newColumns = [...this.state.columns];
    if (type === configJSON.TypeColumn) {
      const [removed] = newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, removed);
      this.changeColumnPosition(Number(draggableId.split('_')[1]), destination.index)
    } else {
      if (destination.droppableId === source.droppableId) {
        // if the column is same
        const droppableColumnIndex = newColumns.findIndex((column) => column.id === source.droppableId)
        const [removed] = newColumns[droppableColumnIndex].cards.splice(source.index, 1);
        newColumns[droppableColumnIndex].cards.splice(destination.index, 0, removed);
      } else {
        //if moved to different column
        this.setState({ draggableId: Number(result.draggableId), droppableId: result.destination!.droppableId }, async () => {
          const draggedTask = this.state.allTasks.filter((task) => task.id === Number(draggableId));
          if (draggedTask[0].member_id === this.state.loginUserId || draggedTask[0].account_id === this.state.loginUserId) {
            this.getTaskDetails(Number(result.draggableId))
            const sourceColumnIndex = newColumns.findIndex((column) => column.id === source.droppableId)
            const droppablecolumnIndex = newColumns.findIndex((column) => column.id === destination.droppableId)
            const [removed] = newColumns[sourceColumnIndex].cards.splice(source.index, 1);
            newColumns[droppablecolumnIndex].cards.splice(destination.index, 0, removed);
          }
        })
      }
    }
    this.setState({ columns: newColumns })
  };

  getTaskDetails = async (taskId: number) => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaskDetailsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_tasks/tasks/${taskId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getTaskDetailsBySearch = async (query: string) => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaskDetailsBySearchApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.searchEndPoint}?search_type=tasks&project_id=${this.state.projectId}&query=${query}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getPhase4Drodowns = async () => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPhase4DropdownApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projecttemplates/phases/phase4_tool2_dropdown`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleOpenMemberList = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, menuType: "memberList" });
  }


  handleChangeMemberList = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, menuType: "change-member" });
  }


  getProjectTasks = async () => {
    this.setState({ isLoading: true });
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    let endPoint = `${configJSON.customColumnEndPoint}/${this.state.projectId}/${configJSON.tasks_kanban_menu}`;
    if (window.location.pathname.includes('my')) {
      endPoint = `${configJSON.customColumnEndPoint}/${this.state.projectId}/${configJSON.tasks_kanban_menu}?query=my_task`
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectTasksApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getProjectMembers = async () => {
    const header = {
      token: await getStorageData(configJSON.Token)
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectMembersApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projectportfolio/projects/${this.state.projectId}/list_project_members`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  addColumnApiCall = async (columnName: string) => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.ContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addColumnApiCallId = requestMessage.messageId;
    const httpBody = {
      "section": {
        "name": columnName.trim(),
        "sectionable_type": configJSON.sectionableType,
        "sectionable_id": this.state.projectId
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.customColumnEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  renameColumnApiCall = async (columnName: string) => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.ContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.renameColumnApiCallId = requestMessage.messageId;
    const httpBody = {
      "section": {
        "name": columnName,
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PUT);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.customColumnEndPoint}/${this.state.openEditColumnId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteColumnApiCall = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteColumnApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.DELETE);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.customColumnEndPoint}/${this.state.openEditColumnId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPhases = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPhasesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projecttemplates/phases`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPredefinedTaskForm = async () => {
    this.setState({ isLoading: true })
    const header = {
      token: await getStorageData(configJSON.Token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPredefinedTaskFormApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_projecttemplates/phases/${this.state.selectedTool}/show_tool`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isProjectManager = () => {
    return this.state.role === configJSON.ProjectManagerRole
  }

  changeCardStatus = async (taskId: number, destinationColumn: IColumns) => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.ContentType,
    };
    if (destinationColumn.type === 'default') {
      const destinationStatus = this.getCardStatus(destinationColumn.title);
      switch (true) {
        case this.state.movedCardStatus === 'to_do' && destinationStatus !== 'in_progress':
        case !this.isProjectManager() && destinationStatus === 'to_do' && this.state.movedCardStatus !== 'to_do':
        case !this.isProjectManager() && destinationStatus === 'in_progress' && this.state.movedCardStatus === 'need_review':
        case !this.isProjectManager() && destinationStatus !== 'completed' && this.state.movedCardStatus === 'completed':
        case !this.isProjectManager() && destinationStatus === 'completed':
        case destinationStatus === 'completed' && (this.state.movedCardStatus === 'in_progress' || this.state.movedCardStatus === 'to_do'):
          this.getProjectTasks();
          return;
        case this.state.taskDetails?.attributes.task_type !== 'predefined' &&
          this.state.movedCardStatus === 'in_progress' &&
          destinationStatus === 'need_review' &&
          this.state.taskDetails?.attributes.sub_tasks.some((subtask) => (subtask.status !== 'submitted_for_review')) === true:
          this.setState({ openMessage: true, messageType: 'error', message: "Cannot move the task to review as there are some subtasks that are either not submitted or have not been acted upon." })
          this.getProjectTasks();
          return;
        case this.state.taskDetails?.attributes.task_type !== 'predefined' &&
          this.state.movedCardStatus === 'need_review' &&
          destinationStatus === 'completed' &&
          this.state.taskDetails?.attributes.sub_tasks.some((subtask) => (subtask.status !== 'accepted')) === true:
          this.setState({ openMessage: true, messageType: 'error', message: "There are some subtasks that have not been reviewed yet. Please review and accept them to mark the task complete." })
          this.getProjectTasks();
          return;

      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changeTaskStatusApiCallId = requestMessage.messageId;
    const httpBody = {
      "task": {
        "status": destinationColumn.type === 'default' ? this.getCardStatus(destinationColumn.title) : this.state.movedCardStatus,
        "section_id": destinationColumn.id?.split('_')?.[1]
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PUT);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.tasksEndPoint}/${taskId}/status_change`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getCardStatus = (status: string) => {
    switch (status) {
      case "To Do": return 'to_do';
      case "In Progress": return "in_progress";
      case "Need Review": return "need_review";
      case "Completed": return "completed";
      default: return 'to_do'
    }
  }


  buttonTestIdAndMethods = () => {
    return [
      { "data-test-id": "open-create-task", "onClick": this.handleOpenStartAddNewTaskModal },
      { "data-test-id": "project-id", "onClick": this.setProjectId },
      { "data-test-id": "pm-role", "onClick": this.setPMrole },
      { "data-test-id": "pt-role", "onClick": this.setParticipantRole },
      { "data-test-id": "change-column", "onClick": () => this.changeColumnPosition(1, 2) },
      { "data-test-id": "task-predefined", "onClick": this.clickPredefinedTaskCard },
      { "data-test-id": "task-action", "data-taskid": "1", "onClick": (event: React.MouseEvent<HTMLElement>) => this.handleOpenTaskActions(event, 'scratch') },
      { "data-test-id": "change-status", "onClick": () => this.changeCardStatus(1, { id: '1', type: 'default', title: 'test', position: 1, count: null, cards: [] }) },
      { "data-test-id": "change-status2", "onClick": () => this.changeCardStatus(1, { id: '1', type: 'custom', title: 'test', position: 1, count: null, cards: [] }) },
      { "data-test-id": "rename-col", "onClick": this.renameColumnName },
      { "data-test-id": "add-col", "onClick": this.addColumn },
      { "data-test-id": "getTaskDetails", "onClick": () => this.getTaskDetails(1) },
      { "data-test-id": "col-view", "onClick": this.openAddColumnView },
      { "data-test-id": "hide-view", "onClick": this.closeAddEditColumnView },
      { "data-test-id": "add-comment", "onClick": this.addComment },
      { 'data-test-id': 'cardClickOnHold', 'onClick': this.onCardClickInOnHold }
    ];
  }

  renderModalActionBtns = ({ secondary, primary }: { primary: { actionFn: () => void, label: string }, secondary: { actionFn: () => void, label: string } }) => {
    return <Box data-test-id='modal-action' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <ModalSecondaryButton onClick={secondary.actionFn} data-test-id='cancel' >{secondary.label}</ModalSecondaryButton>
      <StyledPrimaryButton onClick={primary.actionFn} data-test-id='done' >{primary.label}</StyledPrimaryButton>
    </Box>
  }

  formatDateInPredefined = (date: string | Date | null) => {
    return moment(date).format('DD MMMM YYYY')
  }

  formatCommentTileTime = (date: string | undefined) => {
    return moment(date).format('MMM DD, YYYY h:mm A');
  }

  renderAddColumnIcon = () => {
    return !this.state.editColumnMenu && <AddCircleOutlineRoundedIcon style={{ marginRight: '10px' }} />
  }

  apiCallFunction = async (data: ApiData) => {
    const { contentType, method, endPoint, body, } = data;

    const header = {
      "Content-Type": contentType,
      "token": await getStorageData("USER_TOKEN")
    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return requestMessagee.messageId;
  };

  ContentAPICall = async () => {
    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  DataSettingAPICall = async () => {

    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  projectListResponse = async (responseJson: ProjectList2) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successOrderResponse(responseJson)
      }

    }
  }
  sectionListResponse = (responseJson: sectionList) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSectionListResponse(responseJson)
      }

    }
  }
  settingListResponse = (responseJson: settingList1) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSettingListResponse(responseJson)
      }

    }
  }
  projectList = async () => {
    this.projectListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_taskallocator/kanbanboard?from=${this.state.startDate}&to=${this.state.endDate}`,
    });
  }
  sectionList = async () => {
    this.sectionListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/sections`,
    });
  }
  settingList = async () => {
    this.settingListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/settings`,
    });
  }
  successOrderResponse(responseJson: ProjectList2) {
    this.setState(
      {
        project_in_Queue: responseJson['Project in Queue'],
        dataLength: responseJson['Project in Queue'].length
      }
    )
  }
  successSectionListResponse(responseJson: sectionList) {
    let tempArray: string[] = []
    responseJson.data.map((item: { attributes: { section_type: string } }) => {
      tempArray.push(item.attributes.section_type)
    })
    this.setState({ statusFlow: tempArray, statusData: responseJson.data })
  }

  successSettingListResponse(responseJson: settingList) {
    responseJson.data.map((item: { attributes: { active: boolean } }, index: number) => {
      if (item.attributes.active) {
        let theme = responseJson.data[index].attributes.theme
        let dirction = responseJson.data[index].attributes.direction === "vertical"
        this.setState({ theam: theme, isHorizontal: dirction })
      }
    })
  }


  goBack = () => {
    Alert.alert("Logout")
    removeStorageData("USER_TOKEN")
    const message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }
}

// Customizable Area End

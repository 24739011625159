import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  LinearProgress,
  linearProgressClasses,
  Divider,
  CircularProgress,
  MenuItem,
  // Customizable Area End
  IconButton,
} from "@mui/material";

// Customizable Area Start
import styled from "@emotion/styled";
import { calendarIcon, downloadIcon } from "./assets";
import { MoreVert } from "@mui/icons-material";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import CommonPopover from "../../../components/src/CommonPopover";
// Customizable Area End

import ImportExportDataController, {
  Props,
  configJSON,
} from "./ImportExportDataController.web";

export default class ImportExportData extends ImportExportDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  renderStatus = (status: string) => {
    let style = {};
    switch (status) {
      case 'Success': style = { backgroundColor: '#D1FAE5', color: '#059669', fontFamily: "'Inter', sans-serif", }
        break;
      case 'Partial Success': style = { backgroundColor: '#FBF7EE', color: '#D3A436', fontFamily: "'Inter', sans-serif", }
        break;
      case 'Failed': style = { backgroundColor: '#FEE2E2', color: '#F87171', fontFamily: "'Inter', sans-serif", }
        break;
    }
    return <StyledStatus style={{ ...webStyles.statusText, boxSizing: 'border-box', ...style }} >
      {status}
    </StyledStatus>
  }

  renderLoader = () => {
    return <Box style={{ width: '100%', height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px 24px 24px 28px' }} >
      <CircularProgress style={{ color: '#5B4595' }} />
    </Box>
  }

  renderSortMenu = () => {
    return <>
      <MenuItem data-test-id='sortByAZ' style={this.getMenuStyle(configJSON.SortBy.A2Z.label)} onClick={this.handleSortByAZ}  > {this.state.sortParameterLabel === 'A to Z' ? "Z to A" : "A to Z"} </MenuItem>
      <MenuItem data-test-id='sortByTime' style={this.getMenuStyle(configJSON.SortBy.Time.label)} onClick={this.handleSortByTime}  >{this.state.sortParameterLabel === 'Latest' ? "Oldest" : "Latest"}</MenuItem>
    </>
  }

  renderDownloadMenu = () => {
    return <MenuItem data-test-id='groupByOrganization'
      style={{
        backgroundColor: 'rgb(240, 232, 255)',
        borderRadius: '8px',
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
        fontSize: '14px',
        lineHeight: '22px'
      }} onClick={this.handleDownloadFile}
    >
      {configJSON.Download}
    </MenuItem>
  }

  renderMenuList = (type: string) => {
    switch (type) {
      case 'sort': return this.renderSortMenu();
      case 'download': return this.renderDownloadMenu();
      default: return <></>
    }
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardViewWrapper data-test-id='wrapper' {...this.props} pageTopBarProps={{ tabProps: { tabLabel: configJSON.UserBulkReports } }} setCurrentView={this.setCurrentView} >
        <Box style={{ display: 'flex', margin: '10px 0 0 auto' }}>
          <Typography style={{ color: '#94A3B8', fontSize: '16px', lineHeight: '28px', fontFamily: "'Inter', sans-serif", }} >Sort By :</Typography>
          <StyledSortAction data-test-id='sortBy' disableRipple onClick={this.handleOpenSortMenu} >
            {this.state.sortParameterLabel}
          </StyledSortAction>
        </Box >
        <Box sx={webStyles.ReportListScrollWrapper} onScroll={this.handleScroll} >
          {this.state.currentView === 'list' && <StyledGrid container rowSpacing={2} >
            {this.state.uploadLogsData.map((uploadData) =>
              <StyledRow data-test-id='list-card' item lg={12} xs={12} xl={12} md={12} sm={12}>
                <Grid container style={{ borderRadius: '8px', width: '100%', backgroundColor: 'white', padding: '18px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                  <Grid item xs={2} style={{ paddingRight: '10px' }} >
                    <StyledTitle  >{uploadData.attributes.title}</StyledTitle>
                    <StyledSubTitle>{uploadData.attributes.filename}</StyledSubTitle>
                  </Grid>
                  <Grid xs={3} style={{ padding: '0 10px' }}>
                    <StyledSubTitle>{this.handleFormateTime(uploadData.attributes.created_at)}</StyledSubTitle>
                  </Grid>
                  <Grid xs={2} style={{ padding: '0 10px' }}>
                    {this.renderStatus(uploadData.attributes.status)}
                  </Grid>
                  <Grid xs={3} style={{ padding: '0 10px' }}>
                    <Box style={{ width: '100%' }}>
                      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <StyledUploadStatus>{uploadData.attributes.message}</StyledUploadStatus>
                        <Typography style={{ display: 'flex' }} > <Typography style={{ ...webStyles.statusText, ...this.getProgressStyle(uploadData.attributes.created_count, uploadData.attributes.total_rows) }} > {uploadData.attributes.created_count}  </Typography><Typography style={{ ...webStyles.statusText, color: '#475569', fontWeight: 700 }} > &nbsp;/ {uploadData.attributes.total_rows}</Typography></Typography>
                      </Box>
                      <BorderLinearProgress variant="determinate" value={this.getProgress(uploadData.attributes.created_count, uploadData.attributes.total_rows)} style={{ color: '#5B4595' }} />
                    </Box>
                  </Grid>
                  <Grid xs={2} style={{ textAlign: 'right' }}>
                    <Button style={{ backgroundColor: '#7C6AAA', color: 'white', textTransform: "capitalize", height: '44px', fontFamily: "'Inter', sans-serif", }} data-test-id='download' onClick={() => this.handleClickDownload(uploadData.attributes.report_file)} >
                      <img src={downloadIcon} alt="" style={{ marginRight: '5px' }} /> {configJSON.Download}
                    </Button>
                  </Grid>
                </Grid>
              </StyledRow>)}
            {this.state.isLoading && this.state.uploadLogsData.length !== 0 ? <StyledRow item lg={12} xs={12} xl={12} md={12} sm={12}>
              {this.renderLoader()}
            </StyledRow> : null}
          </StyledGrid>}

          {this.state.currentView === 'grid' && <Grid container rowSpacing={5} columnSpacing={2} >
            {this.state.uploadLogsData.map((uploadData) => <Grid data-test-id='grid-card' item xs={12} sm={6} md={6} lg={4} xl={4} >
              <Box style={{ boxShadow: "0px 4px 8px 0px #00000008", padding: '24px', borderRadius: '8px', backgroundColor: 'white' }} >
                <Box style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box style={{ width: '80%' }} >
                    <StyledTitle >{uploadData.attributes.title}</StyledTitle>
                    <Typography style={webStyles.timeStamp} >{uploadData.attributes.filename}</Typography>
                  </Box>
                  <Box style={{ marginLeft: 'auto' }} >
                    <IconButton data-test-id='download-report' data-filelink={uploadData.attributes.report_file} onClick={this.handleOpenKebabMenu} >
                      <MoreVert />
                    </IconButton>
                  </Box>
                </Box>
                <Box style={{ marginBottom: '20px', width: 'fit-content' }} >
                  {this.renderStatus(uploadData.attributes.status)}
                </Box>
                <Box style={{ width: '100%', marginBottom: '20px' }}>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                    <Typography style={webStyles.statusText} >{uploadData.attributes.message}</Typography>
                    <Typography style={{ display: 'flex' }} > <Typography style={{ ...webStyles.statusText, ...this.getProgressStyle(uploadData.attributes.created_count, uploadData.attributes.total_rows) }} > {uploadData.attributes.created_count}  </Typography><Typography style={{ ...webStyles.statusText, color: '#475569', fontWeight: 700, fontFamily: "'Inter', sans-serif", }} > &nbsp;/ {uploadData.attributes.total_rows}</Typography></Typography>
                  </Box>
                  <BorderLinearProgress variant="determinate" value={this.getProgress(uploadData.attributes.created_count, uploadData.attributes.total_rows)} style={{ color: '#5B4595' }} />
                </Box>
                <Divider style={{ marginBottom: '20px' }} />
                <Box style={{ display: 'flex', alignItems: 'center' }} >
                  <img src={calendarIcon} style={{ marginRight: '10px' }} alt="calendar_icon" />
                  <Typography style={webStyles.timeStamp} >{this.handleFormateTime(uploadData.attributes.created_at)}</Typography>
                </Box>
              </Box>
            </Grid>)}
            {this.state.isLoading && this.state.uploadLogsData.length !== 0 ? <Grid data-test-id='grid-card' item xs={12} sm={6} md={6} lg={4} xl={4} >
              {this.renderCardLoadProgress()}
            </Grid> : null}
          </Grid>}

          {this.state.isLoading ? this.renderLoader() : null}
        </Box>
        <Typography style={{ color: '#64748B', fontSize: '12px', lineHeight: '18px', fontWeight: 400, fontFamily: "'Inter', sans-serif", }} > Showing {this.state.uploadLogsData.length} out of {this.state.totalItems} results</Typography>
        <CommonPopover data-test-id='popover' onClose={this.handleCloseKebabMenu} anchorEl={this.state.anchorEl} menuList={this.renderMenuList(this.state.menuType)} />
      </DashboardViewWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledSortAction = styled(Button)({
  color: '#475569',
  padding: '0 10px',
  fontFamily: "'Inter', sans-serif",
  fontSize: '16px',
  textTransform: "initial",
  "@media(max-width:600px)": {
    fontSize: '12px'
  }
})

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 4,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E2E8F0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5B4595',
  },
}));

const StyledGrid = styled(Grid)({
  '&.MuiGrid-root ': {
    maxWidth: 'calc(100vw - 320px)',
    '@media(max-width:960px)': {
      maxWidth: '100%',
      overflowY: 'auto'
    }
  },
});

const StyledRow = styled(Grid)({
  '@media(max-width:960px)': {
    minWidth: '960px'
  }
})

const StyledTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '28px',
  color: '#0F172A',
  fontFamily: "'Inter', sans-serif",
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:hover': {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  }
})

const StyledSubTitle = styled(Typography)({
  color: '#64748B',
  fontFamily: "'Inter', sans-serif",
  lineHeight: '22px',
  fontSize: '14px',
  fontWeight: 400,
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:hover': {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  }
})

const StyledUploadStatus = styled(Typography)({
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 400,
  color: '#64748B',
  fontFamily: "'Inter', sans-serif",
  width: '50%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '&:hover': {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  }
})

const StyledStatus = styled(Typography)({
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 400,
  color: '#64748B',
  fontFamily: "'Inter', sans-serif",
  width: 'fit-content',
  textTransform: 'capitalize',
  padding: '4px 8px',
  borderRadius: '38px'
})

const webStyles = {
  ReportListScrollWrapper: {
    height: "calc(100vh - 364px)",
    overflowY: 'auto',
    minHeight: '300px',
    '@media(max-width:960px)': {
      width: '100%',
    }
  },
  reportTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#0F172A',
    fontFamily: "'Inter', sans-serif",
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  statusText: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
  },
  timeStamp: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 400,
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  sortMenuActive: {
    backgroundColor: 'rgb(240, 232, 255)',
    borderRadius: '8px',
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    lineHeight: '22px'
  } as React.CSSProperties,
  menuItemStyle: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    lineHeight: '22px',
    minHeight: 'auto'
  },
  sortBtn: {
    color: '#475569',
    fontFamily: "'Inter', sans-serif",
    textTransform: "initial",
    padding: '0 10px',
    fontSize: '16px'
  } as React.CSSProperties,
};
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboarContentType = "application/json";
exports.dashboardGetUrl = "bx_block_dashboard/candidates/dashboard_details";
exports.notificationGetUrl = "bx_block_notifications/notifications";
exports.organizationListEndPoint = "account_block/accounts/get_organizations";
exports.organizationProjectListEndPoint = "bx_block_projectportfolio/projects/filter_project";
exports.projectPreDefinedTasksListEndPoint = "bx_block_projectportfolio/projects/";
exports.toolListEndpoint = "bx_block_projecttemplates/phases/phase4_tool2_dropdown";
exports.addProjectEndPoint = "bx_block_projectportfolio/projects";
exports.readAllNotificationsEndPoint = "bx_block_notifications/notifications/read_all"
exports.clearAllNotificationsEndPoint = "bx_block_notifications/notifications/destroy_all"
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarApiMethodType = "GET";
exports.dashboarApiMethodTypeDelete = "DELETE";
exports.dashboarApiMethodTypePatch = "PATCH";
exports.dashboarAccept = "*/*";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarConnection = "keep-alive";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.labelTitleText = "dashboard";
exports.dashboartoken = "";
exports.viewDetailsBtn = "View Details";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.baseUrl = ""
exports.candidateslabelTitleText = "Candidates";
exports.MyProfileEndPoint = "account_block/accounts/my_profile";
exports.labelBodyText = "dashboard Body";
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.Token = 'token';
exports.Projects = "Projects";
exports.Project = "Project";
exports.User = "User";
exports.Account = "Account";
exports.Task = "Task";
exports.SubTask = "SubTask";
exports.Dashboard = "Dashboard";
exports.Users = "Users";
exports.Tasks = "Tasks";
exports.Reports = "Reports";
exports.Settings = "Settings";
exports.pathProject = "projects";
exports.pathDashboard = "dashboard";
exports.pathUsers = "users";
exports.pathTask = "tasks/project-list";
exports.pathReports = "dashboard/reports";
exports.pathSettings = "settings";
exports.BlockProjects = "Projects";
exports.BlockDashboard = "Dashboard";
exports.BlockSettings = "Settings2"
exports.BlockUsers = "Users";
exports.BlockTaskProjects = "TaskProjects";
exports.BlockLogin  ="EmailAccountLoginBlock";
exports.BlockReports = "Reports";
exports.CurrentView = "currentView";
exports.Token = "token";
exports.AllTasks ="All Tasks";
exports.MyTasks = "My Tasks";
exports.pathMyTask = "tasks/my-tasks";
exports.pathAllTask = "tasks/all-tasks";
exports.logoutModal = 'Are you sure want to logout from "Innovation Hub" account?';
exports.ProjectByOrg = 'Projects By Organization';

exports.MemberRemoved = 'member_removed';
exports.PasswordChanged = 'password_changed';
exports.ProjectAssigned = 'project_assigned';
exports.TaskDeleted = 'task_deleted';
exports.TaskDelivered = 'task_delivered';
exports.TaskAccepted = 'task_accepted';
exports.TaskRejected = 'task_rejected';
exports.TaskAssigned = 'task_assigned';
exports.ProjectReminder = 'project_reminder';
exports.TaskReminder = 'task_reminder';
// Customizable Area End
import React from 'react'
import { Box, Typography, Button, styled } from "@material-ui/core";
import ForgotPasswordWebController from "./ForgotPasswordController.web";
import UnAuthorizedUIWrapper from '../../../components/src/UnAuthorizedUIWrapper';
import { keyIcon, passwordPageThumbnail } from './assets';
import InputField from '../../../components/src/InputField';
import Loader from '../../../components/src/Loader.web';

const configJSON = require("./config");

class ForgotPassword extends ForgotPasswordWebController {
    render() {
        const pageData = {
            title: this.state.emailSent ? configJSON.ResetPassword : configJSON.ForgotPassword,
            subTitle: this.state.emailSent ? <span>{configJSON.WeSent} <span style={{ color: '#5B4595', fontFamily: "'Inter', sans-serif", }} >{this.state.email}</span></span> : configJSON.NoWorries,
            buttonText: this.state.emailSent ? configJSON.BackToLogin : configJSON.ResetPassword
        }
        return <UnAuthorizedUIWrapper thumbnail={passwordPageThumbnail} >
            <Loader loading={this.state.isLoading} />
            <Box style={webStyle.pageWrapper}>
                <img style={{ width: '56px', marginBottom: '20px' }} src={keyIcon} alt="key_icon" />
                <Typography data-test-id='title' style={webStyle.pageTitle}>{pageData.title}</Typography>
                <Typography style={webStyle.pageSubTitle}>{pageData.subTitle}</Typography>
                {!this.state.emailSent && <Box sx={{ width: '100%', marginTop: '20px' }}>
                    <InputField
                        data-test-id='email'
                        fullWidth
                        label={configJSON.Email}
                        value={this.state.email}
                        helperText={this.state.emailError}
                        error={Boolean(this.state.emailError)}
                        onChange={this.handleChangeEmail}
                        placeholder={configJSON.EnterYourEmail}
                    />
                </Box>}
                <StyledButton data-test-id='sendEmail' disabled={!this.state.email} variant="contained" onClick={this.state.emailSent ? this.backToLogin : this.resetPassword} >{pageData.buttonText}</StyledButton>
            </Box>
        </UnAuthorizedUIWrapper>
    }
}

export default ForgotPassword;

const StyledButton = styled(Button)({
    marginTop: '150px',
    backgroundColor: "#5B4595",
    height: "56px", width: '100%',
    borderRadius: '8px',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: "#5B4595",
    }
})

const webStyle = {
    pageTitle: { textAlign: 'center', color: '#5B4595', fontSize: '20px', lineHeight: '28px', marginBottom: '40px', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
    pageSubTitle: { color: '#94A3B8', fontSize: '16px', fontWeight: 400, lineHeight: '24px', textAlign: 'center', fontFamily: "'Inter', sans-serif", } as React.CSSProperties,
    pageWrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center' } as React.CSSProperties
}
import React from 'react';
import { Box, Divider } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const GridCardLoader = () => {
    return <Box style={{ boxShadow: "0px 4px 8px 0px #00000008", padding: '24px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
        <Box style={{ width: '100%' }} >
            <Skeleton animation="wave" height={20} width={"50%"} style={{ marginBottom: 10 }} />
            <Skeleton animation="wave" width={"15%"} style={{ marginBottom: 30, padding: '4px 8px', borderRadius: '4px', height: '38px' }} />
            <Skeleton animation="wave" height={10} width={"80%"} style={{ marginBottom: 10 }} />
            <Divider style={{ marginBottom: '10px' }} />
            <Skeleton animation="wave" height={20} width={"80%"} style={{ marginBottom: 10 }} />
        </Box>
    </Box>
}

export default GridCardLoader;
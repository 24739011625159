import React from "react";
import { Popover, PopoverProps } from "@material-ui/core"
import { ReactNode } from "react";

interface Props {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    menuList: ReactNode;
    isOnHover?: boolean;
    style?: React.CSSProperties;
    paperStyle?: React.CSSProperties;
    anchorOrigin?: PopoverProps["anchorOrigin"];
    transformOrigin?: PopoverProps['transformOrigin'];
}

const CommonPopover = ({ anchorEl, onClose, paperStyle, menuList, isOnHover = false, style, anchorOrigin, transformOrigin }: Props) => {
    return <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        style={{ pointerEvents: isOnHover ? 'none' : 'auto', ...style }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            ...anchorOrigin
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            ...transformOrigin
        }}
        PaperProps={{
            style: { ...webStyles.menuPaperStyle, ...paperStyle }
        }}
    >
        {menuList}
    </Popover>
}
export default CommonPopover;

const webStyles = {
    menuPaperStyle: {
        border: '1px solid #E2E8F0',
        borderRadius: '8px',
        boxShadow: "0px 4px 8px 0px #00000008",
        backgroundColor: 'white',
        padding: '4px',
        color: '#334155'
    },
}
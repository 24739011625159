import React from 'react';
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import UserProfileController, { configJSON } from "./UserProfileController.web";
import { Avatar, Box, Button, Grid, IconButton, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import { Hidden, Select } from '@material-ui/core';
import InputField from '../../../components/src/InputField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styled from '@emotion/styled';
import CommonPopover from '../../../components/src/CommonPopover';
import CommonModal from '../../../components/src/CommonModal';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CommonMessage from '../../../components/src/CommonMessage';
import { MoreVert } from "@material-ui/icons";
import { user } from './assets';
import Loader from '../../../components/src/Loader.web';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

class UserProfile extends UserProfileController {
    renderModalAction = () => {
        return <Box data-test-id='modal-action-btns' style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <input
                accept="image/*"
                id="contained-button-file"
                data-test-id='file-upload'
                type="file"
                ref={this.fileUploadRef}
                style={{ display: 'none' }}
                onChange={this.handleFileChange}
            />
            <label htmlFor="contained-button-file">
                <StyledPrimaryButton data-test-id='open-file-select' onClick={this.handleOpenFile}> <FileUploadOutlinedIcon />  Upload</StyledPrimaryButton>
            </label>
            <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
                <ModalSecondaryButton onClick={this.handleCloseModal} >Cancel</ModalSecondaryButton>
                <StyledPrimaryButton disabled={Boolean(!this.state.selectedProfilePic)} data-test-id='update' onClick={this.updateProfilePic} >Update</StyledPrimaryButton>
            </Box>
        </Box>
    }
    render() {
        return <DashboardViewWrapper {...this.props} profilePic={this.state.profilePic} >
            <Typography data-test-id='page' style={webStyles.userProfile} >User Profile</Typography>
            <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
                <Box style={webStyles.profileSectionWrapper} >
                    <Box sx={webStyles.profileAndNameWrapper}>
                        <Box data-test-id='profile' onClick={this.handleOpenProfilePopover} sx={webStyles.avatarWrapper} >
                            <StyledProfile src={this.state.profilePic} alt={this.state.fullName} />
                            <EditRoundedIcon style={{ position: "absolute", bottom: '0', right: '0', color: '#475569', backgroundColor: '#EFECF4', borderRadius: '50%', padding: '5px' }} />
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column' }} >
                            <StyledName >{this.state.fullName}</StyledName>
                            <Box style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                <Typography style={webStyles.roleText} >Role : </Typography>
                                <Typography style={webStyles.roleValue} > &nbsp;{this.state.role} </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Hidden mdUp implementation="css">
                        <IconButton data-test-id='edit-sm' onClick={this.handleOpenEditProfilePopOver} ><MoreVert style={{ color: 'white' }} /></IconButton>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        {!this.state.isEditMode ? <Box style={{ display: 'flex', alignItems: 'center' }} >
                            <Typography style={webStyles.editProfileWrapper} >EDIT PROFILE</Typography>
                            <IconButton data-test-id='edit' onClick={this.editMode} style={webStyles.editIcon}>
                                <EditIcon style={{ color: 'black' }} />
                            </IconButton>
                        </Box> : null}
                    </Hidden>
                </Box>

                <Box style={webStyles.tabWrapper} >
                    <Tabs variant='scrollable' value={this.state.activeTab} onChange={this.handleChangeTab} data-test-id='tabs' TabIndicatorProps={{ hidden: true }} >
                        <Tab style={{ marginBottom: '16px', color: this.state.activeTab === 0 ? '#7C6AAA' : '#94A3B8', paddingTop: 0, paddingBottom: 0, fontFamily: "'Inter', sans-serif", textTransform: "capitalize" }} disableRipple label="Employee Details" {...this.a11yProps(0)} />
                        <Tab style={{ marginBottom: '16px', color: this.state.activeTab === 1 ? '#7C6AAA' : '#94A3B8', paddingTop: 0, paddingBottom: 0, fontFamily: "'Inter', sans-serif", textTransform: "capitalize" }} disableRipple label="Change Password" {...this.a11yProps(1)} />
                    </Tabs>
                </Box>

                <Box style={webStyles.tabItemWrapper} >
                    {this.state.activeTab === 0 ? <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                            <Grid container columnSpacing={5} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <InputField
                                        fullWidth
                                        inputProps={{ maxLength: 80 }}
                                        data-test-id='full-name'
                                        label={configJSON.FullName}
                                        value={this.state.tempfullName}
                                        onChange={this.onChangeFullName}
                                        helperText={this.state.fullNameError}
                                        error={Boolean(this.state.fullNameError)}
                                        style={{ marginBottom: '20px' }}
                                        disabled={!this.state.isEditMode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <InputField
                                        fullWidth
                                        label={configJSON.Email}
                                        value={this.state.email}
                                        endAdornment={
                                            <IconButton onClick={this.handleCopyEmail} >
                                                <ContentCopyIcon />
                                            </IconButton>}
                                        style={{ marginBottom: '20px' }}
                                        disabled
                                    />
                                </Grid>
                                {this.state.role !== 'Super Admin' && <>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <InputField
                                            disabled={!this.state.isEditMode}
                                            data-test-id='phoneNumber'
                                            label={configJSON.Phone}
                                            fullWidth
                                            value={this.state.phoneNumber}
                                            onChange={this.onChangePhoneNumber}
                                            helperText={this.state.phoneNumberError}
                                            error={Boolean(this.state.phoneNumberError)}
                                            style={{ marginBottom: '30px' }}
                                            inputProps={{ maxLength: 10, style: { paddingLeft: 0 } }}
                                            placeholder={configJSON.EnterPhoneNumber}
                                            startAdornment={<StyledSelect
                                                disabled={!this.state.isEditMode}
                                                data-test-id='countryCode'
                                                variant="outlined"
                                                IconComponent={() => <>-</>}
                                                value={this.state.countryCode}
                                                onChange={this.handleSelectPhoneInputList}
                                                displayEmpty
                                                MenuProps={{ style: { maxHeight: '400px' }, PaperProps: { style: webStyles.menuPaperStyle } }}
                                            >
                                                {this.state.countryCodeList.length > 0 ? this.state.countryCodeList.map((listItem: { label: string }) => {
                                                    return <MenuItem key={listItem.label} value={listItem.label} >{listItem.label}</MenuItem>
                                                }) : <MenuItem value={''} disabled >No Item</MenuItem>}
                                            </StyledSelect>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        {this.state.role === 'Participant' ?
                                            <InputField
                                                fullWidth
                                                value={this.state.organization}
                                                label={configJSON.OrganizationName}
                                                style={{ marginBottom: '20px' }}
                                                disabled
                                            /> :
                                            <>
                                                <Typography style={webStyles.organizationLabel} >{configJSON.OrganizationName}</Typography>
                                                <Box style={webStyles.oranizationWrapper} >
                                                    {this.state.assignedOrganizationList.map((organization) => {
                                                        return <li style={webStyles.organizationListItem} > {organization}</li>
                                                    })}
                                                </Box>
                                            </>
                                        }
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>
                        <Grid item ></Grid>
                    </Grid> : null}
                    {this.state.activeTab === 1 ? <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography style={webStyles.passwordInst} >In order to protect your account make sure your password  : </Typography>
                            <Box style={webStyles.conditionWrapprt}>
                                <ul style={{ paddingLeft: '20px', margin: '0' }} >
                                    {configJSON.PwdConstraints.map((text: string) => <li key={text} style={{ color: '#475569' }} > <Typography style={{ color: "#475569", fontSize: '14px', lineHeight: '22px', fontFamily: "'Inter', sans-serif", }} >{text} </Typography></li>)}
                                </ul>
                            </Box>
                            <InputField
                                fullWidth
                                data-test-id='old-password'
                                label={configJSON.OldPassword}
                                type={this.renderOldPasswordEye().type as "password" | "text"}
                                value={this.state.oldPassword}
                                onChange={this.onChangeOldPassword}
                                helperText={this.state.oldPasswordError}
                                error={Boolean(this.state.oldPasswordError)}
                                style={{ marginBottom: '30px' }}
                                placeholder={configJSON.EnterOldPassword}
                                endAdornment={<IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.onOldPasswordEyeClick}
                                    edge="end"
                                    disabled={!this.state.isEditMode}
                                >
                                    {this.renderOldPasswordEye().icon}
                                </IconButton>}
                                asterisk
                                disabled={!this.state.isEditMode}
                            />
                            <InputField
                                fullWidth
                                data-test-id='new-password'
                                label={configJSON.NewPassword}
                                type={this.renderNewPasswordEye().type as "password" | "text"}
                                value={this.state.newPassword}
                                helperText={this.state.newPasswordError}
                                onChange={this.onChangeNewPassword}
                                error={Boolean(this.state.newPasswordError)}
                                style={{ marginBottom: '30px' }}
                                placeholder={configJSON.EnterNewPassword}
                                asterisk
                                disabled={!this.state.isEditMode}
                                endAdornment={<IconButton
                                    disabled={!this.state.isEditMode}
                                    aria-label="toggle password visibility"
                                    onClick={this.onNewPasswordEyeClick}
                                    edge="end"
                                >
                                    {this.renderNewPasswordEye().icon}
                                </IconButton>}
                            />
                            <InputField
                                fullWidth
                                data-test-id='confirm-password'
                                label={configJSON.ConfirmPassword}
                                type={this.renderConfirmPasswordEye().type as "password" | "text"}
                                onChange={this.onChangeConfirmPassoword}
                                value={this.state.confirmPassword}
                                helperText={this.state.confirmPasswordError}
                                error={Boolean(this.state.confirmPasswordError)}
                                style={{ marginBottom: '30px' }}
                                placeholder={configJSON.EnterConfirmPassword}
                                asterisk
                                disabled={!this.state.isEditMode}
                                endAdornment={<IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.onConfirmPasswordEyeClick}
                                    edge="end"
                                    disabled={!this.state.isEditMode}
                                >
                                    {this.renderConfirmPasswordEye().icon}
                                </IconButton>}
                            />
                        </Grid>
                    </Grid> : null}
                </Box>

                {this.state.isEditMode ? <Box style={webStyles.actionWrapper} >
                    <Box style={{ marginLeft: 'auto', display: "flex", justifyContent: 'flex-end' }} >
                        <StyledSecondaryButton data-test-id='cancel' onClick={this.cancelEdit}  >Cancel</StyledSecondaryButton>
                        <StyledButton data-test-id='save' disabled={this.getIsSaveDisabled()} onClick={this.onSave}  >{this.saveBtnLabel()}</StyledButton>
                    </Box>
                </Box> : null}
            </Box>

            <CommonMessage data-test-id='message' open={this.state.showCommonMessage} messageType={this.state.messageType} message={this.state.message} onClose={this.onCloseMessage} />
            <Loader loading={this.state.isLoading} />
            <CommonPopover
                style={this.state.popOverStyle}
                data-test-id='popover'
                anchorOrigin={this.state.popOverAnchorOrigin}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopOver}
                menuList={this.renderPopOverMenu(this.state.popOverMenuType)}
            />

            <CommonModal data-test-id='modal' modalTitle={configJSON.ChangeProfilePicture} open={this.state.openModal} onClose={this.handleCloseModal} closeIcon actionComponent={this.renderModalAction()} >
                <Box style={webStyles.modalImageWrapper}>
                    <img style={{ maxWidth: '500px' }} src={this.state.selectedProfilePic as string || user} alt="" />
                </Box>
            </CommonModal>
        </DashboardViewWrapper >
    }
}

export default UserProfile;

const StyledProfile = styled(Avatar)({
    '& .MuiSvgIcon-root.MuiAvatar-fallback': {
        width: '100%'
    },
    '& .MuiAvatar-img': {
        backgroundColor: 'white'
    },
    '&.MuiAvatar-root': {
        width: '132px',
        height: "132px",
        fontWeight: 600,
        fontSize: '40px',
        textTransform: 'capitalize',
        '@media(max-width:600px)': {
            width: '100px',
            height: '100px'
        }
    }
})

const StyledName = styled(Typography)({
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    marginBottom: "10px",
    textOverflow: "ellipsis",
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    '@media(max-width:768px)': {
        fontSize: '20px',
        lineHeight: '30px'
    }
})

const StyledButton = styled(Button)({
    backgroundColor: '#5B4595',
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    height: '56px',
    fontWeight: 400,
    padding: '16px',
    width: '240px',
    borderRadius: '8px',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#5B4595'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#F1F5F9",
        color: '#64748B',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px'
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})

const StyledSecondaryButton = styled(Button)({
    backgroundColor: '#DEDAEA',
    color: '#5B4595',
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    height: '56px',
    fontWeight: 400,
    width: '240px',
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    marginRight: '20px',
    '&:hover': {
        backgroundColor: '#DEDAEA'
    },
    '&.MuiButton-root.Mui-disabled': {
        backgroundColor: "#F1F5F9",
        color: '#64748B',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px'
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})


const StyledPrimaryButton = styled(Button)({
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: '#5B4595',
    height: '56px',
    textTransform: "capitalize",
    fontWeight: 700,
    width: '120px',
    padding: '16px',
    fontSize: '16px',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#5B4595'
    },
    '&.MuiButton-root.Mui-disabled': {
        color: '#64748B',
        backgroundColor: "#F1F5F9",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px'
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})

const ModalSecondaryButton = styled(Button)({
    height: '56px',
    backgroundColor: '#DEDAEA',
    color: '#5B4595',
    fontFamily: "'Inter', sans-serif",
    textTransform: "capitalize",
    fontWeight: 700,
    width: '120px',
    padding: '16px',
    fontSize: '16px',
    borderRadius: '8px',
    marginRight: '20px',
    '&:hover': {
        backgroundColor: '#DEDAEA'
    },
    '&.MuiButton-root.Mui-disabled': {
        color: '#64748B',
        backgroundColor: "#F1F5F9",
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})

const StyledSelect = styled(Select)({
    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        paddingRight: '10px'
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    height: '56px'
})

const webStyles = {
    oranizationWrapper: {
        border: "1px solid #CBD5E1",
        maxHeight: '100px',
        overflowY: 'auto',
        borderRadius: "8px",
    } as React.CSSProperties,
    organizationListItem: {
        fontFamily: "'Inter', sans-serif",
        margin: '10px 0 10px 20px',
        color: 'rgb(51, 65, 85)',
    },
    organizationLabel: {
        fontWeight: 700,
        color: '#334155',
        lineHeight: '22px',
        fontSize: '14px',
        fontFamily: "'Inter', sans-serif"
    },
    profileAndNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        '@media(max-width:600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '14px'
        }
    },
    menuPaperStyle: {
        border: '1px solid #E2E8F0',
        borderRadius: '8px',
        boxShadow: "0px 4px 8px 0px #00000008",
        backgroundColor: 'white',
        padding: '4px',
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
    },
    avatarWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '132px',
        borderRadius: '50%',
        border: '4px solid #EFECF4',
        marginRight: "32px",
        '@media(max-width:600px)': {
            width: '100px',
            marginRight: "0px",
        }
    },
    tabWrapper: {
        padding: '0 32px',
        boxShadow: '0px 2px 8px 0px #00000014',
        height: '78px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        backgroundColor: 'white'
    },
    roleText: { color: "white", fontSize: '18px', lineHeight: '24px', fontWeight: 400, fontFamily: "'Inter', sans-serif", },
    roleValue: { color: "#CBD5E1", fontSize: '18px', lineHeight: '24px', fontWeight: 400, fontFamily: "'Inter', sans-serif", },
    editProfileWrapper: { color: 'white', fontWeight: 700, fontSize: '14px', lineHeight: '22px', marginRight: '12px', fontFamily: "'Inter', sans-serif", },
    editIcon: { borderRadius: "8px", backgroundColor: 'white', padding: "6px 4px", width: '32px', height: '32px' },
    tabItemWrapper: { padding: '32px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: "12px", backgroundColor: 'white' },
    actionWrapper: { padding: '32px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: "12px" },
    modalImageWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: "200px" },
    passwordInst: { fontSize: '16px', lineHeight: '24px', color: "#94A3B8", marginBottom: '10px', fontFamily: "'Inter', sans-serif", },
    conditionWrapprt: { display: 'flex', flexDirection: "column", alignItems: 'start', width: '100%', marginBottom: '20px' } as React.CSSProperties,
    profileSectionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px', padding: '16px 32px', backgroundColor: '#5B4595', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '12px' },
    userProfile: { color: '#5B4595', fontSize: '16px', lineHeight: '24px', marginTop: '10px', fontFamily: "'Inter', sans-serif", },
}
import React from 'react'
import { Avatar, Box, Drawer, Hidden, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, TextField, styled, Button, Popover, Typography, MenuItem, Divider, List, ListItemAvatar, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Badge } from "@material-ui/core";
import DashboardViewWrapperController, { INotifications, configJSON } from "./DashboardViewWrapperController.web";
import { addIcon, bellIcon, crossIcon, logo, logoutIcon, rightArrowIcon, searchIcon, } from './assets';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Collapse, ListItemButton, Stack } from '@mui/material';
import {
    PiNotePencilBold
} from "react-icons/pi";
import CommonModal from '../../../components/src/CommonModal';
import CommonModalAction from '../../../components/src/CommonModalAction';

const StyledInputField = styled(TextField)({
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: 'none',
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: 'none',
        borderRadius: "8px",
    },
    '& ::placeholder': {
        color: '#475569',
        fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'none',
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "20px",
        background: "#F1F5F9",
        maxWidth: '483px',
        width: '100%'
    }
});

class DashboardViewWrapper extends DashboardViewWrapperController {
    renderTabLabel = ({ count, label, subLabel }: { count: number | null | undefined, label: string | undefined, subLabel: string | undefined }) => (
        <Box style={WebStyles.tabLabelWrapper}><Typography style={{ minWidth: "100px" }}>{label} </Typography>{count ? <Typography style={WebStyles.countStyle} >{this.formatForPad(String(count))}</Typography> : null} {subLabel && <Typography style={{ width: '100px' }} noWrap={true} >{subLabel}</Typography>}</Box>
    );

    renderActionMenu = () => {
        const { backgroundColor: bgList, icon: iconList } = this.getListViewStyles()
        const { backgroundColor: bgGrid, icon: iconGrid } = this.getGridViewStyles()
        return (<>
            {!this.props.pageTopBarProps?.hideViewControlls && <Box sx={WebStyles.actionMenuWrapper} >
                <Button data-test-id='listview' style={{ ...WebStyles.viewBtnCommonStyle, backgroundColor: bgList }} onClick={this.handleListView} >
                    <img src={iconList} alt="list_icon" />
                </Button>
                <Button data-test-id='gridview' style={{ ...WebStyles.viewBtnCommonStyle, backgroundColor: bgGrid }} onClick={this.handleGridView}>
                    <img src={iconGrid} alt="grid_icon" />
                </Button>
            </Box>}
            {this.props.pageTopBarProps?.addUser && <StyledTopBarBtn data-test-id={this.props.pageTopBarProps.addUser?.testId} onClick={this.handleAddUser}   >{this.props.pageTopBarProps?.addUser.buttonTxt} <img style={{ marginLeft: '10px' }} src={addIcon} alt="" /> </StyledTopBarBtn>}
            {this.props.pageTopBarProps?.bulkInvite && <StyledTopBarBtn data-test-id={this.props.pageTopBarProps.bulkInvite?.testId} onClick={this.handleBulkInvite}>{this.props.pageTopBarProps?.bulkInvite.buttonTxt} <img style={{ marginLeft: '10px' }} src={addIcon} alt="" /> </StyledTopBarBtn>}
        </>)
    }

    renderDrawer = () => <Box data-test-id='drawer' style={WebStyles.drawerWrapper}>
        <Box>
            <Box style={WebStyles.logoWrapper}>
                <img src={logo} alt="logo" />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                {this.state.linkList.map((listItem) => (
                    <>
                        <ListItem
                            key={listItem.path}
                            style={this.getListItemStyle(listItem.path)}
                            onClick={() => this.goToBlock(listItem.block)}
                            button>
                            <ListItemIcon style={{ minWidth: '40px' }} >
                                <img src={this.isCurrentPageActive(listItem.path) ? listItem.activeIcon : listItem.icon} alt={listItem.icon} />
                            </ListItemIcon>
                            <StyledListItemText style={this.getListItemTextStyle(listItem.path)} primary={listItem.text} />
                            {listItem.hasSubmenu && <IconButton style={{ padding: 0 }} disableRipple onClick={(event) => { event.stopPropagation(); this.handleOpenSubMenu(listItem.index) }} >
                                {this.state.openSubMenuIndex === listItem.index ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                            </IconButton>}
                        </ListItem>
                        {listItem.subMenu && <Collapse in={this.state.openSubMenuIndex === listItem.index} timeout="auto" unmountOnExit>
                            {listItem.subMenu.map((submenu) => <ListItemButton key={submenu.block} sx={{ pl: 9 }} onClick={() => this.goToBlock(submenu.block)} >
                                <StyledListItemText primary={submenu.text} style={{ ...this.getListItemTextStyle(submenu.path), textTransform: 'capitalize' }} />
                            </ListItemButton>)}
                        </Collapse>}
                    </>
                ))}
            </Box>
        </Box>
        <ListItem data-test-id='logout' button onClick={this.handleChangeLogoutModal} style={{ margin: '20px 0', paddingLeft: '32px', paddingRight: '32px' }} >
            <ListItemIcon style={{ minWidth: '40px' }}>
                <img src={logoutIcon} alt="" />
            </ListItemIcon>
            <StyledListItemText style={{ color: "#94A3B8", textTransform: 'uppercase' }} primary="Logout" />
            <ListItemIcon style={{ minWidth: '20px' }}>
                <img src={rightArrowIcon} alt="" />
            </ListItemIcon>
        </ListItem>
    </Box>

    renderSearchInput = () => {
        const currentMenu = this.state.linkList.find(item => this.state.pathName.includes(item.path));
        const menuList = this.state.linkList.filter(item => [2, 3, 4].includes(item.index))
        const updatedMenuList = menuList.filter((item) => item.index !== currentMenu?.index)
        return (<><StyledInputField
            data-test-id='search'
            variant='outlined'
            placeholder='Search'
            fullWidth
            onClick={this.handleMenuSearchOpen}
            style={{ width: '100%' }}
            value={this.state.searchValue}
            onKeyDown={this.handleKeyDownForSearch}
            onChange={this.handleSearch}
            InputProps={{
                startAdornment:
                    <InputAdornment position='start'>
                        {!this.state.showSearchInMobile && <img src={searchIcon} alt="" />}
                        {
                            [2, 3, 4].includes(currentMenu?.index || 0) && <Stack direction="row" data-test-id="stackSearchMenu">
                                <Box style={{ ...WebStyles.searchMenuList, backgroundColor: "#7C6AAA", color: "white" }}>
                                    <img style={{ paddingRight: "7px", filter: "invert(100%) brightness(10)" }} src={currentMenu?.icon} alt={currentMenu?.text} />
                                    <Typography data-test-id="stackCurrentMenu" style={{ ...WebStyles.searchMenuText, color: "white" }}>{currentMenu?.text}</Typography>
                                </Box>
                            </Stack>
                        }
                    </InputAdornment>,
                endAdornment:
                    <InputAdornment position="end">
                        {(this.state.clearSearch || this.state.showSearchInMobile) && <IconButton aria-label="clear search" data-test-id="clearSearch" onClick={(e) => { e.stopPropagation(); this.handleClearSearch() }} >
                            <img src={crossIcon} alt="" />
                        </IconButton>}
                    </InputAdornment>,

            }}
        />
            <Popover
                open={Boolean(this.state.anchorElSearch)}
                PaperProps={{ style: WebStyles.menuPaperStyle }}
                anchorEl={this.state.anchorElSearch}
                onClose={this.handleMenuSearchClose}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Stack direction="row" spacing={1}>
                    {
                        updatedMenuList.map((item, idx) => {
                            return (
                                <MenuItem key={idx} data-test-id="handleChangeCurrentFilterSearch" style={WebStyles.searchMenuList} onClick={() => this.handleChangeCurrentFilterSearch(item.index, item.block)}>
                                    <img style={{ paddingRight: "7px", }} src={item.icon} alt={item.text} />
                                    <Typography style={WebStyles.searchMenuText}>{item.text}</Typography>
                                </MenuItem>)
                        })
                    }
                </Stack>
            </Popover>
        </>
        )
    }

    renderNotificationItem = (item: INotifications) => {
        const { attributes, id } = item

        const { is_read, created_at, contents, notificable_id, notificable_type, headings } = attributes
        const notiicationType = notificable_type.split("::")[1] || ""
        return (
            <>
                <ListItem
                    alignItems='flex-start' onClick={() => this.handleNavigationNotification(notiicationType, headings, notificable_id, contents)}
                    key={id}
                    data-test-id={`notificationItem${id}`}
                    style={{ backgroundColor: is_read ? "#FFFFFF" : "#F1F5F9", padding: "20px", justifyContent: "space-between", width: "100%" }}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                        <PiNotePencilBold />
                    </ListItemIcon>
                    <ListItemText
                        data-test-id="listItemPrimary"
                        secondary={<>
                            <Typography style={WebStyles.notificationText}>
                                {contents}
                            </Typography>
                            <Box style={WebStyles.notificationProjOrgWrapper}>
                                <StyledNotificationBadge> {attributes.organization_name}</StyledNotificationBadge>
                                <StyledNotificationBadge> {attributes.project_name}</StyledNotificationBadge>
                            </Box>
                        </>
                        }
                    />
                    <ListItemText style={{ display: "flex", justifyContent: "flex-end" }} primary={
                        <Typography style={WebStyles.notificationDate} noWrap>
                            {this.renderIntervalTime(created_at)}
                        </Typography>
                    } />
                </ListItem>
                <Divider />
            </>
        )
    }
    renderNotifications = () => {
        return (
            <Box style={{ maxHeight: "450px" }} data-test-id="notificationBox"
            >
                <Typography style={WebStyles.notificationTitleStyle}>Notifications</Typography>

                {this.state.notificationList.length > 0 && (<Button
                    component="button"
                    onClick={this.handleClearAllNotifications}
                    style={{ ...WebStyles.notificationButtonStyle, position: "absolute", top: '15', right: "8" }}
                    data-test-id={`notificationClearAll`}
                >
                    Clear all
                </Button>)}
                <Divider />
                <List data-test-id="notificationList" ref={this.listRef} style={{ maxHeight: "300px", overflow: "auto", margin: "0", padding: "0" }} onScroll={this.handleScroll}>
                    {
                        this.state.notificationList.length > 0 ?
                            <Box key='notifications'>
                                {this.state.notificationList.map((item) => {
                                    return this.renderNotificationItem(item)
                                })}
                                {
                                    this.state.scrollLoading && <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <CircularProgress style={{ color: '#5B4595' }} />
                                    </Box>
                                }
                            </Box>
                            :
                            <Typography key={"noNotification"} data-test-id="noNotification" style={WebStyles.notificationNotFound}>No Notifications found</Typography>
                    }
                </List>
                <Divider />
                {this.state.notificationCountNotRead > 0 && (<Button
                    style={WebStyles.notificationButtonStyle}
                    hidden={true}
                    component="button" onClick={this.handleReadAllNotifications} data-test-id={`markAllRead`}>
                    Mark all as read
                </Button>)}
            </Box>
        )
    }
    render() {
        return <Box style={WebStyles.pageWrapper}>
            <Hidden smDown implementation="css">
                {this.renderDrawer()}
            </Hidden>
            <Hidden xsUp implementation="css">
                <Drawer
                    style={{ height: '100%' }}
                    open={this.state.openDrawer}
                    onClose={this.handleOpenDrawer}
                    ModalProps={{
                        keepMounted: true,
                    }}>
                    {this.renderDrawer()}
                </Drawer>
            </Hidden>
            <Box style={WebStyles.pageContentWrapper}>
                <Box sx={WebStyles.topBar}>
                    {this.state.showSearchInMobile ? <>{this.renderSearchInput()}</> :
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: '100%' }}>
                            <Box style={{ display: "flex", alignItems: 'center', width: '483px' }}>
                                <Hidden mdUp implementation="css">
                                    <IconButton data-test-id='menuBtn' onClick={this.handleOpenDrawer} >
                                        <MenuRoundedIcon />
                                    </IconButton>
                                </Hidden>
                                <Box style={{ width: '100%' }}>
                                    <Hidden xsDown implementation="css">
                                        {this.renderSearchInput()}
                                    </Hidden>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                <Hidden smUp implementation="css">
                                    <IconButton data-test-id='searchBtn' onClick={this.handleOpenSearchInMobile}>
                                        <img src={searchIcon} alt="" />
                                    </IconButton>
                                </Hidden>
                                {!this.isSuperAdmin() && <IconButton style={{ marginRight: '10px' }} onClick={this.handleOpenNotification} data-test-id="bellButton">
                                    <Badge badgeContent={this.state.notificationCountNotRead} variant='dot' data-test-id="notificationRed" color='error'>
                                        <img src={bellIcon} alt="bell_icon" />
                                    </Badge>
                                </IconButton>}
                                {Boolean(this.state.anchorElNotification) && (<Popover
                                    style={{ margin: '0' }}
                                    data-test-id="dropdownNotification"
                                    anchorEl={this.state.anchorElNotification}
                                    keepMounted
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(this.state.anchorElNotification)}
                                    onClose={this.handleCloseNotification}
                                    PaperProps={{ style: WebStyles.menuPaperNotificationStyle }}
                                >
                                    <Box style={WebStyles.notificationModal}>
                                        {this.renderNotifications()}
                                    </Box>
                                </Popover>)}
                                <StyledProfile src={this.state.profilePic} onClick={this.handleGoToProfile} alt={this.state.fullName} />
                            </Box>
                        </Box>}
                </Box>
                {this.props.pageTopBarProps && <Box sx={WebStyles.topBarWrapper}>
                    <StyledTabs value={this.state.activeTab} onChange={this.handleChangeTab} TabIndicatorProps={{ style: { borderRadius: '15px', backgroundColor: '#7C6AAA', height: '4px' } }} >
                        <StyledTab style={{ marginBottom: '16px', color: '#7C6AAA', paddingTop: 0, paddingBottom: 0, fontFamily: "'Inter', sans-serif", textTransform: 'capitalize' }} disableRipple label={this.renderTabLabel({ count: this.props.pageTopBarProps.tabProps?.count, label: this.props.pageTopBarProps.tabProps?.tabLabel, subLabel: this.props.pageTopBarProps.tabProps?.subTabLabel })} {...this.a11yProps(0)} />
                    </StyledTabs>
                    <Box sx={WebStyles.topbarActionBtnsWrapper}>
                        <Hidden smDown >
                            {this.renderActionMenu()}
                        </Hidden>
                        <Hidden mdUp>
                            <Box></Box>
                        </Hidden>
                        <Hidden mdUp>
                            {!this.props.pageTopBarProps.hideViewControlls && <IconButton data-test-id='menuBtn' onClick={this.handleMenuClick} >
                                <MoreVertIcon />
                            </IconButton>}
                        </Hidden>
                        <Popover
                            style={{ margin: '0' }}
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleCloseMenu}
                            PaperProps={{ style: WebStyles.menuPaperStyle }}
                        >
                            <Box style={WebStyles.menuItemWrapper}>
                                {this.renderActionMenu()}
                            </Box>
                        </Popover>
                    </Box>
                </Box>}
                {this.props.children}
            </Box>
            {this.state.isLogoutModalOpen && <CommonModal data-test-id="logoutModal" modalTitle='LOGOUT' open={this.state.isLogoutModalOpen} actionComponent={<CommonModalAction data-test-id="common-action" primary={{ label: "Logout", actionFn: this.handleUserLogout }} secondary={{ label: 'Cancel', actionFn: this.handleChangeLogoutModal }} />} onClose={this.handleChangeLogoutModal} closeIcon >
                <Typography style={WebStyles.logOutText} > {configJSON.logoutModal} </Typography>
            </CommonModal>}
        </Box >
    }
}

export default DashboardViewWrapper;

const StyledNotificationBadge = styled(Typography)({
    padding: '4px 8px',
    backgroundColor: '#EFECF4',
    borderRadius: '38px',
    color: '#5B4595',
    fontFamily: "'Inter', sans-serif",
    width: 'fit-content',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
        overflow: 'visible'
    }
})

const StyledTab = styled(Tab)({
    '& .MuiTab-wrapper': {
        fontFamily: "'Inter', sans-serif",
        textTransform: "capitalize"
    },
})

const StyledTabs = styled(Tabs)({
    '&.MuiTabs-root': {
        textTransform: "capitalize",
        fontFamily: "'Inter', sans-serif",
    }
})

const StyledListItemText = styled(ListItemText)({
    '& .MuiTypography-root': {
        textTransform: "capitalize",
        fontFamily: "'Inter', sans-serif",
    }
})

const StyledProfile = styled(Avatar)({
    '& .MuiSvgIcon-root.MuiAvatar-fallback': {
        width: '100%',
        fontFamily: "'Inter', sans-serif",
    },
    '&.MuiAvatar-root': {
        width: '56px',
        height: "56px",
        fontFamily: "'Inter', sans-serif",
        fontWeight: 600,
        fontSize: '20px',
        textTransform: 'capitalize'
    },
    '& .MuiAvatar-img': {
        backgroundColor: 'white'
    },
})

const WebStyles = {
    notificationProjOrgWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
        marginTop: '5px'
    },
    topBar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        boxShadow: "0px 2px 8px 0px #00000014",
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '32px',
        height: 'fit-content',
        '@media(max-width:600px)': {
            padding: "8px 16px"
        }
    },
    pageWrapper: {
        display: 'flex',
        padding: '20px',
        gap: '20px',
        backgroundColor: '#F8FAFC',
        height: "100%"
    },
    logoWrapper: {
        borderBottom: '1px solid #F1F5F9',
        height: '120px',
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pageContentWrapper: {
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        gap: '20px',
        marginBottom: '-20px'
    } as React.CSSProperties,
    drawerWrapper: {
        minWidth: '258px',
        height: '100%',
        backgroundColor: 'white',
        // boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    } as React.CSSProperties,
    logOutText: {
        color: '#64748B',
        fontSize: '20px',
        lineHeight: '32px',
        fontFamily: "'Inter', sans-serif"
    },
    viewButton: {
        width: '44px',
        height: '44px',
        minWidth: 'auto',
    },
    topbarActionBtnsWrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        maxWidth: "458px",
        width: '100%',
        gap: '10px',
        '@media(min-width:1024px)': {
            maxWidth: "458px",
        },
        '@media(max-width:1024px)': {
            maxWidth: "400px",
        },
        '@media(max-width:960px)': {
            width: 'auto'
        }
    },
    menuPaperStyle: {
        padding: '10px',
        borderRadius: '8px',
        boxShadow: "0px 4px 8px 0px #00000008",
        border: '1px solid #E2E8F0'
    },
    menuPaperNotificationStyle: {
        borderRadius: '8px',
        boxShadow: "0px 4px 8px 0px #00000008",
        border: '1px solid #E2E8F0'
    },
    menuItemWrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        maxWidth: "380px",
        width: '100%',
        flexDirection: 'column',
        gap: '10px',
    } as React.CSSProperties,
    topBarWrapper: {
        minHeight: '78px',
        padding: "0 34px",
        boxShadow: "0px 2px 8px 0px #00000014",
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        '@media(max-width:600px)': {
            padding: "0 8px",
        }
    },
    actionMenuWrapper: {
        maxWidth: '100px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 'auto',
        '@media(max-width:960px)': {
            margin: 'auto',
        }
    },
    viewBtnCommonStyle: {
        width: '44px',
        height: '44px',
        minWidth: 'auto'
    },
    countStyle: {
        backgroundColor: '#D9B051',
        lineHeight: '32px',
        height: '32px',
        fontFamily: "'Inter', sans-serif",
        borderRadius: '100px',
        padding: "0px 16px",
        color: 'white',
        marginLeft: '20px',
        marginRight: '20px'
    },
    tabLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'space-between',
        textTransform: 'none' as const
    },
    searchMenuList: {
        backgroundColor: "#EFECF4",
        margin: "15px 5px",
        padding: "2px 20px",
        borderRadius: "8px",
        height: "31px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    searchMenuText: {
        fontWeight: "400",
        fontSize: "14px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
    },
    notificationModal: {
        width: "440px",
        maxHeight: "480px",
        '@media(max-width:400px)': {
            width: 'auto'
        }
    } as React.CSSProperties,
    notificationText: {
        fontSize: "12px",
        fontWeight: "500",
        fontFamily: "'Inter', sans-serif",
        maxWidth: "280px"
    },
    notificationTextPrimary: {
        fontSize: "14px",
        fontWeight: "600",
        fontFamily: "'Inter', sans-serif",
        maxWidth: "280px"
    },
    notificationDate: {
        color: "#94A3BB",
        fontSize: "12px",
        fontWeight: "500",
        width: '90px',
        display: "flex",
        alignItems: "start",
        justifyContent: "end",
        fontFamily: "'Inter', sans-serif",
    },
    notificationButtonStyle: {
        fontWeight: "500",
        fontSize: "14px",
        color: "#5B4595",
        fontFamily: "'Inter', sans-serif",
        textTransform: "none" as const,
        textDecoration: "underline",
    },
    notificationTitleStyle: {
        fontSize: "20px",
        fontWeight: "600",
        padding: "15px",
        fontFamily: "'Inter', sans-serif",
    },
    notificationNotFound: {
        height: "150px",
        fontSize: "20px",
        fontWeight: "600",
        color: "#5B4595",
        alignContent: "center",
        fontFamily: "'Inter', sans-serif",
        textAlign: "center" as const,
    },
    dialogTitleStyle: {
        padding: "24px 40px"
    },
    dialogTitleTextStyle: {
        fontWeight: "700",
        fontSize: "20px",
    },
    diaogContentStyle: {
        padding: "24px 40px",
        fontWeight: "400",
        fontSize: "20px",
        color: "#64748B",
        fontFamily: "Inter, sans-serif"
    },
    dialogActionsStyle: {
        textTransform: "capitalize" as const,
        padding: "12px 32px",
        fontWeight: "700",
        fontSize: "16px",
        color: "#5B4595"
    }
}

const StyledTopBarBtn = styled(Button)({
    color: 'white',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize',
    minHeight: "44px",
    fontSize: '16px',
    lignHeight: '24px',
    fontWeight: 400,
    padding: '6px 16px 4px 16px',
    width: "fit-content",
    backgroundColor: '#7C6AAA',
    '&:hover': {
        backgroundColor: '#7C6AAA',
    },
    '@media(max-width:1024px)': {
        padding: '0px 5px',
    },
    '@media(max-width:960px)': {
        width: '100%',
        padding: '6px 16px 4px 16px',
    }
})

// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  Avatar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  MenuItem,
  styled,
} from "@material-ui/core";

import DashboardPhaseController, {
IAssignees,
  Props,
} from "./DashboardPhaseController";
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import { AvatarGroup, Skeleton } from "@material-ui/lab";
import CommonPopover from "../../../components/src/CommonPopover";

export default class DashboardPhase extends DashboardPhaseController {

    constructor(props: Props) {
        super(props);
        }
    renderChip = (color: string, backgroundColor: string, text: string) => {
        return (
            <Chip data-test-id="renderChip" label={text} style={{ color, backgroundColor, minWidth: "90px"}} />
        )
    }
    renderStatusBadge = (status: string) => {
        switch(status) {
            case "Completed": 
                return this.renderChip('#059669', '#D1FAEF', status)
            case "In progress": 
            case "Need review": 
                return this.renderChip('#D3A436', '#FBF7EE', status)
            case "Not started": 
                return this.renderChip('#5B4595', '#EFECF4', status)
            case "To do":
                return this.renderChip('#5B4595', '#EFECF4', status)
            default: 
                return this.renderChip('black', 'grey', status)
        }
    }
    renderPopoverMenu = (assignes: IAssignees[]) => {
        return (
            <Box maxHeight="250px">
                {
                    assignes.slice(3).map((item, idx) => {
                        return (
                            <Box key={idx} display="flex" width="auto" alignItems="center" padding="3px 15px">
                                <Avatar style={{marginRight: "5px"}} src={item.profile_image} />
                                <Typography>{ item.name }</Typography>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }
    renderMemberList = () => {
        const data = this.state.phase.length > 0 ? this.state.phase.find(phase => phase.task_id === this.state.currentTaskId)?.assignee : []
        return  data && data.length > 0 ? data.map((member) => (
          <MenuItem data-test-id='member-list' key={member.member_id} style={{
            color: '#334155',
            fontSize: '14px',
            lineHeight: '22px',
            minHeight: 'auto',
            padding: '6px 16px'
          }} >
            <Avatar style={{ width: '24px', height: '24px', marginRight: '10px', fontWeight: 700, textTransform: 'capitalize' }} src={member.profile_image} alt={member.profile_image} />
            <Typography style={{ color: '#64748B', fontWeight: 400, fontSize: '12px', lineHeight: '22px' }} >{member.name}</Typography>
          </MenuItem>
        )) : <Box style={{ display: 'flex', alignItems: 'center', lineHeight: '22px', padding: '6px 16px' }}>
          <Skeleton style={{ marginRight: '10px', height: '30px', width: '60px' }} animation="wave" variant="circle" />
          <Skeleton animation="wave" height={20} width={"100%"} />
        </Box>
      }
    renderPhaseTable = () => {
        return (
            <Paper elevation={1} data-test-id="phaseTable" style={{ width: "100%", overflow:"hidden", borderRadius:"10px" }}>
            <TableContainer style={{ maxHeight: '90vh'}}>
                <Table stickyHeader>
                <TableHead >
                    <TableRow>
                    <TableCell style={{backgroundColor: "#F1F5F9", ...webStyles.tableHeadTitle}}>Task Name</TableCell>
                    <TableCell style={{backgroundColor: "#F1F5F9", ...webStyles.tableHeadTitle}}>Status</TableCell>
                    <TableCell style={{backgroundColor: "#F1F5F9", ...webStyles.tableHeadTitle}}>Assignee</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    this.state.phase.map((data) => {
                        return (
                        <TableRow key={data.task_id}>
                            <TableCell style={webStyles.tableBodyText}>{ data.task_name }</TableCell>
                            <TableCell style={webStyles.tableBodyText}>
                                { this.renderStatusBadge(data.status) }
                            </TableCell>
                            <TableCell style={webStyles.tableBodyText}>
                                <Box style={{ display: "flex", justifyContent: "flex-start" }}>
                                {
                                    data.assignee.length > 0 ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <StyledAvatarGroup data-test-id='avatarGroup' style={{ width: 'fit-content' }} data-projectid={data.task_id} onMouseEnter={this.handleOpenMemberList} onMouseLeave={this.handleCloseKebabMenu} max={4}>
                                        {data.assignee.map((member) => <Avatar alt={member.name} key={member.member_id} style={{ width: '32px', height: '32px', textTransform: 'capitalize' }} src={member.profile_image} />)}
                                    </StyledAvatarGroup>
                                    </Box> :
                                    <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 400, color: "#64748B" }}>No Member</Typography>
                                }
                            </Box>
                            </TableCell>
                        </TableRow>
                        )
                    })
                    }
                </TableBody>
                </Table>
                </TableContainer>
                </Paper>
        )
    }
    renderContentLoadProgress = () => <Box style={{ width: '100%' }} >
        <Skeleton animation="wave" height={70} width={"100%"} style={{ marginBottom: 3 }} />
        <Skeleton animation="wave" height={50} width={"100%"} />
        <Skeleton animation="wave" height={50} width={"100%"} />
      </Box>  
    renderReports = () => {
        return this.state.phase.length > 0 ? 
            this.renderPhaseTable()
            :
            <Box style={webStyles.superAdminResults} data-test-id="noFilter">
                <Box>
                    <Typography style={webStyles.resultTable}>No task found</Typography>
                </Box>
            </Box>
    }
  render() {
    return (
        <DashboardViewWrapper {...this.props} pageTopBarProps={{ tabProps: { tabLabel: this.state.organizationName, count: this.state.phase.length, subTabLabel:this.state.projectName }, hideViewControlls: true}}>
            {
                this.state.isLoading ? this.renderContentLoadProgress()
                : this.renderReports()
            }
            <CommonPopover data-test-id='popover' isOnHover={true} onClose={this.handleCloseKebabMenu} anchorEl={this.state.anchorEl} menuList={this.renderMemberList()} />
        </DashboardViewWrapper>
    );
  }
}
const StyledAvatarGroup = styled(AvatarGroup)({
    '& .MuiAvatar-root': {
      width: 24,
      height: 24,
    },
    '& .MuiAvatarGroup-avatar': {
      width: 32,
      height: 32,
      fontSize: '12px',
      color: '#7C6AAA',
      fontWeight: 700
    },
  });
const webStyles = {
    tableHeadTitle: {
        color: "#96A5B8",
        fontWeight: "400",
        fontSize: "14px",
        width: "33%"
    },
    tableBodyText: {
        color: "#444A6D",
        fontWeight: "400",
        fontSize: "14px"
    },
    tooltip: {
        fontWeight: "500",
        fontSize: "15px",
        fontFamily: "'Inter', sans-serif",
        color: "#241C3C",
        textAlign: "center" as const,
        backgroundColor: 'white',
      },
    resultTable: {
        fontWeight: "700",
        fontSize: "30px",
        fontFamily: "'Inter', sans-serif",
        color: "#241C3C",
        textAlign: "center" as const,
      },
    superAdminResults: {
        height: "90vh", 
        display:"flex", 
        justifyContent: "center", 
        alignItems: "center",
    },
};

// Customizable Area End

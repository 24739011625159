import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  styled,
  Avatar,
  MenuItem,
  Tooltip
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { ITask, ITaskList } from "./types";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DashboardViewWrapper from "../../../blocks/dashboard/src/DashboardViewWrapper.web";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import InputField from "../../../components/src/InputField";
import CommonDatePicker from "../../../components/src/CommonDatePicker";
import CommonModal from "../../../components/src/CommonModal";
import CommonModalAction from "../../../components/src/CommonModalAction";
import CommonPopover from "../../../components/src/CommonPopover";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommonPageActions from "../../../components/src/CommonPageActions";
import Loader from "../../../components/src/Loader.web";
import { accepted, assigned, rejected, submitted } from "./assets";
import { Skeleton } from "@material-ui/lab";
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
// Customizable Area End

export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderModalContent = () => {
    switch (this.state.modalType) {
      case configJSON.ModalType.MoveToCompleted:
        return {
          title: 'Mark as Completed Task',
          content: <Typography style={webStyle.modalInfo} >{configJSON.ModalDescription.MarkCompleted}</Typography>,
          actionComponent: <CommonModalAction data-test-id='common-modal-actions1' secondary={{ label: 'No', actionFn: this.closeModal }} primary={{ label: 'Yes', actionFn: () => { this.setState({ isAcceptEvent: true }); this.changeCardStatus("completed"); } }} />
        }
      case configJSON.ModalType.RemoveAttachment:
        return {
          title: 'Remove Attachment',
          content: <Typography style={webStyle.modalInfo}>{configJSON.ModalDescription.RemoveAttachment}</Typography>,
          actionComponent: <CommonModalAction
            data-test-id='common-modal-actions'
            primary={{
              label: 'remove',
              actionFn: this.state.currentRemovingAttachmentType === 'PM' ? this.removePMArrayAttachment : this.removeParticipantArrayAttachment
            }}
            secondary={{
              label: 'cancel', actionFn: this.closeModal
            }} />
        }
      case "remove-subtask-attachment":
        return {
          title: 'Remove Attachment',
          content: <Typography style={webStyle.modalInfo}>{configJSON.ModalDescription.RemoveAttachment}</Typography>,
          actionComponent: <CommonModalAction
            data-test-id='common-modal-actions'
            primary={{
              label: 'remove',
              actionFn: this.removeSubTaskAttachment
            }}
            secondary={{
              label: 'cancel', actionFn: this.closeModal
            }} />
        }
      default:
        return {
          title: '',
          content: undefined,
          actionComponent: undefined
        }
    }
  }

  renderParticipantAttachment = () => {
    return <>{this.state.participantAttachmentData.length > 0 ?
      <>
        {this.state.participantAttachmentData.map((attachment) => (<Box sx={webStyle.participantAttachmentWrapper}>
          <Box sx={webStyle.participantAttachmentInnerWrapper}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <AttachFileIcon />
              <Box>
                <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{attachment.fileName}</Typography>
                <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{attachment.size}</Typography>
              </Box>
            </Box>
            <Box>
              <StyledSecondaryButton disabled={attachment.isLocal} onClick={() => window.open(attachment.url, 'blank')} style={{ ...webStyle.browseBtn, borderRadius: '4px' }}>View</StyledSecondaryButton>
              {this.state.isEditMode && !this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'need_review' && <IconButton data-test-id='answer-remove' onClick={() => this.openAttachmentRemoveConfirmation(attachment.id, 'PT')} >
                <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
              </IconButton>}
            </Box>
          </Box>
        </Box>))}
      </> :
      this.isProjectManager() && (<Box>
        <Typography style={subtaskWebStyle.attachmentTitle}>Attachments</Typography>
        <Typography style={{ color: '#334155', fontWeight: 700, fontFamily: "'Inter', sans-serif", }}> No Attachment</Typography>
      </Box>)}</>
  }

  renderSubtaskStatus = (status: string) => {
    switch (status) {
      case 'assigned':
        return { image: assigned, label: 'Assigned' };
      case 'submitted_for_review':
        return { image: submitted, label: 'Submitted for review' }
      case 'accepted':
        return { image: accepted, label: 'Accepted' }
      case 'rejected':
        return { image: rejected, label: 'Rejected' }
    }
  }

  renderSubTask = () => {
    return !this.state.isEditMode || !this.isProjectManager() || this.state.taskDetails?.attributes.status === 'need_review' ?
      <>{this.state.subTasksArray.length > 0 ?
        <Box sx={{ margin: '0', display: 'flex', flexDirection: 'column', gap: '20px' }} >
          {this.state.taskDetails?.attributes.sub_tasks.map((subtask, idex) => <Box key={subtask.id} style={webStyle.subTaskWrapper} >
            <Box>
              <Typography style={webStyle.subtaskTitle} > Subtask_{`${idex + 1}`.padStart(2, '0')} </Typography>
              <Typography style={{ color: '#64748B', fontSize: '16px', fontFamily: "'Inter', sans-serif", }} >{subtask.name} </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography data-test-id='view-more' style={webStyle.viewMore} onClick={() => this.clickSubTask(subtask.id)} >View</Typography>
              <Box style={webStyle.toolTipWrapper}>
                <Tooltip title={this.renderSubtaskStatus(subtask.status)?.label} slotProps={{ tooltip: { style: { background: 'black' } }, arrow: { style: { color: 'black' } } }} arrow>
                  <img src={this.renderSubtaskStatus(subtask.status)?.image} alt="" />
                </Tooltip>
              </Box>
            </Box>
          </Box>)}
        </Box> : <Typography style={{ color: '#334155', fontWeight: 400, fontFamily: "'Inter', sans-serif", }}>No Subtasks</Typography>}</>
      : <>
        <Box style={webStyle.subtaskEditWrapper} >
          {this.state.subTasksArray.map((subtasks, indexNumber) => {
            return <Box sx={webStyle.subtaskInputFieldWrapper} >
              <InputField
                fullWidth
                inputProps={{ maxLength: 100 }}
                data-test-id='sub-task'
                label={`Subtask - ${indexNumber + 1}`}
                value={subtasks.subtask}
                disabled={this.disabledFields()}
                placeholder="Enter subtask"
                onChange={(text) => this.onChangeSubTask(text, subtasks.id)}
                error={Boolean(subtasks.error)}
                helperText={subtasks.error}
                style={{ marginBottom: '20px' }}
              />
              {this.isProjectManager() && <IconButton data-test-id='delete-subtask' style={{ marginLeft: '10px' }} onClick={() => this.removeSubtask(subtasks.id)} >
                <DeleteOutlineRoundedIcon style={{ color: 'red', fontSize: '30px' }} />
              </IconButton>}
            </Box>
          })}
        </Box>
        {!this.disabledFields() && <Button onClick={this.addMoreSubTask} data-test-id='add-members' style={{ textTransform: 'capitalize', marginLeft: 'auto' }}  >
          <Typography style={webStyle.addSubTask} >
            <AddCircleOutlineRoundedIcon style={{ fontWeight: 700, marginRight: '10px' }} /> Add Subtask</Typography>
        </Button>}
        {!this.state.isDuplicate && !this.state.isEdit && this.state.subTasksArray.length === 0 && <Typography style={{ color: '#64748B', fontWeight: 400, fontFamily: "'Inter', sans-serif", }}>No Subtasks</Typography>}
      </>
  }

  renderAnswerAttachment = () => {
    return !(!this.state.taskDetails?.attributes.is_reviewed && this.isProjectManager()) ? <Box style={webStyle.answerAttachmentWrapper} >
      {!this.isProjectManager() && <InputField
        fullWidth
        label="Attachment"
        placeholder="Add attchment"
        readOnly
        style={{ marginBottom: '20px' }}
        endAdornment={<>
          <input
            accept={configJSON.AcceptTypes}
            id="contained-button-file"
            data-test-id='file-upload'
            type="file"
            ref={this.ParticipantAttachmentUploadRef}
            style={{ display: 'none' }}
            onChange={this.AnswerFileChange}
          />
          <label htmlFor="contained-button-file">
            <StyledSecondaryButton onClick={this.handleParticipantBrowseFiles} disabled={this.state.isRename || !this.state.isEditMode || this.state.taskDetails?.attributes.status === 'need_review' || this.state.participantAttachmentData.length === 7} style={webStyle.browseBtn}>Browse</StyledSecondaryButton>
          </label>
        </>}
      />}
      {this.renderParticipantAttachment()}
    </Box> : null
  }

  renderAttachments = () => {
    return <Box style={{ display: 'flex', flexDirection: 'column' }} >
      <Typography style={webStyle.title} >Attachment</Typography>
      <input
        accept={configJSON.AcceptTypes}
        id="contained-button-file"
        data-test-id='file-upload'
        type="file"
        disabled={this.disabledFields() || !this.state.isEditMode}
        ref={this.PMAttachmentUploadRef}
        style={{ display: 'none' }}
        onChange={this.ProjectManagerAttachmentFileChange}
      />
      {this.isProjectManager() && <label htmlFor="contained-button-file" style={{ width: 'fit-content', marginBottom: '20px' }}>
        <StyledSecondaryButton disabled={this.disabledFields() || !this.state.isEditMode || this.state.projectManagerAttachmentData.length === 7} data-test-id='browse' style={{ width: '124px', height: '40px', marginRight: '0' }} onClick={this.handleBrowseFiles} >Browse</StyledSecondaryButton>
      </label>}
      {this.state.projectManagerAttachmentData.length > 0 ? <Box style={webStyle.PMAttachmentWrapper} >
        {this.state.projectManagerAttachmentData.map((attachment) => <Box>
          <Button disabled={attachment.isLocal} onClick={() => window.open(attachment.url, 'Blank')} style={webStyle.downloadAttachmentWrapper}>
            {!attachment.isLocal && <SaveAltRoundedIcon style={{ fontSize: '20px', marginRight: '5px' }} />} {attachment.fileName}
          </Button>
          {(!this.disabledFields() && this.state.isEditMode) && <IconButton data-test-id='remove-attachment' onClick={() => this.openAttachmentRemoveConfirmation(attachment.id, 'PM')} > <CloseIcon style={{ color: '#334155' }} /> </IconButton>}
        </Box>)}
      </Box> : !this.isProjectManager() && <Typography style={{ color: '#334155', fontWeight: 400, fontSize: '16px', fontFamily: "'Inter', sans-serif", }}>No Attachment</Typography>}
    </Box>
  }

  renderSkeletonForComments = () => {
    return <Box style={{ width: '100%', height: "100px", display: 'flex', flexDirection: 'column', padding: '24px 24px 10px 0px' }} >
      <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
        <Skeleton style={{ marginRight: '10px', height: '40px', width: '40px' }} animation="wave" variant="circle" />
        <Skeleton animation="wave" height={20} width={"30%"} style={{ marginBottom: 10 }} />
      </Box>
      <Skeleton animation="wave" height={16} width={"100%"} />
      <Skeleton animation="wave" height={16} width={"100%"} />
      <Skeleton animation="wave" height={16} width={"50%"} />
    </Box>
  }

  renderComments = () => {
    return !this.state.isDuplicate ?
      <Box sx={webStyle.boxWrapper}>
        <Typography style={webStyle.feedbackWrapper}>Comments</Typography>
        <Box>
          {this.state.allComments.length > 0 && <Box data-test-id='comment-wrapper' sx={{ maxHeight: '355px', overflowY: 'auto', margin: '10px 0 15px' }} onScroll={this.handleLoadCommentsOnScroll}>
            {this.state.allComments.map((comment, indx) => {
              return <>
                <Box sx={webStyle.commentUserWrapper}>
                  <Avatar style={{ width: '24px', height: '24px' }} src={comment.attributes.account.profile_image} alt={comment.attributes.account.full_name} />
                  <Typography style={webStyle.commentUserName} >{comment.attributes.account.full_name}</Typography>
                  <Typography style={{ fontSize: '12px', color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{this.formatCommentTileTime(comment.attributes.created_at)}</Typography>
                </Box>
                <Typography style={webStyle.commentText} >{comment.attributes.comment}</Typography>
                {indx + 1 !== this.state.allComments.length && <Divider style={{ margin: '10px 0' }} />}
              </>
            })}
            {this.state.isCommentLoading ? this.renderSkeletonForComments() : null}
          </Box>}
          <InputField
            multiline
            inputProps={{ maxLength: 300, style: { maxHeight: '100px', overflow: 'auto' } }}
            data-test-id='comment'
            onChange={this.onChangeComment}
            value={this.state.comment}
            endAdornment={<StyledSecondaryButton disabled={this.disableComment()} onClick={() => this.addComment('task')} style={{ ...webStyle.browseBtn, borderRadius: '4px' }}>Add</StyledSecondaryButton>}
            placeholder="Add your comment here..."
          />
        </Box>
      </Box> : null
  }

  // subtask render components 

  renderSubTaskComments = () => {
    return !this.state.isDuplicate ?
      <Box sx={webStyle.boxWrapper}>
        <Typography style={webStyle.feedbackWrapper}>Comments</Typography>
        <Box>
          {this.state.allSubTaskComments.length > 0 && <Box data-test-id='comment-wrapper' sx={{ maxHeight: '355px', overflowY: 'auto', margin: '10px 0 15px' }} onScroll={this.handleLoadSubtaskCommentsOnScroll}>
            {this.state.allSubTaskComments.map((comment, indx) => {
              return <>
                <Box sx={webStyle.commentUserWrapper}>
                  <Avatar style={{ width: '24px', height: '24px' }} src={comment.attributes.account.profile_image} alt={comment.attributes.account.full_name} />
                  <Typography style={{ color: '#0F172A', fontSize: '12px', fontWeight: 600, fontFamily: "'Inter', sans-serif", }} >{comment.attributes.account.full_name}</Typography>
                  <Typography style={{ fontSize: '12px', color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{this.formatCommentTileTime(comment.attributes.created_at)}</Typography>
                </Box>
                <Typography style={webStyle.commentText} >{comment.attributes.comment}</Typography>
                {indx + 1 !== this.state.allSubTaskComments.length && <Divider style={{ margin: '10px 0' }} />}
              </>
            })}
            {this.state.isSubTaskCommentLoading ? this.renderSkeletonForComments() : null}
          </Box>}
          <InputField
            multiline
            data-test-id='comment'
            inputProps={{ maxLength: 300, style: { maxHeight: '100px', overflow: 'auto' } }}
            value={this.state.comment}
            onChange={this.onChangeComment}
            placeholder="Add your comment here..."
            endAdornment={<StyledSecondaryButton disabled={this.disableComment()} onClick={() => this.addComment('subtask')} style={{ ...webStyle.browseBtn, borderRadius: '4px' }}>Add</StyledSecondaryButton>}
          />
        </Box>
      </Box> : null
  }

  renderParticipantAttachmentAction = (attachment: { id: number, url: string }) => {
    return !this.isProjectManager() ? <IconButton disabled={this.state.taskDetails?.attributes.status !== "in_progress"} data-test-id='answer-remove' onClick={() => this.openRemoveSubtaskAttachmentModal(attachment.id)} >
      <DeleteOutlineRoundedIcon style={{ fontSize: '30px' }} />
    </IconButton> : null
  }

  renderSubTaskParticipantAttachment = () => {
    return <>{this.state.attachments.length > 0 ?
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {this.state.attachments.map((attachment) => (<Box sx={subtaskWebStyle.participantAttachmentWrapper}>
          <Box sx={subtaskWebStyle.participantAttachmentDataWrapper}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <AttachFileIcon />
              <Box>
                <Typography style={{ color: '#0F172A', fontFamily: "'Inter', sans-serif", }} >{attachment.fileName}</Typography>
                <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", }} >{attachment.size}</Typography>
              </Box>
            </Box>
            <Box>
              <StyledSecondaryButton disabled={attachment.isLocal} onClick={() => window.open(attachment.url, 'blank')} style={{ ...subtaskWebStyle.browseBtn, borderRadius: '4px' }}>View</StyledSecondaryButton>
              {this.state.taskDetails?.attributes.status === 'in_progress' && (this.state.subtaskDetails?.attributes.status === 'assigned' || this.state.subtaskDetails?.attributes.status === 'rejected') ? this.renderParticipantAttachmentAction(attachment) : null}
            </Box>
          </Box>
        </Box>))}
      </Box> :
      this.isProjectManager() && (<>
        <Typography style={subtaskWebStyle.attachmentTitle}>Attachments</Typography>
        <Typography style={{ color: '#334155', fontWeight: 700, fontSize: '16px', fontFamily: "'Inter', sans-serif", }} > No Attachment</Typography>
      </>)}
    </>
  }

  renderSubtaskAnswerAttachment = () => {
    return this.state.taskDetails?.attributes.is_reviewed || (!this.state.taskDetails?.attributes.is_reviewed && !this.isProjectManager()) ?
      <Box style={subtaskWebStyle.boxWrapper} >
        {!this.isProjectManager() && <InputField
          fullWidth
          label="Attachment"
          placeholder="Add attchment"
          readOnly
          style={{ marginBottom: '20px' }}
          endAdornment={<>
            <input
              accept={configJSON.AcceptTypes}
              id="contained-button-file"
              data-test-id='file-upload'
              type="file"
              disabled={this.disabledBrowse()}
              ref={this.AttachmentRef}
              style={{ display: 'none' }}
              onChange={this.fileChange}
            />
            <label htmlFor="contained-button-file">
              <StyledSecondaryButton disabled={this.disabledBrowse()} onClick={this.browseFiles} style={subtaskWebStyle.browseBtn}>Browse</StyledSecondaryButton>
            </label>
          </>}
        />}
        {this.renderSubTaskParticipantAttachment()}
      </Box>
      : null
  }

  renderSubtaskComponent = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box style={subtaskWebStyle.pageWrapper} >
        <Box sx={{ padding: '0 32px 32px 32px', }}>
          <Typography style={subtaskWebStyle.projectNameWrapper}>{this.state.projectName} - {this.state.taskName}</Typography>
          {!this.isProjectManager() || (this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'to_do') ?
            <Typography style={subtaskWebStyle.subtaskNameWrapper}>
              {this.state.subtaskName}
            </Typography> :
            <InputField
              asterisk
              disabled={this.disabledSubTaskFields()}
              placeholder="Enter Subtask name"
              label="Subtask Title"
              data-test-id='title'
              value={this.state.subtaskName}
              error={Boolean(this.state.subtaskNameError)}
              helperText={this.state.subtaskNameError}
              onChange={this.onChangeSubTaskName}
              inputProps={{ maxLength: 80 }}
            />}
        </Box>
        <Divider />
        <Box sx={{ padding: '32px 32px 0' }}>
          {!this.isProjectManager() || (this.isProjectManager() && this.state.taskDetails?.attributes.status !== 'to_do') ? <>
            <Typography style={subtaskWebStyle.title}> Subtask Description </Typography>
            <Typography style={subtaskWebStyle.description} >
              {this.state.subtaskDescription?.length ? this.state.subtaskDescription : 'No Description'}
            </Typography>
          </> :
            <InputField
              label="Subtask Description"
              multiline
              data-test-id='description'
              disabled={this.disabledSubTaskFields()}
              style={{ marginBottom: '20px' }}
              inputProps={{ maxLength: 1000 }}
              minRows={3}
              value={this.state.subtaskDescription}
              onChange={this.onChangeSubTaskDescription}
              placeholder="Enter subtask description"
            />}
        </Box>
      </Box>

      {this.renderSubtaskAnswerAttachment()}

      {this.renderSubTaskComments()}

      <Box style={subtaskWebStyle.actionButtonWrapper}>
        <Box style={{ marginLeft: 'auto', display: "flex", justifyContent: 'flex-end' }} >
          <StyledSecondaryButton data-test-id='cancel' onClick={this.cancelBtnData().actionFn} >{this.cancelBtnData().label}</StyledSecondaryButton>
          {this.state.subtaskDetails?.attributes.status !== 'assigned' &&
            this.state.taskDetails?.attributes.status !== 'in_progress' ?
            <StyledPrimaryButton data-test-id='status' disabled={this.disabledStatus()} onClick={this.handleOpenReviewActions} style={{ border: '1px solid #E2E8F0', borderRadius: '8px', textTransform: 'capitalize' }}  >
              {this.state.reviewOptions.length > 0 ? this.state.reviewOptions.filter((status) => status.value === this.state.subtaskStatus)?.[0]?.label : 'Assigned'}
              <KeyboardArrowDownRoundedIcon style={{ color: "#94A3B8" }} />
            </StyledPrimaryButton> :
            <StyledPrimaryButton disabled={this.disabledSubmitBtn()} data-test-id='submit' onClick={this.onSave} > {this.getPrimaryActionButtonLabel()}</StyledPrimaryButton>}
        </Box>
      </Box>

      <Box sx={{ display: 'none' }}>
        <input accept=".pdf" id="contained-button-file" data-test-id='file-upload' type="file" ref={this.AttachmentRef} style={{ display: 'none' }} onChange={this.fileChange} />
        <label htmlFor="contained-button-file">
          <StyledSecondaryButton data-test-id='browse' disabled={!this.isProjectManager()} onClick={this.browseFiles} style={subtaskWebStyle.browseBtn}>Browse</StyledSecondaryButton>
        </label>
      </Box>

      <CommonPopover
        data-test-id='subtask-popover'
        anchorEl={this.state.anchorSubtaskEl}
        onClose={this.handleClosePopover}
        menuList={this.state.reviewOptions.map((status, itemIndex) => (<MenuItem
          data-test-id='status-list'
          disabled={itemIndex === 0 || itemIndex === 1}
          style={subtaskWebStyle.menuStyle}
          onClick={() => this.onClickStatus(status.value)}>{status.label} </MenuItem>))}
      />
    </Box>
  }

  // subtask render components end

  renderTaskComponent = () => {
    return <Box style={webStyle.pageWrapper} >
      <Box style={webStyle.taskInfoWrapper}>
        <Typography data-test-id='project-title' style={webStyle.mainLabel} >{this.state.projectName}</Typography>
        <Box style={webStyle.editModeFieldsWrapper} >
          {!(this.state.isEditMode && this.isProjectManager()) ?
            <>
              <Typography style={webStyle.title} >{"Task Name"}</Typography>
              <Typography style={webStyle.value} >{this.state.taskDetails?.attributes.title}</Typography>
            </>
            : <InputField
              asterisk
              label="Task Name"
              data-test-id='task-name'
              disabled={this.isAuthorized() ? !this.isProjectManager() || this.state.taskDetails?.attributes.status === 'need_review' : true}
              placeholder="Enter Task name"
              inputProps={{ maxLength: 80 }}
              onChange={this.onChangeTaskTitle}
              value={this.state.taskName}
              error={Boolean(this.state.taskNameError)}
              helperText={this.state.taskNameError}
            />}
        </Box>

        <Box style={webStyle.editModeFieldsWrapper} >
          {!(this.state.isEditMode && this.isProjectManager()) ?
            <>
              <Typography style={webStyle.title} >{"Task Description"}</Typography>
              <Typography style={webStyle.value} >{this.state.taskDetails?.attributes.description}</Typography>
            </>
            : <InputField
              asterisk
              label="Task Description"
              data-test-id='task-description'
              value={this.state.taskDescription}
              disabled={this.disabledFields()}
              multiline
              minRows={4}
              onChange={this.onChangeTaskDescription}
              placeholder="Enter task description"
              inputProps={{ maxLength: 1000 }}
              error={Boolean(this.state.taskDescriptionError)}
              helperText={this.state.taskDescriptionError}
            />}
        </Box>

        <Box style={webStyle.flexColumn} >
          {!(this.state.isEditMode && this.isProjectManager()) ?
            <>
              <Typography style={webStyle.title} >{"Task Due date"}</Typography>
              <Typography style={webStyle.value} >{this.formatDueDate(this.state.taskDetails?.attributes.due_date)}</Typography>
            </>
            : <CommonDatePicker
              disablePast
              asterisk
              label="Task Due date"
              data-test-id='due-date'
              disabled={this.disabledFields()}
              disableToolbar
              placeholder="Enter due date"
              InputProps={{ readOnly: true }}
              value={this.state.labelDate}
              onChangeDate={this.onChangeDueDate}
              onBlurClose={this.closeDatePickerOnBlur}
              onClickOpen={this.openDatePicker}
              anchorEl={this.state.dueDateAnchorEl}
              error={Boolean(this.state.dueDateError)}
              helperText={this.state.dueDateError}
            />}
        </Box>
        <Divider />
        <Box style={webStyle.flexColumn} >
          <Typography style={webStyle.title} >Members</Typography>
          <Box style={webStyle.addMemberButton} >
            <Button disabled={this.disabledFields() || !this.state.isEditMode}
              data-test-id='memberList'
              onClick={this.handleOpenMemberList}
              style={webStyle.memberButtonWrapper} >
              {this.state.selectedMember && <>
                <Avatar src={this.state.selectedMember.profile} style={webStyle.memberAvatarIcon} />
                <Typography style={webStyle.memberName} >
                  {this.state.selectedMember.name}
                </Typography>
              </>}
              <KeyboardArrowDownRoundedIcon style={{ color: "#94A3B8" }} />
            </Button>
          </Box>
        </Box>
        <Divider />
        {this.renderAttachments()}
        <Box>
          <StyledAccordion data-test-id='members' expanded={this.state.expanded === 'panel1'} onChange={this.handleChangeAccordion('panel1')}>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreRoundedIcon style={{ color: '#0F172A', fontSize: '40px' }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography style={webStyle.subtask} >
                Subtask
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {this.renderSubTask()}
            </StyledAccordionDetails>
          </StyledAccordion>
        </Box>
      </Box>
      {!this.state.isDuplicate ? this.renderAnswerAttachment() : null}
      {this.renderComments()}
      <CommonPageActions
        data-test-id='common-actions'
        ternary={this.getTernaryBtnData()}
        primary={{ actionFn: this.getActionBtnLabel().actionFn, label: this.getActionBtnLabel().label, disabled: this.getActionBtnLabel().disabled }}
        secondary={{ actionFn: this.renderCancel().actionFn, label: this.renderCancel().label, disabled: this.renderCancel().disabled }}
      />
    </Box>
  }


  renderHiddenElements = () => {
    return <Box style={{ display: 'none' }}>
      <Button data-test-id='project-id' onClick={this.setProjectId} ></Button>
      <Button data-test-id='pm-role' onClick={this.setPMrole} ></Button>
      <Button data-test-id='pt-role' onClick={this.setParticipantRole} ></Button>
      <Button data-test-id='user-id' onClick={this.setLoginUserId} ></Button>
      <input accept=".pdf" type="file" ref={this.ParticipantAttachmentUploadRef} data-test-id='participant-file' onChange={this.AnswerFileChange} />
      <Button data-test-id='participant-browse' onClick={this.handleParticipantBrowseFiles} ></Button>
      <Button data-test-id='add-task-comment' onClick={() => this.addComment('task')} ></Button>
      <Button data-test-id='add-subtask-comment' onClick={() => this.addComment('subtask')} ></Button>
    </Box>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <DashboardViewWrapper {...this.props}>
        {!this.state.subTaskId ? this.renderTaskComponent() : this.renderSubtaskComponent()}
        {this.renderHiddenElements()}
        <Loader loading={this.state.isLoading} />
        <CommonPopover data-test-id='popover'
          onClose={this.handleCloseMenu}
          anchorEl={this.state.anchorEl}
          paperStyle={{ maxHeight: '150px', overflowY: 'auto' }}
          menuList={this.state.memberList.map((member) => <MenuItem style={this.getMenuStyle(this.state.selectedMember?.name as string, member.full_name)} onClick={() => this.setNewMember(member)} >
            <Avatar src={member.profile_image} style={webStyle.avatarWrapper} />
            <Typography style={webStyle.memberFullName} >
              {member.full_name}
            </Typography>
          </MenuItem>)}
        />
        <CommonModal
          closeIcon
          data-test-id='modal'
          modalTitle={this.renderModalContent().title}
          open={this.state.openModal}
          actionComponent={this.renderModalContent()?.actionComponent}
          onClose={this.closeModal} >
          {this.renderModalContent()?.content}
        </CommonModal>
      </DashboardViewWrapper >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  commentUserWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  commentUserName: {
    color: '#0F172A',
    fontFamily: "'Inter', sans-serif",
    fontSize: '12px',
    fontWeight: 600
  },
  commentText: {
    color: '#64748B',
    fontSize: '12px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '18px',
    marginTop: '10px',
    whiteSpace: 'pre-wrap'
  },
  value: {
    color: '#64748B',
    fontWeight: 400,
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px'
  },
  title: {
    color: '#334155',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px'
  },
  mainLabel: {
    color: '#334155',
    fontWeight: '700',
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px',
    lineHeight: '26px',
  },
  browseBtn: {
    padding: '0',
    fontSize: '14px',
    width: '98px',
    fontFamily: "'Inter', sans-serif",
    marginRight: 0,
    height: '34px',
    fontWeight: 400
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "'Inter', sans-serif",
    border: '1px solid #E2E8F0',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px'
  } as React.CSSProperties,
  modalInfo: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontSize: '20px'
  },
  participantAttachmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  participantAttachmentInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  subTaskWrapper: {
    color: '#475569',
    fontFamily: "'Inter', sans-serif",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subtaskTitle: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    fontWeight: 700
  },
  viewMore: {
    color: '#5B4595',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '10px'
  },
  toolTipWrapper: {
    width: '30px',
    display: 'flex',
    justifyContent: 'center'
  },
  subtaskEditWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    marginBottom: '10px'
  } as React.CSSProperties,
  subtaskInputFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    justifyContent: 'space-between',
    width: '100%'
  },
  addSubTask: {
    color: '#5B4595',
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center'
  },
  answerAttachmentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    border: '1px solid #E2E8F0',
    fontFamily: "'Inter', sans-serif",
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px'
  } as React.CSSProperties,
  downloadAttachmentWrapper: {
    background: 'transparent',
    color: '#7C6AAA',
    border: '1px solid #7C6AAA',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize'
  } as React.CSSProperties,
  PMAttachmentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center',
    gap: '10px'
  } as React.CSSProperties,
  feedbackWrapper: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px',
    fontWeight: 700,
    marginBottom: '10px'
  },
  feedbackInfoWrapper: {
    color: '#334155',
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    borderRadius: '8px'
  } as React.CSSProperties,
  taskInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "'Inter', sans-serif",
    rowGap: '20px',
    border: '1px solid #E2E8F0',
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '8px'
  } as React.CSSProperties,
  editModeFieldsWrapper: {
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    flexDirection: 'column',
  } as React.CSSProperties,
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,
  memberButtonWrapper: {
    border: '1px solid #E2E8F0',
    height: '34px',
    fontFamily: "'Inter', sans-serif",
    borderRadius: '8px',
    textTransform: 'capitalize'
  } as React.CSSProperties,
  memberName: {
    color: '#334155',
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    lineHeight: '26px'
  },
  memberAvatarIcon: {
    width: '24px',
    height: "24px",
    marginRight: "5px"
  },
  subtask: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px'
  },
  addMemberButton: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    marginBottom: '10px'
  },
  avatarWrapper: {
    width: '24px',
    height: "24px",
    fontFamily: "'Inter', sans-serif",
    marginRight: "5px"
  },
  memberFullName: {
    color: '#334155',
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    lineHeight: '26px'
  },
};

const subtaskWebStyle = {
  browseBtn: { padding: '0', fontFamily: "'Inter', sans-serif", fontSize: '14px', width: '98px', marginRight: 0, height: '34px', fontWeight: 400 },
  boxWrapper: { display: 'flex', flexDirection: 'column', border: '1px solid #E2E8F0', backgroundColor: 'white', padding: '32px', borderRadius: '8px' } as React.CSSProperties,
  menuStyle: {
    color: '#334155',
    lineHeight: '22px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    minHeight: 'auto'
  },
  participantAttachmentWrapper: {
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  participantAttachmentDataWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    justifyContent: 'space-between',
    width: '100%'
  },

  attachmentTitle: {
    color: '#334155',
    fontSize: '14px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    marginBottom: '10px'
  },

  feedbackTitle: {
    color: '#334155',
    fontSize: '18px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    marginBottom: '10px'
  },

  feedbackInfoWrapper: {
    color: '#334155',
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },

  noFeedbackWrapper: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontWeight: 700
  },

  pageWrapper: {
    border: '1px solid #E2E8F0',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '32px 0'
  },

  projectNameWrapper: {
    color: '#334155',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    marginBottom: '10px'
  },

  subtaskNameWrapper: {
    color: '#334155',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    fontSize: '18px'
  },

  title: {
    color: '#334155',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700,
    fontSize: '14px',
    marginBottom: '10px'
  },

  description: {
    color: '#64748B',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 400,
    fontSize: '16px',
    marginBottom: '20px'
  },

  actionButtonWrapper: {
    padding: '40px 31px 40px 31px',
    borderRadius: '12px',
    backgroundColor: 'white',
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

};

const StyledPrimaryButton = styled(Button)({
  backgroundColor: '#5B4595',
  color: 'white',
  textTransform: "capitalize",
  height: '56px',
  fontWeight: 400,
  fontFamily: "'Inter', sans-serif",
  padding: '16px',
  minWidth: '240px',
  borderRadius: '8px',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: '#5B4595'
  },
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: "#F1F5F9",
    color: '#64748B',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    "@media(max-width:800px)": {
      width: 'fit-content',
      minWidth: 'auto',
      fontSize: '12px',
    }
  },
  "@media(max-width:800px)": {
    width: 'fit-content',
    minWidth: 'auto',
    fontSize: '12px',
  }
})

const StyledSecondaryButton = styled(Button)({
  backgroundColor: '#DEDAEA',
  color: '#5B4595',
  textTransform: "capitalize",
  height: '56px',
  fontWeight: 400,
  width: '240px',
  fontFamily: "'Inter', sans-serif",
  padding: '16px',
  borderRadius: '8px',
  fontSize: '16px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: '#DEDAEA'
  },
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: "#F1F5F9",
    color: '#64748B',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px'
  },
  "@media(max-width:600px)": {
    width: '50%'
  }
})

const StyledAccordion = styled(Accordion)({
  '&.MuiPaper-root.MuiAccordion-root': {
    borderTop: '1px solid #E4DEDE',
    borderRadius: '0',
    boxShadow: 'none',
  },
  '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
    margin: 0,
  }
})

const StyledAccordionSummary = styled(AccordionSummary)({
  '&.MuiButtonBase-root.MuiAccordionSummary-root': {
    minHeight: '100px',
    padding: '0',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    fontFamily: "'Inter', sans-serif",
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '40px'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '20px 40px 20px 0',
  }
});

const StyledAccordionDetails = styled(AccordionDetails)({
  '&.MuiAccordionDetails-root': {
    padding: "8px 0px 16px 0px"
  }
})

// Customizable Area End

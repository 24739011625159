// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IProject, IProjectAttributes } from "./types";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { GridSize, MenuItem, Typography, Box, Avatar } from "@mui/material";
import { Skeleton } from '@material-ui/lab';
import { debounce } from 'lodash';
const { NavigationActions, StackActions } = require("react-navigation");

export const configJSON = require("./config");

interface ProjectAttributes {
  project_name: string;
  due_date: string;
  description: string;
  status: string;
  members_count: number;
  organization_id: number;
  created_at: string;
  updated_at: string;
  total_task_count: number;
  completed_task_count: number;
  members: IMember[];
}

interface IMember {
  id: number;
  full_name: string;
  profile_image: string;
  email: string;
}

interface Project {
  id: string;
  type: string;
  attributes: ProjectAttributes;
}
export enum ProjectStatus {
  AllProjects = "All Projects",
  NotStarted = "Not Started",
  InProgress = "In Progress",
  OnHold = "On Hold",
  Completed = "Completed"
}
export interface IOrganization {
  id: string;
  type: string;
  attributes: {
    name: string;
    status: string;
  };
}

export interface Member {
  id: number;
  email: string
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isModalVisible: boolean;
  filterValue: string;
  isDeleteModalOpen: boolean;
  projects: IProject[];
  token: string;
  searchInputText: string;
  deleteProjectID: string;
  isFilterMenuOpen: Element | null;
  isDialogOpen: boolean;
  loading: boolean;
  pageNumber: number | null;
  dueDate: string;
  addProjectMode: boolean;
  projectName: string;
  projectNameError: string;
  projectDescription: string;
  projectDescriptionError: string;
  dueDateError: string;
  organization: number | undefined;
  organizationError: string;
  organizationList: Array<{ label: string, value: number }>;
  openModal: boolean;
  selectedOrganizations: Array<number | undefined>;
  selectedOrganizationTitles: Array<string | undefined>;
  selectedMembers: Array<number | undefined>,
  selectedMemberTitles: Array<string | undefined>;
  tempSelectedOrganizations: Array<number | undefined>;
  tempSelectedOrganizationTitles: Array<string | undefined>;
  tempSelectedMembers: Array<number | undefined>,
  oldSelectedMembers: Array<number | undefined>,
  tempSelectedMemberTitles: Array<string | undefined>;
  projectId: number | null;
  memberList: Array<{ label: string, value: number }>;
  selectedOrgError: string;
  selectedMemberError: string;
  showCommonMessage: boolean;
  message: string;
  messageType: "error" | "success" | "default";
  messageTimeOutId: NodeJS.Timeout | null;
  labelDate: Date | string;
  autoHideDuration: number;
  currentView: "grid" | "list";
  breakPoints: GridSize;
  isLoading: boolean;
  page: number;
  totalPages: number;
  totalProjects: number;
  projectList: Project[];
  sortParameterLabel: "Oldest" | "Latest" | "A to Z" | "Z to A";
  sortBy: 'time' | 'status' | 'a-z';
  filterStatus: "" | "not_started" | "in_progress" | "on_hold" | "completed";
  filterStatusLabel: ProjectStatus;
  filterOrganization: string;
  filterOrganizationId: number | null;
  anchorEl: HTMLElement | null;
  kebabMenyType: "sort" | "status" | "status-list" | "kebabMenu" | "memberList" | "organizationList" | "default";
  datePickerAnchorEl: HTMLDivElement | null;
  isRename: boolean;
  isDuplicate: boolean;
  isEdit: boolean;
  projectDetails: Project | null;
  kebabMenuActionType: "rename" | "duplicate" | "delete" | "edit",
  modalType: "add-member" | "delete-project" | "edit-member",
  prevProjectName: string;
  isSubmitDisabled: boolean,
  role: string;
  projectStatus: ProjectStatus;
  showLoader: boolean;
  dir: "asc" | "desc"
}

interface SS {
  id: any;
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  getOrganizationListApiCallId: string = "";
  apiDeleteProjectsListCallId: string | null = "";
  deleteProjectApiCallId: string = "";
  getProjectDetailsApiCallId: string = "";
  editProjectApiCallId: string = "";
  addProjectApiCallId: string = "";
  removeMembersApiCallId: string = "";
  addMembersApiCallId: string = "";
  changeProjectStatusApiCallId: string = "";
  renameProjectApiCallId: string = "";
  getProjectsApiCallId: string = "";
  getMembersListApiCallId: string = "";
  searchProjectsApiCallId: string = "";
  apiGetProjectsListCallId: string | null = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isModalVisible: false,
      filterValue: "All Projects",
      isDeleteModalOpen: false,
      projects: [],
      token: "",
      searchInputText: "",
      deleteProjectID: "",
      isFilterMenuOpen: null,
      isDialogOpen: false,
      loading: false,
      pageNumber: 1,
      dueDate: '',
      addProjectMode: false,
      organizationList: [],
      projectNameError: '',
      projectName: '',
      projectDescriptionError: '',
      projectDescription: '',
      organizationError: '',
      organization: undefined,
      openModal: false,
      dueDateError: '',
      selectedMembers: [],
      projectId: null,
      selectedOrganizations: [],
      memberList: [],
      selectedMemberError: '',
      showCommonMessage: false,
      selectedOrgError: '',
      messageType: 'default',
      message: '',
      selectedMemberTitles: [],
      messageTimeOutId: null,
      tempSelectedOrganizations: [],
      selectedOrganizationTitles: [],
      tempSelectedMembers: [],
      tempSelectedOrganizationTitles: [],
      tempSelectedMemberTitles: [],
      oldSelectedMembers: [],
      autoHideDuration: 4000,
      labelDate: '',
      breakPoints: 12,
      currentView: "list",
      isLoading: false,
      page: 1,
      totalPages: 1,
      projectList: [],
      totalProjects: 0,
      anchorEl: null,
      sortParameterLabel: 'Latest',
      sortBy: 'time',
      filterStatusLabel: ProjectStatus.AllProjects,
      filterStatus: '',
      datePickerAnchorEl: null,
      kebabMenyType: "default",
      projectDetails: null,
      isRename: false,
      kebabMenuActionType: 'rename',
      isDuplicate: false,
      prevProjectName: '',
      modalType: 'add-member',
      projectStatus: ProjectStatus.NotStarted,
      role: 'project_manager',
      isSubmitDisabled: false,
      isEdit: false,
      showLoader: false,
      dir: "desc",
      filterOrganization: "All Organizations",
      filterOrganizationId: null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleResponseMessage = (input: {
    responseJson: { message: string; error: string; errors: string[] };
    errorJson?: string[];
    onSuccess: () => void;
    onFail: () => void;
  }) => {
    const { responseJson, onSuccess, onFail, errorJson } = input;

    if (responseJson && !responseJson.error && !responseJson.errors) {
      onSuccess();
    }

    if (responseJson.error || errorJson || responseJson.errors) {
      onFail();
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.errors?.[0]?.token) {
        removeStorageData('token');
        this.goToBlock('EmailAccountLoginBlock');
        return;
      }

      if (responseJson) {
        if (apiRequestCallId === this.apiGetProjectsListCallId) {
          this.getProjectListHandleResponse(responseJson, errorJson);
        }
        if (apiRequestCallId === this.apiDeleteProjectsListCallId) {
          this.deleteProjectHandleResponse(responseJson, errorJson);
        }
        if (responseJson.status === 404) {
          this.setState({ showCommonMessage: true, message: 'Unknown error occured', messageType: 'error' })
          return;
        }
        this.handleResponse(responseJson, apiRequestCallId)
      }
    }
  }
  updateProjectList = (project: Project, newProjectAttributes: ProjectAttributes) => {
    if (Number(project.id) === this.state.projectId) {
      return { ...project, attributes: { ...newProjectAttributes } }
    }
    return project;
  }
  handleResponse = (responseJson: {
    data: IOrganization[] | Project[] | Project,
    accounts: Member[],
    message: string,
    meta: { total_pages: number; total_count: number }
  },
    apiRequestCallId: string,
  ) => {
    switch (apiRequestCallId) {
      case this.getOrganizationListApiCallId:
        const organizationList: IOrganization[] = responseJson.data as IOrganization[];
        const formattedOrganizationList = organizationList.map((element: IOrganization) => ({ label: element.attributes.name, value: Number(element.id) }))
        this.setState({ organizationList: formattedOrganizationList });
        break;
      case this.getMembersListApiCallId:
        this.handleMemberListResponse(responseJson as { accounts: Member[], message: string })
        break;
      case this.addProjectApiCallId:
        this.setState({ showCommonMessage: true, projectList: [responseJson.data as Project, ...this.state.projectList], message: 'Project Added Successfully', messageType: "success", addProjectMode: false, isLoading: false }, () => { this.getProjects(); this.clearFormFields(); this.goToBlock("Projects") });
        break;
      case this.getProjectsApiCallId:
        this.setState((prevState) => {
          const mergedArray = [...prevState.projectList, ...responseJson.data as Project[]];
          const uniqueMap = new Map();
          mergedArray.forEach(object => {
            if (!uniqueMap.has(object.id)) {
              uniqueMap.set(object.id, object);
            }
          });
          const newArray = Array.from(uniqueMap.values());
          return { projectList: newArray, totalPages: responseJson.meta.total_pages, totalProjects: responseJson.meta.total_count, isLoading: false, showLoader: false }
        })
        break;
      case this.searchProjectsApiCallId:
        this.setState(() => {
          const mergedArray = [...responseJson.data as Project[]];
          const uniqueMap = new Map();
          mergedArray.forEach(object => {
            if (!uniqueMap.has(object.id)) {
              uniqueMap.set(object.id, object);
            }
          });
          const newArray = Array.from(uniqueMap.values());
          return { projectList: newArray, totalPages: responseJson.meta.total_pages, totalProjects: responseJson.meta.total_count, isLoading: false }
        })
        break;
      case this.getProjectDetailsApiCallId:
        this.setState({
          projectDetails: responseJson.data as Project,
        }, () =>
          this.setState({
            projectDescription: this.state.projectDetails!.attributes.description,
            prevProjectName: this.state.projectDetails!.attributes.project_name,
            projectName: this.state.projectDetails!.attributes.project_name,
            labelDate: this.state.projectDetails!.attributes.due_date,
            organization: Number(this.state.projectDetails!.attributes.organization_id),
            selectedOrganizations: [Number(this.state.projectDetails!.attributes.organization_id)],
            dueDate: moment(this.state.projectDetails!.attributes.due_date).format('DD/MM/YYYY'),
            selectedMembers: this.state.projectDetails!.attributes.members.map(member => member.id),
            oldSelectedMembers: this.state.projectDetails!.attributes.members.map(member => member.id),
            tempSelectedOrganizations: [Number(this.state.projectDetails!.attributes.organization_id)],
          }))
        break;
      case this.deleteProjectApiCallId:
        const updatedProjectList = this.state.projectList.filter((project) => {
          return Number(project.id) !== this.state.projectId
        })
        this.setState({ projectList: updatedProjectList, openModal: false, modalType: 'add-member', showCommonMessage: true, messageType: 'success', message: `Project ${this.state.prevProjectName} Deleted Successfully`, prevProjectName: '', projectDetails: null }, () => this.getProjects())
        break;
      case this.renameProjectApiCallId:
        const updatedRenamedProjectList = this.state.projectList.map((project) => {
          return this.updateProjectList(project, { ...project.attributes, project_name: this.state.projectName })
        })
        this.setState({ isLoading: false, projectList: updatedRenamedProjectList, showCommonMessage: true, messageType: 'success', message: "Project Renamed Successfully", addProjectMode: false })
        this.handleCancelProject()
        break;
      case this.addMembersApiCallId:
        break;
      case this.editProjectApiCallId:
        const editProject = this.state.projectList.map((project) => {
          return this.updateProjectList(project, { ...project.attributes, project_name: this.state.projectName })
        })
        this.setState({ isLoading: false, projectList: editProject, showCommonMessage: true, messageType: 'success', message: "Project Edited Successfully", addProjectMode: false })
        this.goToBlock('Projects')
        break;
      case this.changeProjectStatusApiCallId:
        const changeStatusProject = this.state.projectList.map((project) => {
          return this.updateProjectList(project, { ...project.attributes, status: this.state.projectStatus })
        })
        this.setState({ isLoading: false, projectList: changeStatusProject, showCommonMessage: true, messageType: 'success', message: "Project Status Changed Successfully", addProjectMode: false })
        break;
    }
  }

  handleMemberListResponse = (responseJson: { accounts: Member[], message: string }) => {
    if (responseJson.message) {
      this.setState({ selectedMemberError: "No data found!", memberList: [] });
      return;
    }
    if (responseJson.accounts) {
      const members = responseJson.accounts;
      const formattedMembers = members.map((element: Member) => ({ label: element.email, value: Number(element.id) }))
      const memberValues = formattedMembers.map(member => member.value);
      const memberLabels = formattedMembers.map(member => member.label);
      const filteredValues = this.state.tempSelectedMembers.filter(value => memberValues.includes(value!));
      const filteredLabels = this.state.tempSelectedMemberTitles.filter(label => memberLabels.includes(label!));
      this.setState({ memberList: formattedMembers, tempSelectedMemberTitles: filteredLabels, tempSelectedMembers: filteredValues, selectedMemberError: "" })
    }
  }

  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  goToProjectDetails = async (projectId: number) => {
    let destinationBlock = 'ProjectDetails';
    if (window.location.pathname !== '/projects') {
      destinationBlock = 'KanbanBoard'
      await setStorageData('task-project-id', projectId);
    }
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), destinationBlock);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), projectId);
    this.send(message);
  }
  projectAction = (type: string) => {
    switch (type) {
      case 'edit':
        this.setState({ isEdit: true, isSubmitDisabled: true, modalType: 'edit-member' })
        return
      case 'rename':
        this.setState({ isRename: true, isSubmitDisabled: true })
        return
      case 'duplicate':
        this.setState({
          isDuplicate: true,
          dueDate: '',
          labelDate: '',
        })
        return
    }
  }
  async componentDidMount() {
    await removeStorageData('task-project-id');
    await removeStorageData('task-id');
    let token = await getStorageData("token")
    const encryptedRole = await getStorageData('role');
    const role = this.deCryptRole(configJSON.Secretkey, encryptedRole);
    const isCreateMode = window.location.pathname.includes('create');
    this.setState({ token: token, role: role, addProjectMode: isCreateMode, showLoader: true });
    this.getProjects();
    this.getOrganizationList();
    const projectId = this.props.navigation.getParam('navigationBarTitleText')
    if (Boolean(projectId)) {
      const type = window.location.pathname.split("/")[2]
      this.projectAction(type)
      this.setState({
        projectId: projectId,
        addProjectMode: Boolean(projectId),
      })
      this.getProjectDetails(projectId)
    }
    const currentSelectedView = await getStorageData(configJSON.currentView);
    if (currentSelectedView) {
      this.setState({ currentView: currentSelectedView });
    } else {
      this.setCurrentView('list')
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.tempSelectedOrganizations !== this.state.tempSelectedOrganizations) {
      this.getMembersList()
    }
    if (prevState.organization !== this.state.organization) {
      this.setState({ isSubmitDisabled: false })
    }
    if (prevState.projectName !== this.state.projectName) {
      this.setState({ isSubmitDisabled: false })
    }
    if (prevState.projectDescription !== this.state.projectDescription) {
      this.setState({ isSubmitDisabled: false })
    }
    if (prevState.dueDate !== this.state.dueDate) {
      this.setState({ isSubmitDisabled: false })
    }
  }

  async componentWillUnmount() {
    await removeStorageData(configJSON.currentView);
    await removeStorageData('sortBy')
  }

  getProjectListHandleResponse = (
    responseJson: {
      data: { data: [{ id: string; attributes: IProjectAttributes }] };
      total_page: number;
      message: string;
      error: string;
      errors: string[];
    },
    errorJson: string[]
  ) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        let meta_userId = await getStorageData("meta_userID");
        if (!responseJson.data.data.length) {
          this.setState({ pageNumber: null, loading: false });
          return;
        }
        const projectsData = responseJson.data.data.map(
          (element: { id: string; attributes: IProjectAttributes }) => {
            return {
              projectId: element.id,
              project_name: element.attributes.project_name,
              start_date: element.attributes.project.data
                ? element.attributes.project.data.attributes.start_date
                : "",
              end_date: element.attributes.project.data
                ? element.attributes.project.data.attributes.end_date
                : "",
              isMyProject:
                element.attributes.account_id.toString() === meta_userId,
            };
          }
        );
        this.setState({
          loading: false,
          projects:
            this.state.pageNumber === 1
              ? projectsData
              : [...this.state.projects, ...projectsData],
        });
      },
      onFail: () => {
        this.setState({ loading: false });
        this.showAlert("Error", responseJson.error);
      },
    });
  };

  handleCloseCommonMessage = () => {
    this.resetMessage()
  }

  handleScroll: React.UIEventHandler<HTMLDivElement> = async (event) => {
    const { isLoading } = this.state;
    if (isLoading) return;
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    const isFilterSortApplied = this.state.filterOrganizationId || this.state.filterStatusLabel !== "All Projects" || !(this.state.sortBy === "time" && this.state.dir == "asc")
    if ((scrollTop + offsetHeight >= scrollHeight - 100) && this.state.page !== this.state.totalPages && !this.state.addProjectMode) {
      this.setState((prevState) => {
        return { isLoading: true, page: prevState.page + 1 }
      }, () => { isFilterSortApplied ? this.getSortedProjects() : this.getProjects(); });
    }
  };

  getOrganizationList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrganizationListApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.organizationListEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getMembersList = async () => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.validationApiContentType,
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMembersListApiCallId = reqMessage.messageId;
    if (!this.state.tempSelectedOrganizations.length) {
      this.setState({ memberList: [] })
      return;
    }
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.searchMembersEndPoint}?organization_ids=[${this.state.tempSelectedOrganizations}]`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  changeTitleToSnake = (title: string) => {
    const words = title.split(' ')
    const titleSnakce = words.map(word => word.toLowerCase()).join("_")
    return titleSnakce
  }
  changeProjectStatus = async (id: number, status: ProjectStatus) => {
    this.setState({ projectStatus: status, anchorEl: null })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const body = { status: this.changeTitleToSnake(status) }
    this.changeProjectStatusApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${id}/change_status`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchAPiEndMethod);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  openStatusList = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    this.getProjectDetails(Number(id))
    this.setState({ anchorEl: event.currentTarget, kebabMenyType: "status-list", projectId: Number(id) })
  }

  hideElement = () => {
    return this.state.role === 'project_manager' && window.location.pathname === '/projects' ? 'visible' : 'hidden'
  }

  getAddProjectProps = () => {
    return this.state.role === 'project_manager' && window.location.pathname === '/projects' ? { buttonTxt: "New Project", actionFn: this.handleOpenCreateProject, testId: 'add-project' } : undefined;
  }

  disableActionButton = () => {
    return !(this.state.role === 'project_manager' && window.location.pathname === '/projects')
  }

  isProjectManager = () => {
    return this.state.role === 'project_manager'
  }

  getBreakPoints = () => {
    const isActionEnabledScreen = this.disableActionButton()
    return {
      title: 4,
      progress: isActionEnabledScreen ? 2.5 : 2,
      profileIcons: 2,
      status: isActionEnabledScreen ? 1.5 : 2,
      actionBtn: isActionEnabledScreen ? 0 : 1
    }
  }

  setCurrentView = async (view: "list" | "grid") => {
    await setStorageData('currentView', view);
    this.setState({ currentView: view });
  }

  clearFormFields = () => {
    this.setState({ projectName: '', projectDescription: '', organization: undefined, dueDate: '', labelDate: '', dueDateError: '', projectNameError: '', projectDescriptionError: '', organizationError: '', selectedMemberError: '', selectedOrganizationTitles: [], selectedOrganizations: [], selectedMembers: [], selectedMemberTitles: [], selectedOrgError: '' });
  }

  resetMessage = () => {
    this.setState({ showCommonMessage: false, message: '', messageType: 'default', autoHideDuration: 4000 })
  }

  onMultiSelectChange = (values: Array<number | undefined>, labels: Array<string | undefined>, _selectedObject: ({ label: string; value: number; } | undefined)[]) => {
    this.setState({ tempSelectedMembers: values, selectedMemberError: '', tempSelectedMemberTitles: labels });
  };

  onMultiOrganizationSelect = (values: Array<number | undefined>, labels: Array<string | undefined>, _selectedObject: ({ label: string; value: number; } | undefined)[]) => {
    this.setState({ tempSelectedOrganizations: values, selectedOrgError: '', tempSelectedOrganizationTitles: labels });
  }

  handleAddMember = () => {
    let error = false;
    if (!this.state.tempSelectedMembers.length) {
      this.setState({ selectedMemberError: "Select atleast one member" });
      error = true;
    }
    if (!this.state.tempSelectedOrganizations.length) {
      this.setState({ selectedOrgError: "Select atleast one organization" });
      error = true;
    }
    if (!error) {
      this.setState((prevState) => {
        return {
          openModal: false,
          selectedMemberTitles: this.state.tempSelectedMemberTitles,
          selectedMembers: this.state.tempSelectedMembers,
          selectedOrganizationTitles: this.state.tempSelectedOrganizationTitles,
          selectedOrganizations: this.state.tempSelectedOrganizations,
          messageType: "success",
          showCommonMessage: true,
          message: prevState.selectedMembers === this.state.tempSelectedMembers ? "Selected members are already added" : 'Members added successfully'
        }
      });
    }
  }

  handleCancelAddMemberModal = () => {
    if (this.state.isEdit) {
      this.setState({ openModal: false, })
      return;
    }
    this.setState({ openModal: false, selectedMemberError: '', selectedOrgError: '' }, () => this.getProjects())
  }

  handleOpenModal = () => {
    this.setState({
      openModal: true,
      tempSelectedOrganizations: this.state.selectedOrganizations,
      tempSelectedMembers: this.state.selectedMembers,
      tempSelectedOrganizationTitles: this.state.selectedOrganizationTitles,
      tempSelectedMemberTitles: this.state.selectedMemberTitles,
    })
  }

  handleCloseModal = () => {
    this.setState({
      openModal: false,
      tempSelectedMembers: [],
      tempSelectedMemberTitles: [],
      tempSelectedOrganizations: [],
      tempSelectedOrganizationTitles: [],
      selectedMemberError: '',
      selectedOrgError: ''
    })
  }

  onChangeOrganization = (text: unknown) => {
    this.setState({ organization: text as number, organizationError: "" })
  }

  onChangeProjectName = (text: string) => {
    this.setState({ projectName: text, projectNameError: '' })
  }

  onChangeProjectDescription = (text: string) => {
    this.setState({ projectDescription: text, projectDescriptionError: '' })
  }

  onChangeDueDate = (date: Date) => {
    this.setState({ labelDate: date, dueDate: moment(date).format('DD/MM/YYYY'), dueDateError: '', datePickerAnchorEl: null })
  }

  handleOpenDatePicker: React.MouseEventHandler<HTMLDivElement> = (event) => {
    this.setState({ datePickerAnchorEl: event.currentTarget })
  }

  handleOpenCreateProject = () => {
    this.goToBlock('CreateProject')
    this.setState({ addProjectMode: true })
  }

  handleCancelProject = () => {
    this.goToBlock("Projects")
    this.setState({ addProjectMode: false, isRename: false, isDuplicate: false, isEdit: false }, () => this.clearFormFields())
  }

  handleCreateProject = async () => {
    const error = this.validationProject()

    if (error) {
      return;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };

    const httpBody = {
      data: {
        due_date: this.state.dueDate,
        description: this.state.projectDescription.trim(),
        project_name: this.state.projectName.trim(),
        organization_id: Number(this.state.organization),
        member_ids: this.state.selectedMembers
      }
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addProjectApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addProjectEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  renameProject = async () => {
    let error = false;
    if (this.isEmpty(this.state.projectName)) {
      error = true
      this.setState({ projectNameError: this.getEmptyErrorMsg(configJSON.ErrorProjectName), projectName: '' });
    } else if (this.state.prevProjectName === this.state.projectName) {
      error = true
      this.setState({ projectNameError: configJSON.ProjectNameDuplicateError });
    }

    if (error) {
      return;
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };

    const httpBody = {
      new_project_name: this.state.projectName.trim()
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.renameProjectApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${this.state.projectId}/rename_project_name`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(reqMessage.id, reqMessage);

  }
  validationProject = () => {
    let error = false;
    if (this.isEmpty(this.state.projectName)) {
      error = true
      this.setState({ projectNameError: this.getEmptyErrorMsg(configJSON.ErrorProjectName), projectName: '' })
    }

    if (this.isEmpty(this.state.projectDescription)) {
      error = true
      this.setState({ projectDescriptionError: this.getEmptyErrorMsg(configJSON.ErrorProjectDescription), projectDescription: '' })
    }

    if (!(this.state.organization)) {
      error = true
      this.setState({ organizationError: this.getEmptyErrorMsg('Organization') })
    }

    if (!this.state.dueDate) {
      error = true
      this.setState({ dueDateError: this.getEmptyErrorMsg(configJSON.ErrorDueDate) })
    }
    return error
  }
  editProject = async () => {

    const error = this.validationProject()

    if (error) {
      return;
    }
    this.editProjectDetails()
    this.addMembers()
    const removeMembers = this.state.oldSelectedMembers.filter(member => !this.state.selectedMembers.includes(member))
    removeMembers.length > 0 && removeMembers.forEach((member) => {
      this.removeMembers(member || 0)
    })
  }
  editProjectDetails = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };

    const httpBody = {
      data: {
        due_date: this.state.dueDate,
        description: this.state.projectDescription.trim(),
        project_name: this.state.projectName.trim(),
        organization_id: Number(this.state.organization),
        member_ids: this.state.selectedMembers
      }
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editProjectApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${this.state.projectId}`);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  addMembers = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.addMembersApiCallId = requestMessage.messageId
    const httpBody = {
      member_ids: this.state.selectedMembers.filter(member => !this.state.oldSelectedMembers.includes(member))
    }
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${this.state.projectId}/add_members`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putAPiEndMethod)
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  removeMembers = async (member: number) => {
    const header = {
      token: await getStorageData(configJSON.Token),
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.removeMembersApiCallId = requestMessage.messageId
    const httpBody = {
      member_id: member
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${this.state.projectId}/remove_member`)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiEndMethod)
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody))
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getProjects = async () => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}?page=${this.state.page}&per_page=10`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  getProjectByQuery = async (query: string) => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.searchProjectsApiCallId = reqMessage.messageId
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.searchEndPoint}?search_type=projects&query=${query}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  getSortedProjects = async () => {
    this.setState({ showLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProjectsApiCallId = reqMessage.messageId;
    let filterByStatus = this.state.filterStatus ? `&status=${this.state.filterStatus}` : "";
    let filterByOrganization = this.state.filterOrganizationId ? `&organization_id=${this.state.filterOrganizationId}` : ''
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.sortProjectEndPoint}?page=${this.state.page}&per_page=10&query=${this.state.sortBy}${filterByStatus}${filterByOrganization}&data_type=${this.state.dir}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  getProjectDetails = async (projectId: number) => {
    this.setState({ projectDetails: null })
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    this.getProjectDetailsApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${projectId}`);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  DeleteProject = async (projectId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData(configJSON.Token),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteProjectApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiEndMethod);
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addProjectEndPoint}/${projectId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  isEmpty = (entity: string) => {
    return entity.trim() === '';
  }

  getEmptyErrorMsg = (fieldName: string) => {
    return `${fieldName} is required!`
  }

  getProgressStyle = (updateCount: number, totalCount: number) => {
    return (updateCount === totalCount) && totalCount !== 0 ? { color: '#475569', fontWeight: 700, fontFamily: "'Inter', sans-serif", } : { color: '#64748B', fontWeight: 400, fontFamily: "'Inter', sans-serif", }
  }

  handleFormateTime = (dateString: string) => {
    return moment(dateString).format("DD MMM, YYYY");
  }

  isPastDueDate = (dueDate: string) => {
    const currentDate = moment();
    const formattedDueDate = moment(dueDate)
    return moment.duration(currentDate.diff(formattedDueDate)).days() > 0
  }

  handleSortByTime = async () => {
    this.setState((prevState) => {
      const isPrevAlphbeticalSelected = (prevState.sortParameterLabel === 'A to Z' || prevState.sortParameterLabel === "Z to A");
      const resetDir = prevState.dir === "asc" ? "desc" : "asc";
      return {
        sortParameterLabel: isPrevAlphbeticalSelected || prevState.sortParameterLabel === "Oldest" ? "Latest" : "Oldest",
        sortBy: configJSON.sortByValue.time,
        dir: isPrevAlphbeticalSelected ? "desc" : resetDir,
        anchorEl: null,
        projectList: [],
        page: 1
      }
    }, () => this.getSortedProjects())
  }

  handleSortByAZ = async () => {
    this.setState((prevState) => {
      return {
        sortParameterLabel: prevState.sortParameterLabel === "A to Z" ? "Z to A" : "A to Z",
        sortBy: configJSON.sortByValue.a2z,
        dir: prevState.dir === "asc" && prevState.sortParameterLabel === "A to Z" ? "desc" : "asc",
        anchorEl: null, projectList: [], page: 1
      }
    }, () => this.getSortedProjects())
  }

  handleFilterByOrganization = (organization: { value: number | null, label: string }) => {
    this.setState({ filterOrganization: organization.label, filterOrganizationId: organization.value, anchorEl: null, kebabMenyType: "default", projectList: [], page: 1 }, () => this.getSortedProjects())
  }

  handleFilterByAll = () => {
    this.setState({ filterStatus: configJSON.filterLabelValue.all.value, filterStatusLabel: configJSON.filterLabelValue.all.label, anchorEl: null, kebabMenyType: "default", projectList: [], page: 1 }, () => this.getSortedProjects())
  }

  handleFilterByNotStarted = () => {
    this.setState({ filterStatus: configJSON.filterLabelValue.notStarted.value, filterStatusLabel: configJSON.filterLabelValue.notStarted.label, anchorEl: null, kebabMenyType: "default", projectList: [], page: 1 }, () => this.getSortedProjects())
  }

  handleFilterByInProgress = () => {
    this.setState({ filterStatus: configJSON.filterLabelValue.inProgress.value, filterStatusLabel: configJSON.filterLabelValue.inProgress.label, anchorEl: null, kebabMenyType: "default", projectList: [], page: 1 }, () => this.getSortedProjects())
  }

  handleFilterByOnHold = () => {
    this.setState({ filterStatus: configJSON.filterLabelValue.onHold.value, filterStatusLabel: configJSON.filterLabelValue.onHold.label, anchorEl: null, kebabMenyType: "default", projectList: [], page: 1 }, () => this.getSortedProjects())
  }

  handleFilterByCompleted = () => {
    this.setState({ filterStatus: configJSON.filterLabelValue.completed.value, filterStatusLabel: configJSON.filterLabelValue.completed.label, anchorEl: null, kebabMenyType: "default", projectList: [], page: 1 }, () => this.getSortedProjects())
  }

  handleOpenStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget, kebabMenyType: "status" })
  }

  handleOpenSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget, kebabMenyType: "sort" })
  }

  handleOpenOrganizationList = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget, kebabMenyType: "organizationList" })
  }


  handleOpenKebabMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    this.getProjectDetails(Number(event.currentTarget.dataset.projectid));
    this.setState({ anchorEl: event.currentTarget, kebabMenyType: "kebabMenu", projectId: Number(event.currentTarget.dataset.projectid) })
  }

  handleOpenMemberList = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.getProjectDetails(Number(event.currentTarget.dataset.projectid));
    this.setState({ anchorEl: event.currentTarget, kebabMenyType: "memberList", projectId: Number(event.currentTarget.dataset.projectid) });
  }

  handleCloseKebabMenu = () => {
    this.setState({ anchorEl: null, kebabMenyType: "default" })
  }

  handleCloseDatePickerOnBlur = () => {
    this.setState({ datePickerAnchorEl: null })
  }


  getModalHeader = () => {
    switch (this.state.modalType) {
      case 'delete-project':
        return 'Delete Project'
      case 'edit-member':
        return 'Edit Members'
      case 'add-member':
        return "Add Members"
      default:
        return 'Member'
    }
  }
  showListGridLoader = () => {
    return this.state.isLoading && this.state.projectList.length !== 0;
  }
  handleRenameDuplicateProject = (type: "rename" | "duplicate" | "delete" | "edit") => {
    this.setState({
      isDuplicate: type === 'duplicate' ? true : false,
      dueDate: type === 'duplicate' ? '' : this.state.dueDate,
      labelDate: type === 'duplicate' ? '' : this.state.labelDate,
      isRename: type === 'rename' ? true : false,
      isEdit: type === 'edit' ? true : false,
      isSubmitDisabled: type === "edit" ? true : false,
      addProjectMode: true,
      kebabMenuActionType: type,
      anchorEl: null
    })
    switch (type) {
      case 'edit':
        this.goToWithParams('EditProject', JSON.stringify(this.state.projectId))
        return
      case 'duplicate':
        this.goToWithParams('DuplicateProject', JSON.stringify(this.state.projectId))
        return
      case 'rename':
        this.goToWithParams('RenameProject', JSON.stringify(this.state.projectId))
        return
    }
  }
  goToWithParams = (block: string, param: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), block);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    this.send(message);
  }
  handleDeleteProject = () => {
    this.DeleteProject(this.state.projectId as number)
  }

  handleOpenDeleteProjectModal = () => {
    this.setState({ kebabMenuActionType: "delete", anchorEl: null, modalType: 'delete-project', openModal: true })
  }

  renderMemberList = () => {
    return this.state.projectDetails && this.state.projectDetails?.attributes?.members?.length > 0 ? this.state.projectDetails?.attributes.members.map((member) => (
      <MenuItem data-test-id='member-list' key={member.id} style={{
        color: '#334155',
        fontFamily: "'Inter', sans-serif",
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: 'auto',
        padding: '6px 16px'
      }} >
        <Avatar style={{ width: '24px', height: '24px', marginRight: '10px', fontSize: '16px', fontWeight: 600, textTransform: 'capitalize' }} src={member.profile_image} alt={member.full_name} />
        <Typography style={{ color: '#64748B', fontFamily: "'Inter', sans-serif", fontWeight: 400, fontSize: '12px', lineHeight: '22px' }} >{member.full_name}</Typography>
      </MenuItem>
    )) : <Box style={{ display: 'flex', alignItems: 'center', lineHeight: '22px', padding: '6px 16px' }}>
      <Skeleton style={{ marginRight: '10px', height: '30px', width: '60px' }} animation="wave" variant="circle" />
      <Skeleton animation="wave" height={20} width={"100%"} />
    </Box>
  }

  deCryptRole = (salt: string, encoded: string): string => {
    const TextToChars = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
    const applySaltToChar = (code: number): number => TextToChars(salt).reduce((first, second) => first ^ second, code);
    return encoded
      .match(/.{1,2}/g)
      ?.map((hexa) => parseInt(hexa, 16))
      ?.map(applySaltToChar)
      ?.map((charCode) => String.fromCharCode(charCode))
      .join("") ?? "";
  };

  deleteProjectHandleResponse = (
    responseJson: { message: string; error: string; errors: string[] },
    errorJson: string[]
  ) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        this.getProjectsListData("");
        this.setState({ loading: false });
        if (responseJson.message) {
          this.showAlert("Success", responseJson.message);
        } else {
          this.showAlert("Error", responseJson.error);
        }
      },
      onFail: () => { },
    });
  };

  getProjectsListData = async (searchText: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    const getAPIEndPoint =
      this.state.filterValue === "All Projects"
        ? configJSON.getAllProjectsAPIEndPoint +
        "?page=" +
        this.state.pageNumber +
        "&search_key=" +
        searchText
        : configJSON.getMyProjectsAPIEndPoint +
        "?page=" +
        this.state.pageNumber +
        "&search_key=" +
        searchText;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteProject = async () => {
    this.setState({ isDeleteModalOpen: false, loading: true, pageNumber: 1 });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteProjectsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteProjectAPIEndPoint + this.state.deleteProjectID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSearch = debounce((text: string) => {
    this.getProjectByQuery(text)
  }, 500)

  changeApi(type: string) {
    this.setState(
      {
        filterValue: type,
        isModalVisible: false,
        projects: [],
        searchInputText: "",
        pageNumber: 1,
      },
      () => {
        this.getProjectsListData("");
      }
    );
  }

  goBack() {
    const message: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getProgress = (updateCount: number, totalCount: number) => {
    return totalCount !== 0 ? (updateCount / totalCount) * 100 : 0
  }

  openFilterMenu() {
    this.setState({ isModalVisible: true });
  }

  deleteModal(projectID: string) {
    this.setState({ isDeleteModalOpen: true, deleteProjectID: projectID });
  }

  closeDeleteModal() {
    this.setState({ isDeleteModalOpen: false });
  }

  filterModal() {
    this.setState({ isModalVisible: false });
  }

  naviagteToCreatePage = () => {
    const message: Message = new Message(
      //getName(MessageEnum.CreateProjectMessage)
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  naviagteToEditPage = (projectId: string) => {
    const message: Message = new Message(
      //getName(MessageEnum.CreateProjectMessage)
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      //getName(MessageEnum.PropsData)
      "Dummy text because Enum does not exist", projectId);
    this.send(message);
  };

  naviagteToViewPage = (projectId: string) => {
    const message: Message = new Message(
      //getName(MessageEnum.ViewProjectMessage)
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      //getName(MessageEnum.PropsData)
      "Dummy text because Enum does not exist", projectId);
    this.send(message);
  };
  loadMoreData = () => {
    if (this.state.pageNumber !== null) {
      this.setState(
        {
          pageNumber: Number(this.state.pageNumber) + 1,
        },
        () => this.getProjectsListData("")
      );
    }
  };
  logOutFunction = () => {
    removeStorageData("token");
    this.props.navigation.dispatch(
      StackActions.reset({
        index: 0,
        actions: [
          NavigationActions.navigate({
            routeName: "EmailAccountLoginScreen",
          }),
        ],
      })
    );
  };
}

// Customizable Area End

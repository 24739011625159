import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../../packages/framework/src/Message";
import React from 'react';
import { GridSize } from "@material-ui/core";
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import Papa from 'papaparse'
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area Start
import { debounce } from "lodash"
// Customizable Area End

export const configJSON = require("./config.js");

interface BulkUploadAttributes {
    title: string;
    status: string;
    message: string;
    total_rows: number;
    filename: string;
    report_file: string;
    created_count: number;
    account_id: number;
    created_at: string;
}

interface BulkUploadData {
    id: string;
    type: string;
    attributes: BulkUploadAttributes;
}

interface IOrganization {
    id: string;
    type: string;
    attributes: {
        name: string;
        status: string;
    };
}

export type IOrganizationAcc = IOrganization & { attributes: { users_count: number } };

interface Meta {
    total_users: number;
    total_pages: number;
}

interface User {
    id: string;
    type: string;
    attributes: UserAttributes;
}

interface UserAttributes {
    activated: boolean;
    country_code: string;
    email: string;
    full_name: string;
    full_phone_number: string;
    phone_number: string;
    profile_image: string;
    created_at: string;
    title: string;
    organization: Organization;
    updated_at: string;
    device_id: string | null;
    role: Role;
}

interface Organization {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
}

interface Role {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface Props {
    navigation: any;
    // Customizable Area Start

    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    openModal: boolean;
    modalType: string;
    csvFile: File | null;
    phonePrefix: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    role: string;
    title: string;
    organization: string;
    canCreateTask: boolean;
    countryCodeList: { label: string }[];
    successUpload: boolean;
    failedUpload: boolean;
    uploading: boolean;
    currentView: string;
    anchorEl: null | HTMLElement;
    expanded: string | false;
    organizationList: Array<{ label: string, value: string }>;
    fullNameError: string;
    emailError: string;
    titleError: string;
    organizationError: string;
    phoneNumberError: string;
    showCommonMessage: boolean;
    message: string;
    messageType: "error" | "success" | "default",
    messageTimeOutId: NodeJS.Timeout | null;
    isActivated: boolean;
    groupByParameter: string;
    groupByMenu: boolean;
    userId: number | null;
    isEditMode: boolean;
    userList: User[];
    totalUsers: number;
    organizationWiseInfo: IOrganizationAcc[];
    isLoading: boolean;
    isSelectedUserActive: boolean;
    usersPage: number;
    organizationListPage: number;
    showLoader: boolean;
    openedOrganizationId: string | null;
    lastUpdatedUser: User | null;
    csvFormatError: boolean;
    organizationTotalPages: number;
    usersTotalPage: number;
    gridContentedLoaded: boolean;
    gridUserGroupByContentLoaded: boolean;
    csvFileData: string;
    reportLink: string;
    autoHideDuration: number;
    usersInGroupPage: number;
    userInGroupTotalPage: number;
    labelGroupBy: string;
    searchQuery: string;
    // Customizable Area End
}
interface SS { }

export default class AllUsersController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    gridComponentRef: React.RefObject<HTMLInputElement> = React.createRef();
    fileUploadRef: React.RefObject<HTMLInputElement> = React.createRef();
    getOrganizationListApiCallId: string = "";
    updateUserApiCallId: string = "";
    disableUserApiCallId: string = "";
    getUserApiCallId: string = "";
    addUserApiCallId: string = "";
    getOrganizationDetailsApiCallId: string = "";
    getUsersByOrganizationApiCallId: string = "";
    searchUsersApiCallId: string = "";
    getAllUsersApiCallId: string = "";
    downloadReportApiCallId: string = "";
    bulkCreateUserApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            openModal: false,
            modalType: 'bulkInvite',
            csvFile: null,
            fullName: "",
            phonePrefix: '+91',
            email: "",
            phoneNumber: '',
            role: '',
            title: '',
            organization: '',
            canCreateTask: true,
            countryCodeList: [],
            uploading: false,
            successUpload: false,
            failedUpload: false,
            currentView: 'list',
            anchorEl: null,
            expanded: false,
            organizationList: [],
            fullNameError: '',
            emailError: '',
            titleError: '',
            organizationError: '',
            phoneNumberError: '',
            showCommonMessage: false,
            message: '',
            messageType: 'default',
            messageTimeOutId: null,
            isActivated: false,
            groupByParameter: 'none',
            labelGroupBy: 'All',
            groupByMenu: false,
            userId: null,
            isEditMode: false,
            userList: [],
            totalUsers: 0,
            organizationWiseInfo: [],
            isLoading: false,
            isSelectedUserActive: false,
            usersPage: 1,
            showLoader: false,
            openedOrganizationId: null,
            lastUpdatedUser: null,
            csvFormatError: false,
            organizationTotalPages: 1,
            organizationListPage: 1,
            usersTotalPage: 1,
            gridContentedLoaded: false,
            gridUserGroupByContentLoaded: false,
            csvFileData: '',
            reportLink: '',
            autoHideDuration: 4000,
            usersInGroupPage: 1,
            userInGroupTotalPage: 1,
            searchQuery: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const currentSelectedView = await getStorageData('currentView');
        const groupby = await getStorageData("groupByParameter");
        const encryptedRole = await getStorageData(configJSON.Role);
        const role = this.DeCrypt(configJSON.Secretkey, encryptedRole);
        if (currentSelectedView) {
            this.setState({ currentView: currentSelectedView })
        } else {
            this.setCurrentView('list')
        }
        if (groupby === 'organization') {
            this.getOrganizationDetails();
            this.setState({ groupByParameter: groupby, labelGroupBy: 'Organization' })
        } else {
            this.setState({ groupByParameter: 'none', labelGroupBy: 'All' })
            this.getUsersList();
        }
        const codeList = configJSON.phoneNumberCodes.map((element: string) => ({ label: element, value: element }))
        this.getOrganizationList();
        this.setState({ countryCodeList: codeList, role: role })
        // Customizable Area End
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (!this.state.gridContentedLoaded && this.state.userList.length > 0 && this.state.currentView === 'grid' && this.state.groupByParameter === 'none') {
            this.handleLoadContentInGridView()
        }
    }

    async componentWillUnmount(): Promise<void> {
        const { messageTimeOutId } = this.state
        if (messageTimeOutId) {
            clearTimeout(messageTimeOutId);
        }
    }

    DeCrypt = (salt: string, encoded: string): string => {
        const textToChar = (text: string): number[] => text.split("").map((element) => element.charCodeAt(0));
        const applySaltToChar = (code: number): number => textToChar(salt).reduce((first, second) => first ^ second, code);
        return encoded
            .match(/.{1,2}/g)
            ?.map((hexa) => parseInt(hexa, 16))
            ?.map(applySaltToChar)
            ?.map((charCode) => String.fromCharCode(charCode))
            .join("") ?? "";
    };

    handleLoadContentInAccordionGridView = () => {
        if (!this.state.gridUserGroupByContentLoaded && this.state.usersInGroupPage !== this.state.userInGroupTotalPage && this.state.userInGroupTotalPage > 1 && this.state.groupByParameter === "organization") {
            this.setState((prevState) => {
                return { isLoading: true, usersInGroupPage: prevState.usersInGroupPage + 1, gridUserGroupByContentLoaded: true }
            }, () => this.getUsersByOrganization(this.state.openedOrganizationId as string))
        }
    }

    handleLoadContentInGridView = () => {
        const { scrollHeight, clientHeight } = document.documentElement;
        if (scrollHeight <= clientHeight && this.state.usersPage !== this.state.usersTotalPage && this.state.groupByParameter === "none") {
            this.setState((prevState) => {
                return { isLoading: true, usersPage: prevState.usersPage + 1, gridContentedLoaded: true }
            }, () => { this.getUsersList(); });
        }
    }

    handleScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
        const { isLoading } = this.state;
        if (isLoading) return;
        const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
        if ((scrollTop + offsetHeight >= scrollHeight - 100) && this.state.usersPage !== this.state.usersTotalPage && this.state.groupByParameter === "none") {
            this.setState((prevState) => {
                return { isLoading: true, usersPage: prevState.usersPage + 1 }
            }, () => { this.getUsersList(); });
        }

        if (scrollTop + offsetHeight >= scrollHeight - 100 && this.state.groupByParameter === 'organization' && this.state.organizationListPage !== this.state.organizationTotalPages) {
            this.setState((prevState) => {
                return { isLoading: true, organizationListPage: prevState.organizationListPage + 1 }
            }, () => { this.getOrganizationDetails() });
        }
    };

    handleLoadOrganizationWiseUser: React.UIEventHandler<HTMLDivElement> = (event) => {
        if (this.state.isLoading) {
            return;
        }
        const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
        if (scrollTop + offsetHeight >= scrollHeight - 100 && this.state.usersInGroupPage !== this.state.usersTotalPage) {
            this.setState((prevState) => {
                return { isLoading: true, usersInGroupPage: prevState.usersInGroupPage + 1 }
            }, () => this.getUsersByOrganization(this.state.openedOrganizationId as string))
        }
    };

    shouldGroupByVisible = () => {
        return this.state.userList?.length > 0 || this.state.organizationWiseInfo?.length > 0
    }

    handleOpenModal = (type: string) => {
        this.setState({ openModal: true, modalType: type })
    }

    handleBulkInviteModal = () => {
        this.handleOpenModal(configJSON.BulkInvite);
    }

    handleOpenAddUser = () => {
        this.handleOpenModal(configJSON.AddUser);
    }

    refetchDataOnModalClose = () => {
        this.state.groupByParameter === 'organization' ? this.getOrganizationDetails() : this.getUsersList()
    }

    handleCloseModal = () => {
        this.setState({ openModal: false, reportLink: '' }, () => { this.clearAllFormFields(); this.setState({ organizationWiseInfo: [], userList: [] }, () => this.refetchDataOnModalClose()) });
    }

    handleFileChange = () => {
        this.setState({ csvFormatError: false })
        const file = this.fileUploadRef.current?.files?.[0] ?? null;
        if (file && file.size > 2 * 1024 * 1024) {
            this.setState({ showCommonMessage: true, openModal: false, csvFile: null, autoHideDuration: 6000, csvFormatError: true, message: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 2 MB.', messageType: configJSON.MessageTypes.Error })
            return;
        }
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            this.setState({ csvFileData: event.target?.result as string, csvFile: file });
        };
        reader.readAsText(file as Blob);
    };

    parseCsvData = (csvData: string) => {
        let rowCount = 0;
        let isFileEmpty = true;
        let isErrorOccured = false;
        let fileHasMoreThan500Rows = false;
        const config: Papa.ParseConfig = {
            header: true,
            step: (_results, parser) => {
                rowCount++;
                if (rowCount > 501) {
                    fileHasMoreThan500Rows = true;
                    this.setState({ showCommonMessage: true, csvFile: null, autoHideDuration: 6000, openModal: false, csvFormatError: true, message: 'Maximum upto 500 records are allowed to upload using a CSV file.', messageType: configJSON.MessageTypes.Error })
                    parser.abort();
                    isErrorOccured = true;
                }
                isFileEmpty = false;
            },
            complete: () => {
                if (isFileEmpty) {
                    this.setState({ showCommonMessage: true, csvFile: null, autoHideDuration: 6000, openModal: false, csvFormatError: true, message: 'The uploaded file is empty. Please upload a file containing the required Participant data.', messageType: configJSON.MessageTypes.Error })
                    isErrorOccured = true;
                }
                if (!(fileHasMoreThan500Rows || isFileEmpty)) {
                    const parsedData = Papa.parse(csvData, { header: true });
                    isErrorOccured = this.validateCsvColumns(parsedData.meta.fields);
                }
            }
        };
        Papa.parse(csvData, config);
        return isErrorOccured;
    };

    validateCsvColumns = (fields: string[] | undefined) => {
        const columnNames = fields!;
        const intendedColumnNames = ["Full Name*", "Email Address*", "Phone Number with Country Code*", "Title", "Organization Name*"];
        const missingColumns = intendedColumnNames.filter(
            (columnName) => !columnNames.includes(columnName)
        );
        if (missingColumns.length) {
            this.setState({ showCommonMessage: true, autoHideDuration: 6000, openModal: false, csvFile: null, csvFormatError: true, message: 'In the selected file, certain columns are either missing or do not match the column names provided in the sample CSV file.', messageType: configJSON.MessageTypes.Error })
            return true;
        }
        return false;
    }

    handleBulkUpload = () => {
        if (!this.parseCsvData(this.state.csvFileData)) {
            this.setState({ modalType: configJSON.UploadProgress, uploading: true });
            this.bulkCreateUsers();
        }
    }

    handleClickUpload = () => {
        this.fileUploadRef.current?.click()
    }

    handleSelectPhoneInputList = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ phonePrefix: event.target.value as string, phoneNumberError: '' })
    }

    handleChangeCanCreate = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ canCreateTask: event.target.checked })
    }

    onChangeFullName = (text: string) => {
        if (text === '' || configJSON.FullNameRegex.test(text)) {
            this.setState({ fullName: text, fullNameError: '' })
        }
    }

    onChangeEmail = (text: string) => {
        this.setState({ email: text, emailError: '' })
    }

    onChangePhoneNumber = (text: string) => {
        this.setState({ phoneNumber: text.replace(configJSON.DigitRegex, ''), phoneNumberError: '' })
    }

    onChangeTitle = (text: string) => {
        this.setState({ title: text, titleError: '' })
    }

    onChangeOrgnization = (text: unknown) => {
        this.setState({ organization: text as string, organizationError: '' })
    }


    setCurrentView = async (view: string) => {
        await setStorageData('currentView', view)
        this.setState({ currentView: view })
    }

    handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget, groupByMenu: false, userId: Number(event.currentTarget.id), isSelectedUserActive: event.currentTarget.dataset.status === 'true' })
    }

    handleOpenGroupByMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget, groupByMenu: true })
    }

    handleCloseUserMenu = () => {
        this.setState({ anchorEl: null })
    }

    handleAccordionChange = (panel: string, organizationId: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({ expanded: isExpanded ? panel : false, openedOrganizationId: organizationId, userInGroupTotalPage: 1, usersInGroupPage: 1, userList: [] }, () => this.getUsersByOrganization(organizationId));
    };

    clearAllFormFields = () => {
        this.setState({ csvFile: null, csvFormatError: false, isEditMode: false, userId: null, fullName: '', fullNameError: '', email: '', emailError: '', phoneNumber: '', phoneNumberError: '', phonePrefix: '+91', title: '', titleError: '', organization: '', organizationError: '' })
    }

    resetMessage = () => {
        this.setState({ showCommonMessage: false, message: '', autoHideDuration: 4000, messageType: "default" })
    }

    handleCancelModal = () => {
        this.setState({ openModal: false }, () => this.clearAllFormFields())
    }

    handleUserLogout = async () => {
        this.setState({ isLoading: false })
        await removeStorageData('token');
        this.goToBlock('EmailAccountLoginBlock')
    }

    handleCloseCommonMessage = () => {
        this.resetMessage();
    }

    handleGoToReports = () => {
        this.goToBlock('Reports')
    }
    handleSearch = debounce((query: string) => {
        this.setState({ searchQuery: query })
        if (this.state.searchQuery) {
            this.getUsersBySearch()
        } else {
            this.getUsersList()
        }
    }, 500)
    formatForPad = (number: string) => {
        if (number === '0') {
            return '0 User'
        } else if (number === '1') {
            return `${number.padStart(2, '0')} User`
        }
        return `${number.padStart(2, '0')} Users`;
    }

    capitalizeString = (string: string) => {
        return (string).charAt(0).toUpperCase() + string.slice(1)
    }

    goToBlock = (blockName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    handleGroupByMenuParameter = (groupBy: string) => {
        this.setState({ groupByParameter: groupBy, anchorEl: null, gridContentedLoaded: false }, async () => await setStorageData("groupByParameter", groupBy))
    }

    handleGroupByNone = () => {
        this.handleGroupByMenuParameter('none');
        this.setState({ labelGroupBy: 'All' })
        this.getUsersList();
    }

    handleGroupByOrganization = () => {
        this.setState({ expanded: false, organizationListPage: 1, organizationWiseInfo: [] })
        this.handleGroupByMenuParameter('organization');
        this.setState({ labelGroupBy: 'Organization' })
        this.getOrganizationDetails()
    }

    startLoader = () => {
        this.setState({ isLoading: true })
    }

    getUIBreakPoints = () => {
        const isListView = this.state.currentView === configJSON.Views.List;
        const currenViewBreakPoint: GridSize = isListView ? 12 : 4;
        const lgXlBreakPoints: GridSize = isListView ? 12 : 3;
        const mdBreakPoint: GridSize = isListView ? 12 : 6;
        return {
            currenViewBreakPoint,
            mdBreakPoint,
            lgXlBreakPoints
        }
    }

    setPMRole = () => {
        this.setState({ role: "project_manager" })
    }

    handleDownloadDummyCSV = async () => {
        const token = await getStorageData(configJSON.Token);
        window.open(baseURL + '/' + configJSON.downloadDummyCSVEndPoint + `?token=${token}`, '_blank')
    }

    getOrganizationList = async () => {
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token),
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOrganizationListApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.organizationListEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getUsersList = async () => {
        this.startLoader();
        const header = {
            token: await getStorageData(configJSON.Token),
            "Content-Type": configJSON.urlHeaderTypeJSON,
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getAllUsersApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.listUsersEndPoint}?query=nope&page=${this.state.usersPage}&per_page=10`);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    getOrganizationDetails = async () => {
        this.setState({ isLoading: true })
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token),
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getOrganizationDetailsApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.listUsersEndPoint}?query=organization&page=${this.state.organizationListPage}&per_page=10`);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    getUsersByOrganization = async (organizationId: string) => {
        this.startLoader();
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token),
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUsersByOrganizationApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.baseUserEndPoint}/${organizationId}/${configJSON.listUsersByOrg}?page=${this.state.usersInGroupPage}`);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    getUsersBySearch = async () => {
        this.startLoader();
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token)
        }
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.searchUsersApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.searchEndPoint}?search_type=users&query=${this.state.searchQuery}`);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }
    bulkCreateUsers = async () => {
        this.startLoader();
        const header = {
            token: await getStorageData(configJSON.Token)
        };
        const formData = new FormData();
        formData.append("file", this.state.csvFile as Blob);
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.bulkCreateUserApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.BulkUploadEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodPost);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    downloadReport = async () => {
        this.startLoader();
        window.open(this.state.reportLink, '_blank');
    }

    removeSubstring(mainString: string, subString: string) {
        return mainString.replace(subString, '');
    }

    isEmpty = (entity: string) => {
        return entity.trim() === ''
    }

    getEmptyErrorMsg = (fieldName: string) => {
        return `${fieldName} is required!`
    }

    handleFormValidation = () => {
        let error = false;

        if (this.isEmpty(this.state.fullName)) {
            error = true;
            this.setState({ fullNameError: this.getEmptyErrorMsg(configJSON.FullName) });
        }

        if (this.isEmpty(this.state.email)) {
            error = true;
            this.setState({ emailError: this.getEmptyErrorMsg(configJSON.Email) });
        } else if (!configJSON.IdealEmailRegex.test(this.state.email)) {
            error = true;
            this.setState({ emailError: configJSON.EnteredEmailInvalid });
        }

        if (this.isEmpty(this.state.phoneNumber)) {
            error = true;
            this.setState({ phoneNumberError: this.getEmptyErrorMsg(configJSON.PhoneNumber) });
        } else if (this.state.phoneNumber.length < 4) {
            error = true
            this.setState({ phoneNumberError: configJSON.errorMsgPhoneNumber })
        }

        if (this.isEmpty(this.state.organization)) {
            error = true;
            this.setState({ organizationError: this.getEmptyErrorMsg(`${configJSON.Organization} Name`) });
        }
        return error;
    }

    getUser = async () => {
        this.setState({ anchorEl: null, isEditMode: true, showLoader: true })
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token),
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getUserApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.baseUserEndPoint}/${this.state.userId}`)
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodGet);
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    addUser = async () => {
        if (this.handleFormValidation()) {
            return
        }
        this.setState({ showLoader: true })
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token),
        };

        const payloadData = {
            full_name: this.state.fullName.trim().replace(configJSON.StringRegex, ' '),
            title: this.state.title.trim().replace(configJSON.StringRegex, ' '),
            email: this.state.email.toLowerCase(),
            full_phone_number: this.state.phonePrefix.substring(1).concat(this.state.phoneNumber),
            organization_id: this.state.organization,
        };

        const attrs = {
            "type": "email_account",
            attributes: payloadData
        }

        const httpBody = {
            data: attrs
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.addUserApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.baseUserEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodPost);
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    disableUser = async () => {
        this.setState({ anchorEl: null, showLoader: true })
        const header = {
            token: await getStorageData(configJSON.Token),
            "Content-Type": configJSON.urlHeaderTypeJSON,
        };
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.disableUserApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.baseUserEndPoint}/${this.state.userId}`)
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodDelete)
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    updateUser = async () => {
        if (this.handleFormValidation()) {
            return;
        }
        this.setState({ showLoader: true });
        const header = {
            "Content-Type": configJSON.urlHeaderTypeJSON,
            token: await getStorageData(configJSON.Token),
        };

        const payloadData = {
            full_phone_number: this.state.phonePrefix.substring(1).concat(this.state.phoneNumber),
            full_name: this.state.fullName.trim().replace(configJSON.StringRegex, ' '),
            title: this.state.title.trim().replace(configJSON.StringRegex, ' '),
            organization_id: this.state.organization,
            email: this.state.email.toLowerCase(),
        };

        const httpBody = {
            data: payloadData,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.updateUserApiCallId = reqMessage.messageId
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.baseUserEndPoint}/${this.state.userId}/${configJSON.updateUserEndPoint}`);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.MethodPut);
        runEngine.sendMessage(reqMessage.id, reqMessage)
    }

    handleNonePageData = (type: string, user?: User) => {
        switch (type) {
            case "update":
                if (user) {
                    const updatedArray = this.state.userList.map(userItem => {
                        if (userItem.id === user.id) {
                            return user;
                        }
                        return userItem;
                    });
                    this.setState({ userList: updatedArray })
                }
                break;
            case "disable":
                const updatedAfterDeleteArray = this.state.userList.filter(user => {
                    if (Number(user.id) !== this.state.userId) {
                        return user
                    }
                })
                this.setState({ userList: updatedAfterDeleteArray, totalUsers: this.state.totalUsers - 1 })
                break;
            case "add":
                const arrayAfterAdd = [user, ...this.state.userList] as User[];
                this.setState({ userList: arrayAfterAdd, totalUsers: this.state.totalUsers + 1 });
                break;
        }
    }

    handleFetchListDataAgain = (type: string, user?: User) => {
        if (this.state.groupByParameter === 'none') {
            this.handleNonePageData(type, user)
        } else {
            let totalUserUpdate = 0
            switch (type) {
                case 'add': totalUserUpdate = 1
                    break;
                case 'disable': totalUserUpdate = -1
                    break;
            }
            this.setState({ organizationWiseInfo: [], totalUsers: this.state.totalUsers + totalUserUpdate }, () => { this.getOrganizationDetails(); this.getUsersByOrganization(this.state.openedOrganizationId as string) });
        }
    }

    handleResponse = (apiRequestCallId: string, responseJson: { data: User | IOrganization[] | User[] | IOrganizationAcc[] | BulkUploadData; errors: Array<{ [keyName: string]: string }> | { [keyName: string]: string }; message: string; meta: Meta; }) => {
        if (responseJson.data || responseJson.message) {
            switch (apiRequestCallId) {
                case this.getOrganizationListApiCallId:
                    const organizationList: IOrganization[] = responseJson.data as IOrganization[]
                    const formattedOrganizationList = organizationList.map((element: IOrganization) => ({ label: element.attributes.name, value: element.id }))
                    this.setState({ organizationList: formattedOrganizationList })
                    break;
                case this.getUserApiCallId:
                    const userInfo: User = responseJson.data as User
                    const attributes: UserAttributes = userInfo.attributes
                    let organizations = [...this.state.organizationList]
                    organizations.push({ label: attributes.organization.name, value: `${attributes.organization.id}` })
                    this.setState({
                        openModal: true,
                        fullName: attributes.full_name,
                        modalType: 'editUser',
                        email: attributes.email,
                        phoneNumber: attributes.phone_number,
                        phonePrefix: `+${attributes.country_code}`,
                        title: attributes.title,
                        organization: String(attributes.organization.id),
                        isActivated: attributes.activated,
                        isLoading: false,
                        showLoader: false,
                        organizationList: attributes.organization.status === 'Inactive' ? organizations : this.state.organizationList
                    });
                    break;
                case this.addUserApiCallId:
                    this.handleFetchListDataAgain('add', responseJson.data as User)
                    this.handleCancelModal()
                    this.setState({ showCommonMessage: true, message: configJSON.NewUserAddedSuccessfully, messageType: configJSON.MessageTypes.Success, isLoading: false, showLoader: false })
                    break;
                case this.disableUserApiCallId:
                    this.handleFetchListDataAgain('disable')
                    this.setState({ showCommonMessage: true, messageType: configJSON.MessageTypes.Success, message: responseJson.message, isLoading: false, showLoader: false })
                    break;
                case this.getAllUsersApiCallId:
                    this.handleAllUsersResponse(responseJson as { data: User[]; meta: Meta; })
                    break;
                case this.searchUsersApiCallId:
                    this.handleUserSearchResponse(responseJson as { data: User[]; meta: Meta })
                    break;
                case this.getOrganizationDetailsApiCallId:
                    this.setState((prevState) => { return { organizationWiseInfo: [...prevState.organizationWiseInfo, ...responseJson.data as IOrganizationAcc[]], isLoading: false, showLoader: false, organizationTotalPages: responseJson.meta.total_pages } })
                    break;
                case this.getUsersByOrganizationApiCallId:
                    this.handleUsersByOrganizationResponse(responseJson as { data: User[]; meta: Meta; })
                    break;
                case this.updateUserApiCallId:
                    this.handleFetchListDataAgain('update', responseJson.data as User)
                    this.handleCancelModal()
                    this.setState({ showCommonMessage: true, message: configJSON.UserInformationUpdatedSuccessfully, messageType: configJSON.MessageTypes.Success, isLoading: false, showLoader: false })
                    break;
                case this.bulkCreateUserApiCallId:
                    if (responseJson.message) {
                        this.setState({ openModal: true, modalType: configJSON.ErrorModal })
                        return;
                    }
                    const bulkUploadResponse = responseJson.data as BulkUploadData;
                    const reportFile = bulkUploadResponse.attributes.report_file;
                    this.setState({ uploading: false, modalType: configJSON.SuccessModal, reportLink: reportFile })
                    break;
            }
        } else {
            this.handleError(responseJson.errors)
        }
    }


    handleAllUsersResponse = (responseJson: { data: User[]; meta: Meta; }) => {
        this.setState((prevState) => {
            const mergedArray = [...prevState.userList, ...responseJson.data]
            const uniqueMap = new Map()
            mergedArray.forEach(object => {
                if (!uniqueMap.has(object.id)) {
                    uniqueMap.set(object.id, object)
                }
            });
            const newArray = Array.from(uniqueMap.values())
            return {
                userList: newArray as User[],
                usersTotalPage: responseJson.meta.total_pages,
                isLoading: false,
                totalUsers: responseJson.meta.total_users,
                showLoader: false,
            }
        })
    }
    handleUserSearchResponse = (responseJson: { data: User[]; meta: Meta; }) => {
        this.setState(() => {
            const mergedArray = [...responseJson.data]
            const uniqueMap = new Map()
            mergedArray.forEach(object => {
                if (!uniqueMap.has(object.id)) {
                    uniqueMap.set(object.id, object)
                }
            });
            const newArray = Array.from(uniqueMap.values())
            return {
                userList: newArray as User[],
                usersTotalPage: responseJson.meta.total_pages,
                isLoading: false,
                totalUsers: responseJson.meta.total_users,
                showLoader: false,
            }
        })
    }
    handleUsersByOrganizationResponse = (responseJson: { data: User[]; meta: Meta; }) => {
        this.setState((prevState) => {
            const mergedArray = [...prevState.userList, ...responseJson.data];
            const uniqueMap = new Map();
            mergedArray.forEach(object => {
                if (!uniqueMap.has(object.id)) {
                    uniqueMap.set(object.id, object)
                }
            });
            const newArray = Array.from(uniqueMap.values())
            return { userList: newArray as User[], isLoading: false, showLoader: false, userInGroupTotalPage: responseJson.meta.total_pages };
        }, () => this.handleLoadContentInAccordionGridView())
    }

    handleError = (errors: Array<{ [keyName: string]: string }> | { [keyName: string]: string }) => {
        let errorObj = Array.isArray(errors) ? errors[0] : errors;
        if (!Array.isArray(errors)) {
            errors = [{ ...errors }]
        }

        if (errorObj.token) {
            this.handleUserLogout()
        } else {
            errors.forEach((object) => {
                if (Object.keys(object).includes('phone_number')) {
                    this.setState({ phoneNumberError: configJSON.EnteredPhoneNumberInvalid, isLoading: false, showLoader: false })
                }
                if (Object.keys(object).includes('email')) {
                    this.setState({ emailError: configJSON.EnterEmailIsTaken, isLoading: false, showLoader: false })
                }
            })
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    this.setState({ showCommonMessage: true, message: "Unknown Error Occurred!", messageType: "error", showLoader: false, isLoading: false })
                    return;
                }
                this.handleResponse(apiRequestCallId, responseJson);
            }
        }
    }
    // Customizable Area Start
    // Customizable Area End
}

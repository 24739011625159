Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorPasswordNotValid = "Password not valid.";
exports.errorEmailNotValid = "Email not valid.";
exports.placeHolderPassword = "Password";
exports.placeHolderEmail = "Email";
exports.btnTxtLogin = "LOG IN";
exports.labelHeader = "The reason we require login";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelRememberMe = "Remember Me";
exports.labelTitle = "Log in";
exports.labelOr = "OR";
exports.Login = "Login";
exports.LoginBtnTxt = "Log In";
exports.EnterYourPassword = "Enter your password";
exports.ForgotPassword = "Forgot Password?";
exports.Email = "Email";
exports.EnterYourEmailID = "Enter your Email ID";
exports.WelcomeTxt = "Welcome to the gateway of innovation.";
exports.Password = "Password";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.WelcomeSubtitle = "Please login to access your account";
exports.loginEndPoint = "bx_block_login/logins";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.IdealEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.IdealPasswordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.getApiMethod = "GET";
exports.apiContentType = "application/json";
exports.putApiMethod = "PUT";
exports.postApiMethod = "POST";

exports.deleteApiMethod = "DELETE";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.tasksApiEndPoint = "tasks/tasks";

exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorTitle = "Error";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textName = "Name";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textUpdatedAt = "Updated At";
exports.textCreatedAt = "Created At";
exports.Token = 'token';
exports.textOr = "OR";
exports.taskDueDateFormat = "DD MMM, YYYY";
exports.Role = 'role';
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.GET = "GET";
exports.PATCH = "PATCH";
exports.ProjectManagerRole = "project_manager";
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.tasks_kanban_menu = "tasks_kanban_menu";
exports.customColumnEndPoint = "bx_block_kanbanboard/sections";
exports.tasksEndPoint = "bx_block_tasks/tasks";
exports.ModalDescription = {
  MarkCompleted:"Are you sure want to mark as completed task?",
  RemoveAttachment:"Do you want to remove this attachment?"
};
exports.ModalType = {
  MoveToCompleted:"move-to-completed",
  RemoveAttachment:"remove-attachment"
};
exports.AcceptTypes = ".pdf,.xls,.xlsx,.jpg,.jpeg,.svg,.png"
// Customizable Area End

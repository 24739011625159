// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData.web";
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AllUsers from '../../blocks/social-media-account/src/AllUsers.web'
import DashboardViewWrapper from '../../blocks/dashboard/src/DashboardViewWrapper.web'
import Dashboard from '../../blocks/dashboard/src/Dashboard.web'
import DashboardPhase from '../../blocks/dashboard/src/DashboardPhase.web'
import ProjectPortfolio from '../../blocks/projectportfolio/src/ProjectPortfolio.web'
import ProjectDetails from '../../blocks/projectportfolio/src/ProjectPortfolioDetail.web'
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web';
import KanbanBoard from '../../blocks/kanbanboard/src/KanbanBoard.web';
import TaskList from '../../blocks/tasks/src/TaskList.web';
import Tasks from '../../blocks/tasks/src/Tasks.web'

const routeMap = {
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/settings"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login'
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: "/LanguageOptions"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Dashboard: {
    component: Dashboard,
    path: '/dashboard',
    exact: true
  },
  DashboardPhase: {
    component: DashboardPhase,
    path: '/dashboard/admin/reports/:navigationBarTitleText',
  },
  Users: {
    component: AllUsers,
    path: '/users'
  },
  Reports: {
    component: ImportExportData,
    path: '/dashboard/reports',
  },
  Projects: {
    component: ProjectPortfolio,
    path: '/projects',
    exact: true
  },
  CreateProject: {
    component: ProjectPortfolio,
    path: '/projects/create',
    exact: true
  },
  EditProject: {
    component: ProjectPortfolio,
    path: '/projects/edit/:navigationBarTitleText',
    exact: true
  },
  RenameProject: {
    component: ProjectPortfolio,
    path: '/projects/rename/:navigationBarTitleText',
    exact: true
  },
  DuplicateProject: {
    component: ProjectPortfolio,
    path: '/projects/duplicate/:navigationBarTitleText',
    exact: true
  },
  ProjectDetails: {
    component: ProjectDetails,
    path: '/projects/details/:navigationBarTitleText',
    exact: true
  },
  UserProfile: {
    component: UserProfile,
    path: '/profile'
  },
  TaskProjects: {
    component: ProjectPortfolio,
    path: '/tasks/project-list',
    exact: true
  },
  MyTasks: {
    component: KanbanBoard,
    path: '/tasks/project-list/my/project-tasks/',
    exact: true
  },
  KanbanBoard: {
    component: KanbanBoard,
    path: '/tasks/project-list/all/project-tasks/',
  },
  KanbanBoardWithParams: {
    component: KanbanBoard,
    path: '/tasks/project-list/view/all/project-tasks/:navigationBarTitleText',
    exact: true
  },
  TaskDetailsDuplicate: {
    component: TaskList,
    path: '/tasks/project-list/all/duplicate/:navigationBarTitleText',
    exact: true
  },
  TaskDetailsRename: {
    component: TaskList,
    path: '/tasks/project-list/all/rename/:navigationBarTitleText',
    exact: true
  },
  TaskDetails: {
    component: TaskList,
    path: '/tasks/project-list/all/edit/:navigationBarTitleText',
    exact: true
  },
  SubTaskDetails: {
    component: TaskList,
    path: '/tasks/project-list/all/view/subTask/:navigationBarTitleText',
    exact: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ minHeight: '100vh' }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;